import { createSlice } from "@reduxjs/toolkit";
import {
  checkSubscriptionStatus,
  checkTransactionSubscriptionStatus,
  editCardDetails,
  fetchCardDetails,
  fetchSepaDetails,
  getAllInvoices,
  getAllPaymentMethods,
  getComingInvoice,
  getTransactionComingInvoice,
} from "../actions/stripeActions";
import { ComingInvoiceResponse, InvoiceResponse } from "../../@types";

type CustomerPortalState = {
  invoices: InvoiceResponse | null;
  comingInvoice: ComingInvoiceResponse | null;
  paymentMethods: null | any;
  cardPaymentMethods: any | null;
  sepaPaymentMethods: any | null;
  subscriptionStatus: string | null;
  transactionInvoices: InvoiceResponse | null;
  transactionComingInvoice: ComingInvoiceResponse | null;
  transactionSubscriptionStatus: string | null;
  loading: boolean;
  error: any;
};

// Specify the initialState with the defined type
const initialState: CustomerPortalState = {
  invoices: null,
  comingInvoice: null,
  paymentMethods: null,
  cardPaymentMethods: null,
  sepaPaymentMethods: null,
  subscriptionStatus: null,
  loading: false,
  error: null,
  transactionInvoices: null,
  transactionComingInvoice: null,
  transactionSubscriptionStatus: null,
};
const customerPortalSlice = createSlice({
  name: "customerPortal",
  initialState,
  reducers: {
    clearState: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Altum Subscption
      .addCase(getAllInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload.invoice;
      })
      .addCase(getComingInvoice.fulfilled, (state, action) => {
        state.comingInvoice = action.payload;
      })
      .addCase(getAllPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods = action.payload;
      })
      .addCase(checkSubscriptionStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(checkSubscriptionStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionStatus = action.payload.status;
      })

      // Transaction Subscription
      .addCase(getTransactionComingInvoice.fulfilled, (state, action) => {
        state.transactionComingInvoice = action.payload;
      })
      .addCase(
        checkTransactionSubscriptionStatus.fulfilled,
        (state, action) => {
          state.transactionSubscriptionStatus = action.payload.status;
        },
      )
      .addCase(editCardDetails.fulfilled, (state, action) => {
        // If needed, you can perform additional state updates here
      })

      .addCase(fetchCardDetails.fulfilled, (state, action) => {
        state.cardPaymentMethods = action.payload;
      })
      .addCase(fetchSepaDetails.fulfilled, (state, action) => {
        state.sepaPaymentMethods = action.payload;
      });
  },
});

export default customerPortalSlice.reducer;
