import { useState } from "react";
import { ValidationInput } from "../@types";

interface ValidationRule {
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  regex?: RegExp;
}

interface ErrorMessage {
  minLength?: string;
  maxLength?: string;
  required?: string;
  regex?: string;
}

type ValidationRules = {
  [key in keyof ValidationInput]: ValidationRule;
};

type ErrorMessages = {
  [key in keyof ValidationInput]: ErrorMessage;
};

type ValidationErrors = {
  [key in keyof ValidationInput]: string;
};

const RULES: ValidationRules = {
  email: {
    required: true,
    regex: /^[A-Z0-9._]+@[A-Z0-9.-]+\.[A-Z]{1,4}$/i,
    minLength: 6,
  },
  password: {
    required: true,
    minLength: 8,
  },
  kvk: {
    required: true,
    maxLength: 8,
    regex: /^[0-9]{8}$/i,
  },
  company: {
    required: true,
    minLength: 2,
  },
  vat: {
    required: true,
    minLength: 2,
    regex: /^[N][L][0-9]{9}[B][0-9]{2}$/i,
  },
};

const ERROR_MESSAGES: ErrorMessages = {
  firstName: {
    minLength: "Voornaam moet minimaal 2 tekens lang zijn",
  },

  lastName: {
    minLength: "Achternaam moet minimaal 2 tekens lang zijn",
  },
  name: {
    minLength: "Voornaam moet minimaal 2 tekens lang zijn",
  },
  email: {
    required: "E-mailadres is vereist",
    regex: "E-mailadres is niet geldig",
  },
  password: {
    required: "Een wachtwoord is verplicht",
    minLength: "Wachtwoord moet minimaal 8 tekens lang zijn",
  },
  kvk: {
    required: "kvk is verplicht",
    maxLength: "Het KvK-nummer bestaat uit 8 cijfers.",
    regex: "Het KvK-nummer bestaat uit 8 cijfers.",
  },
  company: {
    required: "kvk is verplicht",
    minLength: "het moet minimaal 2 karakters zijn",
  },
  vat: {
    required: "BTW nummer is verplicht",
    regex:
      "Het BTW nummer is incorrect, volg het volgende format: NL123456789B01",
    minLength: "het moet minimaal 2 karakters zijn",
  },
};

export const useValidation = () => {
  const [errors, setErrors] = useState<ValidationErrors>({});

  const validate = (
    input: ValidationInput,
    customRules?: ValidationRules,
    customErrorMessages?: ErrorMessages,
  ) => {
    const newErrors: ValidationErrors = {};
    if (!input) {
      return newErrors;
    }

    const combinedRules = { ...RULES, ...customRules };
    const combinedErrorMessages = { ...ERROR_MESSAGES, ...customErrorMessages };

    for (const [field, rules] of Object.entries(combinedRules)) {
      const value = input[field];

      if (rules.required && !value) {
        newErrors[field] = "Dit veld is verplicht";
        continue;
      }

      if (value) {
        if (rules.regex && !rules.regex.test(value)) {
          newErrors[field] =
            combinedErrorMessages[field]?.regex || "Ongeldige invoer";
          continue;
        }

        if (rules.minLength && value.length < rules.minLength) {
          newErrors[field] =
            combinedErrorMessages[field]?.minLength ||
            `Minimaal ${rules.minLength} tekens vereist`;
          continue;
        }

        if (rules.maxLength && value.length > rules.maxLength) {
          newErrors[field] =
            combinedErrorMessages[field]?.maxLength ||
            `Maximaal ${rules.maxLength} tekens toegestaan`;
          continue;
        }
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  return { errors, validate };
};
