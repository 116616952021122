import React from "react";

type Props = {
  children: React.ReactNode;
};

const AuthContainer = (props: Props) => {
  return (
    <div className="w-full md:w-1/2 p-5 mt-[50px] gap-2 flex w-full flex-col">
      {props.children}
    </div>
  );
};

export default AuthContainer;
