import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import {
  SolarPanel,
  FloorInsulation,
  WindowGlass,
  WallInsulation,
  HeatPump,
  Shower,
  Ventilation,
} from "../../../../../assets/images/api/sustainability/sustainabilityImages";
import convertToEuFormat from "../../../../../helpers/convertToEuFormat";

interface CompleteOverzichtPageProps {
  data: any;
}

const RecommendationItem: React.FC<{
  icon: string;
  title: string;
  description: string;
  co2Reduction: number;
  monthlySavings: number;
  installationCost: number;
}> = ({
  icon,
  title,
  description,
  co2Reduction,
  monthlySavings,
  installationCost,
}) => (
  <View style={styles.recommendationContainer}>
    <View style={styles.recommendationHeader}>
      <Image src={icon} style={styles.recommendationIcon} />
      <Text style={styles.recommendationTitle}>{title}</Text>
    </View>

    <Text style={styles.recommendationText}>{description}</Text>

    <View style={styles.recommendationPoints}>
      <Text style={styles.recommendationPoint}>
        - Significante verlaging van de elektriciteitskosten
      </Text>
      <Text style={styles.recommendationPoint}>
        - Verlaging van de CO2 emissies met {convertToEuFormat(co2Reduction)}kg
        per jaar
      </Text>
      <Text style={styles.recommendationPoint}>
        - Maandelijkse besparing van €
        {convertToEuFormat(Number(monthlySavings.toFixed(2)) / 12)}
      </Text>
    </View>

    <Text style={styles.installationCost}>
      Installatiekosten: €{convertToEuFormat(installationCost.toFixed(2))}
    </Text>
  </View>
);

const getIconForMeasure = (measure: string) => {
  switch (measure) {
    case "solar_panels":
      return SolarPanel;
    case "floor_insulation":
      return FloorInsulation;
    case "living_room_windows":
      return WindowGlass;
    case "bedroom_windows":
      return WindowGlass;
    case "wall_insulation":
      return WallInsulation;
    case "instalation":
      return HeatPump;
    case "shower":
      return Shower;
    case "ventilation":
      return Ventilation;
    default:
      return "";
  }
};

const CompleteOverzichtPage: React.FC<CompleteOverzichtPageProps> = ({
  data,
}) => {
  const measureNames = [
    "solar_panels",
    "floor_insulation",
    "living_room_windows",
    "bedroom_windows",
    "wall_insulation",
    "instalation",
    "shower",
    "ventilation",
  ];

  const measureTitles: { [key: string]: string } = {
    solar_panels: "Aantal zonnepanelen",
    floor_insulation: "Vloerisolatie",
    living_room_windows: "Glas woonkamer",
    bedroom_windows: "Glas slaapkamers",
    wall_insulation: "Gevelisolatie",
    instalation: "CV-ketel of warmtepomp",
    shower: "Huidige douche WTW",
    ventilation: "Ventilatie",
  };

  const nonZeroMeasures = measureNames.filter(
    (measure) => data.measures[measure]?.investment > 0,
  );

  return (
    <>
      <Page size="A4" style={styles.page}>
        <Text style={styles.pageTitle}>Aanbevelingen</Text>

        <Text style={styles.pageText}>
          Op basis van de huidige situatie zijn hier specifieke maatregelen voor
          een lager energieverbruik en een betere duurzaamheid
        </Text>

        {nonZeroMeasures.slice(0, 2).map((measure) => {
          const measureData = data.measures[measure];
          const icon = getIconForMeasure(measure); // Use the reusable function

          return (
            <RecommendationItem
              key={measure}
              icon={icon}
              title={measureTitles[measure]}
              description={`Wij raden aan om ${measureData.after.desc} te plaatsen op uw woning. Hieronder vindt u de voordelen:`}
              co2Reduction={measureData.co2_reduce}
              monthlySavings={measureData.saving}
              installationCost={measureData.investment}
            />
          );
        })}

        <View style={styles.footer}>
          <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
          <Image src={altumLogo} style={styles.footerLogo} />
        </View>
      </Page>

      {nonZeroMeasures.length > 2 && (
        <Page size="A4" style={styles.page}>
          {nonZeroMeasures.slice(2, 5).map((measure) => {
            const measureData = data.measures[measure];
            const icon = getIconForMeasure(measure);

            return (
              <RecommendationItem
                key={measure}
                icon={icon}
                title={measureTitles[measure]}
                description={`Wij raden aan om ${measureData.after.desc} te plaatsen op uw woning. Hieronder vindt u de voordelen:`}
                co2Reduction={measureData.co2_reduce}
                monthlySavings={measureData.saving}
                installationCost={measureData.investment}
              />
            );
          })}

          <View style={styles.footer}>
            <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
            <Image src={altumLogo} style={styles.footerLogo} />
          </View>
        </Page>
      )}

      {nonZeroMeasures.length > 5 && (
        <Page size="A4" style={styles.page}>
          {nonZeroMeasures.slice(5).map((measure) => {
            const measureData = data.measures[measure];
            const icon = getIconForMeasure(measure); // Use the reusable function

            return (
              <RecommendationItem
                key={measure}
                icon={icon}
                title={measureTitles[measure]}
                description={`Wij raden aan om ${measureData.after.desc} te plaatsen op uw woning. Hieronder vindt u de voordelen:`}
                co2Reduction={measureData.co2_reduce}
                monthlySavings={measureData.saving}
                installationCost={measureData.investment}
              />
            );
          })}

          <View style={styles.footer}>
            <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
            <Image src={altumLogo} style={styles.footerLogo} />
          </View>
        </Page>
      )}
    </>
  );
};

export default CompleteOverzichtPage;
