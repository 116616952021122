import createApiSlice from "../../helpers/createApiSlice";
import {
  energyInsightOneTimeUse,
  postEnergyInsight,
} from "../actions/energyInsightActions";

const energyInsight = createApiSlice(
  "energyInsight",
  postEnergyInsight,
  energyInsightOneTimeUse,
);

export const { clearResults, modifyQueries } = energyInsight.actions;

export default energyInsight.reducer;
