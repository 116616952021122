import React, { FC } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AsyncThunk } from "@reduxjs/toolkit";
import { FaCreditCard } from "react-icons/fa6";
import Text from "../../../../components/Text";
import { useAppDispatch } from "../../../../redux/hooks";

interface PaymentMethodData {
  id: string;
  card: {
    brand: string;
    last4: string;
    exp_month: number;
    exp_year: number;
  };
}

interface PaymentMethod {
  data: PaymentMethodData[];
}

interface DefaultPaymentMethod {
  payment_method_id: string;
}

interface CreditCardProps {
  paymentMethod: PaymentMethod;
  handleUpdatePaymentModal: () => void;
  setSepaActive: (active: boolean) => void;
  setCardActive: (active: boolean) => void;
  defaultPaymentMethod: DefaultPaymentMethod;
  fetchCardDetails: AsyncThunk<unknown, void, any>;
  getAllPaymentMethods: AsyncThunk<unknown, void, any>;
}

const CreditCard: FC<CreditCardProps> = ({
  paymentMethod,
  handleUpdatePaymentModal,
  setSepaActive,
  setCardActive,
  fetchCardDetails,
  defaultPaymentMethod,
  getAllPaymentMethods,
}) => {
  const handleCardUpdate = () => {
    setSepaActive(false);
    setCardActive(true);
    handleUpdatePaymentModal();
  };
  const dispatch = useAppDispatch();
  const handleRemove = async (paymentMethodId: string) => {
    try {
      await axios.post("/api/v1/mopsus/remove-payment-details", {
        paymentMethodId,
      });
      dispatch(fetchCardDetails());
      dispatch(getAllPaymentMethods());
      toast.success("Betalingsmethode succesvol verwijderd");
    } catch (err: any) {
      toast.error(err.response?.data.message || "An error occurred");
    }
  };

  const setDefaultPaymentMethod = async (paymentMethodId: string) => {
    try {
      const body = {
        paymentMethodId,
      };
      const res = await axios.post<any>(
        "/api/v1/mopsus/set-default-payment-method",
        body,
      );
      dispatch(fetchCardDetails());
      dispatch(getAllPaymentMethods());
      toast.success(res.data.msg);
    } catch (err: any) {
      toast.error(err.response?.data.message);
    }
  };

  const getCardIcon = (brand: string) => {
    switch (brand.toLowerCase()) {
      case "mastercard":
        return (
          <img
            src="https://img.icons8.com/color/30/000000/mastercard-logo.png"
            alt="image1"
          />
        );
      case "visa":
        return (
          <img
            src="https://img.icons8.com/color/30/000000/visa.png"
            alt="image2"
          />
        );

      default:
        return <FaCreditCard size="30" />;
    }
  };

  return (
    <div className="w-full mx-auto">
      {paymentMethod.data?.map((pm) => (
        <div
          key={pm.id}
          className="flex flex-col md:flex-row items-center p-2 rounded-md gap-3 md:justify-between py-4 border border-gray-200 mt-4"
        >
          <div className="flex items-center space-x-4 self-start">
            {getCardIcon(pm.card.brand)}
            <div>
              <Text>•••• •••• •••• {pm.card.last4}</Text>
              <Text className="text-sm text-gray-500">
                Expires {pm.card.exp_month.toString().padStart(2, "0")}/
                {pm.card.exp_year.toString().slice(-2)}
              </Text>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            {defaultPaymentMethod.payment_method_id === pm.id ? (
              <span className="text-sm text-primary font-medium">
                Primaire betaalmethode
              </span>
            ) : (
              <button
                onClick={() => setDefaultPaymentMethod(pm.id)}
                className="text-sm text-blue-600 hover:text-blue-800 font-medium"
              >
                Maak primaire betaalmethode
              </button>
            )}
            <button
              onClick={() => handleRemove(pm.id)}
              className="text-red-500 hover:text-red-700 text-sm font-medium"
            >
              Verwijderen
            </button>
            <button
              onClick={handleCardUpdate}
              className="text-blue-500 hover:text-blue-700 text-sm font-medium"
            >
              Wijzigen gegevens
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CreditCard;
