const translateToDutch = (name: string): string => {
  const translations: Record<string, string> = {
    indoor: 'Binnen',
    outdoor: 'Buiten',
    living_room: 'Huiskamer',
    kitchen: 'Keuken',
    bathroom: 'Badkamer',
    bedroom: 'Slaapkamer',
    other_room: 'Andere kamers',
    other: 'Overig',
    hall_corridor: 'Hal gang',
    room: 'Kamer',
    scullery: 'Bijkeuken',
    facade: 'Facade',
    view: 'Weergave',
    street: 'Straat',
    building_part: 'Gebouw deel',
    no_building: 'Geen gebouw',
    'balcony-garden': 'Balkon Tuin',
  };

  return translations[name] ?? 'Ander';
};
export default translateToDutch;
