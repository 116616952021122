import React, { FC, ReactNode } from "react";
import Logo from "../../../components/Logo";
import Title from "../../../components/TItle";
import Text from "../../../components/Text";

type Props = {
  title: string;
  description: string;
  children?: ReactNode;
};

const AuthHeader: FC<Props> = ({ title, description, children }) => {
  return (
    <div className="flex flex-col max-w-[424px] text-center mx-auto mb-4 w-[340px]">
      <Logo className="w-[240px] mx-auto mb-[24px]" />
      <Title>{title}</Title>
      <Text className="text-gray-light">
        {description} {children}
      </Text>
    </div>
  );
};

export default AuthHeader;
