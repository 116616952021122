import React, {
  useState,
  useEffect,
  FC,
  ChangeEvent,
  FormEventHandler,
  FormEvent,
  ChangeEventHandler,
} from "react";
import styled from "styled-components";
import { Button } from "../styles/styled";
import { FormField, ValidationInput } from "../@types";
import { useValidation } from "../hooks/useValidator";
import { Colors } from "../styles/Theme";
import { FaCheck } from "react-icons/fa6";

interface FormProps {
  fields: FormField[];
  initInput: any;
  handleSubmit: (formData: any) => void;
  cta: string;
}

export const Form: FC<FormProps> = ({
  fields,
  initInput,
  handleSubmit,
  cta,
}) => {
  const [submitState, setSetSubmitState] = useState({
    submitting: false,
    submitted: false,
  });

  const [userInput, setUserInput] = useState({});
  const { errors } = useValidation();
  const resetState = () =>
    setSetSubmitState({ submitting: false, submitted: false });

  const { submitting, submitted } = submitState;
  const isDone = !submitting && submitted;

  useEffect(() => {
    setTimeout(resetState, 700);
  }, [isDone]);

  useEffect(() => {
    if (!submitting || submitted) return;
    if (Object.keys(userInput).length !== fields.length) return;
    if (!errors) {
      setSetSubmitState({ submitting: true, submitted: true });
    } else {
      setSetSubmitState({ submitting: false, submitted: false });
    }
  }, [errors, userInput, fields, submitting, submitted]);

  const submit: FormEventHandler = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSetSubmitState({ submitting: true, submitted: false });

    if (Object.keys(userInput).length !== Object.keys(initInput).length) {
      return setUserInput({
        ...initInput,
        ...userInput,
      });
    }

    if (Object.keys(errors).length > 0) {
      return;
    }
    handleSubmit(userInput);
    setSetSubmitState({ submitting: false, submitted: true });
  };

  const handleChange: ChangeEventHandler = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setUserInput({
      ...userInput,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <FormStyle
      onSubmit={submit}
      className={`${submitting ? "submitting" : ""} flex flex-col px-4 md:px-8`}
    >
      {!isDone &&
        fields.map(({ label: placeholder, type, name }) => (
          <>
            <input
              className="h-10 w-full rounded-md"
              onChange={handleChange}
              {...{ placeholder, type, name }}
              style={{
                border: `1px solid ${
                  errors && errors[name as keyof ValidationInput]
                    ? "red"
                    : "gray"
                }`,
              }}
            />
            {errors &&
              !submitting &&
              Object.keys(errors).map(
                (field) =>
                  field === name && (
                    <label
                      key={field}
                      style={{
                        color: "red",
                        overflowWrap: "break-word",
                        inlineSize: "364px",
                      }}
                    >
                      {errors[field]}
                    </label>
                  ),
              )}
          </>
        ))}

      <button
        className={`${
          Object.keys(errors).length > 0 ? "btn-disabled" : ""
        } self-end`}
        disabled={Object.keys(errors).length > 0}
      >
        <FaCheck className="text-primary" />
      </button>
    </FormStyle>
  );
};

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 10px;
  align-items: center;

  .-submitting {
    opacity: 0.5;
  }

  .name {
    display: flex;
  }
`;
