import { ChangeEventHandler, FC } from "react";
import styled from "styled-components";
import { Colors } from "../styles/Theme";

const InputWrapper = styled.label`
  position: relative;
`;

const Input = styled.input`
  display: none;

  &:checked + span {
    background-color: #0888ff;

    &:before {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }

  &:focus + span {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
  &:focus:checked + span {
    box-shadow: 0 0 0 2px ${Colors.main.lightGreen};
  }
`;

const Slider = styled.span<{ checked: boolean | undefined }>`
  display: flex;
  cursor: pointer;
  width: 30px;
  height: 16px;
  border-radius: 100px;
  background-color: white;
  border: 2px solid #0888ff;
  position: relative;
  transition: background-color 0.2s, box-shadow 0.2s;

  &:before {
    content: "";
    position: absolute;
    top: 1.7px;
    left: 2px;
    right: 2px;
    width: 9px;
    height: 9px;
    border-radius: 45px;
    transition: 0.1s;
    background: ${({ checked }) => (checked ? "#fff" : "#0888ff")};
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  }

  &:active:before {
    width: 14px;
  }
`;
interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  check?: boolean;
}
const Switch: FC<Props> = ({ onChange, check }) => {
  return (
    <InputWrapper>
      <Input type="checkbox" onChange={onChange} checked={check} />
      <Slider checked={check} />
    </InputWrapper>
  );
};

export default Switch;
