import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { Redirect, useHistory } from "react-router-dom";
import RedoButton from "../../components/RedoButtons";
import {
  clearWWSResults,
  modifyWWSQueries,
} from "../../../../../redux/actions/wwsActions";
import WWWResultDetails, {
  WWWResultInterface,
} from "../components/WWWResultDetails";
import DashboardContainer from "../../../../Dashboard/components/Container";
import Header from "../../../../Dashboard/components/Header";
import ProgressSection from "../../components/ProgressSection";
import { Ecovalue } from "../../../../../assets/images/api/APIimages";
import { progress } from "../data";
import Loading from "../../../../../components/Loading";

const WWWResult = () => {
  const { result, loading } = useAppSelector((state) => state.wws);
  const resultCopy = result as WWWResultInterface;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const clearResults = () => {
    dispatch(clearWWSResults());
    history.push("/wws");
  };

  const modifyResults = () => {
    dispatch(modifyWWSQueries());
    history.push("/wws");
  };

  if (Object.keys(result).length === 0 && !loading) {
    return <Redirect to={{ pathname: "/wws" }} />;
  }

  return (
    <DashboardContainer pageTitle="WWS-punten Resultaat - Altum AI">
      <Header
        title="WWS-punten Resultaat"
        subtitle="Bekijk de berekende WWS-punten voor de woning"
      />
      <div className="flex flex-col-reverse gap-1 lg:gap-8 lg:flex-row max-w-[1100px] pt-4 md:pt-4 relative">
        <div className="flex flex-col gap-8 w-full lg:w-3/4">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <Loading />
            </div>
          ) : (
            <>
              <WWWResultDetails result={resultCopy} />
              <RedoButton modify={modifyResults} clear={clearResults} />
            </>
          )}
        </div>
        <ProgressSection page={6} image={Ecovalue} progress={progress} />
      </div>
    </DashboardContainer>
  );
};

export default WWWResult;
