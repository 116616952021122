import React from "react";
import Account from "./Account";
import { useTabContext } from "../../../../components/Tab";
import ApiKey from "./ApiKey";

type Props = {};

const AllTabs = (props: Props) => {
  const { activeIndex } = useTabContext();
  return activeIndex === 0 ? (
    <Account />
  ) : activeIndex === 1 ? (
    <ApiKey />
  ) : null;
};

export default AllTabs;
