import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import ImageLabelForm from "./ImageLabelForm";
import Loading from "../../Loading";
const ImageLabel = () => {
  const { result, loading } = useAppSelector((state) => state.imageLabel);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/foto-labelen-result",
          }}
        />
      ) : (
        <ImageLabelForm />
      )}
    </>
  );
};

export default ImageLabel;
