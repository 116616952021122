import styled from "styled-components"
import {PhoneCall} from "react-basicons"
import {P, Img} from "../styles/styled"
import altumai from "../assets/images/altumai-svg.svg"
import ISAE from "../assets/images/ISAE3000.png"
import useWindowSize from "../hooks/useWindowSize"
import {Colors, device, TextStyles} from "../styles/Theme"

export function Footer() {
	const Toepassingen = [
		{
			title: "Woningwaarde model",
			path: "https://altum.ai/automated-valuation-model/",
		},
		{title: "Verduurzamen", path: "https://altum.ai/verduurzaming-data/"},
		{
			title: "Portfolio waardering",
			path: "https://altum.ai/portefeuille-waardering/",
		},
		{title: "Vastgoeddata", path: "https://altum.ai/vastgoeddata/"},
	]
	const AltumAi = [
		{title: "Over Altum AI", path: "https://altum.ai/over-altum-ai/"},
		{title: "Nieuws", path: "https://altum.ai/nieuws/"},
		{title: "Documentatie", path: "https://docs.altum.ai/"},
		{title: "Uptime monitor", path: "https://status.altum.ai/"},
		{title: "Sandbox development", path: "https://sandbox.altum.ai/"},
	]
	const Vlog = [
		{
			title: "LinkedIn",
			path: "https://www.linkedin.com/company/altum-artificialintelligence/",
		},
		{title: "Twitter", path: "https://twitter.com/Altum_AI"},
	]
	const Links = [
		{title: "Privacy Statement", path: "https://altum.ai/privacy"},
		{
			title: "Algemene voorwaarden",
			path: "https://altum.ai/algemene-voorwaarden",
		},
	]
	const listItemRight = Links.map((item, i) => (
		<Li key={i}>
			<a href={item.path} target="_blank" rel="noreferrer">
				{item.title}
			</a>
		</Li>
	))
	const listItemCenter = AltumAi.map((item, i) => (
		<Li key={i}>
			<a href={item.path} target="_blank" rel="noreferrer">
				{item.title}
			</a>
		</Li>
	))
	const listItemCenterRight = Vlog.map((item, i) => (
		<Li key={i}>
			<a href={item.path} target="_blank" rel="noreferrer">
				{item.title}
			</a>
		</Li>
	))
	const listItemLeft = Toepassingen.map((item, i) => (
		<Li key={i}>
			<a href={item.path} target="_blank" rel="noreferrer">
				{item.title}
			</a>
		</Li>
	))

	const windowSize = useWindowSize()

	return (
		<ColumnContainer>
			<Container>
				<ImgColumnFlex>
					<Img src={altumai} width="20%" height="20%" />
					<p>{new Date().getFullYear()}</p>
					<p>
						Copyright © Altum AI B.V. <br /> KvK 71992375
						<br /> BTW NL858932490B01
					</p>
					<div style={{display: "flex", gap: "10px"}}>
						<PhoneCall />
						<p> +31 79 2340990</p>
					</div>
				</ImgColumnFlex>
				<Container>
					<Subflex>
						<ColumnFlex>
							<Pstyle> Toepassingen </Pstyle>
							{listItemLeft}
						</ColumnFlex>

						<ColumnFlex>
							<Pstyle> Altum AI </Pstyle> {listItemCenter}
						</ColumnFlex>

						<ColumnFlex>
							<Pstyle> Volg ons </Pstyle> {listItemCenterRight}
						</ColumnFlex>
						<ColumnFlex>
							<Pstyle> Links </Pstyle>
							{listItemRight}
						</ColumnFlex>
					</Subflex>
				</Container>
			</Container>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: "30px",
					gap: "5px",
					backgroundColor: "white",
				}}
			>
				<P
					style={{
						fontSize: "16px",
					}}
				>
					Altum AI is ISAE 3000 gecertificeerd
				</P>
				<Img
					src={ISAE}
					width={windowSize.width <= 760 ? "20%" : "10%"}
				/>
			</div>
		</ColumnContainer>
	)
}

const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const Container = styled.div`
	display: flex;
	padding: 2rem;
	width: 100%;
	gap: 150px;
	background: ${Colors.main.white};
	align-items: top;
	@media ${device.laptopL} {
		gap: 120px;
	}
	@media ${device.laptop} {
		padding: 1rem;
		gap: 50px;
	}
	@media ${device.tablet} {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
	}
	@media ${device.tablet} {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
	}
`

const ColumnFlex = styled.ul`
	display: flex;
	flex-direction: column;
	list-style-type: disc !important;
`

const ImgColumnFlex = styled.div`
	display: flex;
	flex-direction: column;
	/* margin-top: 20px; */
	gap: 20px;
	margin-left: 50px;
	p {
		color: ${Colors.main.darkgrey};
	}

	@media ${device.tablet} {
		flex-direction: column;
		justify-content: center;
	}
`

const Li = styled.li`
	/* ${TextStyles.Bundler(TextStyles.Text.SmallText)}  */
	list-style: disc outside none;
	display: list-item;
	align-items: center;
	color: ${Colors.main.green} !important;
	font-size: 20px !important;

	&::before {
		content: "•";
		color: ${Colors.main.lightgrey};
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}

	a {
		color: ${Colors.main.green} !important;
	}
`

const Pstyle = styled(P)`
	margin-left: -50px;
`

const Subflex = styled.div`
	display: flex;
	gap: 150px;

	@media ${device.laptopL} {
		gap: 120px;
	}

	@media ${device.laptop} {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 70px;
	}

	@media ${device.tablet} {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
`
