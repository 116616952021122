import React, { FC } from "react";
import Subtitle from "../../../components/Subtitle";
import Text from "../../../components/Text";
import NN from "../../../assets/images/national.png";
import Taxapi from "../../../assets/images/taxapi.png";
import Woonu from "../../../assets/images/woonnu.png";
const AuthLeftSection: FC<{ image: string }> = ({ image }) => (
  <div className="w-full md:w-1/2 bg-secondary p-8 hidden md:block">
    <div className="flex flex-col h-full justify-evenly max-w-[450px] m-auto">
      <img src={image} alt="avm" />
      <div className="text-center">
        <Subtitle className="text-2xl">
          Integreer woningdata in élke toepassing
        </Subtitle>
        <Text className="text-gray-dark mt-2 text-md">
          Bij Altum AI is toegang tot woningdata in een paar klikken geregeld.
          Je hoeft niet langer door eindeloze datasets te graven – wij
          verzamelen dagelijks alle relevante data bronnen en ontsluiten onze
          woningdata modellen eenvoudig en snel. Start vandaag nog met de
          integratie van woningwaardes, verduurzamingadvies en nog veel meer
          relevante woningdata- en kenmerken in jouw toepassing.
        </Text>
      </div>
      <div className="flex gap-4 justify-center">
        <img src={Taxapi} alt="avm" className="w-24 md:w-full" />
        <img src={Woonu} alt="avm" className="w-24 md:w-full" />
        <img src={NN} alt="avm" className="w-24 md:w-full" />
      </div>
    </div>
  </div>
);

export default AuthLeftSection;
