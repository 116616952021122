import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import addGAEvent from "../../helpers/addGAEvent";
import { OnboardingQuestion } from "../../@types";

export const getOnboardingStatus = createAsyncThunk(
  "onboarding/status",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get<{ isCompleted: boolean }>(
        "/api/v1/onboarding/status",
      );
      return res.data.isCompleted;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
      return false;
    }
  },
);

export const getOnboardingQuestions = createAsyncThunk(
  "onboarding/data",
  async (body, { rejectWithValue }) => {
    try {
      const resQuest = await axios.get<{
        onboardQuestions: OnboardingQuestion[];
      }>("/api/v1/onboarding/questions");
      return resQuest.data.onboardQuestions;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  },
);

export const addOnboardingState = createAsyncThunk(
  "onboarding/states",
  async (stepId: string, { rejectWithValue }) => {
    try {
      await axios.post("/api/v1/onboarding/states", {
        step_id: stepId,
      });
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  },
);

export const getOnboardingState = createAsyncThunk(
  "onboarding/states",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get("/api/v1/onboarding/states");
      return res.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  },
);

export const setAllOnboardingState = createAsyncThunk(
  "onboarding/states",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.post("/api/v1/onboarding/update-states");
      return res.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  },
);
export const addOnboardingResponse = createAsyncThunk(
  "onboarding/response",
  async (
    arg: {
      data: { question_id: string; responses: string[] }[];
      stepId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      await axios.post("/api/v1/onboarding/responses", arg);
      addGAEvent("Onboarding", "Onboading question submitted");
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  },
);
