import React, { Dispatch, FC, SetStateAction } from "react";
import RadioUsageFormComponent from "../../components/RadioUsageFormComponent";

type Props = {
  isMeasure: string | number;
  setIsMeasure: Dispatch<SetStateAction<string | number>>;
};
const options = [
  { label: "Ja", value: "yes" },
  { label: "Nee", value: "no" },
];
const IsMeasureImplemented: FC<Props> = ({ isMeasure, setIsMeasure }) => {
  return (
    <>
      <RadioUsageFormComponent
        label="Zijn er duurzaamheidsmaatregelen in uw woning doorgevoerd? Zo ja, welke veranderingen zijn er sinds de bouw aangebracht, zoals het vervangen van het isolatiesysteem of het installeren van zonnepanelen? Beantwoord met ja en specificeer op de volgende pagina."
        options={options}
        value={isMeasure}
        onChange={(value) => setIsMeasure(value)}
      />
      {/* <Modal trigger={false}>
        <div>This is a modal</div>
      </Modal> */}
    </>
  );
};

export default IsMeasureImplemented;
