import React, { FC } from "react";
import Dropzone, { DropzoneProps } from "react-dropzone";
import { GoPlus } from "react-icons/go";

interface DragNDropProps {
  setImageOptions: (file: File[]) => void;
}

const DragNDrop: FC<DragNDropProps> = ({ setImageOptions }) => {
  const handleDrop: DropzoneProps["onDrop"] = (acceptedFiles) => {
    setImageOptions(acceptedFiles);
  };

  return (
    <div>
      <Dropzone multiple={false} accept="image/*" onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed #1e9d66",
              width: "100%",
              height: "230px",
              minWidth: "280px",
              maxWidth: "600px",
              padding: "20px",
              borderRadius: "2%",
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              justifyContent: "center",
            }}
          >
            <input {...getInputProps()} />
            <p style={{ color: "#9a9a9a" }}>
              Drag 'n' drop the images here, or click to select files
            </p>
            <GoPlus size={70} color="#9a9a9a" />
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default DragNDrop;
