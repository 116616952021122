import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import "../Dashboard/NewDashboard.css";
import "./Auth.css";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteAccount } from "../../redux/actions/userActions";

function DeleteAccount() {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [password, setPassword] = useState("");

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    user && dispatch(deleteAccount({ password, id: user.user_id }));
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-content-section">
        <div className="dashboard-content-title-box">
          <h2 id="dashboard-content-title">
            <span>Deactivate Account</span>
          </h2>
        </div>

        <div className="dashboard-content-account-container">
          <div className="dashboard-deactivate-box">
            <form className="form" onSubmit={onSubmitHandler}>
              <div className="form-group">
                <label>Password:</label>
                <input
                  type="password"
                  placeholder="Wachtwoord"
                  name="password"
                  value={password}
                  onChange={onChangeHandler}
                />
              </div>
              <button type="submit" className="btn btn-danger">
                Delete Account
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
