import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postObjectData = createApiThunk(
  "objectdata/postObjectDate",
  "api",
  "Move Data api used",
  "objectdata",
);

export const clearObjectDataResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "objectdata/clearResults" });
};

export const modifyObjectDataQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "objectdata/modifyQueries" });
};

export const objectDataOneTimeUse = createApiThunk(
  "objectdata/objectDataOneTimeUse",
  "demo",
  "Move Data demo used",
  "objectdata",
);
