import DashboardContainer from "../components/Container";
import Header from "../components/Header";
import Tabs, { TabProvider } from "../../../components/Tab";
import AllTabs from "./components/AllTabs";
import { SubscriptionProvider } from "../../../hooks/useSubscriptionContext";

type Props = {};
const tabsData = ["Overzicht", "Abonnement", "Betalingsmethoden"];
const SubscriptionPage = (props: Props) => {
  return (
    <DashboardContainer pageTitle="Abonnement & Betaling - Altum AI">
      <Header title={"Abonnement & Betaling"} />
      <SubscriptionProvider>
        <TabProvider>
          <Tabs tabs={tabsData}></Tabs>
          <AllTabs />
        </TabProvider>
      </SubscriptionProvider>
    </DashboardContainer>
  );
};

export default SubscriptionPage;
