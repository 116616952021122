import "./PrivacyTerms.css";
import { Helmet } from "react-helmet";

function PrivacyStatement() {
  return (
    <>
      <Helmet>Privacy beleid - Altum AI</Helmet>
      <div className="container privacy-box">
        <div className="privacy-section">
          <h1 id="privacy-title">Privacy beleid</h1>
          <small>Versie 1.0 — 1 september 2020.</small>
        </div>

        <div className="privacy-section">
          <p>
            Via onze webwinkel worden privacygevoelige gegevens oftewel
            persoonsgegevens verwerkt. Altum AI B.V. acht een zorgvuldige omgang
            met persoonsgegevens van groot belang. Persoonlijke gegevens worden
            door ons dan ook zorgvuldig verwerkt en beveiligd.
          </p>

          <p>
            Bij onze verwerking houden wij ons aan de eisen die de
            privacywetgeving stelt. Dat betekent onder andere dat wij:
          </p>
          <ul className="privacy-ul">
            <li>
              onze doeleinden duidelijk specificeren voordat wij
              persoonsgegevens verwerken, via deze privacyverklaring;
            </li>
            <li>
              onze verzameling van persoonsgegevens beperken tot alleen de
              persoonsgegevens die nodig zijn voor legitieme doeleinden;
            </li>
            <li>
              u eerst expliciet toestemming vragen om uw persoonsgegevens te
              verwerken in gevallen waarin uw toestemming is vereist;
            </li>
            <li>
              passende beveiligingsmaatregelen nemen om uw persoonsgegevens te
              beschermen en dat ook eisen van partijen die in onze opdracht
              persoonsgegevens verwerken;
            </li>
            <li>
              uw recht respecteren om uw persoonsgegevens op uw aanvraag ter
              inzage te bieden, te corrigeren of te verwijderen.
            </li>
          </ul>

          <p>
            Altum AI B.V. is de verantwoordelijke voor de gegevensverwerking. In
            deze privacyverklaring leggen wij uit welke persoonsgegevens wij
            verzamelen en gebruiken en met welk doel bij de webwinkel
            <a href="https://mopsus.altum.ai.">https://mopsus.altum.ai</a>. Wij
            raden u aan deze zorgvuldig te lezen.
          </p>
          <p>
            Als u vragen heeft, of wilt weten wat we precies van u bijhouden,
            neem dan contact op met Altum AI B.V.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Afhandelen bestelling</h3>
          <p>
            Wanneer u bij ons een bestelling plaatst, gebruiken wij uw
            persoonsgegevens om deze netjes af te kunnen handelen. Wij mogen uw
            persoonsgegevens dan aan onze bezorgdienst geven om de bestelling
            bij u te laten bezorgen. Ook krijgen wij informatie over uw betaling
            van uw bank of creditcardmaatschappij. Hiervoor gebruiken wij uw
            e-mailadres en ip-adres. Hier hebben wij een legitiem belang bij.
            Wij bewaren deze informatie tot uw bestelling is afgerond. Bepaalde
            klantgegevens bewaren wij langer in verband met de wettelijke
            fiscale bewaarplicht.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Verstrekken aan derden</h3>
          <p>
            Wij werken met bepaalde bedrijven samen, die uw hierboven genoemde
            persoonsgegevens van ons kunnen ontvangen.
          </p>
          <p>Voor het afhandelen van de bestellingen werken wij samen met:</p>
          <ul className="privacy-ul">
            <li>
              Onze betalingsprovider Stripe, Inc. (gevestigd buiten de EU)
            </li>
          </ul>
        </div>

        <div className="privacy-section">
          <h3>Uw account</h3>
          <p>
            Bij bepaalde onderdelen van onze webwinkel moet u zich eerst
            registreren. U moet dan informatie over uzelf opgeven en een
            gebruikersnaam kiezen. Daarmee maken wij een account, waarop u kunt
            inloggen met die gebruikersnaam en een zelfgekozen wachtwoord.
          </p>
          <p>
            Hiervoor gebruiken wij uw e-mailadres. Hier hebben wij een legitiem
            belang bij. Wij bewaren deze informatie tot u het account opheft.
          </p>
          <p>
            Wij bewaren deze informatie zodat u ze niet steeds opnieuw in hoeft
            te vullen en zodat wij u kunnen contacteren in het kader van
            uitvoering van de overeenkomst. U kunt via uw account informatie
            aanpassen wanneer u dat wilt.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Verstrekken aan derden</h3>
          <p>
            Wij werken met bepaalde bedrijven samen, die uw hierboven genoemde
            persoonsgegevens van ons kunnen ontvangen.
          </p>
          <p>
            Van deze is partijen zijn Amazon en Google gevestigd buiten de EU.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Contactformulier en Nieuwsbrief</h3>
          <p>
            Met het contactformulier kunt u ons vragen stellen of aanvragen
            doen.
          </p>
          <p>
            Hiervoor gebruiken wij uw e-mailadres. Dit doen wij op basis van uw
            toestemming. Wij bewaren deze informatie totdat we zeker weten dat u
            tevreden bent met onze reactie.
          </p>
          <p>
            Wij bieden een nieuwsbrief waarmee wij geïnteresseerden willen
            informeren over onze producten en/of diensten. Iedere nieuwsbrief
            bevat een link waarmee u zich kunt afmelden.
          </p>
          <p>Via uw account kunt u dit ook doorgeven.</p>
          <p>
            Uw e-mailadres wordt alleen met uw toestemming toegevoegd aan de
            lijst van abonnees. Deze gegevens worden bewaard totdat u het
            abonnement opzegt.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Verstrekken aan derden</h3>
          <p>
            Wij werken met bepaalde bedrijven samen, die uw hierboven genoemde
            persoonsgegevens van ons kunnen ontvangen.
          </p>
          <p>
            Van deze partijen zijn Sendgrid en Amazon gevestigd buiten de EU.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Locatiegegevens</h3>
          <p>
            Als dat nodig is voor de dienst, kunnen wij locatiegegevens (GPS)
            van u verzamelen. Hiervoor wordt op het moment zelf uw toestemming
            gevraagd.
          </p>

          <p>
            Tevens kunnen deze (locatie) gegevens opgeslagen en verwerkt worden
            door de aanbieder van bijvoorbeeld de navigatie/kaartensoftware,
            zoals Google Maps of Mapbox, maar de gegevens kunnen ook worden
            gebruikt door bijvoorbeeld Google of Apple zelf. Daar hebben wij
            geen controle over. Lees dus altijd ook de privacyverklaringen van
            de desbetreffende aanbieder.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Verstrekking aan andere bedrijven of instellingen</h3>
          <p>
            Met uitzondering van de hierboven genoemde partners, geven wij uw
            persoonsgegevens onder geen voorwaarde aan andere bedrijven of
            instellingen, behalve als wij dat wettelijk verplicht zijn
            (bijvoorbeeld als de politie dat eist bij een vermoeden van een
            misdrijf).
          </p>
        </div>

        <div className="privacy-section">
          <h3>Statistieken</h3>
          <p>Wij houden statistieken bij over het gebruik van onze webwinkel</p>
          <p>
            In onze webwinkel zijn social media buttons opgenomen. Hiermee
            verzamelen de beheerders van deze diensten uw persoonsgegevens.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Cookies</h3>
          <p>
            Onze webwinkel gebruikt cookies. Cookies zijn kleine bestandjes waar
            we informatie in kunnen opslaan zodat u die niet steeds hoeft in te
            vullen. Maar we kunnen er ook mee zien dat u ons weer bezoekt.
          </p>
          <p>
            Wanneer u onze webwinkel voor het eerst bezoekt, tonen wij een
            melding met uitleg over cookies. Hierbij zullen we vragen om uw
            akkoord voor het gebruik van deze cookies.
          </p>
          <p>
            U kunt via uw browser het plaatsen van cookies uitschakelen, maar
            sommige dingen van onze webwinkel werken dan niet goed meer.
          </p>
          <p>
            Met andere bedrijven die cookies plaatsen hebben wij afspraken
            gemaakt over het gebruik van de cookies. Toch hebben wij geen
            volledige controle op wat zij zelf met de cookies doen. Lees dus ook
            hun privacyverklaringen. (Let op: deze kunnen regelmatig wijzigen)
          </p>
        </div>

        <div className="privacy-section">
          <h3>Google Analytics</h3>
          <p>
            Wij gebruiken Google Analytics om bij te houden hoe bezoekers onze
            webwinkel gebruiken. Wij hebben een verwerkersovereenkomst met
            Google gesloten. Daarin staan strikte afspraken te maken over wat
            zij mogen bijhouden. Wij hebben Google niet toegestaan de verkregen
            Analytics informatie te gebruiken voor andere Google diensten. Wij
            laten Google de IP-adressen anonimiseren.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Beveiliging</h3>
          <p>
            Beveiliging van persoonsgegevens is voor ons van groot belang. Om uw
            privacy te beschermen, nemen wij de volgende maatregelen:
          </p>
          <ul className="privacy-ul">
            <li>
              Toegang tot persoonsgegevens wordt afgeschermd met een
              gebruikersnaam en wachtwoord;
            </li>
            <li>
              Toegang tot persoonsgegevens wordt afgeschermd met een
              gebruikersnaam en een login token;
            </li>
            <li>
              Wij nemen fysieke maatregelen zoals sloten en kluizen voor
              toegangsbescherming van de systemen waarin persoonsgegevens
              opslagen zijn;
            </li>
            <li>
              Wij maken gebruik van beveiligde verbindingen (Secure Sockets
              Layer of SSL) waarmee alle informatie tussen u en onze website
              wordt afgeschermd wanneer uw persoonsgegevens invoert;
            </li>
            <li>
              Wij houden logs bij van alle opvragingen van persoonsgegevens.
            </li>
          </ul>
        </div>

        <div className="privacy-section">
          <h3>Wijzigingen in deze privacyverklaring</h3>
          <p>
            Wij behouden ons het recht voor om wijzigingen aan te brengen in
            deze privacyverklaring. Het verdient aanbeveling om deze
            privacyverklaring geregeld te raadplegen, zodat u van deze
            wijzigingen op de hoogte bent. Wij zullen ons best doen wijzigingen
            ook apart aan te kondigen.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Inzage, wijzigen en verwijderen van uw gegevens</h3>
          <p>
            Als u vragen hebt of wilt weten welke persoonsgegevens wij van u
            hebben, kunt u altijd contact met ons opnemen. Zie de
            contactgegevens hieronder.
          </p>
        </div>

        <div className="privacy-section">
          <h3>U hebt de volgende rechten:</h3>
          <ul className="privacy-ul">
            <li>
              uitleg krijgen over welke persoonsgegevens we hebben en wat we
              daarmee doen;
            </li>
            <li>
              inzage in de precieze persoonsgegevens die we hebben het laten
              corrigeren van fouten;
            </li>
            <li>
              het laten verwijderen van verouderde persoonsgegevens; intrekken
              van toestemming;
            </li>
            <li>bezwaar maken tegen een bepaald gebruik.</li>
          </ul>
          <p>
            Let op dat u altijd duidelijk aangeeft wie u bent, zodat we zeker
            weten dat we geen gegevens van de verkeerde persoon aanpassen of
            verwijderen.
          </p>
        </div>

        <div className="privacy-section">
          <h3>Klacht indienen</h3>
          <p>
            Als u vindt dat wij u niet op de juiste manier helpen, dan heeft u
            het recht om een klacht in te dienen bij de toezichthouder. Deze
            heet de Autoriteit Persoonsgegevens.
          </p>
        </div>
        <div className="privacy-section contact">
          <h3>Contactgegevens</h3>
          <p>Altum AI B.V.</p>
          <p>Bellefleurhof 5</p>
          <p>2728KH Zoetermeer</p>
          <p>info@altum.ai</p>
        </div>
      </div>
    </>
  );
}

export default PrivacyStatement;
