import React, { ChangeEventHandler, FC } from "react";
import Input from "./TextInput";
import { CiSearch } from "react-icons/ci";
import { IconType } from "react-icons";

type Props = {
  className?: string;
  onChange?: ChangeEventHandler;
  icon: IconType;
};

const Search: FC<Props> = ({ onChange, icon: Icon, className }) => {
  return (
    <Input
      type={"text"}
      name={"search"}
      placeholder={"Zoek API"}
      icon={Icon}
      onChange={onChange}
      size="md"
      className={`${className} border-gray-light`}
    />
  );
};

export default Search;
