export const MOPSUS = {
  _100: "Altum AI - 100",
  _250: "Altum AI - 250",
  _750: "Altum AI - 750",
  _2000: "Altum AI - 2000",
  _1200: "Altum AI - 1200",
  _3000: "Altum AI - 3000",
  _9000: "Altum AI - 9000",
  _24000: "Altum AI - 24000",
  _20_DAILY: "Dagelijks limiet 20",
  _100_DAILY: "Dagelijks limiet 100",
  _250_DAILY: "Dagelijks limiet 250",
};

export const TRANSACTION = {
  _100: "Kadaster transacties - 100",
};

export const UNLIMITED = {
  WOZ: "WOZ API",
  OBJ_DATA: "Woningkenmerken API",
  AVM: "Woningwaarde API",
  REFERENCE: "Woning Referenties API",
  ECO: "Verduurzaming API",
  ENERGY: "NTA 8800 Energielabel API",
};

export const REACT_APP_STRIPE = {
  MOPSUS: {
    _100: "price_1NhcCjDFtlz8Oje6lPlH9bQy",
    _250: "price_1LaKJqDFtlz8Oje6Ired8OWh",
    _750: "price_1LaKNCDFtlz8Oje6HsitTbis",
    _2000: "price_1LaKOIDFtlz8Oje6ag1JjPC5",
    // _3000: "price_1MSd4FDFtlz8Oje6RprmwDA3",
    // _9000: "price_1MSdFyDFtlz8Oje6nC8TMCHq",
    // _24000: "price_1MSdGWDFtlz8Oje6RyWGIXGZ",
  },
  TRANSACTION: {
    _100: "price_1NjmjPDFtlz8Oje6mh5MXfGc",
  },
  UNLIMITED: {
    WOZ: "price_1MmezXDFtlz8Oje6QvVHT5x2",
    OBJ_DATA: "price_1MmeznDFtlz8Oje6TyMkdLJd",
    AVM: "price_1MmdSYDFtlz8Oje6W6YwPqLH",
    REFERENCE: "price_1MmdSiDFtlz8Oje6ypSnMG8Z",
    ECO: "price_1MmdT3DFtlz8Oje656Wb0Cmj",
    ENERGY: "price_1MmdTGDFtlz8Oje6y6qUohMs",
  },
  RECURRING: {
    _100: "price_1Nhc9eDFtlz8Oje6KkEVCyqO",
    _250: "price_1LormjDFtlz8Oje66fpVbD3W",
    _750: "price_1LormzDFtlz8Oje6pkrmIAEz",
    _2000: "price_1LornSDFtlz8Oje6suudmHfY",
    // Yearly
    _1200: "price_1Nn8ADDFtlz8Oje6SmQ2uaeM",
    _3000: "price_1MSd4FDFtlz8Oje6RprmwDA3",
    _9000: "price_1MSdFyDFtlz8Oje6nC8TMCHq",
    _24000: "price_1MSdGWDFtlz8Oje6RyWGIXGZ",
    //Daily
    _20_DAILY: "price_1LmdWyDFtlz8Oje6D6hHr1xU",
    _100_DAILY: "price_1MxoBMDFtlz8Oje6pNxcMREK",
    _250_DAILY: "price_1MxoCTDFtlz8Oje6OjhMmvH0",
  },
  DAILY_QUOTA: {
    _20: "price_1LmdWyDFtlz8Oje6D6hHr1xU",
    _100: "price_1MxoBMDFtlz8Oje6pNxcMREK",
    _250: "price_1MxoCTDFtlz8Oje6OjhMmvH0",
  },
  TRANSACTION_RECURRING: {
    _100: "price_1NjmjPDFtlz8Oje6eQO9Chuc",
  },

  TAX_RATE: {
    MOPSUS: "txr_1HMYiDDFtlz8Oje6j6rq84vg",
  },

  TEST_PRICES: {
    MOPSUS: {
      _100: "price_1Njm2WDFtlz8Oje645wtteYK",
      _250: "price_1LkFB5DFtlz8Oje65ECiACmi",
      _750: "price_1LkFGMDFtlz8Oje63BKtO2RQ",
      _2000: "price_1LkO73DFtlz8Oje6FTPMmX2U",
    },
    RECURRING: {
      _100: "price_1Njm2WDFtlz8Oje6YBNNw6on",
      _250: "price_1LpDkCDFtlz8Oje60L8KdxGv",
      _750: "price_1LpDhWDFtlz8Oje6putrnNWq",
      // TODO: replace price _2000 with the correct value from stripe test
      _2000: "price_1LkO73DFtlz8Oje6FTPMmX2U",

      // Yearly
      _1200: "price_1Njm7tDFtlz8Oje6WJ4Sgja4",
      _3000: "price_1MTmjWDFtlz8Oje6vj4udSJc",
      _9000: "price_1MTmooDFtlz8Oje69cRTFXIr",
      _24000: "price_1MTmqtDFtlz8Oje6oT995fZ1",

      _20_DAILY: "price_1N5qZXDFtlz8Oje6s2dHevsW",
      _100_DAILY: "price_1N5qXvDFtlz8Oje6ht3Ed53E",
      _250_DAILY: "price_1N5qWBDFtlz8Oje6biNAGoMr",
    },
    TRANSACTION: {
      _100: "price_1NjmHtDFtlz8Oje6OX1njkET",
    },
    UNLIMITED: {
      WOZ: "price_1O3CoTDFtlz8Oje66TpHMwYa",
      OBJ_DATA: "price_1O3Ct9DFtlz8Oje65EO0E6Rx",
      AVM: "price_1NyBLiDFtlz8Oje6IBi8Jg0a",
      REFERENCE: "price_1NyBIUDFtlz8Oje6gzNMSY7Y",
      ECO: "price_1O5bioDFtlz8Oje6CZ70bVFG",
      ENERGY: "price_1O5aPbDFtlz8Oje6wGOIs5qS",
    },
    TRANSACTION_RECURRING: {
      _100: "price_1NjmHtDFtlz8Oje6mfsa6Bq0",
    },
    TAX_RATE_TEST: {
      MOPSUS: "txr_1HMYiDDFtlz8Oje6j6rq84vg",
    },
  },
};
