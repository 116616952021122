import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import ImageForm, { ImageOptions } from "../ImageForm";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { postLabelling } from "../../../redux/actions/imageLabelActions";
import house from "../../../imgs/fotoHouse.jpg";

const ImageLabelForm = () => {
  const [imageOptions, setImageOptions] = useState<ImageOptions>({
    url: "",
    image: undefined,
    preview: undefined,
  });
    const { savedQueries } = useAppSelector((state) => state.imageLabel);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setImageOptions({
        ...(savedQueries as ImageOptions),
      });
    }
  }, [savedQueries]);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "url") {
      setImageOptions({
        image: undefined,
        url: e.target.value,
        preview: e.target.value,
      });
    }
  };
  const dispatch = useAppDispatch();
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    const formData: ImageOptions = {
      url: imageOptions.url,
      image: imageOptions.image,
      preview: imageOptions.preview,
    };

    apiKey &&
      dispatch(
        postLabelling({
          formData,
          apiKey,
          contentType: formData.url
            ? "application/json"
            : "multipart/form-data",
          endpoint: formData.url ? "url" : "img",
        }),
      );
  };

  return (
    <ImageForm
      handleFormSubmit={handleFormSubmit}
      imageOptions={imageOptions}
      setImageOptions={setImageOptions}
      handleChange={handleChange}
      title={"Woningfoto gelabeld - Altum AI"}
      desc={
        "De Foto labelen API verwerkt afbeeldingen van huizen en genereert het juiste label voor zowel binnen- als buitenafbeeldingen. Gebruik de uitkomst om te herkennen waar de foto is genomen, om daarna de foto’s te sorteren of te prioriteren"
      }
      path={"https://docs.altum.ai/apis/labelling-api"}
      initialFields={[]}
      img={house}
    />
  );
};

export default ImageLabelForm;
