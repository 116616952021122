import React from "react";
import convertToEuFormat from "../../../../../helpers/convertToEuFormat";
import Table from "../../../../../components/Table";
import Subtitle from "../../../../../components/Subtitle";
import { keyTranslations } from "../translations";

export interface WWWResultInterface {
  total_wws_points: string;
  points_per_component: {
    [key: string]: string;
  };
}

const WWWResultDetails: React.FC<{ result: WWWResultInterface }> = ({
  result,
}) => {
  const translateKey = (key: string) => {
    return keyTranslations[key] || key.replace(/_/g, " ");
  };

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-bold mb-4">WWS-punten Details</h2>
      <Table
        headers={["Component", "Punten"]}
        noDataText={""}
        tableItems={Object.entries(result.points_per_component).map(
          ([key, value]) => ({
            Component: translateKey(key),
            Punten: convertToEuFormat(value),
          }),
        )}
      />
      <Subtitle className="text-xl">
        {translateKey("total_wws_points")}:{" "}
        {convertToEuFormat(result.total_wws_points)}
      </Subtitle>
    </div>
  );
};

export default WWWResultDetails;
