import createApiSlice from '../../helpers/createApiSlice';
import {
  postReference,
  referenceOneTimeUse,
} from '../actions/referenceActions';

const referenceSlice = createApiSlice(
  'reference',
  postReference,
  referenceOneTimeUse,
);

export const { clearResults, modifyQueries } = referenceSlice.actions;

export default referenceSlice.reducer;
