import ReactGA from 'react-ga';

// Adding event to Google Analytics
const addGAEvent = (category: string, action: string, label?: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export default addGAEvent;
