import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postSolarscan = createApiThunk(
  "solarscan/postSolarscan",
  "api",
  "Solarscan api used",
  "solar",
);

export const clearSolarscanResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "solarscan/clearResults" });
};

export const modifySolarscanQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "solarscan/modifyQueries" });
};

export const solarscanOneTimeUse = createApiThunk(
  "solarscan/solarscanOneTimeUse",
  "demo",
  "Solarscan demo used",
  "solar",
);
