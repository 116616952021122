import React from "react";
import { useTabContext } from "../../../../components/Tab";
import Overview from "./Overview";
import Plan from "./Plan";
import PaymentMethods from "./PaymentMethods";

type Props = {};

const AllTabs = (props: Props) => {
  const { activeIndex } = useTabContext();
  return activeIndex === 0 ? (
    <Overview />
  ) : activeIndex === 1 ? (
    <Plan />
  ) : (
    <PaymentMethods />
  );
};

export default AllTabs;
