import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getUsers,
  getAllMessages,
  postNewMessage,
  deleteMessages,
  getAnalytics,
  getAnalyticsLineChart,
  saveEditedUser,
  deleteUser,
} from '../actions/adminActions';
import { User } from '../../@types';

interface AdminState {
  users: User[];
  messages: any[];
  analytics: any;
  lineAnalytics: any;
  loading: boolean;
  error: unknown | null;
}

const initialState: AdminState = {
  users: [],
  messages: [],
  analytics: {},
  lineAnalytics: {},
  loading: false,
  error: null,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Users
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getUsers.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.users = action.payload;
      },
    );
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // Get All Messages
    builder.addCase(getAllMessages.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllMessages.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.messages = action.payload;
      },
    );
    builder.addCase(getAllMessages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // post New Message
    builder.addCase(postNewMessage.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postNewMessage.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(postNewMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete Messages
    builder.addCase(deleteMessages.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteMessages.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMessages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Get Analytics
    builder.addCase(getAnalytics.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAnalytics.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.analytics = action.payload;
      },
    );
    builder.addCase(getAnalytics.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Get Analytics Line Chart
    builder.addCase(getAnalyticsLineChart.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAnalyticsLineChart.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.lineAnalytics = action.payload;
      },
    );
    builder.addCase(getAnalyticsLineChart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Save Edited User
    builder.addCase(saveEditedUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(saveEditedUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(saveEditedUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete User
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default adminSlice.reducer;
