import createApiSlice from '../../helpers/createApiSlice';
import {
  energyLabelOneTimeUse,
  postEnergyLabel,
} from '../actions/energyLabelActions';

const energyLabelSlice = createApiSlice(
  'energyLabel',
  postEnergyLabel,
  energyLabelOneTimeUse,
);

export const { clearResults, modifyQueries } = energyLabelSlice.actions;

export default energyLabelSlice.reducer;
