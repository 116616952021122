const calculatePercentageDifference = (
	previousUsage: number,
	currentUsage: number
) => {
	if (previousUsage === 0) {
		return currentUsage === 0 ? 0 : Infinity;
	}
	const difference = currentUsage - previousUsage;
	const percentageDifference = (difference / previousUsage) * 100;
	return parseInt(percentageDifference.toFixed(2));
};

export default calculatePercentageDifference;
