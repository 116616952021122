import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postEnergyClimate = createApiThunk(
  "energy-climate/postEnergyClimate",
  "api",
  "Energy climate Api Used",
  "energy-climate",
);

export const clearEnergyClimateResults =
  () => async (dispatch: AppDispatch) => {
    dispatch({ type: "energy-climate/clearResults" });
  };

export const modifyEnergyClimateQueries =
  () => async (dispatch: AppDispatch) => {
    dispatch({ type: "energy-climate/modifyQueries" });
  };

export const energyClimateOneTimeUse = createApiThunk(
  "energyClimate/energyClimateOneTimeUse",
  "demo",
  "energyClimate demo used",
  "energy-climate",
);
