import { useState, useEffect } from "react";
import APIResult from "../APIResult";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { useHistory } from "react-router-dom";
import {
  clearLabellingResults,
  modifyLabellingQueries,
} from "../../../redux/actions/imageLabelActions";
import translateToDutch from "../../../helpers/translateToDutch";

const ConditionScoreResult = () => {
  const { result, loading, savedQueries } = useAppSelector(
    (state) => state.conditionScore,
  );
  const property: any = result;

  const [buildingPhoto, setBuildingPhoto] = useState<string>("");

  useEffect(() => {
    const value =
      Object.keys(savedQueries).length > 0 && (savedQueries as any)["preview"];
    setBuildingPhoto(value);
  }, [savedQueries]);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearLabellingResults());
    history.push("/conditie-score");
  };

  const modifyResults = () => {
    dispatch(modifyLabellingQueries());
    history.push("/conditie-score");
  };

  const overview = [
    {
      title: "Label",
      details: [
        {
          key: "Label",
          value: `${translateToDutch(property.label)}`,
        },
        {
          key: "Betrouwbaarheid",
          value: `${convertToEuFormat(property.confidence)} %`,
        },
        {
          key: "Conditie score",
          value: `${convertToEuFormat(property.condition_score)}`,
        },
      ],
    },
  ];

  const reference = [
    {
      text: "Keuken en badkamer waarderen",
      path: "/conditie-score",
    },
  ];

  return (
    <APIResult
      property={property}
      loading={loading}
      title={"Woningfoto gelabeld - Altum AI"}
      street={" "}
      postCode={" "}
      city={" "}
      overview={overview}
      reference={reference}
      descSection={[]}
      buildingPhoto={buildingPhoto}
      result={result}
      path="https://docs.altum.ai/apis/amenities-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
    />
  );
};

export default ConditionScoreResult;
