import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../Loading";
import EnergyInsightForm from "./EnergyInsightForm";
import { FormProvider } from "../../../pages/Api/usage-pages/components/FormContext";

const EnergyInsight = () => {
  const { loading, result } = useAppSelector((state) => state.energyInsight);

  if (loading) {
    return <Loading />;
  }

  return (
    <FormProvider>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/energy-insights-result",
          }}
        />
      ) : (
        <EnergyInsightForm />
      )}
    </FormProvider>
  );
};

export default EnergyInsight;
