import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
} from "@stripe/react-stripe-js";

interface CreditCardFormProps {
  setError: (error: any) => void;
  setCardComplete: (complete: boolean) => void;
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  setError,
  setCardComplete,
}) => {
  const CARD_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "#32325d",
        color: "#000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#32325d",
        },
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        iconColor: "#32325d",
        color: "#32325d",
      },
    },
  };
  return (
    <fieldset className="checkout-formgroup">
      <CardElement
        options={CARD_OPTIONS}
        onChange={(e) => {
          setError(e.error);
          setCardComplete(e.complete);
        }}
      />

      {/* <div className="input-flex">
        <CardExpiryElement
          options={CARD_OPTIONS}
          onChange={(e) => {
					  setError(e.error);
					  setCardComplete(e.complete);
          }}
        />

        <CardCvcElement
          options={CARD_OPTIONS}
          onChange={(e) => {
					  setError(e.error);
					  setCardComplete(e.complete);
          }}
        />
      </div> */}
    </fieldset>
  );
};

export default CreditCardForm;
