import React, { useState } from "react";
import { FiClipboard, FiX } from "react-icons/fi";
import useTypingEffect from "../../../hooks/useTypingEffect";
import { LuClipboardCheck } from "react-icons/lu";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import RatingForm from "./RatingForm";

interface PropertyDescriptionProps {
  description: string;
  chatHistoryId: string;
}

const PropertyDescription: React.FC<PropertyDescriptionProps> = ({
  description,
  chatHistoryId,
}) => {
  const [copied, setCopied] = useState(false);
  const { displayedText, isTyping } = useTypingEffect(description);
  const [showFeedbackForm, setShowFeedbackForm] = useState(true);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(description);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="w-full mx-auto px-4 py-8 bg-white relative">
      {copied && (
        <div className="text-green-600 text-sm mt-2">
          Text copied to clipboard!
        </div>
      )}
      <button
        onClick={copyToClipboard}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 focus:outline-none"
        title="Copy to clipboard"
      >
        {copied ? (
          <LuClipboardCheck className="h-6 w-6 text-primary" />
        ) : (
          <FiClipboard className="h-6 w-6" />
        )}
      </button>
      <div className="prose p-3">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            h2: ({ node, children, ...props }) =>
              children ? (
                <h2 {...props} style={{ marginTop: "20px" }}>
                  {children}
                </h2>
              ) : null,
            p: ({ node, ...props }) => (
              <p {...props} style={{ marginTop: "20px" }} />
            ),
          }}
        >
          {displayedText}
        </ReactMarkdown>
      </div>
      {!isTyping && showFeedbackForm && (
        <div className="mt-6 relative">
          <button
            onClick={() => setShowFeedbackForm(false)}
            className="absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-700"
          >
            <FiX size={20} />
          </button>
          <RatingForm chatHistoryId={chatHistoryId} />
        </div>
      )}
    </div>
  );
};

export default PropertyDescription;
