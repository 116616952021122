import { AppDispatch } from '../store';
import { createApiThunk } from '../../helpers/createApiThunk';

export const postBag = createApiThunk(
  'bag/postBag',
  'api',
  'Bag api used',
  'bag',
);

export const clearBagResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'bag/clearResults' });
};

export const modifyBagQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'bag/modifyQueries' });
};

export const bagOneTimeUse = createApiThunk(
  'bag/avmOneTimeUse',
  'demo',
  'Bag demo used',
  'bag',
);
