import React, { ReactNode } from "react";
import ApiFormContainer from "../../components/ApiFormContainer";
import { Ecovalue } from "../../../../../assets/images/api/APIimages";

const progress = [
  "Woning identificatie",
  "Huidige status",
  "Voorkeuren",
  "Resultaat",
];

const SustainabilityContainer: React.FC<{
  children: ReactNode;
  page: number;
}> = ({ children, page }) => {
  return (
    <ApiFormContainer
      page={page}
      title="Verduurzamingsadvies genereren"
      subtitle="Genereer een compleet verduurzaming advies o.b.v. de NTA 8800 norm met een accurate inschatting van het energielabel, maatregelen, investeringskosten en CO2-reductie."
      progress={progress}
      resultSelector={(state) => state.sustainability}
      link="https://docs.altum.ai/apis/sustainability-api"
      image={Ecovalue}
    >
      {children}
    </ApiFormContainer>
  );
};

export default SustainabilityContainer;
