const apiNameConverter = (apiName: string) => {
  switch (apiName) {
    case "AVMAPI":
    case "AVM+":
      return "Woningwaarde model";

    case "ObjectDataAPI":
      return "Woningkenmerken";
    case "SustainabilityAPI":
      return "Verduurzaming advies";
    case "WOZAPI":
      return "WOZ waarde";
    case "InteractiveReference-API":
    case "ReferenceAPI":
      return "Woning referenties opvragen";
    case "TransactionAPI":
      return "Kadaster transacties";
    case "EnergyLabelAPI":
      return "NTA 8800 Energy label";
    case "OBJECT-GEOMETRY-API":
      return "Object geometrie opvragen";
    case "MoveDataAPI":
      return "Verhuisdata";
    case "RentalReferenceAPI":
      return "Huurreferentie";
    case "AutoSuggestAPI":
      return "Autosuggestie";
    case "EnergyClimateAPI":
      return "Energie & klimaat";
    case "EnergyInsightAPI":
      return "Energielabel inzichten";
    case "LabellingAPI":
      return "Foto labelen";
    case "LocationAPI":
    case "Location-Data":
      return "Locatie-gegevens";
    case "RebuildAPI":
      return "Herbouwwaarde";
    case "SolarAPI":
      return "Zonnepanelen dakenscan";
    case "Autosuggest":
      return "Autosuggestie";
    default:
      return "";
  }
};

export default apiNameConverter;
