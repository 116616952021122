import React, { useState } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import { Document, Page, pdfjs } from "react-pdf";
import PDFReport from "./PDFReport";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

//this due a Worker not found error
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/legacy/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const PDFPreview: React.FC<{
  data: any;
  buildingPhoto: string;
  map: string;
}> = ({ data, buildingPhoto, map }) => {
  const [pageCount, setPageCount] = useState(1);
  return (
    // <PDFViewer style={{ width: "100%", height: "100vh" }}>
    //   <PDFReport
    //     data={data}
    //     buildingPhoto={buildingPhoto}
    //     map={map}
    //     featuredMeasures={[]}
    //   />
    // </PDFViewer>
    <BlobProvider
      document={
        <PDFReport
          data={data}
          buildingPhoto={buildingPhoto}
          map={map}
          featuredMeasures={[]}
        />
      }
    >
      {({ blob, url, loading, error }) => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>{error.message}</div>;

        return (
          <Document
            file={url}
            onLoadSuccess={(pdf) => setPageCount(pdf.numPages)}
          >
            {Array.from({ length: pageCount }, (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        );
      }}
    </BlobProvider>
  );
};

export default PDFPreview;
