import { FormEvent, useEffect, useState } from "react";
import { ObjectDataField } from "./objectDataFields";
import FormBody from "../FormBody";
import { ObjectData } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { postObjectData } from "../../../redux/actions/objectDataActions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const ObjectDataForm = () => {
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 2 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.objectData);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);

  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    formValues.postcode = formValues.postcode.split(" ").join("").toUpperCase();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey &&
      dispatch(postObjectData({ formData: newFormData, apiKey: apiKey }));
  };

  return (
    <div>
      <FormBody
        title={"Woningkenmerken API"}
        sectionFields={sectionFields}
        desc={
          "Deze API biedt een verschillende details over een enkel woning object zoals het woningtype, oppervlaktes, tuin oriëntatie, monument status en meer."
        }
        img={ObjectData}
        path={""}
        initialFields={ObjectDataField}
        handleSubmit={handleFormSubmit}
      />
    </div>
  );
};

export default ObjectDataForm;
