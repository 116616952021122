import React, { FC } from "react";
import { Modal as FBModal, ModalBody, ModalHeader } from "flowbite-react";

interface ModalProps {
  children: React.ReactNode;
  trigger: boolean;
  header?: React.ReactNode;
  setTrigger?: (value: boolean) => void;
}

const Modal: FC<ModalProps> = ({ children, trigger, header, setTrigger }) => {
  return (
    <FBModal
      show={trigger}
      onClose={() => setTrigger && setTrigger(!trigger)}
      popup
    >
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </FBModal>
  );
};

export default Modal;
