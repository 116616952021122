import React from "react";
import SkeletonText from "../../../../components/skeleton/components/Text";
import Subtitle from "../../../../components/Subtitle";

type Props = {};

const APILogSkeleton = (props: Props) => {
  return (
    <div className="w-full h-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-lg animate-pulse">
      <div className="flex justify-between">
        <Subtitle className="text-base">API logboek</Subtitle>
      </div>
      <div className="mt-8 w-[330px] md:w-full flex flex-col gap-6">
        {Array.from({ length: 10 }).map((_, index) => (
          <div className="flex gap-4" key={index}>
            <SkeletonText width="w-1/3" height="h-8" />
            <SkeletonText width="w-1/3" height="h-8" />
            <SkeletonText width="w-1/3" height="h-8" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default APILogSkeleton;
