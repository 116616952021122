import React from "react";

const SkeletonImage: React.FC<{ width: string; height: string }> = ({
  width,
  height,
}) => {
  return <div className={`${width} ${height} bg-gray-300 rounded-full`}></div>;
};

export default SkeletonImage;
