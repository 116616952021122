import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postRentalReference = createApiThunk(
  "rentalReference/postRentalReference",
  "api",
  "Rental Reference api used",
  "rental-reference",
);

export const clearRentalReferenceResults =
  () => async (dispatch: AppDispatch) => {
    dispatch({ type: "rentalReference/clearResults" });
  };

export const modifyRentalReferenceQueries =
  () => async (dispatch: AppDispatch) => {
    dispatch({ type: "rentalReference/modifyQueries" });
  };

export const RentalReferenceOneTimeUse = createApiThunk(
  "rentalReference/referenceOneTimeUse",
  "demo",
  "Rental Reference demo used",
  "rental-reference",
);
