import React from "react";
import { formatQuery } from "react-querybuilder";
import { CopyBlock, a11yDark } from "react-code-blocks";

interface Values {
  text: any;
}

const CodeBlock: React.FC<Values> = ({ text }) => {
  return (
    <div
      className="w-full"
      style={{
        fontFamily: "IBM Plex Mono",
      }}
    >
      <CopyBlock
        text={formatQuery(text as any, "json")}
        theme={a11yDark}
        codeBlock
        language="json"
        customStyle={{
          minHeight: "700px",
          maxHeight: "780px",
          overflowY: "auto",
          borderRadius: "5px",
          boxShadow: "1px 2px 3px rgba(0,0,0,0.35)",
          fontSize: "1rem",
          margin: "0px 0.75rem",
        }}
      />
    </div>
  );
};

export default CodeBlock;
