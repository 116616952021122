import axios from "axios";

export const sendToSlack = async (
  email: string,
  responses: {
    question: string | undefined;
    answers: string;
  }[],
) => {
  try {
    const slackMessage = {
      text: `New submission from ${email}`,
      attachments: [
        {
          color: "#36a64f", // You can use a good color for the attachment
          author_name: email,
          title: "New Onboarding Responses",
          text: `Email: ${email}`,
          fields: responses.map((response) => ({
            title: response.question,
            value: response.answers,
            short: false,
          })),
        },
      ],
    };
    await axios.post(
      "https://hooks.slack.com/services/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",
      JSON.stringify(slackMessage),
    );
  } catch (error) {
    console.error("error", error);
  }
};
