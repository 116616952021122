import { MOPSUS, TRANSACTION, UNLIMITED } from "./stripePlanTypes";

const productData = {
  [MOPSUS._100]: {
    price: 47,
    credits: 100,
    ppu: 0.47,
    discount: "",
  },
  [MOPSUS._250]: {
    price: 85,
    credits: 250,
    ppu: 0.34,
    discount: "28%",
  },
  [MOPSUS._750]: {
    price: 200,
    credits: 750,
    ppu: 0.267,
    discount: "43%",
  },
  [MOPSUS._2000]: {
    price: 390,
    credits: 2000,
    ppu: 0.195,
    discount: "58%",
  },
  [MOPSUS._20_DAILY]: {
    price: 75,
    credits: 20,
    ppu: 0.125,
    discount: "73.40%",
  },
  [MOPSUS._100_DAILY]: {
    price: 250,
    credits: 100,
    ppu: 0.083,
    discount: "82.34%",
  },
  [MOPSUS._250_DAILY]: {
    price: 400,
    credits: 250,
    ppu: 0.053,
    discount: "88.72%",
  },
  [MOPSUS._1200]: {
    price: 462,
    credits: 1200,
    ppu: 0.385,
    discount: "40,68%",
  },

  [MOPSUS._3000]: {
    price: 836,
    credits: 3000,
    ppu: 0.279,
    discount: "40,68%",
  },
  [MOPSUS._9000]: {
    price: 1968,
    credits: 9000,
    ppu: 0.219,
    discount: "53,48%",
  },
  [MOPSUS._24000]: {
    price: 3838,
    credits: 24000,
    ppu: 0.16,
    discount: "65,98%",
  },

  [TRANSACTION._100]: {
    price: 75,
    credits: 100,
    ppu: 0.75,
    discount: "",
  },
  [UNLIMITED.WOZ]: {
    price: 1250,
    credits: "onbeperkt",
    ppu: 0,
    discount: "",
  },
  [UNLIMITED.OBJ_DATA]: {
    price: 750,
    credits: "onbeperkt",
    ppu: 0,
    discount: "",
  },
  [UNLIMITED.AVM]: {
    price: 2500,
    credits: "onbeperkt",
    ppu: 0,
    discount: "",
  },
  [UNLIMITED.REFERENCE]: {
    price: 1500,
    credits: "onbeperkt",
    ppu: 0,
    discount: "",
  },
  [UNLIMITED.ECO]: {
    price: 2500,
    credits: "onbeperkt",
    ppu: 0,
    discount: "",
  },
  [UNLIMITED.ENERGY]: {
    price: 1000,
    credits: "onbeperkt",
    ppu: 0,
    discount: "",
  },
};

export const getPrice = (product: string) => productData[product]?.price || 0;

export const getCredits = (product: string) => productData[product]?.credits;

export const getPpu = (product: string) => productData[product]?.ppu;

export const getDiscount = (product: string) =>
  productData[product]?.discount || "";

export const isYearlyPlan = (plan: string): boolean => {
  const yearlyPlans = [MOPSUS._1200, MOPSUS._3000, MOPSUS._9000, MOPSUS._24000];

  return yearlyPlans.includes(plan);
};
export const isUnlimitedPlan = (plan: string): boolean => {
  return Object.values(UNLIMITED).includes(plan);
};
export const isTransactionPlan = (plan: string): boolean => {
  const transactionPlan = [TRANSACTION._100];
  return transactionPlan.includes(plan);
};
export const isPPUPlan = (plan: string): boolean => {
  const ppu = [MOPSUS._100, MOPSUS._250, MOPSUS._750, MOPSUS._2000];
  return ppu.includes(plan);
};

export const isDailyQuota = (plan: string): boolean => {
  const dailyQuotaPlans = [
    MOPSUS._20_DAILY,
    MOPSUS._100_DAILY,
    MOPSUS._250_DAILY,
  ];
  return dailyQuotaPlans.includes(plan);
};
