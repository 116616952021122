import React from "react";
import logo from "../assets/images/Logo-AltumAI.png";
type Props = {
  className?: string;
};

const Logo = (props: Props) => {
  return (
    <div className={props.className + ""}>
      <img src={logo} alt="logo" className={"w-full"} />
    </div>
  );
};

export default Logo;
