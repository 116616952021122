import { useEffect, useState } from "react";
import axios from "axios";
import Settings from "./Settings";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getAllUserEmailSettings } from "../../../../redux/actions/settingAction";
import Subtitle from "../../../../components/Subtitle";
import Text from "../../../../components/Text";
import { HiOutlineMail, HiOutlineUser } from "react-icons/hi";
import VAT from "../../../../assets/images/VAT.png";
function Account() {
  const [vatId, setVatId] = useState<any[]>([]);
  const { user } = useAppSelector((state) => state.auth);
  const { emailSettings } = useAppSelector((state) => state.settings);

  const dispatch = useAppDispatch();

  const getVatId = async () => {
    try {
      const res = await axios.get<any>("/api/v1/users/vat");
      setVatId(res.data.taxIds.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVatId();
    user && dispatch(getAllUserEmailSettings(user?.user_id));
  }, [emailSettings, user, dispatch]);

  return (
    <div className="flex flex-col gap-6 mt-4 md:mt-8">
      <div className="flex items-center">
        <HiOutlineUser size={20} className="mr-4" />
        <Subtitle className="text-base">Accountinformatie</Subtitle>
      </div>
      <div className="flex items-center gap-4 mt-4 self-start">
        <img
          className="object-fill rounded-full w-full h-28"
          src={`https://ui-avatars.com/api/?name=${
            user?.company || user?.first_name || user?.email
          }`}
          alt="avatar"
        />
        <div className="flex flex-col">
          <Subtitle>
            {user?.company.toUpperCase() ||
              `${user?.first_name.toUpperCase()} ${user?.last_name.toUpperCase()}`}
          </Subtitle>
          <div className="flex items-center">
            <HiOutlineMail size={20} className="mr-2 text-blue-900" />
            <Text className="text-gray-light">{user?.email}</Text>
          </div>
          <div className="flex items-center">
            <img
              src={`https://d5mm8ez7cpmwi.cloudfront.net/eyJidWNrZXQiOiJzaWduaWNhdC1idWNrZXQiLCJrZXkiOiJsb2dvcy9rdmstbG9nby5wbmciLCJlZGl0cyI6eyJ3ZWJwIjp7InF1YWxpdHkiOjgyfSwicmVzaXplIjp7IndpZHRoIjoxMDI0LCJoZWlnaHQiOjU1OSwiZml0IjoiY292ZXIifSwic2hhcnBlbiI6dHJ1ZX19`}
              alt="KVK"
              className="mr-2 w-5"
            />
            <Text>{user?.kvk}</Text>
          </div>
          <div className="flex items-center">
            <img src={VAT} alt="VAT" className="mr-2 w-5" />
            <Text>{vatId[0]?.value}</Text>
          </div>
        </div>
      </div>
      {emailSettings && <Settings {...emailSettings} />}
    </div>
  );
}

export default Account;
