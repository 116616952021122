export const energyClimateFields = [
  {
    label: "Postcode*",
    name: "postcode",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "housenumber",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "houseaddition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Bron",
    name: "source",
    type: "radio",
    placeholder: "",
    tooltip: "",
    options: [
      { label: "Ophalen van EP-online", value: 0 },
      { label: "Ophalen uit de database met méér data", value: 1 },
    ],
  },
];
