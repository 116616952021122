import React, { useEffect, useState } from "react";
import APIResult, { LightText } from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { rearrangeDate } from "../../../helpers/common";
import { useHistory, Redirect } from "react-router-dom";
import {
  clearWozResults,
  modifyWozQueries,
} from "../../../redux/actions/wozActions";
import {
  FormProvider,
  useFormContext,
} from "../../../pages/Api/usage-pages/components/FormContext";

const index = () => {
  return (
    <FormProvider>
      <WozResult />
    </FormProvider>
  );
};

const WozResult = () => {
  const { result, loading } = useAppSelector((state) => state.woz);
  const { buildingPhoto, setPostalAddress, fetchAddressData } =
    useFormContext();

  const property: any = result;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearWozResults());
    history.push("/woz");
  };

  const modifyResults = () => {
    dispatch(modifyWozQueries());
    history.push("/woz");
  };

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      setPostalAddress(property.HouseAddress);
    }
  }, [property, setPostalAddress]);
  useEffect(() => {
    if (Object.keys(result).length > 0 && buildingPhoto.length === 0) {
      fetchAddressData();
    }
  }, [result, buildingPhoto.length, fetchAddressData]);

  if (Object.keys(property).length === 0) {
    return <Redirect to="/woz" />;
  }
  const overview = [
    {
      title: "Resultaat",
      details: [
        {
          key: "Woningtype",
          value: property.HouseType,
        },

        {
          key: "Bouwjaar",
          value: property.BuildYear,
        },
        {
          key: "Perceeloppervlakte",
          value: `${convertToEuFormat(property.OuterSurfaceArea)} m2`,
        },
        {
          key: "Opgezocht op",
          value: property["WOZ-source_date"]
            ? rearrangeDate(property["WOZ-source_date"])
            : "",
        },
      ],
    },
    {
      title: "Peildatum - WOZ-waarde",
      details: property.wozvalue.map(
        (wozValueItem: { Date: string; Value: string }) => ({
          key: wozValueItem.Date,
          value: `€ ${convertToEuFormat(wozValueItem.Value)}`,
        }),
      ),
    },
  ];

  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: property.BagID,
    },
    {
      img: "",
      title: "Huisnummer toevoeging",
      result: `${property.HouseAddition ? property.HouseAddition : "-"}`,
    },
    {
      img: "",
      title: "Latitude",
      result: property.Latitude,
    },
    {
      img: "",
      title: "Longitude",
      result: property.Longitude,
    },
  ];

  const reference = [
    { text: "Marktwaarde bepalen", path: "/avm" },
    { text: "Referenties opvragen", path: "/referentie" },
    { text: "Kadaster transactie ophalen", path: "/transactie-api" },
  ];
  return (
    <APIResult
      property={property}
      loading={loading}
      title={"WOZ waarde gegevens - Altum AI"}
      street={property.HouseNumber + " " + property.Street}
      postCode={property.PostCode}
      city={property.City}
      overview={overview}
      reference={reference}
      buildingPhoto={buildingPhoto}
      descSection={desc}
      result={result}
      path="https://docs.altum.ai/apis/woz-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
      description={<WOZDesc property={property} />}
    />
  );
};

const WOZDesc: React.FC<{ property: any }> = ({ property }) => {
  const details = property.wozvalue.map(
    (wozValueItem: { Date: string; Value: string }) => ({
      key: wozValueItem.Date,
      value: `€${convertToEuFormat(wozValueItem.Value)}`,
    }),
  );

  const firstItemValue = details[0]?.value;

  return (
    <LightText style={{ marginBottom: "20px", textAlign: "left" }}>
      Voor de woning uit <strong>{property.BuildYear}</strong>, gelegen aan de{" "}
      {property.Street +
        " " +
        property.HouseNumber +
        " " +
        property.HouseAddition +
        " " +
        property.City}{" "}
      is de laatst bekende WOZ waarde: {firstItemValue}.
    </LightText>
  );
};

export default index;
