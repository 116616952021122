import axios from "axios";
import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsage } from "./apiUsage";
import addGAEvent from "../../helpers/addGAEvent";
import { getAllUnreadMessagesUser } from "./messageActions";
import { User } from "../../@types";
import { AppDispatch } from "../store";

export interface SignUpPayload {
  message: string;
  id: string;
  active: boolean;
  accountInitialised: boolean;
  status: string;
}
export interface SignInPayload {
  token: string;
  user: User;
}

interface LoadUserPayload {
  status: string;
  user: User;
}
interface FormData {
  email: string;
  password: string;
}

// Async thunk to signUp
export const signUp = createAsyncThunk<string, FormData>(
  "auth/signUp",
  async (formData, thunkApi) => {
    try {
      const data = {
        first_name: "",
        last_name: "",
        email: formData.email,
        password: formData.password,
      };
      const config = { headers: { "Content-Type": "application/json" } };

      // POST to API server
      const response = await axios.post<SignUpPayload>(
        "/api/v1/users/signup",
        data,
        config,
      );
      // After account creating, send google analytics event
      addGAEvent("User", "Account created");

      // Send amplitude event
      const tagBody = { event: "Signup", userId: formData.email };
      await axios.post("/api/v1/mopsus/tag-event", tagBody, config);
      toast.info(response.data.message);
      if (response.data.id) {
      }
      return response.data.id;
    } catch (error: any) {
      toast.error(error.response?.data.message);
      return thunkApi.rejectWithValue(error.response?.data.message);
    }
  },
);

// Async thunk to signIn
export const signIn = createAsyncThunk<
  SignInPayload,
  { formData: Omit<FormData, "name">; dispatch: AppDispatch }
>("auth/signIn", async ({ formData, dispatch }, thunkApi) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const data = {
      email: formData.email,
      password: formData.password,
    };
    // POST to API server
    const res = await axios.post<SignInPayload>(
      "/api/v1/users/signin",
      data,
      config,
    );
    // After auth success, send google analytics event
    addGAEvent("User", "Log In");

    // Send amplitude event
    const tagBody = { event: "Login", userId: formData.email };
    await axios.post("/api/v1/mopsus/tag-event", tagBody, config);

    // After auth success, load user
    dispatch(loadUser(dispatch));
    return { token: res.data.token, user: res.data.user };
  } catch (error: any) {
    const errors = error.response?.data.message || error.message;
    toast.error(errors);
    return thunkApi.rejectWithValue(errors);
  }
});

export const loadUser = createAsyncThunk<User, AppDispatch>(
  "auth/loadUser",
  async (dispatch, thunkApi) => {
    try {
      axios.defaults.withCredentials = true;
      // GET request to API server
      const res = await axios.get<LoadUserPayload>("/api/v1/users");
      const userId = res.data.user.user_id;

      // Dispatch get API usage
      dispatch(getUsage());
      dispatch(getAllUnreadMessagesUser({ userId, dispatch }));

      return res.data.user;
    } catch (error: any) {
      const errors = error.response?.data.message;
      return thunkApi.rejectWithValue(errors);
    }
  },
);

export const logout = createAsyncThunk("auth/logout", async () => {
  window.localStorage.removeItem("profile");
  window.localStorage.removeItem("profileToken");

  const res = await axios.get("/api/v1/users/logout");
  return res.data;
});

export const verifyAccount = createAsyncThunk<boolean, string>(
  "auth/verify",
  async (userId: string, { rejectWithValue }) => {
    try {
      await axios.patch(`/api/v1/users/account-verification/${userId}`);
      toast.success("Succesvol aangemeld");
      return true;
    } catch (error) {
      return rejectWithValue(false);
    }
  },
);

export const verifyEmail = createAsyncThunk(
  "auth/verify-email",
  async ({ email, otp }: { email: string; otp: string }, thunkApi) => {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const data = { email: email, code: otp };
      const res = await axios.post<SignInPayload>(
        `/api/v1/users/verify-email`,
        data,
        config,
      );
      toast.success("Succesvol aangemeld");
      return { token: res.data.token, user: res.data.user };
    } catch (error: any) {
      toast.error(error.response?.data.message);
      throw new Error(error);
    }
  },
);

export const resendCode = createAsyncThunk(
  "auth/resendCode",
  async (email: string, thunkApi) => {
    try {
      const res = await axios.post<{ message: string }>(
        "/api/v1/users/send-verification-email",

        {
          email: email,
        },
      );
      toast.success(res.data.message);
      return res;
    } catch (error: any) {
      toast.error(error.response?.data.message);
      throw new Error(error);
    }
  },
);

export const allowSignupConfirmation = createAsyncThunk(
  "auth/allowSignupConfirm",
  async (userId: string) => {
    try {
      await axios.get(`/api/v1/users/allow-signup-confirmation/${userId}`);
      return true;
    } catch (error) {
      return false;
    }
  },
);
