import React, { useState, useEffect } from "react";
import APIResult from "../APIResult";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { useHistory } from "react-router-dom";
import {
  clearLabellingResults,
  modifyLabellingQueries,
} from "../../../redux/actions/imageLabelActions";
import translateToDutch from "../../../helpers/translateToDutch";
const ImageLabelResult = () => {
  const { result, loading, savedQueries } = useAppSelector(
    (state) => state.imageLabel,
  );
  const property: any = result;

  const [buildingPhoto, setBuildingPhoto] = useState<string>("");

  useEffect(() => {
    const value =
      Object.keys(savedQueries).length > 0 && (savedQueries as any)["preview"];
    setBuildingPhoto(value);
  }, [savedQueries]);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearLabellingResults());
    history.push("/foto-labelen");
  };

  const modifyResults = () => {
    dispatch(modifyLabellingQueries());
    history.push("/foto-labelen");
  };

  const overview = [
    {
      title: "Label",
      details: [
        {
          key: property.Image_Class && " Afbeelding klasse",
          value: `${
            property.Image_Class && translateToDutch(property.Image_Class)
          }`,
        },
        {
          key:
            property.ConfidencePercent_Image_Class &&
            "Betrouwbaarheidspercentage afbeeldingsklasse",
          value: `${
            property.ConfidencePercent_Image_Class &&
            convertToEuFormat(property.ConfidencePercent_Image_Class)
          } %`,
        },

        {
          key: property.Scene_Class1 && "Scène klasse1",
          value: `${
            property.Scene_Class1 && translateToDutch(property.Scene_Class1)
          } `,
        },
        {
          key:
            property.ConfidencePercent_Scene_Class1 &&
            "Betrouwbaarheidspercentage Scène klasse1",
          value: `${
            property.ConfidencePercent_Scene_Class1 &&
            convertToEuFormat(property.ConfidencePercent_Scene_Class1)
          } %`,
        },
      ],
    },
  ];

  const reference = [
    {
      text: "Keuken en badkamer waarderen",
      path: "/conditie-score",
    },
  ];

  return (
    <>
      <APIResult
        property={property}
        loading={loading}
        title={"Woningfoto gelabeld - Altum AI"}
        street={" "}
        postCode={" "}
        city={" "}
        overview={overview}
        reference={reference}
        descSection={[]}
        buildingPhoto={buildingPhoto}
        result={result}
        path="https://docs.altum.ai/apis/amenities-api"
        clearResult={clearResults}
        modifyResult={modifyResults}
      />
    </>
  );
};

export default ImageLabelResult;
