import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postRebuild = createApiThunk(
  "rebuild/postRebuild",
  "api",
  "Rebuild api used",
  "rebuild",
);

export const clearRebuildResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "rebuild/clearyResults" });
};

export const modifyRebuildQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "rebuild/modifyQueries" });
};

export const rebuildOneTimeUse = createApiThunk(
  "rebuild/rebuildOneTimeUse",
  "demo",
  "Rebuild demo used",
  "rebuild",
);
