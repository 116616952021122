import createApiSlice from "../../helpers/createApiSlice";
import {
  objectDataOneTimeUse,
  postObjectData,
} from "../actions/objectDataActions";

const objectDataSlice = createApiSlice(
  "objectdata",
  postObjectData,
  objectDataOneTimeUse,
);

export const { clearResults, modifyQueries } = objectDataSlice.actions;

export default objectDataSlice.reducer;
