import { Dropdown as FDropdown } from "flowbite-react";
import { FC, ReactNode } from "react";

interface Props {
  label: string | ReactNode;
  items: string[];
  setItem: React.Dispatch<React.SetStateAction<string>>;
  noFloat?: boolean | undefined;
}
const Dropdown: FC<Props> = ({ label, items, setItem, noFloat }) => {
  return (
    <FDropdown
      label={label}
      color={"primary"}
      arrowIcon={!noFloat}
      theme={{
        floating: {
          target:
            "max-w-[468px] focus:ring-0 dark:focus:ring-0 items-start justify-start text-primary",
        },
      }}
    >
      {items.map((item, index) => (
        <FDropdown.Item key={index} onClick={() => setItem(item)}>
          {item}
        </FDropdown.Item>
      ))}
    </FDropdown>
  );
};

export default Dropdown;
