import React, { useState } from "react";
import { FormProvider } from "../../components/FormContext";
import Sustainability from "../components/SustainabilityContainer";
import SustainabilityResult from "./SustainabilityResult";

const Index = () => {
  const [page] = useState(4);

  return (
    <FormProvider>
      <Sustainability page={page}>
        <SustainabilityResult />
      </Sustainability>
    </FormProvider>
  );
};

export default Index;
