import { FormEvent, useEffect, useState } from "react";
import FormBody from "../FormBody";
import { postAvmDetails } from "../../../redux/actions/avmActions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { postEnergyClimate } from "../../../redux/actions/energyClimateActions";
import { energyClimate } from "../../../assets/images/api/APIimages";
import { energyClimateFields } from "./energyClimateFields";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const EnergyClimateForm = () => {
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 3 }];
  const { savedQueries } = useAppSelector((state) => state.avm);

  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  const dispatch = useAppDispatch();

  const { formValues, setFormValues } = useFormContext();

  //TODO: [ALTUMAI-1625] Replicate this for all the forms so they can use the same query
  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);
  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions | FormOptionsSustainability = {
      postcode: "",
      housenumber: "",
    };
    formValues.postcode = formValues.postcode.split(" ").join("").toUpperCase();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof (FormOptions | FormOptionsSustainability)] =
          value;
      }
    });
    apiKey && dispatch(postEnergyClimate({ formData: newFormData, apiKey }));
  };

  return (
    <FormBody
      title={"Energie & klimaat"}
      desc={
        "Voor het opvragen van alle relevante BAG-, energielabel- & klimaatgegevens voor een woning."
      }
      path={"https://docs.altum.ai/apis/energie-and-klimaat-api"}
      img={energyClimate}
      sectionFields={sectionFields}
      initialFields={energyClimateFields}
      handleSubmit={handleFormSubmit}
    />
  );
};

export default EnergyClimateForm;
