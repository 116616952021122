import { AppDispatch } from '../store';
import { createApiThunkImgApi } from '../../helpers/createApiThunk';
// Dispatch function to POST Image label API
export const postLabelling = createApiThunkImgApi(
  'labelling/postLabelling',
  'api',
  'Labelling api used',
  'labelling',
);

export const clearLabellingResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'labelling/clearResults' });
};

export const modifyLabellingQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'labelling/modifyQueries' });
};

export const postLabellingOneTimeUse = createApiThunkImgApi(
  'labelling/postConditionScore',
  'demo',
  'Labelling demo used',
  'labelling',
);
