import { Button } from "flowbite-react";
import { FC, useCallback } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";

const BackButton: FC<{ link?: string; color?: string }> = ({
  link,
  color = "text-black",
}) => {
  const history = useHistory();
  const handleBack = useCallback(() => {
    if (link) history.push(link);
    else history.goBack();
  }, [history, link]);

  return (
    <Button onClick={handleBack} className=" self-start mt-0 p-0">
      <IoMdArrowBack className={`h-6 w-6 ${color}`} />
    </Button>
  );
};

export default BackButton;
