import React, { useCallback, useMemo } from "react";
import Slider from "react-input-slider";
import { handleTargetLabel } from "../helpers/common";

interface EnergyLabelSliderProps {
  inputName?: string;
  targetLabel?: string;
  onSliderChange?: (value: number) => void;
}

const EnergyLabelSlider: React.FC<EnergyLabelSliderProps> = ({
  inputName,
  targetLabel = "A",
  onSliderChange,
}) => {
  const handleChange = useCallback(
    ({ x }: { x: number }) => {
      onSliderChange?.(x);
    },
    [onSliderChange],
  );

  const sliderValue = useMemo(
    () => handleTargetLabel(targetLabel),
    [targetLabel],
  );

  const sliderStyles = {
    active: {
      background: "#14a562",
    },
  };

  return (
    <div className="input-flex">
      <div id={inputName} style={{ marginTop: "10px" }}>
        <Slider
          axis="x"
          xstep={1}
          xmin={0}
          xmax={10}
          x={sliderValue}
          onChange={handleChange}
          styles={sliderStyles}
        />
      </div>
    </div>
  );
};

function arePropsEqual(
  prevProps: EnergyLabelSliderProps,
  nextProps: EnergyLabelSliderProps,
) {
  return (
    handleTargetLabel(prevProps.targetLabel) ===
    handleTargetLabel(nextProps.targetLabel)
  );
}

export default React.memo(EnergyLabelSlider, arePropsEqual);
