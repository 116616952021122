import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getDate, getMonth, getYear } from "date-fns";
import { User } from "../../@types";

interface AllUsers {
  users: User[];
}
interface AllMessages {
  data: {
    messages: {
      message: {
        id: string;
      };
    }[];
  };
}
interface AdminAnalytics {
  results: [
    { event: string; count: number },
    {
      event: string;
      count: number;
    },
  ];
}

const returnUsagePlan = (usagePlan: string) => {
  switch (usagePlan) {
    case "812sc7":
      return "Platform  - Free Tier";
    case "0bpa4v":
      return "Platform  - Pay per use";
  }
};

export const getUsers = createAsyncThunk("admin/getUsers", async () => {
  const config = { headers: { "Content-Type": "application/json" } };
  const res = await axios.get<AllUsers>("/api/v1/admin/get-users", config);

  const { users } = res.data;
  const formatUsers = [
    ...users.map((user: any) => {
      const date = new Date(user.created_at);
      const day = getDate(date);
      const month = getMonth(date);
      const year = getYear(date);

      return {
        ...user,
        created_at: `${day}/${month + 1}/${year}`,
        active: String(user.active),
        current_usage_plan: returnUsagePlan(user.current_usage_plan),
      };
    }),
  ];

  return formatUsers;
});

export const getAllMessages = createAsyncThunk(
  "admin/getAllMessages",
  async () => {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get<AllMessages>("/api/v1/messages", config);
    const { messages } = res.data.data;
    const formatMessages = [
      ...messages.map((message: any) => {
        const date = new Date(message.created_at);
        const day = getDate(date);
        const month = getMonth(date);
        const year = getYear(date);

        return {
          ...message,
          created_at: `${day}/${month + 1}/${year}`,
        };
      }),
    ];

    return formatMessages;
  },
);

export const postNewMessage = createAsyncThunk(
  "admin/postNewMessage",
  async (data: any) => {
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.post("/api/v1/messages/general-message", data, config);
    toast.success("Message created");
  },
);

export const deleteMessages = createAsyncThunk(
  "admin/deleteMessages",
  async (idArr: string[]) => {
    const config = { headers: { "Content-Type": "application/json" } };
    const data = { ids: idArr };
    await axios.patch("/api/v1/messages", data, config);
    toast.dark("Message deleted");
  },
);

export const getAnalytics = createAsyncThunk(
  "admin/getAnalytics",
  async (date: string) => {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get<AdminAnalytics>(
      `/api/v1/admin/analytics/${date}`,
      config,
    );
    return res.data.results;
  },
);

export const getAnalyticsLineChart = createAsyncThunk(
  "admin/getAnalyticsLineChart",
  async (arg: { start: string; end: string }) => {
    const { start, end } = arg;
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get<AdminAnalytics>(
      `/api/v1/admin/analytics/line/${start}/${end}`,
      config,
    );

    return res.data.results;
  },
);

export const saveEditedUser = createAsyncThunk(
  "admin/saveEditedUser",
  async ({ id, data }: { id: string; data: any }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.patch(`/api/v1/admin/edit-user/${id}`, data, config);
    toast.success("User updated");
  },
);

export const deleteUser = createAsyncThunk(
  "admin/deleteUser",
  async (id: string) => {
    const config = { headers: { "Content-Type": "application/json" } };

    await axios.delete(`/api/v1/admin/user/${id}`, config);
    toast.success("User deleted");
  },
);
