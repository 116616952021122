import React, { FC, useEffect } from "react";
import Subtitle from "../../../../../components/Subtitle";
import ReturnInputField from "../../../../../components/ReturnInputField";
import { preference } from "../data";
import { useFormContext } from "../../components/FormContext";
import { ActionMeta, MultiValue } from "react-select";

type Props = {};
type Option = {
  label: string;
  value: string | number;
};

const Preferences: FC<Props> = () => {
  const { formValues, setFormValues } = useFormContext();
  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      replace_boiler: "true",
      heat_pump_allowed: "true",
      replace_windowframes: "false",
    }));
  }, [setFormValues]);
  return (
    <div className="">
      <Subtitle className="text-base">
        Welke maatregelen sluit je liever uit in het advies?
      </Subtitle>
      <div className="grid grid-cols-1 gap-8 mt-4 max-w-[487px]">
        {preference.map((field, index) => (
          <ReturnInputField
            key={index}
            label={field.label}
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            options={field.options}
            onChange={(
              e:
                | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
                | MultiValue<Option>
                | Option,
              a?: ActionMeta<Option>,
            ) => {
              if (Array.isArray(e)) {
                const result = e?.map(({ value }) => value);
                if (a?.name === "lock_measures") {
                  setFormValues({ ...formValues, [a.name]: { lock: result } });
                } else {
                  a?.name && setFormValues({ ...formValues, [a.name]: result });
                }
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Preferences;
