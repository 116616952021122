import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import { FaStar } from "react-icons/fa";

interface RatingFormProps {
  chatHistoryId: string;
}

const RatingForm: React.FC<RatingFormProps> = ({ chatHistoryId }) => {
  const [rating, setRating] = useState<number | null>(null);
  const [hover, setHover] = useState<number | null>(null);
  const [comment, setComment] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleRatingSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!rating) return;

    try {
      await axios.post("/api/v1/property-description/rate-comment", {
        id: chatHistoryId,
        rate: rating,
        comment: comment || undefined,
      });
      toast.success("Beoordeling succesvol ingediend!");
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting rating:", error);
      toast.error(
        "Er is een fout opgetreden bij het indienen van de beoordeling.",
      );
    }
  };

  if (isSubmitted) {
    return (
      <div className="mt-6 border rounded-lg p-4 text-gray-dark bg-gray-100">
        <h3 className="text-xl font-semibold mb-3">
          Bedankt voor uw feedback!
        </h3>
      </div>
    );
  }

  return (
    <div className="mt-6 border-t pt-4 bg-gray-100 p-4 rounded-md">
      <h3 className="text-xl font-semibold mb-3">
        Beoordeel deze beschrijving
      </h3>
      <form onSubmit={handleRatingSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Beoordeling
          </label>
          <div className="flex justify-between gap-4">
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <label key={index} className="cursor-pointer w-full">
                  <Button
                    className={`mr-1 w-full ${
                      ratingValue === (hover || rating || 0)
                        ? "bg-[#BB6BD9] text-white"
                        : "bg-white text-gray-dark"
                    }`}
                    onClick={() => setRating(ratingValue)}
                    onMouseEnter={() => setHover(ratingValue)}
                    onMouseLeave={() => setHover(null)}
                  >
                    {ratingValue}
                  </Button>
                </label>
              );
            })}
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="comment"
            className="block text-sm font-medium text-gray-700"
          >
            Commentaar (optioneel)
          </label>
          <textarea
            id="comment"
            name="comment"
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="Voer uw commentaar hier in..."
          ></textarea>
        </div>
        <Button
          type="submit"
          className="w-full md:w-auto bg-[#BB6BD9] text-white"
          disabled={!rating}
        >
          Verstuur beoordeling
        </Button>
      </form>
    </div>
  );
};

export default RatingForm;
