import google from "../../../assets/images/Google.png";
import linkedin from "../../../assets/images/linkedin.png";

type Props = {};

const Social = (props: Props) => {
  return (
    <div className="flex gap-2 mx-auto w-24">
      <a href="/auth/google" className="p-2 border rounded-md shadow">
        <img src={google} alt="google" />
      </a>
      <a href="/auth/linkedin">
        <img
          src={linkedin}
          alt="linkedin"
          className="p-2 border rounded-md shadow"
        />
      </a>
    </div>
  );
};

export default Social;
