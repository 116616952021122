import { ToggleSwitch } from "flowbite-react";
import React, { FC } from "react";
import Subtitle from "../../../../components/Subtitle";
import Text from "../../../../components/Text";
import { TbExternalLink } from "react-icons/tb";
import Switch from "../../../../components/Switch";

type Props = {
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  link: string;
};

const JsonToggle: FC<Props> = ({ toggle, setToggle, link }) => {
  return (
    <div className="flex justify-between items-center max-w-[1100px] mb-4 p-2">
      <div className="flex gap-2 items-center">
        <Text className="text-base font-semibold">Visueel</Text>
        <Switch check={toggle} onChange={(e) => setToggle(e.target.checked)} />
        <Text className="text-base font-semibold">JSON </Text>
      </div>
      <div className="flex gap-2 ml-6 text-primary">
        <a href={link} target="__blank">
          <Text>Documentatie bekijken</Text>
        </a>
        <TbExternalLink />
      </div>
    </div>
  );
};

export default JsonToggle;
