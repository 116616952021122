import { useState, useEffect, FC, MouseEvent } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import { AsyncThunk } from "@reduxjs/toolkit";
import { FaCreditCard } from "react-icons/fa";
import { SiSepa } from "react-icons/si";
import { StripeError } from "@stripe/stripe-js";
import { handleSubmit } from "./checkoutSubmitHandler";
import { SubmitButton } from "./SubmitButton";
import { ErrorMessage } from "./ErrorMessage";
import IbanForm from "./IbanForm";
import { CreateSubscriptionPayload, User } from "../../@types";
import CheckoutOptions from "./CheckoutOptions";
import { BillingDetails } from "./BillingDetails";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CreditCardForm from "./CreditCardForm";
import { AppDispatch } from "../../redux/store";
import mastercard from "../../assets/images/mastercard.jpeg";
import americanExpress from "../../assets/images/American_Express-Logo.png";
import visa from "../../assets/images/visa.jpeg";
import sepa from "../../assets/images/sepa.png";
import {
  getMeteredTransactionId,
  getMeteredPlanId,
  getRecurringTransactionId,
  getRecurringPlanId,
  getRecurringUnlimitedId,
} from "../../helpers/stripeValues";
import Button from "../Button";
import stripeImg from "../../assets/images/Stripe.png";
import secure from "../../assets/images/3dsecure.png";

interface CheckoutFormProps {
  plan: string;
  user: User;
  history: any;
  createSubscription: AsyncThunk<
    boolean,
    {
      body: CreateSubscriptionPayload;
      dispatch: AppDispatch;
    },
    any
  >;
  updateSubscription: AsyncThunk<
    boolean,
    {
      body: CreateSubscriptionPayload;
      dispatch: AppDispatch;
    },
    any
  >;
  status: string | null;
}

export const CheckoutForm: FC<CheckoutFormProps> = ({
  plan,
  user,
  history,
  createSubscription,
  updateSubscription,
  status,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<Error | StripeError | null>(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    email: user.email,
    name: `${user.first_name} ${user.last_name}` || user.email,
  });
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isCard, setIsCard] = useState(true);
  const dispatch = useAppDispatch();
  const { cardPaymentMethods, sepaPaymentMethods } = useAppSelector(
    (state) => state.portal,
  );

  useEffect(() => {
    const getClientSecret = async () => {
      try {
        const result = await axios.get<{ clientSecret: string }>(
          "/api/v1/mopsus/create-setup-intent",
        );
        setClientSecret(result.data.clientSecret);
      } catch (error: any) {
        toast.error(error.response);
      }
    };
    getClientSecret();
  }, []);

  function handlePaymentMethodReuse(event: MouseEvent) {
    event.preventDefault();
    setProcessing(true);
    const body = {
      customerId: user.stripe_customer_id,
      paymentMethodId: isCard
        ? cardPaymentMethods.data[0].id
        : sepaPaymentMethods.data[0].id,
      apikey: plan.includes("Kadaster")
        ? user.transaction_api_key
        : user.api_key,
      meteredPriceId: plan.includes("Kadaster")
        ? getMeteredTransactionId(plan)
        : getMeteredPlanId(plan),
      recurringPriceId: plan.includes("Kadaster")
        ? getRecurringTransactionId(plan)
        : plan.includes("Altum AI") || plan.includes("Dagelijks")
        ? getRecurringPlanId(plan)
        : getRecurringUnlimitedId(plan),
    };
    if (status === "active") {
      dispatch(updateSubscription({ body, dispatch })).then(() => {
        setProcessing(false);
        history.push("/success");
      });
    } else {
      dispatch(createSubscription({ body, dispatch })).then(() => {
        setProcessing(false);
        history.push("/success");
      });
    }
  }

  return (
    <div className="p-4 flex flex-col">
      <div className="mb-4 flex justify-center gap-4">
        <CheckoutOptions
          icon={<FaCreditCard size="20" />}
          label="Credit Card"
          isCard={isCard}
          setIsCard={(prop) => setIsCard(prop)}
        />
        <CheckoutOptions
          icon={<SiSepa size="20" />}
          label="SEPA-incasso"
          isCard={!isCard}
          setIsCard={(prop) => setIsCard(!prop)}
        />
      </div>
      {processing ? (
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      ) : (
        <div className="flex justify-center">
          {(isCard &&
            cardPaymentMethods &&
            cardPaymentMethods?.data?.length > 0) ||
          (!isCard &&
            sepaPaymentMethods &&
            sepaPaymentMethods?.data?.length > 0) ? (
            <div className="mb-4 my-4 flex flex-col justify-center">
              <button
                className="flex items-center gap-2 rounded-md shadow-md p-2 transition-transform duration-300 ease-in-out hover:scale-105"
                onClick={handlePaymentMethodReuse}
              >
                <div className="flex items-center gap-2">
                  {isCard ? (
                    <img
                      className="w-14"
                      src={
                        cardPaymentMethods.data[0]?.card?.brand === "mastercard"
                          ? mastercard
                          : cardPaymentMethods?.data[0]?.card?.brand === "visa"
                          ? visa
                          : americanExpress
                      }
                      alt={"brand"}
                    />
                  ) : (
                    <img className="w-14" src={sepa} alt="sepa" />
                  )}
                  <div>
                    {isCard ? (
                      <>
                        <div>
                          Kaart eindigend op{" "}
                          {cardPaymentMethods?.data[0]?.card?.last4}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          Bankcode{" "}
                          {sepaPaymentMethods?.data[0]?.sepa_debit?.bank_code}
                        </div>
                        <div>
                          Iban eindigt op{" "}
                          {sepaPaymentMethods?.data[0]?.sepa_debit?.last4}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </button>
              <div className="my-4 flex justify-center">
                <div className="font-medium">OF</div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <form
        className=""
        onSubmit={(event) =>
          handleSubmit({
            event,
            isCard,
            stripe,
            elements,
            error,
            cardComplete,
            clientSecret,
            billingDetails,
            user,
            plan,
            history,
            createSubscription,
            updateSubscription,
            status,
            setError,
            setProcessing,
            dispatch,
          })
        }
      >
        {/* <BillingDetails
          billingDetails={billingDetails}
          setBillingDetails={(e) => setBillingDetails(e)}
        /> */}
        {isCard && (
          <CreditCardForm
            setError={(error) => setError(error)}
            setCardComplete={(complete) => setCardComplete(complete)}
          />
        )}
        {!isCard && (
          <fieldset className="checkout-formgroup">
            <IbanForm
              onChange={(e) => {
                e.error && setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </fieldset>
        )}

        {error && (
          <div className="mb-4">
            <ErrorMessage
              setError={(e) => {
                setError(e);
              }}
            >
              {error.message}
            </ErrorMessage>
          </div>
        )}

        <Button
          className="bg-primary w-full h-[43px]"
          isProcessing={processing}
          isError={!!error}
          disabled={!stripe}
          type="submit"
        >
          Bevestigen
        </Button>
      </form>
      <div className="flex m-auto w-full mt-8 h-8 items-center justify-center">
        <img src={stripeImg} alt="stripe" className="object-contain h-[20px]" />

        <img src={secure} alt="secure" className="object-scale-down h-[30px]" />
      </div>
    </div>
  );
};
