import React, { FC } from "react";
import ProgressBarComponent from "../../../../components/Progress";
import Title from "../../../../components/TItle";
import Text from "../../../../components/Text";

type Props = {
  step: number;
  title: string;
  description: string;
};

const GetstartedHeader: FC<Props> = ({ step, title, description }) => {
  const percent = Math.round((step * 100) / 6);
  return (
    <>
      <div className="mt-8 mb-8 md:mb-12 md:mt-8 text-center">
        <Title>{title}</Title>
        <Text className="text-gray-dark text-base">{description}</Text>
      </div>
      <Text className="text-gray-dark text-base mb-2">Onboarding</Text>
      <ProgressBarComponent
        label={`${step} van 6 Voltooid`}
        percentage={percent}
      />
    </>
  );
};

export default GetstartedHeader;
