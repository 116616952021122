import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import ImageForm, { ImageOptions } from "../ImageForm";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { postConditionScore } from "../../../redux/actions/conditionScoreActions";
import house from "../../../imgs/conditionHouse.jpg";

const ConditionScoreForm = () => {
  const [imageOptions, setImageOptions] = useState<ImageOptions>({
    url: "",
    image: undefined,
    preview: undefined,
  });
  const { savedQueries } = useAppSelector((state) => state.conditionScore);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setImageOptions({
        ...(savedQueries as ImageOptions),
      });
    }
  }, [savedQueries]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "url") {
      setImageOptions({
        image: undefined,
        url: e.target.value,
        preview: e.target.value,
      });
    }
  };
  const dispatch = useAppDispatch();
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData: ImageOptions = {
      url: imageOptions.url,
      image: imageOptions.image,
      preview: imageOptions.preview,
    };
    apiKey &&
      dispatch(
        postConditionScore({
          formData,
          apiKey,
          contentType: formData.url
            ? "application/json"
            : "multipart/form-data",
          endpoint: formData.url ? "url" : "img",
        }),
      );
  };

  return (
    <ImageForm
      handleFormSubmit={handleFormSubmit}
      imageOptions={imageOptions}
      setImageOptions={setImageOptions}
      handleChange={handleChange}
      title={"Conditie score badkamer en keuken bepalen"}
      desc={
        "Het Conditie Score model is te gebruiken om de conditiescore van badkamers en keukens te waarderen op een vijfpuntsschaal van uitstekend tot vervallen."
      }
      path={"https://docs.altum.ai/apis/condition-score-api"}
      initialFields={[]}
      img={house}
    />
  );
};

export default ConditionScoreForm;
