const apiColorPicker = (apiName: string) => {
  switch (apiName) {
    case "AVMAPI":
    case "AVM+":
      return "#B9E0AB";
    case "ObjectDataAPI":
      return "#C57B72";
    case "SustainabilityAPI":
      return "#5893FE";
    case "WOZAPI":
      return "#9189ED";
    case "ReferenceAPI":
    case "InteractiveReference-API":
      return "#EFA94A";
    case "TransactionAPI":
      return "#F3B7B0";
    case "EnergyLabelAPI":
      return "#E55137";
    case "OBJECT-GEOMETRY-API":
      return "#252850";
    case "MoveDataAPI":
      return "#45A098";
    case "RentalReferenceAPI":
      return "#DCEFD5";
    case "AutoSuggestAPI":
      return " #eae2b7";
    case "EnergyClimateAPI":
      return "#540b0e";
    case "EnergyInsightAPI":
      return "#9747FF";
    case "LabellingAPI":
      return "#7B9572";
    case "LocationAPI":
    case "Location-Data":
      return "#9AF5ED";
    case "RebuildAPI":
      return "#7B9572";
    case "SolarAPI":
      return "#0D5689";
    default:
      return "";
  }
};

export default apiColorPicker;
