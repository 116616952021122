import React, { FC } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { preparedChangedMeasuresFunc } from "./utils/measureUtils";
import PDFReport from "./PDFReport";

// Import fonts
import "./fontsPDF/WorkSans-Bold.ttf";
import "./fontsPDF/WorkSans-ExtraLight.ttf";
import "./fontsPDF/WorkSans-SemiBold.ttf";
import "./fontsPDF/WorkSans-Light.ttf";
import "./fontsPDF/WorkSans-Medium.ttf";
import "./fontsPDF/WorkSans-Regular.ttf";
import { IoMdDownload } from "react-icons/io";
import Button from "../../../../../components/Button";

interface Props {
  data: any;
  buildingPhoto: string;
  map: string;
}

const DownloadReport: FC<Props> = ({ data, buildingPhoto, map }) => {
  const prepareChangedMeasures = preparedChangedMeasuresFunc(data);
  const featuredMeasures = prepareChangedMeasures();

  if (map) {
    return (
      <Button type="button" className="flex items-center bg-primary" size="xl">
        <PDFDownloadLink
          document={
            <PDFReport
              data={data}
              buildingPhoto={buildingPhoto}
              map={map}
              featuredMeasures={featuredMeasures}
            />
          }
          fileName={`Verduurzamings_rapport-${data.address.post_code}-${
            data.address.house_number
          }${data.address.addition ? `-${data.address.addition}` : ""}.pdf`}
          style={{
            display: "flex",
          }}
        >
          <IoMdDownload size={20} />{" "}
          <span className="hidden md:inline ml-2">Download PDF</span>
        </PDFDownloadLink>
      </Button>
    );
  }
  return null;
};

export default React.memo(DownloadReport);
