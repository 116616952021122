import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import APIResult from "../APIResult";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { Redirect, useHistory } from "react-router-dom";
import {
  clearMoveDataResults,
  modifyMoveDataQueries,
} from "../../../redux/actions/moveDataActions";
import {
  FormProvider,
  useFormContext,
} from "../../../pages/Api/usage-pages/components/FormContext";
import { useEffect } from "react";

const MoveResult = () => {
  const { result, loading } = useAppSelector((state) => state.moveData);
  const property: any = result;
  const { buildingPhoto, setPostalAddress, fetchAddressData } =
    useFormContext();

  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      const postalAddress = `${property.PostCode}-${property.HouseNumber}-${
        property.HouseAddition ?? ""
      }`;
      setPostalAddress(postalAddress);
    }
  }, [property, setPostalAddress]);
  useEffect(() => {
    if (Object.keys(result).length > 0 && buildingPhoto.length === 0) {
      fetchAddressData();
    }
  }, [result, buildingPhoto.length, fetchAddressData]);

  const clearResults = () => {
    dispatch(clearMoveDataResults());
    history.push("/verhuisdata");
  };

  const modifyResults = () => {
    dispatch(modifyMoveDataQueries());
    history.push("/verhuisdata");
  };
  if (Object.keys(property).length === 0) {
    return <Redirect to="/verhuisdata" />;
  }
  const overview = [
    {
      title: "Resultaat",
      details: [
        {
          key: "Energielabel",
          value: property.EnergyLabel ? property.EnergyLabel : "-",
        },
        {
          key: "Voorlopig energielabel",
          value: property.ProvisionalEnergyLabel
            ? property.ProvisionalEnergyLabel
            : "-",
        },
        {
          key: "Energie-isolatie",
          value: property.EnergyInsulation ? property.EnergyInsulation : "-",
        },
        {
          key: "Verwarming",
          value: property.Heating ? property.Heating : "-",
        },
        {
          key: "Water opwarmen",
          value: property.WaterHeating ? property.WaterHeating : "-",
        },
        {
          key: "Ketel type",
          value: property.BoilerType ? property.BoilerType : "-",
        },
        {
          key: "Locatie Type",
          value: property.LocationType ? property.LocationType : "-",
        },
        {
          key: "Type tuin",
          value: property.GardenType ? property.GardenType : "-",
        },
        {
          key: "Balkon",
          value: property.Balcony ? property.Balcony : "-",
        },
        {
          key: "Dak, terras",
          value: property.RoofTerrace ? property.RoofTerrace : "-",
        },
        {
          key: "Grootte van de achtertuin",
          value: property.BackyardSize
            ? `${convertToEuFormat(property.BackyardSize)} m2`
            : "-",
        },
        {
          key: "Grootte zonneterras",
          value: property.SunTerraceSize
            ? `${convertToEuFormat(property.SunTerraceSize)} m2`
            : "-",
        },
        {
          key: "Grootte voortuin",
          value: property.FrontYardSize
            ? `${convertToEuFormat(property.FrontYardSize)} m2`
            : "-",
        },
        {
          key: "Maat zijtuin",
          value: property.SideYardSize
            ? `${convertToEuFormat(property.SideYardSize)} m2`
            : "-",
        },
        {
          key: "Patio atrium grootte",
          value: property.PatioAtriumSize
            ? `${convertToEuFormat(property.PatioAtriumSize)} m2`
            : "-",
        },
        {
          key: "Grootte binnenplaats",
          value: property.CourtYardSize
            ? `${convertToEuFormat(property.CourtYardSize)} m2`
            : "-",
        },
        {
          key: "Opslag type",
          value: property.StorageType ? property.StorageType : "-",
        },
        {
          key: "Opslagruimte",
          value: property.StorageRoomFacilities
            ? property.StorageRoomFacilities
            : "-",
        },
        {
          key: "Type parkeerplaats",
          value: property.ParkingType ? property.ParkingType : "-",
        },
        {
          key: "Type garage",
          value: property.GarageType ? property.GarageType : "-",
        },
        {
          key: "Garage Capaciteit",
          value: property.GarageCapacity ? property.GarageCapacity : "-",
        },
        {
          key: "Kwaliteitsopmerkingen",
          value: property.QualityRemarks ? property.QualityRemarks : "-",
        },
        {
          key: "Toegankelijkheid in de bouw",
          value: property.ConstructionAccessability
            ? property.ConstructionAccessability
            : "-",
        },
        {
          key: "Inrichting",
          value: property.Furnishing ? property.Furnishing : "-",
        },
        {
          key: "Appartement",
          value: property.Apartment ? property.Apartment : "-",
        },
        {
          key: "Type appartement",
          value: property.ApartmentType ? property.ApartmentType : "-",
        },
        {
          key: "Gebouwfaciliteiten",
          value: property.BuildingFacilities
            ? property.BuildingFacilities
            : "-",
        },
        {
          key: "Vraagprijs per M2",
          value: property.AskingPricePerM2
            ? `€ ${convertToEuFormat(property.AskingPricePerM2)}`
            : "-",
        },
        {
          key: "Tuinoriëntatie",
          value: property.GardenOrientation ? property.GardenOrientation : "-",
        },
        {
          key: "Objectstatus",
          value: property.ObjectStatus ? property.ObjectStatus : "-",
        },
        {
          key: "Buurtcode",
          value: property.NeighbourhoodCode ? property.NeighbourhoodCode : "-",
        },
        {
          key: "Buurtnaam",
          value: property.NeighbourhoodName ? property.NeighbourhoodName : "-",
        },
        {
          key: "Gemeentecode",
          value: property.MunicipalityCode ? property.MunicipalityCode : "-",
        },
        {
          key: "Gemeente naam",
          value: property.MunicipalityName ? property.MunicipalityName : "-",
        },
        {
          key: "Bron-URL",
          value: property.SourceURL ? property.SourceURL : "-",
        },
        {
          key: "Naam makelaar",
          value: property.RealtorName ? property.RealtorName : "-",
        },
        {
          key: "URL van makelaar",
          value: property.RealtorURL ? property.RealtorURL : "-",
        },
      ],
    },
  ];

  const reference = [
    { text: "Omgevingsdata opvragen", path: "/omgevingsdata" },
    { text: "BAG data opvragen", path: "/bag" },
    { text: "Object data opvragen", path: "/objectdata" },
  ];

  return (
    <APIResult
      property={property}
      loading={loading}
      title={"Verhuisdata - Altum AI"}
      street={property.HouseNumber + "" + property.Street}
      postCode={property.PostCode}
      city={property.City}
      overview={overview}
      reference={reference}
      buildingPhoto={buildingPhoto}
      path="https://docs.altum.ai/apis/move-data"
      clearResult={clearResults}
      modifyResult={modifyResults}
      result={result}
    />
  );
};

const Index = () => {
  return (
    <FormProvider>
      <MoveResult />
    </FormProvider>
  );
};
export default Index;
