import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import WozForm from "./WozForm";
import Loading from "../../Loading";
import { FormProvider } from "../../../pages/Api/usage-pages/components/FormContext";

const Woz = () => {
  const { result, loading } = useAppSelector((state) => state.woz);

  if (loading) {
    return <Loading />;
  }
  return (
    <FormProvider>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/woz-result",
          }}
        />
      ) : (
        <WozForm />
      )}
    </FormProvider>
  );
};

export default Woz;
