import axios from 'axios';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import UnsubscribeSvg from '../imgs/UnsubscribeSvg';

function Unsubscribe() {
  const history = useHistory();
  const [email, setEmail] = useState('');

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const res = await axios.post<any>(
        '/api/v1/users/unsubscribe',
        { email },
        { headers: { 'Content-Type': 'application/json' } },
      );
      const { success, message } = res.data;
      toast.success(message);
      if (success) {
        history.push('/');
      }
    } catch (error: any) {
      toast.error(error.response?.data.error);
    }
  };

  return (
    <div className="container">
      <div className="signup-container">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: 'auto' }}>
            <UnsubscribeSvg />
          </div>
          <form
            className="form"
            onSubmit={handleSubmit}
            autoComplete="off"
            noValidate
          >
            <div
              className="signup-form-group"
              style={{ margin: 'auto', minWidth: '300px' }}
            >
              <input
                type="email"
                pattern="[a-zA-Z0-9!#$%&'*_+-]([\.]?[a-zA-Z0-9!#$%&'*_+-])+@[a-zA-Z0-9]([^@&%$\/()=?¿!.,:;]|\d)+[a-zA-Z0-9][\.][a-zA-Z]{2,4}([\.][a-zA-Z]{2})?"
                placeholder="E-mailadres voorbeeld@mail.com"
                name="email"
								// className={userExists && 'input--error'}
                value={email}
                onChange={handleFormChange}
                tabIndex={3}
                required
                autoComplete="off"
              />
            </div>
            <div className="signup-btn-div">
              <button tabIndex={10} type="submit" className="btn btn-primary">
                Unsubscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Unsubscribe;
