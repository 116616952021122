import React from "react";
import Subtitle from "../../../../components/Subtitle";
import Text from "../../../../components/Text";
import { GoLinkExternal } from "react-icons/go";

type Props = {};

const StartupPlan = (props: Props) => {
  return (
    <div className="shadow-[0px_0px_4px_0px_#00000026] rounded-md p-4 flex flex-col items-center gap-4 mt-4 lg:flex-row">
      <div className="flex flex-col">
        <Subtitle>Startup abonnement</Subtitle>
        <Text>
          Met ons startup aanbod heb je tot 5.000 API-calls per maand voor
          slechts € 100 excl. BTW per maand (een korting tot wel 80% op het
          reguliere tarief) voor een periode van 12 maanden.
        </Text>
      </div>

      <a
        href="https://altum.ai/startup-aanbod"
        target="_blank"
        rel="noreferrer"
        className="flex gap-2 items-center border rounded-md text-primary border-primary p-3 w-[177px] h-[48px] self-center md:self-end text-sm justify-center"
      >
        Apply now <GoLinkExternal />
      </a>
    </div>
  );
};

export default StartupPlan;
