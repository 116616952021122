export function knowWhen(t: Date | string | number) {
  // Functie om het aangepaste datumformaat te parseren met aandacht voor de tijdzone
  function parseCustomDate(dateString: string) {
    // Regelmatige expressie om de datum- en tijdcomponenten en de tijdzone te extraheren
    const parts = dateString.match(
      /^(\d{2})\/([a-zA-Z]{3})\/(\d{4}):(\d{2}):(\d{2}):(\d{2}) \+(\d{4})$/,
    );
    if (!parts) {
      throw new Error("Ongeldig datumformaat");
    }

    const day = parseInt(parts[1], 10);
    const month = [
      "Jan",
      "Feb",
      "Mrt",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ].indexOf(parts[2]);
    const year = parseInt(parts[3], 10);
    const hours = parseInt(parts[4], 10);
    const minutes = parseInt(parts[5], 10);
    const seconds = parseInt(parts[6], 10);
    // Rekening houdend met de tijdzone-offset altijd +0000, kan de tijdzonebehandeling worden vereenvoudigd
    // Voor verschillende tijdzones moet dit deel de offset naar milliseconden converteren en de datum dienovereenkomstig aanpassen
    const utcDate = new Date(
      Date.UTC(year, month, day, hours, minutes, seconds),
    );
    return utcDate;
  }

  let adDate;
  // Controleer of de invoer al een Date-object is
  if (t instanceof Date) {
    adDate = t;
  } else if (typeof t === "string" && t.includes("/")) {
    adDate = parseCustomDate(t);
  } else {
    adDate = new Date(t);
  }

  const tDelta = Date.now() - adDate.getTime();
  const $1m = 1000 * 60;
  const $1h = $1m * 60;
  const { floor } = Math;
  const timeString =
    adDate.toLocaleDateString("nl-NL") +
    " om " +
    adDate.toLocaleTimeString("nl-NL");
  let when = "";
  if (tDelta < 0) {
    when = "in de toekomst";
  } else if (tDelta <= $1m) {
    when = "zojuist";
  } else if (tDelta < $1h) {
    when = floor(tDelta / $1m) + " minuten geleden";
  } else if (tDelta < 24 * $1h) {
    const today = new Date().getDay();
    const adDay = adDate.getDay();
    if (today === adDay) {
      when = "vandaag om " + adDate.toLocaleTimeString("nl-NL");
    } else {
      when = floor(tDelta / $1h) + " uur geleden";
    }
  } else {
    when = timeString;
  }
  return when;
}
