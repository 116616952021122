import { useState, useEffect, useCallback, useMemo } from "react";
import APIResult from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { splitAddress } from "../../../helpers/common";
import formatDate from "../../../helpers/convertToDutchMonth";
import { useHistory, Redirect } from "react-router-dom";
import {
  clearEnergyClimateResults,
  modifyEnergyClimateQueries,
} from "../../../redux/actions/energyClimateActions";
import axios from "axios";
import { toast } from "react-toastify";
import {
  FormProvider,
  useFormContext,
} from "../../../pages/Api/usage-pages/components/FormContext";

const EnergyClimateResult = () => {
  const { result, loading } = useAppSelector((state) => state.energyClimate);
  const property: any = result;
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { buildingPhoto, setPostalAddress, fetchAddressData } =
    useFormContext();

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      const postalAddress = `${property.postcode}-${property.housenumber}-${
        property.housenumberaddition === "None"
          ? ""
          : property.housenumberaddition
      }`;
      setPostalAddress(postalAddress);
    }
  }, [property, setPostalAddress]);

  useEffect(() => {
    if (Object.keys(result).length > 0 && buildingPhoto.length === 0) {
      fetchAddressData();
    }
  }, [result, buildingPhoto.length, fetchAddressData]);

  const clearResults = () => {
    dispatch(clearEnergyClimateResults());
    history.push("/energy-climate");
  };

  const modifyResults = () => {
    dispatch(modifyEnergyClimateQueries());
    history.push("/energy-climate");
  };

  if (Object.keys(property).length === 0) {
    return <Redirect to="/energy-climate" />;
  }
  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: property.bag_id,
    },
    {
      img: "",
      title: "Huisnummer toevoeging",
      result: `${property.houseaddition ? property.houseaddition : "-"}`,
    },
    {
      img: "",
      title: "Latitude",
      result: property.latitude,
    },
    {
      img: "",
      title: "Longitude",
      result: property.longitude,
    },
  ];

  const propertyDetails = [
    {
      title: "Basisinformatie",
      details: [
        {
          key: "Pand-ID",
          value: property.pand_id,
        },
        {
          key: "Postcode",
          value: property.postcode,
        },
        {
          key: "Huisnummer",
          value: property.housenumber,
        },
        {
          key: "Toevoeging huisnummer",
          value: property.housenumberaddition,
        },
        {
          key: "Stad",
          value: property.city,
        },
        {
          key: "Straat",
          value: property.street,
        },
        {
          key: "Status gebouw",
          value: property.building_status,
        },
        {
          key: "Objectfunctie",
          value: property.object_function,
        },
        {
          key: "Status object",
          value: property.object_status,
        },
        {
          key: "Bouwjaar",
          value: property.build_year,
        },
      ],
    },
    {
      title: "Kenmerken van het pand",
      details: [
        {
          key: "Oppervlakte binnen",
          value: `${property.inner_surface_area} m²`,
        },
        {
          key: "Monument",
          value: property.monument,
        },
        {
          key: "Monumentbeschrijving",
          value: property.monument_description,
        },
        {
          key: "Energielabel",
          value: property.epc_label,
        },
        {
          key: "Berekeningsmethode",
          value: property.calculation_method,
        },
        {
          key: "Registratiedatum",
          value: formatDate(property.registration_date),
        },
        {
          key: "Geldigheid",
          value: formatDate(property.validity),
        },
      ],
    },
    {
      title: "Energie- en Milieugegevens",
      details: [
        {
          key: "Primair energieverbruik",
          value: `${property.primary_energy_demand} kWh/m²`,
        },
        {
          key: "Primair energieverbruik (EMG)",
          value: `${property.primary_energy_demand_emg} kWh/m²`,
        },
        {
          key: "Warmtevraag",
          value: `${property.heat_demand} kWh/m²`,
        },
        {
          key: "Energievraag",
          value: `${property.energy_demand} kWh/m²`,
        },
        {
          key: "Temperatuuroverschrijding",
          value: property.temperature_exceedance,
        },
        {
          key: "Bodemzetting (2020-2050 Hoog)",
          value: property.subsidence_2020_2050_high,
        },
        {
          key: "Bodemzetting (2020-2050 Laag)",
          value: property.subsidence_2020_2050_low,
        },
        {
          key: "Gemiddeld hoogste grondwaterniveau (2050 Hoog)",
          value: `${property.average_highest_groundwater_level_2050_high} m`,
        },
        {
          key: "Gemiddeld hoogste grondwaterniveau (Huidig)",
          value: `${property.average_highest_groundwater_level_current} m`,
        },
      ],
    },
    {
      title: "Klimaat- en Milieurisico's",
      details: [
        {
          key: "Stedelijk hitte-eiland effect",
          value: property.urban_heat_island_effect,
        },
        {
          key: "Overstromingsdiepte (Extreme Kans)",
          value: `${property.flood_depth_extreme_probability} m`,
        },
        {
          key: "Overstromingsdiepte (Kleine Kans)",
          value: `${property.flood_depth_small_probability} m`,
        },
        {
          key: "Locatiegebonden overstromingskans 2050 (0 cm)",
          value: property.location_based_flood_probability_2050_0cm,
        },
        {
          key: "Locatiegebonden overstromingskans 2050 (20 cm)",
          value: property.location_based_flood_probability_2050_20cm,
        },
        {
          key: "Watertekort oppervlaktewater (Extreem Droog Jaar Huidig)",
          value: property.surface_water_shortage_extremely_dry_year_current,
        },
        {
          key: "Watertekort oppervlaktewater (Gemiddeld Jaar Huidig)",
          value: property.surface_water_shortage_average_year_current,
        },
      ],
    },
    {
      title: "Klimaatvoorspellingen en Trends",
      details: [
        {
          key: "Aantal ijsdagen (2050 Hoog)",
          value: property.number_of_ice_days_2050_high,
        },
        {
          key: "Aantal ijsdagen (2050 Laag)",
          value: property.number_of_ice_days_2050_low,
        },
        {
          key: "Aantal ijsdagen (Huidig)",
          value: property.number_of_ice_days_current,
        },
        {
          key: "Aantal tropische dagen (2050 Laag)",
          value: property.number_of_tropical_days_2050_low,
        },
        {
          key: "Aantal tropische dagen (Huidig)",
          value: property.number_of_tropical_days_current,
        },
        {
          key: "Aantal vorstdagen (2050 Hoog)",
          value: property.number_of_frost_days_2050_high,
        },
        {
          key: "Aantal vorstdagen (2050 Laag)",
          value: property.number_of_frost_days_2050_low,
        },
        {
          key: "Aantal vorstdagen (Huidig)",
          value: property.number_of_frost_days_current,
        },
        {
          key: "Aantal warme dagen (2050 Hoog)",
          value: property.number_of_warm_days_2050_high,
        },
        {
          key: "Aantal warme dagen (2050 Laag)",
          value: property.number_of_warm_days_2050_low,
        },
        {
          key: "Aantal warme dagen (Huidig)",
          value: property.number_of_warm_days_current,
        },
        {
          key: "Jaarlijkse neerslag (2050 Hoog)",
          value: property.annual_precipitation_2050_high,
        },
        {
          key: "Jaarlijkse neerslag (Huidig)",
          value: property.annual_precipitation_current,
        },
        {
          key: "Jaarlijkse referentie-verdamping (2050 Hoog)",
          value: property.annual_reference_evaporation_2050_high,
        },
        {
          key: "Jaarlijkse referentie-verdamping (Huidig)",
          value: property.annual_reference_evaporation_current,
        },
      ],
    },
    {
      title: "Diversen",
      details: [
        {
          key: "Natuurlijke systeem hoofdklasse",
          value: property.natural_system_main_class,
        },
        {
          key: "Natuurlijke systeem subklasse",
          value: property.natural_system_sub_classa,
        },
        {
          key: "Buurttypologie",
          value: property.neighborhood_typology,
        },
      ],
    },
  ];

  const reference = [
    { text: "Referenties opvragen", path: "/referentie" },
    { text: "Kadaster transactie ophalen", path: "/transactie-api" },
    { text: "WOZ waarde opvragen", path: "/woz" },
  ];
  return (
    <>
      <APIResult
        property={property}
        loading={loading}
        title={"Energie & klimaat API - Altum AI"}
        street={
          property.street +
          " " +
          property.housenumber +
          " " +
          property.houseaddition
        }
        postCode={splitAddress(property.postcode)}
        city={property.city}
        overview={propertyDetails}
        reference={reference}
        descSection={desc}
        buildingPhoto={buildingPhoto}
        result={result}
        path="https://docs.altum.ai/apis/energie-and-klimaat-api"
        clearResult={clearResults}
        modifyResult={modifyResults}
      />
    </>
  );
};

const Index = () => {
  return (
    <FormProvider>
      <EnergyClimateResult />
    </FormProvider>
  );
};
export default Index;
