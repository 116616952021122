import React from "react";
import convertToEUFormat from "../../helpers/convertToEuFormat";
import { BoldText, LightText, ListContainer } from "../apiForms/APIResult";

// export interface MeasuresDetailBoxProps {
//   measure: [string, {
//     before: { desc: string };
//     after: { desc: string };
//     investment: number;
//     saving: number;
//     co2_reduce: number;
//   }];
//   index: number;
// }

const MeasuresDetailBox: React.FC<any> = ({ measure, index }) => {
  // Convert measures from English to Dutch
  const convertToDutch = (str: string): string => {
    switch (str) {
      case "wall insulation":
        return "Gevelisolatie";
      case "floor insulation":
        return "Vloerisolatie";
      case "roof insulation":
        return "Dakisolatie";
      case "living room windows":
        return "Woonkamer Ramen";
      case "bedroom windows":
        return "Slaapkamer Ramen";
      case "solar panels":
        return "Zonnepanelen";
      case "instalation":
        return "Installatie";
      case "shower":
        return "Warmte Terug Winning Douche";
      default:
        return "";
    }
  };

  return (
    <>
      <BoldText>{convertToDutch(measure[0].split("_").join(" "))}</BoldText>
      <ListContainer>
        <LightText>Huidig:</LightText>
        <LightText color="#1E9D66">{measure[1].before.desc}</LightText>
      </ListContainer>
      <ListContainer>
        <LightText>Toekomstig:</LightText>
        <LightText color="#1E9D66">{measure[1].after.desc}</LightText>
      </ListContainer>
      <ListContainer>
        <LightText>Investering:</LightText>
        <LightText color="#1E9D66">
          €{convertToEUFormat(measure[1].investment)}
        </LightText>
      </ListContainer>
      <ListContainer>
        <LightText>CO2 reductie:</LightText>
        <LightText color="#1E9D66">
          {convertToEUFormat(measure[1].co2_reduce)}
        </LightText>
      </ListContainer>
    </>
  );
};

export default MeasuresDetailBox;
