import convertToEuFormat from "./convertToEuFormat";
import { getPpu, getCredits, getPrice } from "./stripeHelper";

export const renderSubscriptionDetails = (selectedPlan: string) => {
  return `Het ${selectedPlan} abonnement bevat ${convertToEuFormat(
    getCredits(selectedPlan),
  )} prepaid credits voor €${getPrice(
    selectedPlan,
  )},- vooruitbetaald aan het begin van de maand. Meerverbruik wordt aan het eind van de maand voor €${convertToEuFormat(
    getPpu(selectedPlan),
  )} per credit in rekening gebracht op de volgende factuur. Het abonnement start direct na abonneren en verlengt stilzwijgend elke maand.`;
};
