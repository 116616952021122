import createApiSlice from "../../helpers/createApiSlice";
import {
  autosuggestOneTimeUse,
  postAutosuggest,
} from "../actions/autosuggestAction";

const autosuggestSlice = createApiSlice(
  "autosuggest",
  postAutosuggest,
  autosuggestOneTimeUse,
);

export const { clearResults, modifyQueries } = autosuggestSlice.actions;

export default autosuggestSlice.reducer;
