import React, { useMemo } from "react";
import ReturnInputField from "../../../../../components/ReturnInputField";
import { useFormContext } from "../../components/FormContext";
import { additionalInfoFields } from "../data";

const AdditionalInfo = () => {
  const { formValues, setFormValues } = useFormContext();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const modField = useMemo(() => {
    return formValues["facilities_for_disabled"] === "false"
      ? additionalInfoFields.filter(
          (field) => field.name !== "costs_spent_for_facilities_for_disabled",
        )
      : additionalInfoFields;
  }, [formValues]);

  return (
    <div>
      <h2>Overig</h2>
      {modField.map(({ name, ...field }) => (
        <ReturnInputField
          key={name}
          {...field}
          name={name}
          value={name && formValues[name] ? formValues[name] : ""}
          onChange={(event) => {
            if ("target" in event) {
              handleInputChange(event as React.ChangeEvent<HTMLInputElement>);
            }
          }}
        />
      ))}
    </div>
  );
};

export default AdditionalInfo;
