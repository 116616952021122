import React from "react";

// Define the possible energy labels using an enum
export enum EnergyLabel {
  "A++++" = "A++++",
  "A+++" = "A+++",
  "A++" = "A++",
  "A+" = "A+",
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
}

interface EnergyLabelContainerProps {
  label: EnergyLabel;
}

const EnergyLabelContainer: React.FC<EnergyLabelContainerProps> = ({
  label,
}) => {
  let backgroundColor: string;

  switch (label) {
    case EnergyLabel.A:
    case EnergyLabel["A+"]:
    case EnergyLabel["A++"]:
    case EnergyLabel["A+++"]:
    case EnergyLabel["A++++"]:
      backgroundColor = "green";
      break;
    case EnergyLabel.B:
      backgroundColor = "lightgreen";
      break;
    case EnergyLabel.C:
      backgroundColor = "yellow";
      break;
    case EnergyLabel.D:
      backgroundColor = "orange";
      break;
    case EnergyLabel.E:
      backgroundColor = "red";
      break;
    default:
      backgroundColor = "gray";
      break;
  }

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        width: "max-content",
        minWidth: "",
        height: "30px",
        borderRadius: "5px",
        display: "inline-block",
        color: "white",
        padding: "0 4px ",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {label}
      </div>
    </div>
  );
};

export default EnergyLabelContainer;
