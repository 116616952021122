import createApiSlice from '../../helpers/createApiSlice';
import {
  postLabelling,
  postLabellingOneTimeUse,
} from '../actions/imageLabelActions';

const LabellingSlice = createApiSlice(
  'labelling',
  postLabelling,
  postLabellingOneTimeUse,
);

export const { clearResults, modifyQueries } = LabellingSlice.actions;

export default LabellingSlice.reducer;
