import React from 'react';

interface CheckoutOptionsProps {
  icon: React.ReactNode;
  label: string;
  isCard: boolean;
  setIsCard: (isCard: boolean) => void;
}

const CheckoutOptions: React.FC<CheckoutOptionsProps> = ({
  icon,
  label,
  isCard,
  setIsCard,
}) => {
  const toggle = () => {
    setIsCard(!isCard);
  };
  return (
    <button
      className={`${isCard ? 'active-payment' : ''} option-card`}
      style={{ padding: '10px' }}
      onClick={toggle}
      disabled={isCard}
    >
      <i>{icon}</i>
      <div>{label}</div>
    </button>
  );
};

export default CheckoutOptions;
