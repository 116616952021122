import { useEffect } from "react";
import { useFormContext } from "../../components/FormContext";
import RadioUsageFormComponent from "../../components/RadioUsageFormComponent";
import { searchCriteria } from "../data";

type Props = {};

const WhatsYourGoal = (props: Props) => {
  const { formValues, setFormValues } = useFormContext();

  useEffect(() => {
    setFormValues((prev) => ({ ...prev, search_criteria: 2 }));
  }, [setFormValues]);

  return (
    <RadioUsageFormComponent
      {...searchCriteria}
      value={formValues && formValues["search_criteria"]}
      onChange={(value) =>
        setFormValues({
          ...formValues,
          [searchCriteria.name]: value,
        })
      }
    />
  );
};

export default WhatsYourGoal;
