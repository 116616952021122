import React, { FC } from "react";
import Subtitle from "../../../components/Subtitle";
import Dropdown from "../../../components/Dropdown";

type Props = {
  periods?: string[];
  period?: string;
  setPeriod?: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
  title: string;
};

const CardContainer: FC<Props> = ({
  period,
  children,
  setPeriod,
  periods,
  title,
}) => {
  return (
    <div className="w-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-2xl">
      <div className="flex justify-between">
        <Subtitle className="text-base">{title}</Subtitle>
        {period && periods && setPeriod && (
          <Dropdown label={period} items={periods} setItem={setPeriod} />
        )}
      </div>
      <hr className="mt-2" />
      {children}
    </div>
  );
};

export default CardContainer;
