import React, { FC, useMemo, useState } from "react";
import Subtitle from "../../../../components/Subtitle";
import Text from "../../../../components/Text";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import Button from "../../../../components/Button";
import RadioInput from "../../../../components/RadioInput";
import {
  getCredits,
  getPpu,
  getPrice,
  isPPUPlan,
} from "../../../../helpers/stripeHelper";
import convertToEuFormat from "../../../../helpers/convertToEuFormat";
import { useSubscriptionContext } from "../../../../hooks/useSubscriptionContext";
import TransactionSummary from "./TransactionSummary";
import { useAppSelector } from "../../../../redux/hooks";
import { useHistory } from "react-router-dom";
import CancelPlanModal from "./CancelPlanModal";
import { MOPSUS, TRANSACTION } from "../../../../helpers/stripePlanTypes";

type Props = {
  planName: string;
  description: string;
  planArr?: string[];
};

const PlanCard: FC<Props> = ({ planName, description, planArr }) => {
  const { altumPlan, transactionPlan } = useSubscriptionContext();
  const [selectedPlan, setSelectedPlan] = useState(planName);
  const [openModal, setOpenModal] = useState(false);
  const price = getPrice(selectedPlan);
  const ppu = getPpu(selectedPlan);
  const isPPU = isPPUPlan(altumPlan);
  const { user } = useAppSelector((state) => state.auth);
  const history = useHistory();
  const [cancelPlanModalDisplay, setCancelPlanModalDisplay] = useState(false);
  const isActive = useMemo(
    () => selectedPlan === altumPlan || selectedPlan === transactionPlan,
    [altumPlan, selectedPlan, transactionPlan],
  );

  const freePlanNotActive = useMemo(
    () => isPPU && selectedPlan === "Altum AI - gratis account",
    [isPPU, selectedPlan],
  );

  const renderButton = () => {
    const title = freePlanNotActive
      ? "Downgraden abonnement"
      : isActive
      ? "Huidig abonnement"
      : "Selecteer abonnement";
    const color = isActive ? "bg-[#F2F2F2]" : "bg-primary";
    return (
      <Button
        className={`${color} h-[43px]`}
        disabled={isActive}
        onClick={() =>
          freePlanNotActive
            ? setCancelPlanModalDisplay(true)
            : setOpenModal(true)
        }
      >
        {title}
      </Button>
    );
  };

  return (
    <>
      <div className="mt-4 py-8 p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-md flex flex-col justify-between h-[597px] w-[336px]">
        <div className="flex flex-col gap-8 mt-4">
          <div>
            <Subtitle className="text-base">{selectedPlan}</Subtitle>
            <Text className="text-gray-light">{description}</Text>
          </div>

          <div>
            <div className="flex items-end">
              <Subtitle className="text-xl">€{price}</Subtitle>
              <Text className="text-gray-light">
                /maand{" "}
                {selectedPlan !== "Altum AI - gratis account" && "(excl. BTW)"}
              </Text>
            </div>
            <Text className="text-gray-light">
              (€{convertToEuFormat(ppu) || 0} per credit)
            </Text>
          </div>
          {planArr && (
            <div className="flex flex-wrap gap-4">
              {planArr.map((plan) => (
                <div className="flex items-center">
                  <RadioInput
                    selected={plan === selectedPlan}
                    onChange={() => setSelectedPlan(plan)}
                  />
                  <Text>{plan}</Text>
                </div>
              ))}
            </div>
          )}
          <div className="flex flex-col gap-3">
            {getPlanOfferings(selectedPlan).map((offering, idx) => (
              <div className="flex gap-2" key={idx}>
                <div>
                  <IoIosCheckmarkCircleOutline className="text-primary" />
                </div>
                <Text>{offering}</Text>
              </div>
            ))}
          </div>
        </div>
        {renderButton()}
      </div>
      <TransactionSummary
        openModal={openModal}
        setOpenModal={setOpenModal}
        selectedPlan={selectedPlan}
      />
      <CancelPlanModal
        user={user}
        plan={altumPlan}
        setCancelPlanModalDisplay={setCancelPlanModalDisplay}
        cancelPlanModalDisplay={cancelPlanModalDisplay}
        history={history}
        downgradePlan
      />
    </>
  );
};

export default PlanCard;

function getPlanOfferings(selectedPlan: string) {
  switch (selectedPlan) {
    case "Altum AI - gratis account":
      return [
        "Toegang tot meer dan 15 woningdata API's exclusief de Kadaster transacties",
        "15 credits",
        "Maandelijks opzegbaar",
      ];
    case MOPSUS._100:
    case MOPSUS._250:
    case MOPSUS._750:
    case MOPSUS._2000:
      return [
        "Toegang tot meer dan 15 woningdata API's exclusief de Kadaster transactie API",
        `${getCredits(selectedPlan)} credits inbegrepen`,
        "Maandelijks opzegbaar",
      ];
    case TRANSACTION._100:
      return [
        "Toegang tot de Kadaster transactie API",
        "100 credits inbegrepen",
        "Maandelijks opzegbaar",
      ];
    default:
      return [];
  }
}
