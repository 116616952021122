import React, { ReactNode } from "react";
import ApiFormContainer from "../../components/ApiFormContainer";
import { progress } from "../data";
import { WWS } from "../../../../../assets/images/api/APIimages";

const WWWContainer: React.FC<{ children: ReactNode; page: number }> = ({
  children,
  page,
}) => {
  return (
    <ApiFormContainer
      page={page}
      title="WWS-punten berekenen"
      subtitle="Bereken de WWS-punten voor een woning op basis van de kenmerken en faciliteiten van de woning."
      progress={progress}
      resultSelector={(state) => state.wws}
      link="https://docs.altum.ai/apis/wws-punten-api"
      image={WWS}
      showImageContainer={false}
    >
      {children}
    </ApiFormContainer>
  );
};

export default WWWContainer;
