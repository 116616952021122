export const AvmFields = [
  {
    label: "Postcode*",
    name: "postcode",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "housenumber",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "houseaddition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },

  {
    label: "Waarderingsdatum",
    name: "valuationdate",
    type: "text",
    placeholder: "20191030 (JJJJMMDD)",
    tooltip:
      "De standaard waarderingsdatum is vandaag. Overschrijf door een datum in te sturen als YYYYMMDD",
  },
  {
    label: "Woonoppervlakte (m2)",
    name: "innersurfacearea",
    type: "number",
    placeholder: "100",
    tooltip:
      "Het oppervlak van de woning heeft invloed op de modelwaarde berekening. Overschrijf de standaard waarde in de database",
  },
  {
    label: "Huidig energielabel",
    name: "energylabel",
    type: "slider",
    tooltip:
      "Het energielabel van de woning heeft invloed op de modelwaarde berekening. Overschrijf de standaard waarde in de database",
  },
];
