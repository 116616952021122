import React from "react";
import { Link } from "react-router-dom";
import Text from "../Text";
import Button from "../Button";
import Subtitle from "../Subtitle";
import { Badge } from "flowbite-react";

interface ApiCardProps {
  image: string;
  title: string;
  text: string;
  category: string;
  link: string;
  isNew?: boolean | undefined;
}

const ApiCard: React.FC<ApiCardProps> = ({
  title,
  text,
  link,
  category,
  image,
  isNew,
}) => {
  return (
    <div className="flex flex-col border rounded-lg p-3 justify-between w-full h-[201px] md:w-full shadow-md">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <div className="w-10 h-10">
            <img src={image} alt={title} className="w-full" />
          </div>
          {isNew && <Badge color="purple">nieuw</Badge>}
        </div>
        <Text>{category}</Text>
      </div>
      <div className="flex flex-col gap-2">
        <Subtitle className={"font-medium text-base leading-4"}>
          {title}
        </Subtitle>
        <Text className="md:text-xs">{text}</Text>
      </div>
      <Button className="mt-0 bg-primary w-[79px] h-8 self-end py-5" size="sm">
        <Link to={link} className="text-xs">
          Starten
        </Link>
      </Button>
    </div>
  );
};

export default ApiCard;
