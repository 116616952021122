export const SolarScanField = [
  {
    label: "Postcode*",
    name: "postcode",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "housenumber",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "addition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Paneel breedte (m)",
    name: "panel_width",
    type: "text",
    placeholder: "1",
    tooltip: "",
  },
  {
    label: "Paneel hoogte (m)",
    name: "panel_height",
    type: "number",
    placeholder: "1.6",
    tooltip: "",
  },
  {
    label: "Horizontale afstand (m)",
    name: "horizontal_spacing",
    type: "number",
    placeholder: "0.05",
    tooltip: "",
  },
  {
    label: "Verticale afstand (m)",
    name: "vertical_spacing",
    type: "number",
    placeholder: "0.05",
    tooltip: "",
  },
  {
    label: "Afstand tot dakrand (m)",
    name: "roof_margin",
    type: "number",
    placeholder: "0.3",
    tooltip: "",
  },
  {
    label: "Aantal te plaatsen panelen (optioneel)",
    name: "n_panels",
    type: "number",
    placeholder: "2",
    tooltip: "",
  },
];
