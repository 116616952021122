import { FormEvent, useEffect, useState } from "react";
import { EnergyLabelField } from "./energyLabelField";
import FormBody from "../FormBody";
import { EnergyLabel } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { postEnergyLabel } from "../../../redux/actions/energyLabelActions";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const EnergyLabelForm = () => {
  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.energyLabel);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptionsSustainability),
      });
    }
  }, [savedQueries]);
  const sectionFields = [{ title: "", startIndex: 0, endIndex: 2 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const dispatch = useAppDispatch();

  const handleEnergyLabelFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedData: FormOptionsSustainability = {
      post_code: "",
      house_number: "",
    };
    formValues.post_code =
      formValues.post_code &&
      formValues.post_code.split("").join("").toUpperCase();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        updatedData[key] = value;
      }
    });

    apiKey && dispatch(postEnergyLabel({ formData: updatedData, apiKey }));
  };

  // const handleChange = (e: any) => {
  //   setHouseOptions({ ...house, [e.target.name]: undefined });
  // };

  return (
    <FormBody
      title={"NTA 8800 Energielabel API bepalen"}
      desc={
        "Bepaal het geschatte huidige of potentiële energielabel volgens de NTA 8800 norm op basis van de informatie over de huidige of verwachte situatie van het pand."
      }
      path={"https://docs.altum.ai/apis/energielabel-api"}
      img={EnergyLabel}
      initialFields={EnergyLabelField}
      sectionFields={sectionFields}
      handleSubmit={handleEnergyLabelFormSubmit}
    />
  );
};

export default EnergyLabelForm;
