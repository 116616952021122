import createApiSlice from "../../helpers/createApiSlice";
import {
  energyClimateOneTimeUse,
  postEnergyClimate,
} from "../actions/energyClimateActions";

const energyClimate = createApiSlice(
  "energyClimate",
  postEnergyClimate,
  energyClimateOneTimeUse,
);

export const { clearResults, modifyQueries } = energyClimate.actions;

export default energyClimate.reducer;
