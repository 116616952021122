import { useState } from 'react';

type CopyFunction = (text: string) => Promise<boolean>;

const useCopy = (): [string | null, CopyFunction] => {
  const [copiedText, setCopiedText] = useState<string | null>(null);

  const copy: CopyFunction = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (e) {
      console.warn('copy failed', e);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
};

export default useCopy;
