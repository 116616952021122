import { createSlice, isRejected } from "@reduxjs/toolkit";
import {
  cancelSubscription,
  createSubscription,
  getApiUsage,
  getWalletBalance,
  updateSubscription,
} from "../actions/subscriptionActions";

interface SubscriptionState {
  loading: boolean;
  error: string | null;
  balance: number;
  apiCost: any[];
  usageLogs: any[];
}
const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    loading: false,
    error: null,
    balance: 0,
    apiCost: [],
    usageLogs: [],
  } as SubscriptionState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSubscription.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSubscription.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getWalletBalance.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
        state.apiCost = action.payload.apiCost;
      })
      .addCase(getApiUsage.fulfilled, (state, action) => {
        state.usageLogs = [...action.payload];
      })
      .addMatcher(isRejected(createSubscription), (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addMatcher(isRejected(updateSubscription), (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addMatcher(isRejected(cancelSubscription), (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      });
  },
});

// Reducer
export default subscriptionSlice.reducer;
