export function findTopFiveApiUsage(apiData: { [key: string]: number }) {
  const sortedApis = Object.entries(apiData)
    .filter(([_, value]) => typeof value === "number" && value > 0)
    .sort((a, b) => b[1] - a[1]);

  const topFiveApis = sortedApis.slice(0, 10).map(([apiName, usage]) => ({
    apiName,
    usage,
  }));

  return topFiveApis;
}
