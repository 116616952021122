import React from "react";
import SkeletonText from "../../../../components/skeleton/components/Text";

type Props = {};

const GetStartedSkeleton = (props: Props) => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <div className="flex gap-4 mt-4 mb-2 animate-pulse" key={index}>
          <div className="bg-secondary min-w-8 h-8 rounded-md text-center flex justify-center items-center"></div>
          <div className="flex flex-col gap-2 w-full">
            <SkeletonText width={"w-3/4"} height={"h-4"} />
            <SkeletonText width={"w-full"} height={"h-2"} />
            <SkeletonText width={"w-full"} height={"h-2"} />
            <SkeletonText width={"w-3/4"} height={"h-2"} />
          </div>
        </div>
      ))}
    </>
  );
};

export default GetStartedSkeleton;
