import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import {
  CancelSubscriptionPayload,
  CreateSubscriptionPayload,
} from "../../@types";
import { AppDispatch } from "../store";
import { clearApiUsageState } from "./apiUsage";
import { clearStripeState } from "./stripeActions";

export const createSubscription = createAsyncThunk(
  "subscription/createSubscription",
  async (
    {
      body,
      dispatch,
    }: { body: CreateSubscriptionPayload; dispatch: AppDispatch },
    { rejectWithValue },
  ) => {
    try {
      const res = await axios.post("/api/v1/mopsus/create-subscription", body, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.status === 200) {
        toast.success("Abonnement succesvol geactiveerd");
        dispatch(clearApiUsageState());
        dispatch(clearStripeState());
        return true;
      }
      throw new Error();
    } catch (error: any) {
      toast.error(error.response?.data.error.message);
      rejectWithValue(error.response?.data.error.message);
      return false;
    }
  },
);

export const updateSubscription = createAsyncThunk(
  "subscription/updateSubscription",
  async (
    {
      body,
      dispatch,
    }: { body: CreateSubscriptionPayload; dispatch: AppDispatch },
    { rejectWithValue },
  ) => {
    try {
      const res = await axios.post("/api/v1/mopsus/update-subscription", body, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.status === 200) {
        toast.success("Abonnement Bijgewerkt");
        dispatch(clearApiUsageState());
        dispatch(clearStripeState());
        return true;
      }
      throw new Error();
    } catch (error: any) {
      toast.error(error.response?.data.error.message);
      rejectWithValue(error.response?.data.error.message);
      return false;
    }
  },
);

export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (body: CancelSubscriptionPayload, { rejectWithValue }) => {
    try {
      const res = await axios.post("/api/v1/mopsus/cancel-subscription", body, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.status === 200) {
        toast.success("Abonnement Opgezegd");
        return true;
      }
      throw new Error();
    } catch (error: any) {
      return rejectWithValue(error.response?.data.error.message);
    }
  },
);

export const getWalletBalance = createAsyncThunk(
  "subscription/getWalletBalance",
  async () => {
    try {
      const res = await axios.get<{ balance: number; apiCost: any }>(
        "/api/v1/mopsus/wallet-balance",
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response?.data.error.message);
      return { balance: 0, apiCost: "" };
    }
  },
);

export const getApiUsage = createAsyncThunk(
  "subscription/getApiUsage",
  async () => {
    try {
      const res = await axios.get<{ usage: any[] }>("/api/v1/mopsus/api-usage");

      return res.data.usage;
    } catch (error: any) {
      toast.error(error.response?.data.error.message);
      return [];
    }
  },
);
