import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import "../Dashboard/NewDashboard.css";
import "./Auth.css";
import { changePassword } from "../../redux/actions/userActions";
import { useAppDispatch } from "../../redux/hooks";

function ChangePassword() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onPasswordsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { oldPassword, newPassword, confirmPassword } = passwords;
    dispatch(changePassword({ oldPassword, newPassword, confirmPassword }));
    setPasswords({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  return (
    <div
      style={{
        margin: "0 1rem",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <div className="dashboard-content-title-box">
        <h2 id="dashboard-content-title">
          <span>Wachtwoord veranderen</span>
        </h2>
      </div>

      <div className="dashboard-content-account-container">
        <div className="dashboard-change-password-box">
          <form className="form" onSubmit={onSubmitHandler}>
            <div className="form-group">
              <input
                type="password"
                placeholder="Oud wachtwoord"
                name="oldPassword"
                value={passwords.oldPassword}
                onChange={onPasswordsChange}
                id="old-password-change-field"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Nieuw wachtwoord"
                name="newPassword"
                value={passwords.newPassword}
                onChange={onPasswordsChange}
                id="new-password-change-field"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Bevestig nieuw wachtwoord"
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={onPasswordsChange}
                id="confirm-password-change-field"
              />
            </div>
            <button type="submit" className="btn btn-primary flow">
              Verander wachtwoord
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
