import React from "react";
import { Document } from "@react-pdf/renderer";
import CoverPage from "./CoverPage";
import EcowaarderapportPage from "./EcowaarderapportPage";
import BasisgegevensPage from "./BasisgegevensPage";
import HuidigeSituatiePage from "./HuidigeSituatiePage";
import PotentieleSituatiePage from "./PotentieleSituatiePage";
import IndividueleMaatregelenPage from "./IndividueleMaatregelenPage";
import CompleteOverzichtPage from "./CompleteOverzichtPage";
import RendementVoorDeWereldPage from "./RendementVoorDeWereldPage";

interface PDFReportProps {
  data: any;
  buildingPhoto: string;
  map: string;
  featuredMeasures: any[];
}

const PDFReport: React.FC<PDFReportProps> = ({
  data,
  buildingPhoto,
  map,
  featuredMeasures,
}) => {
  return (
    <Document>
      <CoverPage data={data} />
      <EcowaarderapportPage data={data} />
      <BasisgegevensPage data={data} buildingPhoto={buildingPhoto} map={map} />
      <IndividueleMaatregelenPage data={data} />
      <HuidigeSituatiePage data={data} />
      <PotentieleSituatiePage data={data} featuredMeasures={featuredMeasures} />

      <CompleteOverzichtPage data={data} />
      <RendementVoorDeWereldPage data={data} />
    </Document>
  );
};

export default PDFReport;
