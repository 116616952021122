import createApiSlice from "../../helpers/createApiSlice";
import {
  locationDataOneTimeUse,
  postLocationData,
} from "../actions/locationDataActions";

const locationDataSlice = createApiSlice(
  "locationData",
  postLocationData,
  locationDataOneTimeUse,
);

export const { clearResults, modifyQueries } = locationDataSlice.actions;

export default locationDataSlice.reducer;
