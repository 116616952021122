import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';

interface WithTrackerOptions {
  [key: string]: any;
}

function withTracker<P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: WithTrackerOptions = {},
): React.ComponentType<P> {
  const trackPage = (page: string) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component<P> {
    componentDidMount() {
      const {
        location: { pathname: page },
      } = this.props;
      trackPage(page);
    }

    componentDidUpdate(prevProps: P) {
      const {
        location: { pathname: currentPage },
      } = this.props;
      const nextPage = prevProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}

export default withTracker;
