import { FC } from "react";
import SignupRightSection from "./components/SignupRightSection";
import AuthLeftSection from "../components/AuthLeftSection";
import AuthImage from "../../../assets/images/login-image.png";
import ReviewBadge from "./components/ReviewBadge";

const Signup: FC = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-grow">
        <AuthLeftSection image={AuthImage} />
        <SignupRightSection />
      </div>
    </div>
  );
};
export default Signup;
