import { AppDispatch } from '../store';
import { createApiThunk } from '../../helpers/createApiThunk';

export const postMoveData = createApiThunk(
  'moveData/postMoveDate',
  'api',
  'Move Data api used',
  'movedata',
);

export const clearMoveDataResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'moveData/clearResults' });
};

export const modifyMoveDataQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'moveData/modifyQueries' });
};

export const moveDataOneTimeUse = createApiThunk(
  'moveData/moveDataOneTimeUse',
  'demo',
  'Move Data demo used',
  'movedata',
);
