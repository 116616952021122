import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import {
  loadUser,
  logout,
  signIn,
  SignInPayload,
  signUp,
  verifyEmail,
} from "../actions/authActions";
import { User } from "../../@types";

// Auth slice
interface AuthState {
  initialLoading: boolean;
  loading: boolean;
  isAuthenticated: boolean;
  id: string;
  user: User | null;
  error: string | null | unknown;
  verified: boolean;
}

const initialState: AuthState = {
  initialLoading: true,
  loading: false,
  id: "",
  isAuthenticated: false,
  user: null,
  error: null,
  verified: false,
};
const cookie = new Cookies();
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearResults: (state) => {
      state.id = "";
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    // signUp
    builder.addCase(signUp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.loading = false;
      state.id = action.payload;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // signIn
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      signIn.fulfilled,
      (state, action: PayloadAction<SignInPayload>) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.error = null;
        cookie.set("x-mopsus-jwt", action.payload.token);
      },
    );
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // loadUser
    builder.addCase(loadUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      loadUser.fulfilled,
      (state, action: PayloadAction<User | null>) => {
        state.loading = false;
        state.initialLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
        state.error = null;
      },
    );
    builder.addCase(loadUser.rejected, (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.initialLoading = false;
      state.user = null;
      cookie.remove("x-mopsus-jwt");
    });

    // logout
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      cookie.remove("x-mopsus-jwt");
    });
    builder.addCase(logout.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(verifyEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      verifyEmail.fulfilled,
      (state, action: PayloadAction<SignInPayload>) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.error = null;
        cookie.set("x-mopsus-jwt", action.payload.token);
      },
    );
    builder.addCase(verifyEmail.rejected, (state) => {
      state.loading = false;
    });
    // (Add other cases for async thunks here)
  },
});

export default authSlice.reducer;
