import { createApiThunk } from '../../helpers/createApiThunk';
import { AppDispatch } from '../store';

export const postSustainability = createApiThunk(
  'sustainability/postSustainability',
  'api',
  'Sustainability api used',
  'sustainability',
);

export const clearSustainabilityResults =	() => async (dispatch: AppDispatch) => {
  dispatch({ type: 'sustainability/clearResults' });
};

export const modifySustainabilityQueries =	() => async (dispatch: AppDispatch) => {
  dispatch({ type: 'sustainability/modifyQueries' });
};

export const sustainabilityOneTimeUse = createApiThunk(
  'sustainability/sustainabilityOneTimeUse',
  'demo',
  'Sustainability demo used',
  'sustainability',
);
