import React from "react";
import Table from "../../../../components/Table";
import CardContainer from "../../components/CardContainer";
import { useAppSelector } from "../../../../redux/hooks";
import { FiDownload } from "react-icons/fi";
import convertToEuFormat from "../../../../helpers/convertToEuFormat";
import timeStampConverter from "../../../../helpers/timeStampConverter";

type Props = {};
const statusTranslation = (status: string) => {
  switch (status.toLowerCase()) {
    case "paid":
      return "Voldaan";
    case "open":
      return "Te betalen";
    case "void":
      return "Nietig verklaard";
    default:
      return "draft";
  }
};

const Invoice = (props: Props) => {
  const { invoices } = useAppSelector((state) => state.portal);

  const invoiceItems = invoices?.data.map((item) => ({
    amount: `${"€" + convertToEuFormat(item.amount_paid / 100)}`,
    status: !!item.status ? statusTranslation(item.status) : "",
    periodEnd: timeStampConverter(item.period_end),
    pdf: item.invoice_pdf ? (
      <a href={item.invoice_pdf}>
        <FiDownload />
      </a>
    ) : (
      ""
    ),
  }));

  return (
    <CardContainer title={"Factuur"}>
      <div className="mt-4 md:w-full">
        <Table
          headers={["Bedrag (incl. BTW)", "Status", "Betaaldatum"]}
          tableItems={invoiceItems}
          noDataText="Er zijn nog geen facturen beschikbaar."
        />
      </div>
    </CardContainer>
  );
};

export default Invoice;
