import React, { FC } from "react";
import { IoLocationSharp } from "react-icons/io5";
import Text from "../../../../components/Text";

type Props = {
  image: string;
  address: string;
};

const ImageContainer: FC<Props> = ({ image, address }) => {
  return (
    <div className="max-w-[1100px] p-2 md:p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-2xl">
      <div className="flex gap-2 items-center mb-2">
        <IoLocationSharp color="#ffc300" />
        <Text>{address}</Text>
      </div>
      <div className="w-full h-64 md:h-full">
        <img
          src={image}
          alt="google-img"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  );
};

export default ImageContainer;
