import { createApiThunk } from '../../helpers/createApiThunk';
import { AppDispatch } from '../store';

export const postTransaction = createApiThunk(
  'transaction/postTransaction',
  'api',
  'Kadaster Transaction api used',
  'transaction',
);

export const clearTransactionResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'transaction/clearResults' });
};

export const modifyTransactionQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'transaction/modifyQueries' });
};

export const pingTransaction = createApiThunk(
  'transaction/pingTransaction',
  'ping',
  'Kadaster Transaction ping used',
  'transaction',
);
export const transactionOneTimeUse = createApiThunk(
  'transaction/transactionOneTimeUse',
  'demo',
  'Kadaster Transaction demo used',
  'transaction',
);
