import styled from 'styled-components';

type ImgProps = {
  width?: string;
  height?: string;
  src: any;
};
export const Img = styled.img<ImgProps>`
	width: ${(p) => (p.width ? p.width : '')};
	height: ${(p) => (p.height ? p.height : '')};
`;
