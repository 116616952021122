import React, { useState } from "react";
import FilteredSearch from "./FilteredSearch";
import Api from "../../../../components/ApiCard/Api";
import Subtitle from "../../../../components/Subtitle";
import { Link } from "react-router-dom";
import Text from "../../../../components/Text";

type Props = {};

const RecommendedAPI = (props: Props) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("Alle producten");
  return (
    <div className="w-96 flex flex-col items-start gap-4 shadow-[0px_0px_4px_0px_#00000026] rounded-lg p-4 md:h-[500px] md:w-full lg:h-[865px]">
      <div className="flex justify-between w-full">
        <Subtitle className="text-base">Aanbevolen API's</Subtitle>
        <Link to="/dashboard/products">
          <Text className="text-primary hover:underline">bekijk alle</Text>
        </Link>
      </div>

      <FilteredSearch
        selected={selected}
        setSelected={setSelected}
        setSearch={setSearch}
      />
      <div className="overflow-y-auto pr-4 flex flex-col gap-3">
        <Api activeCategory={selected} search={search} />
      </div>
    </div>
  );
};

export default RecommendedAPI;
