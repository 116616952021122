import { useState } from "react";
import {
  CheckGoodYes,
  EyePasswordShow,
  EyePasswordHide,
  CopyDuplicate,
} from "react-basicons";
import { APIdata } from "../../../../components/ApiCard/ApisData";

import useCopy from "../../../../hooks/useCopy";
import { useAppSelector } from "../../../../redux/hooks";
import { Colors } from "../../../../styles/Theme";
import Subtitle from "../../../../components/Subtitle";
import { FaAngleDown } from "react-icons/fa6";
import { IoCheckboxOutline } from "react-icons/io5";
import Text from "../../../../components/Text";

function ApiKey() {
  const { user } = useAppSelector((state) => state.auth);
  const [clicked, setClicked] = useState(false);
  const [transactionClicked, setTransactionClicked] = useState(false);
  const [showBullet, setShowBullet] = useState(true);
  const [showTransactionBullet, setShowTransactionBullet] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [transactionDropdown, setTransactionDropdown] = useState(false);

  const [, copyApiKey] = useCopy();

  return (
    <>
      <div className="w-full flex flex-col p-4 rounded-lg shadow-[0px_2px_8px_0px_#00000026]">
        <Subtitle className="mb-4">Altum AI</Subtitle>
        <div className="bg-gray-50 w-full p-4 flex items-center justify-between rounded-lg">
          {/* <Subtitle className="text-base">API Key: </Subtitle> */}
          <div className="w-[250px] md:w-full overflow-auto">
            {showBullet ? (
              <Subtitle className="md:text-lg text-base text-nowrap">
                *******************
              </Subtitle>
            ) : (
              <Subtitle className="md:text-base text-sm text-nowrap w-full">
                {user?.api_key}
              </Subtitle>
            )}
          </div>
          <div className="flex items-center gap-4">
            <div
              onClick={() => setShowBullet(!showBullet)}
              className="shadow-[0px_2px_8px_0px_#00000026] rounded-md p-2 bg-white"
            >
              {showBullet ? <EyePasswordShow /> : <EyePasswordHide />}
            </div>
            <div
              onClick={() => {
                user && copyApiKey(user?.api_key);
                setClicked(!clicked);
              }}
              className="shadow-[0px_2px_8px_0px_#00000026] rounded-md p-2 bg-white"
            >
              {clicked ? (
                <CheckGoodYes color="green" />
              ) : (
                <CopyDuplicate color="black" />
              )}
            </div>
            <button
              onClick={() => setDropdown(!dropdown)}
              className="shadow-[0px_2px_8px_0px_#00000026] rounded-md p-3 bg-white"
            >
              <FaAngleDown />
            </button>
          </div>
        </div>

        <ul className={dropdown ? "block mt-4 h-full" : "hidden h-0"}>
          <Subtitle className="mt-4 text-base">Beschikbare APIs:</Subtitle>
          {APIdata.filter((item) => item.title !== "Kadaster transacties").map(
            (item, index) => (
              <li className="flex items-center gap-4" key={index}>
                <IoCheckboxOutline color={Colors.main.green} size={20} />
                <Text>{item.title}</Text>
              </li>
            ),
          )}
        </ul>
      </div>
      {user?.transaction_api_key && (
        <div className="w-full flex flex-col p-4 rounded-lg shadow-[0px_2px_8px_0px_#00000026] mt-4">
          <Subtitle className="mb-4">Kadaster transacties</Subtitle>
          <div className="bg-gray-50 w-full p-4 flex items-center justify-between rounded-lg">
            <div className="w-[250px] md:w-full overflow-auto">
              {/* <Subtitle className="text-base">API Key: </Subtitle> */}
              {showTransactionBullet ? (
                <Subtitle className="md:text-lg text-base text-nowrap">
                  *******************
                </Subtitle>
              ) : (
                <Subtitle className="md:text-base text-sm text-nowrap">
                  {user?.transaction_api_key}
                </Subtitle>
              )}
            </div>
            <div className="flex items-center gap-4">
              <div
                onClick={() => setShowTransactionBullet(!showTransactionBullet)}
                className="shadow-[0px_2px_8px_0px_#00000026] rounded-md p-2 bg-white"
              >
                {showTransactionBullet ? (
                  <EyePasswordShow />
                ) : (
                  <EyePasswordHide />
                )}
              </div>
              <div
                onClick={() => {
                  user && copyApiKey(user?.transaction_api_key);
                  setTransactionClicked(!transactionClicked);
                }}
                className="shadow-[0px_2px_8px_0px_#00000026] rounded-md p-2 bg-white"
              >
                {transactionClicked ? (
                  <CheckGoodYes color="green" />
                ) : (
                  <CopyDuplicate color="black" />
                )}
              </div>
              <button
                onClick={() => setTransactionDropdown(!transactionDropdown)}
                className="shadow-[0px_2px_8px_0px_#00000026] rounded-md p-3 bg-white"
              >
                <FaAngleDown />
              </button>
            </div>
          </div>

          <ul
            className={transactionDropdown ? "block mt-4 h-full" : "hidden h-0"}
          >
            <Subtitle className="mt-4 text-base">Beschikbare APIs:</Subtitle>
            <li className="flex items-center gap-2">
              <IoCheckboxOutline color={Colors.main.green} size={20} />
              <Text>Kadaster transactie</Text>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}
export default ApiKey;

// const Main = styled.div`
// 	background: ${Colors.main.white};
// 	display: flex;
// 	flex-direction: column;
// 	padding: 2rem;
// 	background: ${Colors.main.white};
// 	box-shadow: 0px 2px 35px rgba(5, 27, 105, 0.06);
// 	border-radius: 20px;
// 	width: 100%;
// 	position: relative;
// 	align-items: left;
// `;
