import React from "react";
import ReturnInputField from "../../../../../components/ReturnInputField";
import { useFormContext } from "../../components/FormContext";
import { sanitaryInfoFields } from "../data";
import { ActionMeta, MultiValue } from "react-select";

type Option = {
  label: string;
  value: string | number;
};
const SanitaryInfo = () => {
  const { formValues, setFormValues } = useFormContext();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };
  console.log(formValues);
  return (
    <div>
      <h2>Sanitair</h2>
      {sanitaryInfoFields.map(({ name, ...field }) => (
        <ReturnInputField
          key={name}
          {...field}
          name={name}
          onChange={(
            e:
              | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
              | MultiValue<Option>
              | Option,
            a?: ActionMeta<Option>,
          ) => {
            if (Array.isArray(e)) {
              const result = e?.map(({ value }) => value);
              a?.name && setFormValues({ ...formValues, [a.name]: result });
            } else if ("target" in e) {
              handleInputChange(e as React.ChangeEvent<HTMLInputElement>);
            }
          }}
        />
      ))}
    </div>
  );
};

export default SanitaryInfo;
