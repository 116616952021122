import React, { FormEvent, useEffect, useState } from "react";
import FormBody from "../FormBody";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RebuildField } from "./RebuildData";
import { postRebuild } from "../../../redux/actions/rebuildAction";
import { rebuild } from "../../../assets/images/api/APIimages";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const RebuidForm = () => {
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 6 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.objectData);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);

  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    formValues.postcode = formValues.postcode.split(" ").join("").toUpperCase();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey && dispatch(postRebuild({ formData: newFormData, apiKey: apiKey }));
  };

  return (
    <div>
      <FormBody
        title={"Herbouwwaarde API"}
        sectionFields={sectionFields}
        desc={
          "Berekening van de herbouwwaarde van woningen t.b.v. opstalverzekeringen."
        }
        img={rebuild}
        path={""}
        initialFields={RebuildField}
        handleSubmit={handleFormSubmit}
      />
    </div>
  );
};

export default RebuidForm;
