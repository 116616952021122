import React, { FC, useState } from "react";
import styled from "styled-components";
import Filter from "../../../../components/FilterDropdown";
import Dropdown from "../../../../components/Dropdown";
import { FilterAlt } from "react-basicons";

type Props = {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

const FilteredSearch: FC<Props> = ({ selected, setSelected, setSearch }) => {
  const [active, setActive] = useState(false);
  const tags = [
    "Verduurzamen",
    "Taxeren",
    "Foto's",
    "Woningdata",
    "Verzekeren",
  ];
  return (
    <SearchbarContainer>
      <div className="absolute">
        <Dropdown
          label={<FilterAlt size={20} />}
          items={tags}
          setItem={setSelected}
          noFloat={true}
        />
      </div>
      <StyledInput
        type="text"
        placeholder={selected === "" ? " API's filteren" : selected}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    </SearchbarContainer>
  );
};

export default FilteredSearch;

const SearchbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: relative;
  width: 292px;
`;

// const Dropdown = styled.div`
//   position: absolute;
//   top: 70px;
//   right: 1opx;
//   z-index: 100;
//   width: 326px;
//   padding: 0.75rem;
//   background-color: #fff;
//   border-radius: 0.375rem;
//   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
//   border: 1px solid #d1d5db;
//   //transform: translate3d(24px, 56px, 0px);
// `;

const DropdownList = styled.ul`
  margin-top: 0.5rem;
`;

const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d1d5db;
`;

const Label = styled.div`
  margin: 0.5rem;
  font-size: 1rem;
  color: #1a202c;
  padding: 0.75rem;
`;
const StyledInput = styled.input`
  border: 1px solid #e5e7eb !important;
  color: #111827;
  font-size: 12px;
  border-radius: 1rem;
  padding: 10px 10px 10px 40px;
  width: 100%;
  outline: none;
  transition: border 0.3s, ring 0.3s;

  &:focus {
    border: 1px solid #27ae60 !important;
    ring: 2px #27ae60 !important ;
  }

  &.dark {
    background-color: #374151;
    border: 1px solid #4b5563;
    color: #f3f4f6;
    placeholder: #9ca3af;

    &:focus {
      ring: 2px #2563eb;
      border: 1px solid #2563eb;
    }
  }
`;

const InputIcon = styled.div`
  fill: #6b7280;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
