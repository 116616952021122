import React, { FC, Fragment } from 'react';
import { IbanElement } from '@stripe/react-stripe-js';
import { StripeIbanElementChangeEvent } from '@stripe/stripe-js';

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  placeholderCountry: 'NL',
  style: IBAN_STYLE,
};

export const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#32325d',
      color: '#000',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#32325d',
      },
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      iconColor: '#32325d',
      color: '#32325d',
    },
  },
};
interface IbanProps {
  onChange: (event: StripeIbanElementChangeEvent) => any;
}
const IbanForm: FC<IbanProps> = ({ onChange }) => (
  <>
    <div className="checkout-formrow">
      <IbanElement options={IBAN_ELEMENT_OPTIONS} onChange={onChange} />
    </div>
    <p
      className="mandate-acceptance"
      style={{
			  marginTop: '1rem',
			  fontSize: '12px',
			  padding: ' 12px',
			  height: '100px',
			  overflow: 'auto',
      }}
    >
      Door uw betalingsgegevens te verstrekken en deze betaling te bevestigen,
      machtiging (A) Altum AI B.V. en Stripe, onze betalingsdienstaanbieder, om
      stuur instructies naar uw bank om uw rekening af te schrijven en (B) uw
      bank naar debiteren van uw rekening in overeenstemming met die
      instructies. Als een deel van jou rechten, heeft u recht op restitutie van
      uw bank onder de voorwaarden en voorwaarden van uw overeenkomst met uw
      bank. Er moet een terugbetaling worden aangevraagd binnen 8 weken vanaf de
      datum waarop uw rekening is afgeschreven. Uw rechten worden toegelicht in
      een verklaring die u kunt verkrijgen bij uw Bank. U stemt ermee in om tot
      2 dagen meldingen te ontvangen voor toekomstige afschrijvingen voordat ze
      zich voordoen.
    </p>
  </>
);

export default IbanForm;
