import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";

interface RendementVoorDeWereldPageProps {
  data: any;
}

const RendementVoorDeWereldPage: React.FC<RendementVoorDeWereldPageProps> = ({
  data,
}) => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>Woordenlijst:</Text>

    <View style={styles.glossaryContainer}>
      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• NTA 8800:</Text>
        <Text style={styles.glossaryDefinition}>
          De Nederlandse standaard voor het bepalen van de energieprestatie van
          gebouwen.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• Ecowaarde:</Text>
        <Text style={styles.glossaryDefinition}>
          De toename van de waarde van de woning als gevolg van de verbetering
          van de energie-efficiëntie.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• Energielabel:</Text>
        <Text style={styles.glossaryDefinition}>
          Een classificatiesysteem (A tot G) dat aangeeft hoe energie-efficiënt
          een gebouw is.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• kWh:</Text>
        <Text style={styles.glossaryDefinition}>
          Kilowattuur, een eenheid van energie die vaak wordt gebruikt bij de
          facturering van elektriciteit.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• m³:</Text>
        <Text style={styles.glossaryDefinition}>
          Kubieke meter, een eenheid die vaak wordt gebruikt voor het meten van
          het gasverbruik.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• CO2 emissies:</Text>
        <Text style={styles.glossaryDefinition}>
          De hoeveelheid kooldioxide die wordt uitgestoten in de atmosfeer, vaak
          als gevolg van energieverbruik.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• HR-combi:</Text>
        <Text style={styles.glossaryDefinition}>
          Een hoog-efficiënte combiboiler voor centrale verwarming en warm
          water.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• WTW:</Text>
        <Text style={styles.glossaryDefinition}>
          Een ventilatiesysteem met warmte-recuperatie.
        </Text>
      </View>
    </View>

    <View style={styles.footer}>
      <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default RendementVoorDeWereldPage;
