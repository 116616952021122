import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postAvmDetails = createApiThunk(
  "avmplus/postAvmplusDetails",
  "api",
  "Avm Api Used",
  "avmplus",
);

export const clearAvmResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "avmplus/clearResults" });
};

export const modifyAvmQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "avmplus/modifyQueries" });
};

export const avmOneTimeUse = createApiThunk(
  "avm/avmOneTimeUse",
  "demo",
  "avm demo used",
  "avmplus",
);
