import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../Loading";
import LocationDataForm from "./locationDataForm";
import { FormProvider } from "../../../pages/Api/usage-pages/components/FormContext";
const LocationData = () => {
  const { loading, result } = useAppSelector((state) => state.locationData);

  if (loading) return <Loading />;

  return (
    <FormProvider>
      {Object.keys(result).length > 0 ? (
        <Redirect to="/location-data-result" />
      ) : (
        <LocationDataForm />
      )}
    </FormProvider>
  );
};

export default LocationData;
