import React, { FC, useCallback, memo } from "react";
import { GoInfo } from "react-icons/go";
import Text from "../../../../components/Text";
import RadioInput from "../../../../components/RadioInput";

interface Option {
  label: string;
  value: string | number;
}

interface RadioUsageFormComponentProps {
  label?: string;
  name?: string;
  value?: string | number;
  tooltip?: string;
  stack?: boolean;
  options?: Option[];
  onChange?: (value: string | number) => void;
}

const RadioUsageFormComponent: FC<RadioUsageFormComponentProps> = ({
  label,
  name,
  value,
  tooltip,
  stack,
  options = [],
  onChange,
}) => {
  const handleChange = useCallback(
    (optionValue: string | number) => {
      onChange?.(optionValue);
    },
    [onChange],
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 text-gray-light items-center">
        <Text className="font-semibold">{label}</Text>
      </div>

      <div className={`flex ${stack ? "flex-col" : "flex-wrap"}`}>
        {options.map((option, index) => (
          <RadioInput
            key={index}
            name={name}
            label={option.label}
            value={option.value}
            selected={option.value === value}
            onChange={() => handleChange(option.value)}
            className="gap-2 mb-2 p-2 mb:mb-4"
          />
        ))}
      </div>
    </div>
  );
};

export default memo(RadioUsageFormComponent);
