import createApiSlice from '../../helpers/createApiSlice';
import {
  postSustainability,
  sustainabilityOneTimeUse,
} from '../actions/sustainabilityActions';

const sustainabilitySlice = createApiSlice(
  'sustainability',
  postSustainability,
  sustainabilityOneTimeUse,
);

export const { clearResults, modifyQueries } = sustainabilitySlice.actions;

export default sustainabilitySlice.reducer;
