import { ReactNode } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Colors } from "../styles/Theme";
import Logo from "../assets/images/altumai-svg.svg";
import useWindowSize from "../hooks/useWindowSize";
import { useAppSelector } from "../redux/hooks";
import { Footer } from "../components/Footer";
import { Img } from "../styles/styled";

function PublicWrapper({ children }: { children: ReactNode }) {
  const path = window.location.hash;
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const windowSize = useWindowSize();
  if (path.includes("signin") || path.includes("signup") || path === "#/") {
    return <>{children}</>;
  }
  return (
    <Container>
      <div className="body">
        <HeaderContainer style={{ backgroundColor: "white" }}>
          {!isAuthenticated && (
            <LogoContainer>
              <Link to="/">
                <Img
                  src={Logo}
                  width={windowSize.width < 560 ? "15%" : "20%"}
                  style={{ marginTop: "10px" }}
                />
              </Link>
            </LogoContainer>
          )}
        </HeaderContainer>
        {children}
        <div style={{ paddingTop: "4rem" }}>
          <Footer />
        </div>
      </div>
    </Container>
  );
}

export default PublicWrapper;

const Container = styled.div`
  display: flex;

  .body {
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.main.lightGreen};
  top: 15px;
  @media (max-width: 980px) {
    margin-top: 20px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
`;
