export function filterAnylyticsData(data: any[]) {
  const called: { [key: string]: number } = {};

  data.forEach((item) => {
    Object.entries(item.apiStats).forEach(([key, value]) => {
      called[key] = (called[key] || 0) + Number(value);
    });
  });

  return called;
}
