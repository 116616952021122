import { FC, useCallback } from "react";
import { postWWS } from "../../../../../redux/actions/wwsActions";
import { FormOptionsWWS } from "../../../../../@types";
import { stringToBoolean, stringToNumber } from "../../../../../helpers/common";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import PageNav from "../../components/PageNav";
import { Redirect } from "react-router-dom";

// Import components for each section (to be created)
import BasicInfo from "../components/BasicInfo";
import EnergyInfo from "../components/EnergyInfo";
import KitchenInfo from "../components/KitchenInfo";
import AdditionalInfo from "../components/AdditionalInfo";
import SanitaryInfo from "../components/SanitaryInfo";
import { useFormContext } from "../../components/FormContext";
import { toast } from "react-toastify";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const WWWForm: FC<Props> = ({ page, setPage }) => {
  const { formValues } = useFormContext();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.wws);
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  const handleFormSubmit = useCallback(() => {
    const updatedData: FormOptionsWWS = {
      house_type: formValues.house_type as string,
      building_year: stringToNumber(formValues.building_year as string),
      energy_label: formValues.energy_label || "A",
    };

    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        if (
          [
            "living_area_m2",
            "surface_area_other_spaces",
            "attic_space_area",
            "inner_surface_area",
            "countertop_lenght",
            "private_outdoor_space_m2",
            "renovation_expenses",
          ].includes(key)
        ) {
          updatedData[key] = stringToNumber(value as string);
        } else if (
          [
            "toilet_installed_in_bathroom_or_shower",
            "fixed_staircase",
            "energy_label_available",
            "energy_performance_payment",
            "countertop_with_base_cabinets",
            "sanitary_facilities",
            "facilities_for_disabled",
            "carport",
            "extra_quality_above_100m2",
            "monument",
          ].includes(key)
        ) {
          updatedData[key] = stringToBoolean(value as string);
        } else {
          updatedData[key] = value as string;
        }
      }
    });

    // Ensure house_type is one of the accepted values
    if (
      !["Eengezinswoning", "Meergezinswoning", "Duplexwoning"].includes(
        updatedData.house_type as string,
      )
    ) {
      toast.error("Ongeldige woningtype");
      return;
    }

    const formData = { ...updatedData };
    console.log(formData);
    apiKey && dispatch(postWWS({ formData, apiKey }));
  }, [apiKey, dispatch, formValues]);

  const next = useCallback(() => {
    if (page === 5) handleFormSubmit();
    setPage((prevPage) => prevPage + 1);
  }, [page, handleFormSubmit, setPage]);

  if (loading) {
    return <Redirect to={{ pathname: "/wws-result" }} />;
  }

  return (
    <>
      {page === 1 && <BasicInfo />}
      {page === 2 && <EnergyInfo />}
      {page === 3 && <KitchenInfo />}
      {page === 4 && <SanitaryInfo />}
      {page === 5 && <AdditionalInfo />}
      {page < 6 && (
        <PageNav
          page={page}
          next={next}
          prev={() => setPage((p) => Math.max(p - 1, 1))}
          disabled={!formValues.house_type || !formValues.building_year}
        />
      )}
    </>
  );
};

export default WWWForm;
