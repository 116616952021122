import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import "./modal.css";
import {
  CardElement,
  useStripe,
  useElements,
  IbanElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import { FaCreditCard } from "react-icons/fa";
import { SiSepa } from "react-icons/si";
import { StripeError } from "@stripe/stripe-js";
import CheckoutOptions from "../../../../components/StripeNotifierPages/CheckoutOptions";
import IbanForm from "../../../../components/StripeNotifierPages/IbanForm";
import { Field } from "../../../../components/StripeNotifierPages/Field";
import { ErrorMessage } from "../../../../components/StripeNotifierPages/ErrorMessage";
import Button from "../../../../components/Button";
import CreditCardForm from "../../../../components/StripeNotifierPages/CreditCardForm";

interface ModalProps {
  getAllPaymentMethods: () => void;
  setUpdatePaymentModalDisplay: (display: string) => void;
  updatePaymentModalDisplay: string;
  user: {
    email: string;
    first_name: string;
    last_name: string;
    stripe_customer_id: string;
    api_key: string;
  };
  cardActive: boolean;
  fetchSepaDetails: () => void;
  fetchCardDetails: () => void;
  addPayment: boolean;
  setAddPayment: (addPayment: boolean) => void;
}

interface BillingDetails {
  email: string;
  name: string;
}

const Modal: React.FC<ModalProps> = ({
  getAllPaymentMethods,
  setUpdatePaymentModalDisplay,
  updatePaymentModalDisplay,
  user,
  cardActive,
  fetchSepaDetails,
  fetchCardDetails,
  addPayment,
  setAddPayment,
}) => {
  const [error, setError] = useState<Error | StripeError | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [, setCheckoutProcessing] = useState(false);
  const [billingDetails, setBillingDetails] = useState<BillingDetails>({
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
  });
  const [active, setActive] = useState(true);
  useEffect(() => {
    setActive(cardActive);
  }, [cardActive]);
  const [cardComplete, setCardComplete] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      if (error) {
        if (active) {
          elements.getElement("card")?.focus();
          return;
        }
        elements.getElement("iban")?.focus();
        return;
      }
      let payload;

      if (active) {
        const cardElement = elements.getElement(CardElement);
        if (cardElement) {
          const result = await getClientSecret();
          const { clientSecret } = result.data;
          payload = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: billingDetails,
            },
          });
        }
      } else {
        const iban = elements.getElement(IbanElement);

        if (iban) {
          // get client secrete
          const result = await getClientSecret();
          const { clientSecret } = result.data;

          payload = await stripe.confirmSepaDebitSetup(clientSecret, {
            payment_method: {
              sepa_debit: iban,
              billing_details: billingDetails,
            },
          });
        }
      }

      setProcessing(false);

      if (payload?.error) {
        setError(payload.error);
      } else {
        setCheckoutProcessing(true);
        if (payload) {
          const body = {
            customerId: user.stripe_customer_id,
            paymentMethodId: payload.setupIntent.payment_method,
            apikey: user.api_key,
          };

          const res = await axios.post<any>(
            "/api/v1/mopsus/update-payment-details",
            body,
          );
          setCheckoutProcessing(true);
          if (res.data.success) {
            toast.success(res.data.msg);
            setCheckoutProcessing(false);
          }
        }
        handleModalClose();
      }
    } catch (error: any) {
      toast.error(error.response);
    }
  };
  const handleModalClose = () => {
    setUpdatePaymentModalDisplay("none");
    getAllPaymentMethods();
    addPayment && setAddPayment(false);
    fetchCardDetails();
    fetchSepaDetails();
  };
  return (
    <>
      <div
        className="backdrop"
        style={{ display: `${updatePaymentModalDisplay}` }}
      />
      <div
        className="modal"
        style={{ display: `${updatePaymentModalDisplay}` }}
      >
        <div className="modal-content">
          <button onClick={handleModalClose} className="close">
            &times;
          </button>
          <div className="creditCardForm">
            {addPayment ? (
              <div
                className="checkout-options"
                style={{ marginBottom: "1rem" }}
              >
                <CheckoutOptions
                  icon={<FaCreditCard size="20" />}
                  label="Credit Card"
                  isCard={active}
                  setIsCard={(prop) => setActive(prop)}
                />
                <CheckoutOptions
                  icon={<SiSepa size="20" />}
                  label="SEPA-incasso"
                  isCard={!active}
                  setIsCard={(prop) => setActive(!prop)}
                />
              </div>
            ) : (
              <div className="heading" style={{ marginBottom: "1rem" }}>
                <h3>{active ? "Creditcard wijzigen" : "SEPA incasso"}</h3>
              </div>
            )}
            <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
              <fieldset className="flex flex-col gap-3">
                <Field
                  id="name"
                  type="text"
                  placeholder="Persoon of Bedrijfsnaam"
                  required
                  autoComplete="name"
                  value={billingDetails.name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setBillingDetails({
                      ...billingDetails,
                      name: e.target.value,
                    });
                  }}
                  disabled={false}
                />
                <Field
                  id="email"
                  type="email"
                  required
                  placeholder="JaneDoe@example.com"
                  autoComplete="email"
                  value={billingDetails.email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setBillingDetails({
                      ...billingDetails,
                      email: e.target.value,
                    });
                  }}
                />
              </fieldset>

              {active ? (
                <CreditCardForm
                  setError={(error) => setError(error)}
                  setCardComplete={(complete) => setCardComplete(complete)}
                />
              ) : (
                <fieldset className="checkout-formgroup--card">
                  <IbanForm
                    onChange={(e) => {
                      e.error && setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </fieldset>
              )}
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
              <Button
                className="bg-primary w-full h-[43px]"
                isProcessing={processing}
                isError={!!error}
                disabled={!stripe}
                type="submit"
              >
                Bevestigen
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
async function getClientSecret() {
  return axios.get<any>("/api/v1/mopsus/create-setup-intent");
}
