import { createSlice } from "@reduxjs/toolkit";
import {
  addOnboardingResponse,
  getOnboardingQuestions,
  getOnboardingState,
  getOnboardingStatus,
} from "../actions/onboardingActions";
import { OnboardingQuestion } from "../../@types";

interface OnboardingState {
  loading: boolean;
  isOnboadingComplete: boolean;
  onboardingQuestions: OnboardingQuestion[] | null | undefined;
  onboardingSteps: any | null;
}
const initialState: OnboardingState = {
  onboardingQuestions: null,
  onboardingSteps: null,
  loading: false,
  isOnboadingComplete: false,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getOnboardingStatus.fulfilled, (state, action) => {
        state.isOnboadingComplete = action.payload;
      })
      .addCase(getOnboardingState.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOnboardingState.fulfilled, (state, action) => {
        state.loading = false;
        state.onboardingSteps = action.payload;
      })
      .addCase(getOnboardingQuestions.fulfilled, (state, action) => {
        state.onboardingQuestions = action.payload;
      })
      .addCase(addOnboardingResponse.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addOnboardingResponse.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addOnboardingResponse.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export default onboardingSlice.reducer;
