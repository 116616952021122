export const Colors = {
  main: {
    green: "#27AE60",
    black: "#000000",
    white: "#FFFFFF",
    red: "#FF3434",
    transparent: "transparent",
    lightGreen: "#F0FFF9",
    mediumGreen: "#e5fff5",
    darkgrey: "#707070",
    lightgrey: "#f1f1f1",
  },
  fonts: {
    body: "Lato, sans-serif",
  },
};

export const TextStyles = {
  Heading: {
    H1: {
      FontSize: "40px",
      LineHeight: "50px",
      LetterSpacing: "0",
      FontWeight: "700",
    },
    H1mobile: {
      FontSize: "32px",
      LineHeight: "48px",
      LetterSpacing: "0",
      FontWeight: "700",
    },
    H1tablet: {
      FontSize: "36px",
      LineHeight: "54px",
      LetterSpacing: "0",
      FontWeight: "700",
    },
    H2: {
      FontSize: "24px",
      LineHeight: "35px",
      LetterSpacing: "0",
      FontWeight: "500",
    },
    H2mobile: {
      FontSize: "20px",
      LineHeight: "30px",
      LetterSpacing: "0",
      FontWeight: "400",
    },
    H2tablet: {
      FontSize: "24px",
      LineHeight: "36px",
      LetterSpacing: "0",
      FontWeight: "400",
    },
  },
  Subtitle: {
    H3: {
      FontSize: "30px",
      LineHeight: "50px",
      LetterSpacing: "0",
      FontWeight: "500",
    },
    H3tablet: {
      FontSize: "30px",
      LineHeight: "45px",
      LetterSpacing: "0",
      FontWeight: "700",
    },
    H3mobile: {
      FontSize: "30px",
      LineHeight: "40px",
      LetterSpacing: "0",
      FontWeight: "600",
    },
    H4: {
      FontSize: "20.4px",
      LineHeight: "40px",
      LetterSpacing: "0",
      FontWeight: "600",
    },
  },
  Text: {
    P: {
      FontSize: "20px",
      LineHeight: "26px",
      LetterSpacing: "0",
      FontWeight: "400",
    },
    SmallText: {
      FontSize: "20px",
      LineHeight: "30px",
      FontWeight: "400",
      LetterSpacing: "0",
    },

    Pmedium: {
      FontSize: "18px",
      LineHeight: "30px",
      FontWeight: "400",
      LetterSpacing: "0",
    },
    Psmall: {
      FontSize: "12px",
      LineHeight: "20px",
      FontWeight: "400",
      LetterSpacing: "0",
    },
  },
  Label: {
    Small: {
      FontSize: "20px",
      LineHeight: "30px",
      FontWeight: "600",
      LetterSpacing: "0",
    },
    SmallNext: {
      FontSize: "18px",
      LineHeight: "35px",
      FontWeight: "600",
      LetterSpacing: "0",
    },
    Medium: {
      FontSize: "22.4px",
      LineHeight: "0",
      FontWeight: "600",
      LetterSpacing: "0",
    },

    Large: {
      FontSize: "24px",
      LineHeight: "30px",
      FontWeight: "600",
      LetterSpacing: "0",
    },
  },
  Bundler(attr: {
    FontSize: any;
    FontWeight: any;
    LineHeight: any;
    LetterSpacing: any;
  }) {
    return `
            font-size: ${attr.FontSize};
            font-weight: ${attr.FontWeight};
            line-height: ${attr.LineHeight};
            letter-spacing: ${attr.LetterSpacing};
        `;
  },
};

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "435px",
  tablet: "760px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop}  )`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
  minmMobileS: `min-width: ${size.mobileS})`,
  minMobileM: `min-width: ${size.mobileM})`,
  minMobileL: `min-width: ${size.mobileL})`,
  minTablet: `min-width: ${size.tablet})`,
  minLaptop: `min-width: ${size.laptop}  )`,
  minLaptopL: `min-width: ${size.laptopL})`,
  minDesktop: `min-width: ${size.desktop})`,
  minDesktopL: `min-width: ${size.desktop})`,
};
