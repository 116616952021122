import { FC } from "react";
import Button from "../../../../components/Button";

type Props = {
  modify: () => void;
  clear: () => void;
};

const RedoButton: FC<Props> = ({ modify, clear }) => {
  return (
    <div className="flex gap-2 self-end">
      <Button
        onClick={() => clear()}
        size="md"
        className={`text-primary bg-white border-2 border-primary h-[40px] flex gap-4`}
      >
        <span className="text-primary">Nieuw API verzoek</span>
      </Button>

      <Button
        className="text-gray-light bg-primary h-[40px]"
        size="md"
        onClick={() => modify()}
      >
        Hergebruik input
      </Button>
    </div>
  );
};

export default RedoButton;
