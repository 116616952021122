import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import EnergyLabelForm from "./EnergyLabelForm";
import Loading from "../../Loading";
import { FormProvider } from "../../../pages/Api/usage-pages/components/FormContext";
const EnergyLabel = () => {
  const { loading, result } = useAppSelector((state) => state.energyLabel);

  if (loading) {
    return <Loading />;
  }
  return (
    <FormProvider>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/energy-label-result",
          }}
        />
      ) : (
        <EnergyLabelForm />
      )}
    </FormProvider>
  );
};

export default EnergyLabel;
