import React from "react";
import SkeletonText from "../../../../../components/skeleton/components/Text";

type Props = {};

const BarChartSkeleton = (props: Props) => {
  return (
    <div className="animate-pulse flex flex-col">
      <div className="flex gap-2 items-end m-auto">
        <SkeletonText width={"w-1"} height={"h-64"} />

        <SkeletonText width={"w-8"} height={"h-24"} />
        <SkeletonText width={"w-8"} height={"h-32"} />
        <SkeletonText width={"w-8"} height={"h-28"} />
        <SkeletonText width={"w-8"} height={"h-64"} />
        <SkeletonText width={"w-8"} height={"h-60"} />
        <SkeletonText width={"w-8"} height={"h-20"} />
        <SkeletonText width={"w-8"} height={"h-24"} />
        <SkeletonText width={"w-8"} height={"h-10"} />
        <SkeletonText width={"w-8"} height={"h-32"} />
        <SkeletonText width={"w-8"} height={"h-20"} />
        <SkeletonText width={"w-8"} height={"h-24"} />
        <SkeletonText width={"w-8"} height={"h-10"} />
        <SkeletonText width={"w-8"} height={"h-32"} />

        <SkeletonText width={"w-8"} height={"h-28"} />
        <SkeletonText width={"w-8"} height={"h-64"} />
        <SkeletonText width={"w-8"} height={"h-60"} />
        <SkeletonText width={"w-8"} height={"h-20"} />

        <SkeletonText width={"w-8"} height={"h-28"} />
        <SkeletonText width={"w-8"} height={"h-64"} />
        <SkeletonText width={"w-8"} height={"h-60"} />
        <SkeletonText width={"w-8"} height={"h-20"} />
      </div>
    </div>
  );
};

export default BarChartSkeleton;
