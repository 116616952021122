import React, { FC } from "react";
import { Page, Text, View, Image, Svg, Path } from "@react-pdf/renderer";
import styles from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";

const CheckIcon = () => (
  <Svg width="12" height="12" viewBox="0 0 24 24">
    <Path
      d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
      fill="#000000"
    />
  </Svg>
);

const BulletPoint: FC<{ children: React.ReactNode }> = ({ children }) => (
  <View style={styles.bulletPointContainer}>
    <View style={styles.bulletIcon}>
      <CheckIcon />
    </View>
    <Text style={styles.pageText}>{children}</Text>
  </View>
);

const EcowaarderapportPage: FC<{ data: any }> = ({ data }) => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>Verduurzamingsrapport</Text>

    <Text style={styles.pageText}>
      Dit rapport geeft een uitgebreide analyse van mogelijke
      verduurzamingsverbeteringen voor uw woning, gebaseerd op de nieuwste NTA
      8800 energielabelnormen. Het biedt:
    </Text>

    <View style={styles.bulletPoints}>
      <BulletPoint>
        Gedetailleerde aanbevelingen voor verbeteringen van de
        energie-efficiëntie
      </BulletPoint>
      <BulletPoint>
        Geschatte kosten en besparingen voor elke upgrade
      </BulletPoint>
      <BulletPoint>Verwachte reductie van de CO2-uitstoot</BulletPoint>
      <BulletPoint>
        Potentiële waardestijging van uw vastgoed (Ecovalue)
      </BulletPoint>
    </View>

    <Text style={styles.pageText}>
      Bovendien benadrukt het de verwachte impact die deze verbeteringen kunnen
      hebben op de waarde van uw eigendom, waardoor u een duidelijke en
      uitvoerbare routekaart krijgt naar een duurzamer en kosteneffectiever
      huis.
    </Text>

    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Wat is de Ecowaarde?</Text>
      <Text style={styles.sectionText}>
        Het maken van een woning duurzamer resulteert niet alleen in een lagere
        energierekening of extra comfort. Het zorgt er ook voor dat de waarde
        van een woning stijgt. We noemen dit de Ecowaarde. De Ecowaarde is de
        extra marktwaarde van een woning die ontstaat omdat de energielabel
        stijgt wanneer een woning duurzamer wordt gemaakt.
      </Text>
    </View>

    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Hoe komt de Ecowaarde tot stand?</Text>
      <Text style={styles.sectionText}>
        De Ecowaarde wordt berekend door de waarde van een woning vòòr en na de
        stijging van het energielabel met elkaar te vergelijken. Hierbij wordt
        gebruik gemaakt van de energiebesparingsverkenner van de Rijksdienst
        voor Ondernemend Nederland, het automatische waarderingsmodel voor
        onroerend goed van Altum AI en openbare informatie over de woning
        afkomstig van het Centraal Bureau voor de Statistiek, en het Kadaster.
      </Text>
    </View>

    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Voorwaarden</Text>
      <Text style={styles.sectionText}>
        De uitkomsten van dit rapport moeten als indicatief worden beschouwd.
        Altum AI spant zich naar beste vermogen in om informatie te verstrekken
        die juist is, maar kan niet in staan voor de juistheid van informatie
        verkregen van derden. Altum AI is niet aansprakelijk voor enige schade
        (direct of indirect) die voortvloeit uit het gebruik van de gegevens in
        het Verduurzamingsrapport.
      </Text>
    </View>

    <View style={styles.footer}>
      <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default EcowaarderapportPage;
