import React, { useState } from "react";
import Subtitle from "../../../../components/Subtitle";
import Button from "../../../../components/Button";
import { FaPlus } from "react-icons/fa6";
import { useAppSelector } from "../../../../redux/hooks";
import CreditCard from "./CreditCard";
import Sepa from "./Sepa";
import {
  fetchCardDetails,
  fetchSepaDetails,
  getAllPaymentMethods,
} from "../../../../redux/actions/stripeActions";
import Modal from "./Modal";

type Props = {};

const PaymentMethods = (props: Props) => {
  const { cardPaymentMethods, sepaPaymentMethods, paymentMethods } =
    useAppSelector((state) => state.portal);
  const { user } = useAppSelector((state) => state.auth);
  const [updatePaymentModalDisplay, setUpdatePaymentModalDisplay] =
    useState("none");
  const [cardActive, setCardActive] = useState(false);
  const [, setSepaActive] = useState(false);
  const [addPayment, setAddPayment] = useState(false);

  const handleUpdatePaymentModal = (data?: string) => {
    setUpdatePaymentModalDisplay("block");
    if (data === "addPayment") setAddPayment(true);
    // setPaymentMethodId(paymentMethod.id);
  };
  return (
    <>
      <div className="w-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-md mt-4">
        <Subtitle className="text-xl">Betalingswijze</Subtitle>

        {cardPaymentMethods && Object.keys(cardPaymentMethods).length > 0 && (
          <CreditCard
            paymentMethod={cardPaymentMethods}
            handleUpdatePaymentModal={() => handleUpdatePaymentModal()}
            setCardActive={(active) => setCardActive(active)}
            setSepaActive={(active) => setSepaActive(active)}
            defaultPaymentMethod={paymentMethods}
            fetchCardDetails={fetchCardDetails}
            getAllPaymentMethods={getAllPaymentMethods}
          />
        )}
        {sepaPaymentMethods && Object.keys(sepaPaymentMethods).length > 0 && (
          <Sepa
            paymentMethod={sepaPaymentMethods}
            handleUpdatePaymentModal={() => handleUpdatePaymentModal()}
            setSepaActive={(active) => setSepaActive(active)}
            setCardActive={(active) => setCardActive(active)}
            defaultPaymentMethod={paymentMethods}
            fetchSepaDetails={fetchSepaDetails}
            getAllPaymentMethods={getAllPaymentMethods}
          />
        )}

        <Button
          className="shadow-[0px_0px_4px_0px_#00000026]"
          onClick={() => handleUpdatePaymentModal("addPayment")}
        >
          <span className="flex items-center">
            <FaPlus className="mr-1" /> Voeg betaalmethode toe
          </span>
        </Button>
      </div>
      {user && (
        <Modal
          setUpdatePaymentModalDisplay={(event) =>
            setUpdatePaymentModalDisplay(event)
          }
          updatePaymentModalDisplay={updatePaymentModalDisplay}
          // editCardDetails={editCardDetails}
          // errorMsg={error}
          getAllPaymentMethods={getAllPaymentMethods}
          user={user}
          cardActive={cardActive}
          fetchSepaDetails={fetchSepaDetails}
          fetchCardDetails={fetchCardDetails}
          addPayment={addPayment}
          setAddPayment={(e) => setAddPayment(e)}
        />
      )}
    </>
  );
};

export default PaymentMethods;
