import React, { FC } from "react";

interface SubtitleProps {
  children: React.ReactNode;
  className?: string;
}

const Subtitle: FC<SubtitleProps> = ({ children, className }) => (
  <h2 className={`${className} text-gray-dark font-semibold`}>{children}</h2>
);

export default Subtitle;
