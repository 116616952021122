import React from "react";
type Props = {
  width: string;
  height: string;
};
const SkeletonText: React.FC<Props> = ({ width, height }) => {
  return <div className={`${width} ${height} bg-gray-200 rounded`}></div>;
};

export default SkeletonText;
