import { AppDispatch } from '../store';
import { createApiThunk } from '../../helpers/createApiThunk';

export const postReference = createApiThunk(
  'reference/postReference',
  'api',
  'Interactive Reference api used',
  'interactive-reference',
);

export const clearReferenceResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'reference/clearResults' });
};

export const modifyReferenceQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'reference/modifyQueries' });
};

export const referenceOneTimeUse = createApiThunk(
  'reference/referenceOneTimeUse',
  'demo',
  'Interactive Reference demo used',
  'interactive-reference',
);
