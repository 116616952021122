import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import Loading from "../Loading";

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { user, isAuthenticated, initialLoading } = useAppSelector(
    (state) => state.auth,
  );
  return (
    <Route
      {...rest}
      render={(props) => {
        if (initialLoading) {
          return <Loading />;
        }
        if (isAuthenticated && user?.role === "user") {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
