import React, { FC } from "react";
import Title from "../../../components/TItle";
import Text from "../../../components/Text";

type Props = {
  title: string;
  subtitle?: string;
};

const Header: FC<Props> = ({ title, subtitle }) => {
  return (
    <div className="mb-10 w-[326px] md:w-full">
      <Title>{title}</Title>
      {subtitle && <Text className="text-gray-light">{subtitle}</Text>}
    </div>
  );
};

export default Header;
