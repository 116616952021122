import React, { FC } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AsyncThunk } from "@reduxjs/toolkit";
import Text from "../../../../components/Text";
import { useAppDispatch } from "../../../../redux/hooks";

interface PaymentMethodData {
  id: string;
  sepa_debit: {
    last4: string;
    bank_code: string;
  };
}

interface PaymentMethod {
  data: PaymentMethodData[];
}

interface DefaultPaymentMethod {
  payment_method_id: string;
}

interface SepaProps {
  paymentMethod: PaymentMethod;
  handleUpdatePaymentModal: () => void;
  setSepaActive: (active: boolean) => void;
  setCardActive: (active: boolean) => void;
  defaultPaymentMethod: DefaultPaymentMethod;
  fetchSepaDetails: AsyncThunk<unknown, void, any>;
  getAllPaymentMethods: AsyncThunk<unknown, void, any>;
}

const Sepa: FC<SepaProps> = ({
  paymentMethod,
  handleUpdatePaymentModal,
  setSepaActive,
  setCardActive,
  defaultPaymentMethod,
  fetchSepaDetails,
  getAllPaymentMethods,
}) => {
  const handleCardUpdate = () => {
    setSepaActive(true);
    setCardActive(false);
    handleUpdatePaymentModal();
  };
  const dispatch = useAppDispatch();
  const handleRemove = async (paymentMethodId: string) => {
    try {
      await axios.post("/api/v1/mopsus/remove-payment-details", {
        paymentMethodId,
      });
      dispatch(fetchSepaDetails());
      dispatch(getAllPaymentMethods());
      toast.success("Betalingsmethode succesvol verwijderd");
    } catch (err: any) {
      toast.error(err.response?.data.message || "An error occurred");
    }
  };

  const setDefaultPaymentMethod = async (paymentMethodId: string) => {
    try {
      const res = await axios.post<{ msg: string }>(
        "/api/v1/mopsus/set-default-payment-method",
        { paymentMethodId },
      );
      dispatch(fetchSepaDetails());
      dispatch(getAllPaymentMethods());
      toast.success(res.data.msg);
    } catch (err: any) {
      toast.error(err.response?.data.message || "An error occurred");
    }
  };

  return (
    <div className="w-full mx-auto">
      {paymentMethod.data?.map((pm) => (
        <div
          key={pm.id}
          className="flex flex-col md:flex-row items-center p-2 rounded-md  gap-3 md:justify-between py-4 border border-gray-200 mt-4"
        >
          <div className="flex items-center space-x-4 self-start">
            <img
              src="https://img.icons8.com/fluency/30/000000/sepa.png"
              alt="sepa"
            />
            <div>
              <Text className="font-medium">•••• {pm.sepa_debit.last4}</Text>
              <Text className="text-sm text-gray-500">
                Bank code: {pm.sepa_debit.bank_code}
              </Text>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            {defaultPaymentMethod.payment_method_id === pm.id ? (
              <span className="text-sm text-primary font-medium">
                Primaire betaalmethode
              </span>
            ) : (
              <button
                onClick={() => setDefaultPaymentMethod(pm.id)}
                className="text-sm text-blue-600 hover:text-blue-800 font-medium"
              >
                Maak primaire betaalmethode
              </button>
            )}
            <button
              onClick={() => handleRemove(pm.id)}
              className="text-red-500 hover:text-red-700 text-sm font-medium"
            >
              Verwijderen
            </button>
            <button
              onClick={handleCardUpdate}
              className="text-blue-500 hover:text-blue-700 text-sm font-medium"
            >
              Wijzigen gegevens
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Sepa;
