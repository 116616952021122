import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { device } from "../styles/Theme";

interface Props {
  children: ReactNode;
}
const Body: FC<Props> = ({ children }) => <Container>{children}</Container>;

export default Body;

const Container = styled.div`
  flex: 10;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  /* background-color: #f4f6f8; */
  margin-bottom: 50px;
  /* padding: 2rem; */

  @media (max-width: 960px) {
    flex: 10;
  }
  @media (max-width: 1600px) {
    justify-content: center;
    align-items: center;
  }
  @media ${device.mobileL} {
    padding: 0.5rem;
  }
`;
