import styled, { css } from 'styled-components';
import { Colors, TextStyles } from '../Theme';

type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonVariant = 'transparent';

interface ButtonProps {
  size?: ButtonSize;
  var?: ButtonVariant;
}

export const Button = styled.button<ButtonProps>`
	border: 1px solid ${Colors.main.green};
	padding: 10px 20px;
	cursor: pointer;
	border-radius: 4px;
	:hover {
		transform: scale(1.05);
	}

	${({ size }) => {
    switch (size) {
      case 'sm':
        return TextStyles.Bundler(TextStyles.Label.SmallNext);

      case 'md':
        return TextStyles.Bundler(TextStyles.Label.Medium);
      case 'lg':
        return TextStyles.Bundler(TextStyles.Label.Large);
      default:
        return TextStyles.Bundler(TextStyles.Label.Small);
    }
  }}

	${({ var: variant }) => {
    switch (variant) {
      default:
        return css`
					background-color: ${Colors.main.green};
					color: ${Colors.main.white};
				`;
      case 'transparent':
        return css`
					background-color: ${Colors.main.transparent};
					color: ${Colors.main.green};
				`;
    }
  }}
`;
