import React from "react";
import PlanCard from "./PlanCard";
import { MOPSUS, TRANSACTION } from "../../../../helpers/stripePlanTypes";
import StartupPlan from "./StartupPlan";

type Props = {};

const Plan = (props: Props) => {
  const ppuPlanArray = [MOPSUS._100, MOPSUS._250, MOPSUS._750, MOPSUS._2000];

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <PlanCard
          planName={"Altum AI - gratis account"}
          description={"Een gratis account met 15 API credits elke maand."}
          planArr={[]}
        />
        <PlanCard
          planName={MOPSUS._100}
          description={
            "Maak onbeperkt gebruik van de API’s en kies het abonnement dat past bij jouw toepassing"
          }
          planArr={ppuPlanArray}
        />
        <PlanCard
          planName={TRANSACTION._100}
          description={
            "Maak onbeperkt gebruik van de Kadaster transactie API en betaal enkel voor succesvolle opvragingen."
          }
          planArr={[TRANSACTION._100]}
        />
      </div>
      <StartupPlan />
    </div>
  );
};

export default Plan;
