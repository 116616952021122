import DashboardContainer from "../components/Container";
import Header from "../components/Header";
import Tabs, { TabProvider } from "../../../components/Tab";
import AllTabs from "./components/AllTabs";

type Props = {};
const tabsData = ["Account overzicht", "API keys"];
const AccountPage = (props: Props) => {
  return (
    <DashboardContainer pageTitle="Account">
      <Header title={"Account"} />
      <TabProvider>
        <Tabs tabs={tabsData}></Tabs>
        <AllTabs />
      </TabProvider>
    </DashboardContainer>
  );
};

export default AccountPage;
