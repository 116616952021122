import axios from "axios";
import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { logout } from "./authActions";
import addGAEvent from "../../helpers/addGAEvent";
import { useAppDispatch } from "../hooks";

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (
    arg: { oldPassword: string; newPassword: string; confirmPassword: string },
    { rejectWithValue },
  ) => {
    try {
      const { oldPassword, newPassword, confirmPassword } = arg;
      const config = { headers: { "Content-Type": "application/json" } };
      const data = { oldPassword, newPassword, confirmPassword };
      await axios.patch("/api/v1/users/account/change-password", data, config);

      // After successful password change, send google analytics event
      addGAEvent("user", "password changed");
      toast.success("Wachtwoord succesvol veranderd");
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message);
    }
  },
);

export const deleteAccount = createAsyncThunk(
  "user/deleteAccount",
  async (arg: { password: string; id: string }, { rejectWithValue }) => {
    try {
      const dispatch = useAppDispatch();
      const { password, id } = arg;
      const config = { headers: { "Content-Type": "application/json" } };
      const data = { password, id };
      await axios.post(
        `/api/v1/users/account/delete-account/${id}`,
        data,
        config,
      );
      dispatch(logout());
    } catch (error: any) {
      // toast.error(error.response.data.message);
      return rejectWithValue(error.response?.data.message);
    }
  },
);
