import React, { FC } from "react";
import { sidebarData } from "../sidebarData";
import { Link, NavLink } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { EditWrite, PhoneCall } from "react-basicons";
import { logout } from "../../redux/actions/authActions";
import { useAppDispatch } from "../../redux/hooks";
import { GoSignOut } from "react-icons/go";
import { AiOutlineQuestionCircle } from "react-icons/ai";
type Props = {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

const Drawer: FC<Props> = ({ openDrawer, setOpenDrawer }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      {openDrawer && (
        <div
          className="w-full h-full bg-black/[.4] absolute blur-sm z-10 top-0 md:hidden"
          onClick={() => setOpenDrawer(false)}
        ></div>
      )}
      <div
        className={`z-20 fixed top-0 right-0 bg-white ${
          openDrawer ? "w-[300px] p-6" : "w-0 p-0"
        } h-full flex flex-col transition-all ease-in-out delay-150 duration-300`}
      >
        <div className="self-end" onClick={() => setOpenDrawer(false)}>
          {openDrawer && <IoCloseOutline size={23} />}
        </div>
        <ul className="">
          {sidebarData.map(({ name, path, Icon, className }, idx) => (
            <li
              key={idx}
              className="text-base flex mb-4 gap-2 hover:bg-secondary p-2"
            >
              <NavLink
                to={path}
                onClick={() => setOpenDrawer(false)}
                className="flex items-center gap-2"
                activeClassName="bg-secondary p-2 w-full"
              >
                <Icon
                  size={20}
                  className={className ? "text-purple-500" : ""}
                />
                <span className={className}>{name}</span>
              </NavLink>
            </li>
          ))}
          <li className="text-base flex mb-4 gap-2 hover:bg-secondary p-2">
            <AiOutlineQuestionCircle size={23} />
            <a
              href="https://docs.altum.ai/mopsus-data-platform"
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>
          </li>

          <li className="text-base flex mb-4 gap-2 hover:bg-secondary p-2">
            <EditWrite size={23} />
            <a href="https://docs.altum.ai/" target="_blank" rel="noreferrer">
              Documentatie
            </a>
          </li>
          <li className="text-base flex mb-4 gap-2 hover:bg-secondary p-2">
            <a
              href="tel:+31792340990"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <PhoneCall size={23} />
              <p>+31 79 2340990</p>
            </a>
          </li>
          <li
            onClick={() => dispatch(logout())}
            className="text-base flex mb-4 gap-2 p-2 text-red-400"
          >
            <GoSignOut size={23} className="text-red" />
            <span className="text-danger"></span>Uitloggen
          </li>
        </ul>
      </div>
    </>
  );
};

export default Drawer;
