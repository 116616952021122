import { createApiThunk } from '../../helpers/createApiThunk';
import { AppDispatch } from '../store';

export const postWoz = createApiThunk(
  'woz/postWoz',
  'api',
  'Woz api used',
  'woz',
);

export const clearWozResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'woz/clearResults' });
};

export const modifyWozQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'woz/modifyQueries' });
};

export const wozOneTimeUse = createApiThunk(
  'woz/wozOneTimeUse',
  'demo',
  'woz demo used',
  'woz',
);
