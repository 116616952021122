import React from "react";
import Subtitle from "../../../../../components/Subtitle";
import { measures } from "../data";
import MeasureInput from "./MeasureInput";

type Props = {};

const MeasureImplemented = (props: Props) => {
  return (
    <div>
      <Subtitle className="text-base">Huidige staat van maatregelen</Subtitle>
      <div className="grid grid-col-1 md:grid-cols-2 gap-4 mt-4">
        {measures.map((measure, index) => (
          <MeasureInput
            image={measure.image}
            title={measure.title}
            description={measure.description}
            inputs={measure.inputs}
            subtitle={measure.subtitle}
          />
        ))}
      </div>
    </div>
  );
};

export default MeasureImplemented;
