import { AppDispatch } from '../store';
import { createApiThunk } from '../../helpers/createApiThunk';

export const postAmenities = createApiThunk(
  'amenities/postAmenities',
  'api',
  'Amenities Api Used',
  'amenities',
);

export const clearAmenitiesResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'amenities/clearResults' });
};

export const modifyAmenitiesQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'amenities/modifyQueries' });
};

export const amenitiesOneTimeUse = createApiThunk(
  'amenities/amenitiesOneTimeUse',
  'demo',
  'amenities demo used',
  'amenities',
);
