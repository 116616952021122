import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { CiLock } from "react-icons/ci";
import AuthForm from "./components/AuthForm";
import AuthHeader from "./components/AuthHeader";
import Text from "../../components/Text";
import TextInput from "../../components/TextInput";
import "./ForgotPassword.css";

interface ResetPasswordProps
  extends RouteComponentProps<{ resetToken: string }> {}

const ResetPassword: React.FC<ResetPasswordProps> = ({ match }) => {
  const [formField, setFormField] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [passwordChangedSuccess, setPasswordChangedSuccess] = useState(false);
  const [resetGrantAccess, setResetGrantAccess] = useState(false);
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    window.scrollTo(0, 0);

    const getResetTokenAccess = async () => {
      try {
        const res = await axios.get<any>(
          `/api/v1/users/resetPassword/${match.params.resetToken}`,
        );
        if (res.data.resetTokenAccess) {
          setResetGrantAccess(true);
        }
      } catch (error: any) {
        console.log(error.response);
      }
    };

    getResetTokenAccess();
  }, [match.params.resetToken]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (passwordChangedSuccess && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [passwordChangedSuccess, timer]);

  if (timer === 0) {
    return <Redirect to="/signin" />;
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormField({ ...formField, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPasswordChangedSuccess(false);
    //   Error checking
    if (
      formField.password.length === 0 ||
      formField.passwordConfirm.length === 0
    ) {
      toast.error("Lege invoervelden");
    } else if (formField.password !== formField.passwordConfirm) {
      toast.error("Wachtwoorden komen niet overeen");
    } else {
      try {
        const config = { headers: { "Content-Type": "application/json" } };
        const data = {
          password: formField.password,
          passwordConfirm: formField.passwordConfirm,
        };
        const res = await axios.patch(
          `/api/v1/users/resetPassword/${match.params.resetToken}`,
          data,
          config,
        );

        if (res.status === 200) {
          setPasswordChangedSuccess(true);
        }
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
      setFormField({ password: "", passwordConfirm: "" });
    }
  };

  return (
    <div className="w-full p-5 mt-[50px] gap-2 flex w-full flex-col">
      <AuthHeader
        title="Wachtwoord opnieuw instellen"
        description="Vul hieronder je e-mailadres in. Binnen enkele minuten ontvang
        je een e-mail voor het aanmaken van een nieuw wachtwoord."
      />
      {resetGrantAccess ? (
        <>
          <AuthForm
            onSubmit={handleSubmit}
            isLoading={false}
            cta={"Wachtwoord resetten"}
          >
            <TextInput
              type="password"
              name="password"
              placeholder="Vul het wachtwoord in (Minimaal 8 tekens)"
              className="mt-3"
              icon={CiLock}
              value={formField.password}
              onChange={handleChange}
            />
            <TextInput
              type="password"
              name="passwordConfirm"
              placeholder="Bevestig wachtwoord (Minimaal 8 tekens)"
              className="mt-3"
              icon={CiLock}
              value={formField.passwordConfirm}
              onChange={handleChange}
            />
          </AuthForm>
          {passwordChangedSuccess ? (
            <div className="text-center">
              <p className="password-change-success-text">
                Het wachtwoord is succesvol gewijzigd. Log in om verder te gaan.
              </p>
              <p className="password-change-redirect-text">
                U wordt omgeleid in {timer} seconden naar uw Dashboard.
              </p>
              <Link to="/signin" className="redirect-link">
                Ga direct naar het Dashboard
              </Link>
            </div>
          ) : null}
        </>
      ) : (
        <Text className="text-center">
          De wachtwoord-reset URL is verlopen of is al gebruikt.
        </Text>
      )}
    </div>
  );
};

export default ResetPassword;
