import { AppDispatch } from '../store';
import { createApiThunk } from '../../helpers/createApiThunk';

export const postGeometry = createApiThunk(
  'geometry/postGeometry',
  'api',
  'Object Geometry api used',
  'geometry',
);

export const clearGeometryResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'geometry/clearyResults' });
};

export const modifyGeometryQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'geometry/modifyQueries' });
};

export const geometryOneTimeUse = createApiThunk(
  'geometry/geometryOneTimeUse',
  'demo',
  'Object Geometry demo used',
  'geometry',
);
