export const energyInsightsFields = [
  {
    label: "Postcode*",
    name: "postcode",
    type: "text",
    placeholder: "1234AB",
  },
  {
    label: "Huisnummer*",
    name: "housenumber",
    type: "text",
    placeholder: "10",
  },
  {
    label: "Huisnummer toevoeging",
    name: "houseaddition",
    type: "text",
    placeholder: "A",
  },
  {
    label: "Doel energielabel*",
    name: "target_energy_label",
    type: "slider",
  },
  {
    label: "Huidig energielabel",
    name: "current_energy_label",
    type: "slider",
  },
];
