import React, { FC, MouseEventHandler } from "react";

interface TextProps {
  children: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler;
}

const Text: FC<TextProps> = ({ children, className, onClick }) => (
  <p className={`text-xs md:text-sm ${className || ""}`} onClick={onClick}>
    {children}
  </p>
);

export default Text;
