import createApiSlice from '../../helpers/createApiSlice';
import {
  amenitiesOneTimeUse,
  postAmenities,
} from '../actions/amenitiesActions';

const amenitiesSlice = createApiSlice(
  'avm',
  postAmenities,
  amenitiesOneTimeUse,
);

export const { clearResults, modifyQueries } = amenitiesSlice.actions;

export default amenitiesSlice.reducer;
