import { FC, Fragment, useEffect } from "react";
import styled from "styled-components";
import { Link, RouteComponentProps } from "react-router-dom";
import { Colors } from "../../styles/Theme";
import "./Auth.css";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { verifyAccount } from "../../redux/actions/authActions";

interface MatchParams {
	userId: string;
}

interface VerifyAccountProps extends RouteComponentProps<MatchParams> {}
const VerifyAccount: FC<VerifyAccountProps> = ({ match, history }) => {
	const { isAuthenticated, verified } = useAppSelector((state) => state.auth);
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (!isAuthenticated) {
			dispatch(verifyAccount(match.params.userId));
		} else {
			history.push("/");
		}
	}, [dispatch, history, isAuthenticated, match.params.userId]);

	return (
		<ColumnContainer>
			<Container>
				{verified ? (
					<>
						<p>Uw account is geverifieerd. Log in om door te gaan.</p>
						<Link
							to="/signin"
							className="btn btn-primary"
							id="verify-account-login-btn"
						>
							Inloggen
						</Link>
					</>
				) : (
					<p>Account bestaat niet of is al geverifieerd.</p>
				)}
			</Container>
		</ColumnContainer>
	);
};

export default VerifyAccount;

const Container = styled.div`
	display: flex;
	padding: 1rem 0;
	background: ${Colors.main.lightGreen};
	align-items: center;
	justify-content: center;
	font-family: "Mulish", sans-serif !important  ;
	min-height: 100%;
	box-sizing: border-box;

	a {
		color: ${Colors.main.green};
		@media (max-width: 960px) {
			font-size: 14px;
		}
	}

	@media (max-width: 1300px) {
		flex-direction: column;
		gap: 50px;
		padding: 2rem;
	}
`;

const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	background: ${Colors.main.lightGreen} !important;

	.copyright {
		margin-top: 50px;
		background: ${Colors.main.lightGreen};
	}
	@media (max-width: 960px) {
		height: 100%;
	}
`;



