import convertToEuFormat from "./convertToEuFormat";
import timeConverter from "./timeStampConverter";

const billingTime = (sub: any, comingEvent: any) => {
  const startDate = timeConverter(sub?.current_period_start);
  const endDate = timeConverter(sub?.current_period_end);
  const amountDue = convertToEuFormat(comingEvent.amount_due / 100);
  return { endDate, startDate, amountDue };
};

export default billingTime;
