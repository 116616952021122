export const RebuildField = [
  {
    label: "Postcode*",
    name: "postcode",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "housenumber",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "houseaddition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Waarderingsdatum ",
    name: "valuationdate",
    type: "text",
    placeholder: "20191030 (JJJJMMDD)",
    tooltip: "",
  },
  {
    label: "Gerenoveerde keuken/badkamer",
    name: "new_amenity",
    type: "radio",
    placeholder: "1",
    tooltip: "",
    options: [
      { label: "Gerenoveerd", value: 0 },
      { label: "Niet gerenoveerd", value: 1 },
    ],
  },
];
