import { useEffect, useMemo, useState } from "react";
import APIResult, { LightText, ListContainer } from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { getStreetViewImage } from "../../../helpers/common";
import {
  clearRentalReferenceResults,
  modifyRentalReferenceQueries,
} from "../../../redux/actions/rentalReferenceAction";
import { CardRental } from "../../SolutionsCard";
import {
  BoldText,
  ColumnContainer,
  GridContainer,
  SubTitle,
} from "../reference/ReferenceResult";
import Modal from "../../Modal";
import {
  FormProvider,
  useFormContext,
} from "../../../pages/Api/usage-pages/components/FormContext";
import { fetchAddressData } from "../../../helpers/addressUtils";
const RentalReferenceResult = () => {
  const { result, loading } = useAppSelector((state) => state.rentalreference);
  const property: any = result;
  const { buildingPhoto, setPostalAddress, fetchAddressData } =
    useFormContext();

  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      const postalAddress = `${property.postcode}-${property.housenumber}-${
        property.houseaddition ?? ""
      }`;
      setPostalAddress(postalAddress);
    }
  }, [property, setPostalAddress]);

  useEffect(() => {
    if (Object.keys(result).length > 0 && buildingPhoto.length === 0) {
      fetchAddressData();
    }
  }, [result, buildingPhoto.length, fetchAddressData]);

  const clearResults = () => {
    dispatch(clearRentalReferenceResults());
    history.push("/rentalreference");
  };

  const modifyResults = () => {
    dispatch(modifyRentalReferenceQueries());
    history.push("/rentalreference");
  };
  if (Object.keys(property).length === 0) {
    return <Redirect to="/rentalreference" />;
  }
  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: property.bag_id,
    },
    {
      img: "",
      title: "Huisnummer toevoeging",
      result: `${property.houseaddition ? property.houseaddition : "-"}`,
    },
    {
      img: "",
      title: "Latitude",
      result: property.latitude,
    },
    {
      img: "",
      title: "Longitude",
      result: property.longitude,
    },
  ];

  const tableValues = property.reference_data.rent_distribution.map(
    (item: any) => {
      return {
        min_max: item.min_sqm + "-" + item.max_sqm,
        sample_count: item.sample_count,
        rent_innersurfacearea: `€${convertToEuFormat(
          item.rent_innersurfacearea,
        )}`,
        avg_rent: `€${convertToEuFormat(item.avg_rent)}p/m`,
      };
    },
  );

  const reference = [
    { text: "Referenties opvragen", path: "/referentie" },
    { text: "Kadaster transactie ophalen", path: "/transactie-api" },
    { text: "WOZ waarde opvragen", path: "/woz" },
  ];
  return (
    <APIResult
      property={property}
      loading={loading}
      title={"Huurreferentie API"}
      street={property.housenumber + "" + property.street}
      postCode={property.postcode}
      city={property.city}
      overview={[]}
      reference={reference}
      descSection={desc}
      buildingPhoto={buildingPhoto}
      path="https://docs.altum.ai/apis/huurreferentie-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
      Ref={RefItems}
      tableValues={tableValues}
    />
  );
};

const Index = () => {
  return (
    <FormProvider>
      <RentalReferenceResult />
    </FormProvider>
  );
};
export default Index;
const RefItems = () => {
  const { result } = useAppSelector((state) => state.rentalreference);
  const property: any = result;

  const [selectedItem, setSelectedItem] = useState<any[]>([]);

  const [trigger, setTrigger] = useState(false);
  const [rentalRefArr, setRentalRefArr] = useState<any[]>([]);

  const handleCardClick = (item: any) => {
    console.log(item);
    const output = [
      {
        key: "Postcode",
        value: item?.postcode,
      },
      {
        key: "Huisnummer",
        value: item?.housenumber,
      },
      {
        key: "Huisnummer toevoeging",
        value: item?.houseaddition,
      },
      {
        key: "Straat",
        value: item?.street,
      },
      {
        key: "Woonplaats",
        value: item?.city,
      },
      {
        key: "Woningtype",
        value: item?.housetype,
      },
      {
        key: "Bouwjaar doelobject",
        value: item?.buildyear,
      },
      {
        key: "Oppervlakte doelobject",
        value: convertToEuFormat(item?.innersurfacearea),
      },
      {
        key: "Energielabel",
        value: item?.energylabel,
      },
      {
        key: "Huurprijs",
        value: "€" + convertToEuFormat(item?.rental_price),
      },
      {
        key: "Slaapkamers",
        value: convertToEuFormat(item?.bedrooms),
      },
    ];
    setSelectedItem(output);
  };

  const referenceHouseArray = useMemo(
    () => [...property?.reference_data?.reference_houses],
    [property?.reference_data?.reference_houses],
  );

  useEffect(() => {
    const fetchAddressDataForHouses = async () => {
      const houses = await Promise.all(
        referenceHouseArray.splice(0, 3).map(async (item: any) => {
          const postalAddress = `${item.postcode}-${item.housenumber}-${
            item.houseaddition ?? ""
          }`;
          const result = await fetchAddressData(postalAddress);
          return { ...item, imageUrl: result?.imageUrl };
        }),
      );
      setRentalRefArr(houses);
    };

    fetchAddressDataForHouses();
  }, [referenceHouseArray]);
  return (
    <GridContainer>
      {rentalRefArr.map((item: any) => {
        return (
          <div
            onClick={() => {
              handleCardClick(item);
              setTrigger(true);
            }}
          >
            <CardRental
              buildingPhoto={item.imageUrl}
              houseNumber={`${
                item.housenumber +
                " " +
                (item.houseaddition ? item.houseaddition : "")
              }`}
              city={item.city}
              street={item.street}
              rentalPrice={item.rental_price}
              innerSurfaceArea={item.innersurfacearea}
            />
          </div>
        );
      })}

      {selectedItem.length > 0 && trigger && (
        <Modal trigger={trigger} setTrigger={setTrigger}>
          <div style={{ overflowY: "auto" }}>
            <SubTitle>Resultaat</SubTitle>
            {selectedItem.map((item, index) => (
              <ColumnContainer key={index}>
                <BoldText>{item.key}</BoldText>
                <ListContainer>
                  <LightText>{item.value}</LightText>
                </ListContainer>
              </ColumnContainer>
            ))}
          </div>
        </Modal>
      )}
    </GridContainer>
  );
};
