import React from "react";
import { propertyStatus } from "../data";
import ReturnInputField from "../../../../../components/ReturnInputField";
import Subtitle from "../../../../../components/Subtitle";
import { useFormContext } from "../../components/FormContext";

type Props = {};

const PropertyStatus = (props: Props) => {
  const { formValues, setFormValues } = useFormContext();
  return (
    <div className="">
      <Subtitle className="text-base">
        Vertel ons iets meer over de bewoning, het verbruik en huidige
        maatregelen
      </Subtitle>
      <div className="grid grid-cols-1 gap-8 mt-4 max-w-[427px]">
        {propertyStatus.map((field, index) => (
          <ReturnInputField
            key={index}
            label={field.label}
            type={field.type}
            name={field.name}
            value={formValues && formValues[field.name]}
            placeholder={field.placeholder}
            onChange={(e) => {
              "target" in e &&
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default PropertyStatus;
