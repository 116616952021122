export const keyTranslations: { [key: string]: string } = {
  total_wws_points: "Totaal WWS-punten",
  "1_area_of_rooms": "Oppervlakte van vertrekken",
  "2_surface_area_other_spaces": "Oppervlakte overige ruimten",
  "3_heating": "Verwarming",
  "4_energy_label": "Energielabel",
  "5_kitchen": "Keuken",
  "6_sanitary_facilities": "Sanitair",
  "7_facilities_for_disables": "Voorzieningen voor gehandicapten",
  "8_private_outdoor_spaces": "Privé-buitenruimten",
  "9_woz_value": "WOZ-waarde",
  "10_renovation": "Renovatie",
  "11_special_facilities": "Bijzondere voorzieningen",
  "12_monument": "Monument",
};
