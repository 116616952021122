export const rentalReferenceFields = [
  {
    label: "Postcode*",
    name: "postcode",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "housenumber",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "houseaddition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Oppervlakte doelobject (m2)",
    name: "innersurfacearea",
    type: "number",
    placeholder: "20",
    tooltip: "Oppervlakte van het doelobject",
  },
  {
    label: "Gemeubileerd",
    name: "furnished_target",
    type: "select",
    placeholder: "",
    tooltip: "Geef aan of de huidige woning gemeubileerd is",
    options: [
      { value: 1, label: "Ja" },
      {
        value: 0,
        label: "Nee",
      },
    ],
  },
  {
    label: "Aanwezigheid tuin",
    name: "garden_target",
    type: "select",
    placeholder: "",
    tooltip: "Geef aan of de huidige woning een tuin heeft",
    options: [
      { value: 1, label: "Ja" },
      {
        value: 0,
        label: "Nee",
      },
    ],
  },

  {
    label: "Aantal referenties (vanaf 10 t/m 30)",
    name: "reference_number",
    type: "number",
    placeholder: "10",
    tooltip: "",
  },

  {
    label: "Transactie historie in maanden (van 6 t/m 60)",
    name: "date_limit",
    type: "number",
    placeholder: "12",
    tooltip:
      "Geef aan tot hoever terug gezocht mag worden naar referenties in maanden",
  },
  {
    label: "Gelimiteerd zoeken",
    name: "window_limit",
    type: "select",
    placeholder: "",
    tooltip:
      "Geef aan of er gezocht mag worden naar objecten buiten de opgegeven waardes",
    options: [
      { value: 1, label: "Ja" },
      {
        value: 0,
        label: "Nee",
      },
    ],
  },
  {
    label: "Gemeubileerd zoeken",
    name: "furnished",
    type: "select",
    placeholder: "",
    tooltip: "Geef aan of er gezocht moet worden naar gemeubileerde objecten",
    options: [
      { value: 1, label: "Ja" },
      {
        value: 0,
        label: "Nee",
      },
    ],
  },
  {
    label: "Tuin zoeken",
    name: "garden",
    type: "select",
    placeholder: "",
    tooltip: "Geef aan of er gezocht moet worden naar objecten met een tuin",
    options: [
      { value: 1, label: "Ja" },
      {
        value: 0,
        label: "Nee",
      },
    ],
  },
  {
    label: "Belang van oppervlakte (0.0 tot 1.0)",
    name: "weight_innersurfacearea",
    type: "number",
    placeholder: "(0-1)",
    tooltip: "",
  },
  {
    label: "Belang van recentheid (0.0 tot 1.0)",
    name: "garden",
    type: "weight_recency",
    placeholder: "(0-1)",
    tooltip: "",
  },
  {
    label: "Belang van afstand (0.0 tot 1.0)",
    name: "weight_distance",
    type: "select",
    placeholder: "(0-1)",
    tooltip: "",
  },
];
