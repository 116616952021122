import { FC } from "react";
import CodeBlock from "../../../../components/apiForms/Codeblock";
import { stringToBoolean } from "../../../../helpers/common";

type Props = {
  formValues: Record<string, any>;
  result: any;
  showFormValues?: boolean;
  transformPostCode?: boolean;
  additionalFields?: Record<string, any>;
};

const JsonViewer: FC<Props> = ({
  formValues,
  result,
  showFormValues = true,
  transformPostCode = true,
  additionalFields = {},
}) => {
  const json = showFormValues ? formValues : result;

  return (
    <div className="w-full h-[780px]">
      <CodeBlock text={json} />
    </div>
  );
};

export default JsonViewer;
