import React, { FC, FormEvent, useCallback } from "react";
import styled from "styled-components";
import ReturnInputField from "../ReturnInputField";
import Button from "../Button";
import { useFormContext } from "../../pages/Api/usage-pages/components/FormContext";
import { tryParseInt } from "../../helpers/tryParseInt";
import Subtitle from "../Subtitle";
import { MultiValue, ActionMeta } from "react-select";

interface FieldOption {
  value: string | number;
  label: string;
}

export interface Field {
  label: string;
  placeholder?: string;
  type: string;
  name: string;
  options?: FieldOption[];
  tooltip?: string;
}

interface Section {
  title: string;
  startIndex: number;
  endIndex: number;
}

interface Props {
  setIsFormActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface FormInputProps {
  initialFields: Field[];
  sectionFields: Section[];
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export enum InputTypes {
  SELECT = "select",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  TEXT = "text",
  SLIDER = "slider",

  NUMBER = "number",
  MULTISELECT = "multiselect",
}

const APIForm: FC<FormInputProps & Props> = ({
  initialFields,
  sectionFields,
  handleSubmit,
}) => {
  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      handleSubmit(event);
    },
    [handleSubmit],
  );
  const { setFormValues } = useFormContext();
  // const [showContent, setShowContent] = useState(false);

  // const fieldsGreaterThanOne = sectionFields.length > 1;'
  const changeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | MultiValue<FieldOption>
      | FieldOption,
    a?: ActionMeta<FieldOption>,
  ) => {
    if (Array.isArray(e)) {
      const result = e?.map(({ value }) => value);

      a?.name &&
        setFormValues((prevValues) => ({
          ...prevValues,
          [String(a.name)]: result,
        }));
    } else if (typeof e === "object") {
      const event = e as React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement
      >;
      const inputValue = event.target.value;
      setFormValues((prevValues) => ({
        ...prevValues,
        [event.target.name]: inputValue,
      }));
    } else {
      const [isInt, parsedValue] = tryParseInt(e);
      setFormValues((prevValues) => ({
        ...prevValues,
        [e]: isInt ? parsedValue : e,
      }));
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {sectionFields?.map((section, sectionIndex) => {
        const { title, startIndex, endIndex } = section;
        const firstSectionData = initialFields.slice(startIndex, 2);
        const secondSectionData = initialFields.slice(2, endIndex + 1);

        return (
          <div key={sectionIndex}>
            {sectionIndex === 0 && (
              <>
                <Subtitle className="text-primary text-lg">{title}</Subtitle>
                <div
                  key={sectionIndex}
                  className="grid grid-cols-2 md:grid-cols-2 gap-8 mt-4"
                >
                  {firstSectionData.map((field) => {
                    return (
                      <ReturnInputField {...field} onChange={changeHandler} />
                    );
                  })}
                </div>
                <div
                  key={sectionIndex}
                  className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4"
                >
                  {secondSectionData.map((field) => {
                    return (
                      <ReturnInputField {...field} onChange={changeHandler} />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}

      {sectionFields?.map((section, sectionIndex) => {
        const { title, startIndex, endIndex } = section;
        const sectionData = initialFields.slice(startIndex);

        return (
          <div key={sectionIndex}>
            {sectionIndex > 0 && (
              <>
                <Subtitle className="text-primary text-lg">{title}</Subtitle>
                <div
                  key={sectionIndex}
                  className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4"
                >
                  {sectionData.map((field) => {
                    return (
                      <ReturnInputField {...field} onChange={changeHandler} />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}
      <Button className="bg-primary w-full md:w-auto" type="submit">
        Versturen
      </Button>
    </Form>
  );
};

function stringToBoolean(value: string) {
  if (typeof value !== "string") {
    throw new Error("Expected a string argument");
  }

  if (value.toLowerCase() === "true") return true;
  if (value.toLowerCase() === "false") return false;

  return value;
}
const Form = styled.form``;

const SubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: green;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const ButtonStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1e9d66;
  width: 200px;
  margin-top: 3rem;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  :hover {
    transform: scale(1.1);
  }
  a {
    color: #1e9d66;
    background: white;
    padding: 1rem;
    border-radius: 4px;
  }
`;

export default APIForm;
