import ApiCard from "./ApiCard";
import { APIdata } from "./ApisData";
import { FC } from "react";

type Props = {
  activeCategory: string;
  search: string;
};
const Api: FC<Props> = ({ activeCategory, search }) => {
  const filteredData = APIdata.filter((data) => {
    const matchesCategory =
      activeCategory === "Alle producten" || data.category === activeCategory;
    const matchesSearch = data.title
      .toLowerCase()
      .includes(search.toLowerCase());
    return matchesCategory && matchesSearch;
  });
  return (
    <>
      {/* <Heading>Woningdata producten</Heading> */}

      {filteredData?.map((item, index) => (
        <ApiCard
          key={index}
          title={item.title}
          text={item.text}
          image={item.img}
          link={item.link}
          category={item.category}
          isNew={item.isNew}
        />
      ))}
    </>
  );
};

export default Api;
