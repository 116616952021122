import SkeletonText from "../../../../components/skeleton/components/Text";

type Props = {};

const ImageSkeleton = (props: Props) => {
  return (
    <div className="max-w-[1100px] p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-2xl gap-8 animate-pulse">
      <SkeletonText width={"w-24"} height={"h-2"} />
      <div className="w-full mt-2">
        <SkeletonText width={"w-full"} height={"h-64"} />
      </div>
    </div>
  );
};

export default ImageSkeleton;
