import createApiSlice from "../../helpers/createApiSlice";
import {
  postRentalReference,
  RentalReferenceOneTimeUse,
} from "../actions/rentalReferenceAction";

const rentalReferenceSlice = createApiSlice(
  "rentalReference",
  postRentalReference,
  RentalReferenceOneTimeUse,
);

export const { clearResults, modifyQueries } = rentalReferenceSlice.actions;

export default rentalReferenceSlice.reducer;
