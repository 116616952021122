import React from "react";
import SkeletonText from "../../../../../components/skeleton/components/Text";

type Props = {};
const UsedAPIListSkeleton = (props: Props) => {
  return (
    <div className="animate-pulse flex flex-col gap-4">
      {Array.from({ length: 10 }).map(() => (
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <SkeletonText width={"w-4"} height={"h-2"} />
            <SkeletonText width={"w-56"} height={"h-3"} />
          </div>
          <SkeletonText width={"w-4"} height={"h-4"} />
        </div>
      ))}
    </div>
  );
};

export default UsedAPIListSkeleton;
