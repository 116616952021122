import { useCallback, useEffect, useState } from "react";
import { fetchChatHistory, usePropertyContext } from "../usePropertyContext";
import Subtitle from "../../../components/Subtitle";
import { Pagination } from "flowbite-react";
import { LuClipboardCheck } from "react-icons/lu";
import { FiClipboard } from "react-icons/fi";
import { TrashDeleteBin } from "react-basicons";
import { TfiArrowCircleDown, TfiArrowCircleUp } from "react-icons/tfi";
import Text from "../../../components/Text";
import { knowWhen } from "../../../helpers/time";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { Link } from "react-router-dom";

const ChatHistory = () => {
  const { chatHistory, setChatHistory, page, setPage, pagination } =
    usePropertyContext();
  const [copied, setCopied] = useState(false);
  const [activeChatId, setActiveChatId] = useState("");

  const copyToClipboard = useCallback(async (description: string) => {
    try {
      await navigator.clipboard.writeText(description);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }, []);
  const handleDeleteChat = useCallback(
    async (id: string) => {
      await axios.delete(`/api/v1/property-description/chat-history/${id}`);
      const ch = await fetchChatHistory(1);
      setChatHistory(ch.chatHistory);
    },
    [setChatHistory],
  );

  return (
    <div className="w-full p-1 md:p-4">
      <div className="flex flex-col gap-8">
        {chatHistory.map(({ id, description, created_at }) => {
          const title = description.split("\n")[0].replace("##", "");
          return (
            <div
              className="flex flex-col gap-3 border-b-2  border-dashed pb-4"
              key={id}
            >
              <Text className="text-base text-gray-400">
                {knowWhen(created_at)}
              </Text>
              <div className="grid md:grid-cols-3 grid-cols-1 shadow-[0px_0px_4px_0px_#00000026] rounded-lg p-4 gap-4">
                <Subtitle className="text-sm md:col-span-2">{title}</Subtitle>
                <div className="flex justify-between md:col-span-1">
                  <div
                    className="flex gap-2"
                    onClick={() => {
                      activeChatId === id
                        ? setActiveChatId("")
                        : setActiveChatId(id);
                    }}
                  >
                    <Text>More details</Text>
                    {activeChatId === id ? (
                      <TfiArrowCircleDown />
                    ) : (
                      <TfiArrowCircleUp />
                    )}
                  </div>
                  <div
                    onClick={() => handleDeleteChat(id)}
                    className="cursor-pointer"
                  >
                    <TrashDeleteBin />
                  </div>
                </div>
                <div
                  className={`${
                    activeChatId === id ? "h-full relative" : "h-0 hidden"
                  } md:col-span-3`}
                >
                  {copied && (
                    <div className="text-green-600 text-sm mt-2">
                      Text copied to clipboard!
                    </div>
                  )}
                  <button
                    onClick={() => copyToClipboard(description)}
                    className="absolute top-2 right-1 text-gray-500 hover:text-gray-800 focus:outline-none"
                    title="Copy to clipboard"
                  >
                    {copied ? (
                      <LuClipboardCheck className="h-6 w-6 text-primary" />
                    ) : (
                      <FiClipboard className="h-6 w-6" />
                    )}
                  </button>
                  <ReactMarkdown>{description}</ReactMarkdown>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col md:flex-row overflow-x-auto justify-center md:justify-between self-center items-center">
        <Pagination
          layout="pagination"
          currentPage={page}
          totalPages={pagination?.totalPages || 1}
          onPageChange={(page) => setPage(page)}
          showIcons
        />
        <div className="underline md:self-end">
          <Link to="/property-description/generate">
            Genereer een nieuwe beschrijving
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ChatHistory;
