import { FC } from "react";
import AuthLeftSection from "../components/AuthLeftSection";
import SigninRightSection from "./components/SigninRightSection";
import Verduuzaming from "../../../assets/images/Verduurzaming-v2.png";

const SignIn: FC = () => {
  return (
    <>
      <div className="flex h-screen">
        <AuthLeftSection image={Verduuzaming} />
        <SigninRightSection />
      </div>

      {/* <div className="copyright">
      <Text>© Altum AI B.V.</Text>
    </div> */}
    </>
  );
};

export default SignIn;
