import React from "react";
import SkeletonText from "../../../../components/skeleton/components/Text";

type Props = {};

const ActivePlanCardSkelecton = (props: Props) => {
  return (
    <div className="shadow-[0px_0px_4px_0px_#00000026] rounded-lg w-full md:w-[360px] h-48 p-3 flex flex-col justify-between animate-pulse">
      <div className="flex justify-between items-center">
        <SkeletonText width="w-2/4" height="h-3" />
        <SkeletonText width="w-1/3" height="h-2" />
      </div>
      <div className="flex flex-col justify-between gap-4">
        <SkeletonText width="w-1/2" height="h-2" />
        <SkeletonText width="w-1/2" height="h-2" />
      </div>
      <div className="flex flex-col justify-between gap-4">
        <SkeletonText width="w-3/4" height="h-2" />
        <SkeletonText width="w-1/3" height="h-2" />
      </div>
    </div>
  );
};

export default ActivePlanCardSkelecton;
