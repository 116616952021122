import { AppDispatch } from '../store';
import { createApiThunk } from '../../helpers/createApiThunk';

export const postEnergyLabel = createApiThunk(
  'energyLabel/postEnergyLabel',
  'api',
  'Energy Label api used',
  'energylabel',
);

export const clearEnergyLabelResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'energyLabel/clearResults' });
};

export const modifyEnergyLabelQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'energyLabel/modifyQueries' });
};

export const energyLabelOneTimeUse = createApiThunk(
  'energyLabel/avmOneTimeUse',
  'demo',
  'Energy Label demo used',
  'energylabel',
);
