import { FC, useEffect, useRef } from "react";

const ReviewBadge: FC = () => {
  const reviewBadgeElement = document.getElementById("feedbackcompany-widget");
  useEffect(() => {
    // Insert the script into the document
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "__fbcw__57f1c44b-147a-43e7-80e5-5b4c9bd33365";
    script.innerHTML = `
      "use strict";!function(){
      window.FeedbackCompanyWidgets=window.FeedbackCompanyWidgets||{queue:[],loaders:[
      ]};var options={uuid:"57f1c44b-147a-43e7-80e5-5b4c9bd33365",version:"1.2.1",prefix:""};if(
      void 0===window.FeedbackCompanyWidget){if(
      window.FeedbackCompanyWidgets.queue.push(options),!document.getElementById(
      "__fbcw_FeedbackCompanyWidget")){var scriptTag=document.createElement("script")
      ;scriptTag.onload=function(){if(window.FeedbackCompanyWidget)for(
      ;0<window.FeedbackCompanyWidgets.queue.length;
      )options=window.FeedbackCompanyWidgets.queue.pop(),
      window.FeedbackCompanyWidgets.loaders.push(
      new window.FeedbackCompanyWidgetLoader(options))},
      scriptTag.id="__fbcw_FeedbackCompanyWidget",
      scriptTag.src="https://www.feedbackcompany.com/includes/widgets/feedback-company-widget.min.js"
      ,document.body.appendChild(scriptTag)}
      }else window.FeedbackCompanyWidgets.loaders.push(
      new window.FeedbackCompanyWidgetLoader(options))}();
    `;
    reviewBadgeElement?.appendChild(script);

    // Clean up function
    return () => {
      reviewBadgeElement?.removeChild(script);
    };
  }, [reviewBadgeElement]);

  return <div id="feedbackcompany-widget"></div>;
};

export default ReviewBadge;
