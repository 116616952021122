import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../Loading";
import ReferenceForm from "./ReferenceForm";
import { FormProvider } from "../../../pages/Api/usage-pages/components/FormContext";
const Reference = () => {
  const { result, loading } = useAppSelector((state) => state.reference);

  if (loading) {
    return <Loading />;
  }
  return (
    <FormProvider>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/reference-result",
          }}
        />
      ) : (
        <ReferenceForm />
      )}
    </FormProvider>
  );
};

export default Reference;
