import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postLocationData = createApiThunk(
  "locationData/postLocationData",
  "api",
  "LocationData api used",
  "location-data",
);

export const clearLocationDataResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "locationData/clearResults" });
};

export const modifyLocationDataQueries =
  () => async (dispatch: AppDispatch) => {
    dispatch({ type: "locationData/modifyQueries" });
  };

export const locationDataOneTimeUse = createApiThunk(
  "locationData/locationDataOneTimeUse",
  "demo",
  "LocationData demo used",
  "location-data",
);
