import React, { FC, useState } from "react";
import Subtitle from "../../../../components/Subtitle";
import Button from "../../../../components/Button";
import Text from "../../../../components/Text";
import { FiLayers } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { LuBadgeCheck } from "react-icons/lu";
import { useTabContext } from "../../../../components/Tab";
import CancelPlanModal from "./CancelPlanModal";
import { useAppSelector } from "../../../../redux/hooks";
import { useHistory } from "react-router-dom";
import { TRANSACTION } from "../../../../helpers/stripePlanTypes";

type Props = {
  planName?: string;
  planPrice?: string | number;
  startDate?: string | number;
  endDate?: string | number;
  amountDue?: string | number;
  apiUsed?: string | number;
};

const SubscriptionCard: FC<Props> = ({
  planName,
  planPrice,
  startDate,
  endDate,
  amountDue,
  apiUsed,
}) => {
  const { setActiveIndex } = useTabContext();
  const { user } = useAppSelector((state) => state.auth);
  const history = useHistory();
  const [cancelPlanModalDisplay, setCancelPlanModalDisplay] = useState(false);
  return (
    <>
      <div className="w-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-2xl flex flex-col justify-between">
        <div className="flex justify-between items-center p-4">
          <div className="flex gap-2 items-center">
            <LuBadgeCheck className="text-primary" />
            <Subtitle className="text-xl">{planName}</Subtitle>
          </div>
          <div className="flex items-end">
            <Subtitle className="text-xl">€{planPrice}</Subtitle>
            <Text className="text-gray-light">
              /maand {planName !== "Altum AI - gratis account" && "(excl. BTW)"}
            </Text>
          </div>
          <div className="hidden lg:flex lg:gap-2 ">
            <Button
              className="mt-0 bg-[#F2F2F2] p-0 h-[35px] gap-2 flex"
              size={"sm"}
              disabled={planName === "Altum AI - gratis account"}
              onClick={() => setCancelPlanModalDisplay(true)}
            >
              <span className="flex items-center">
                <IoCloseCircleOutline className="mr-1" />
                Abonnement opzeggen
              </span>
            </Button>
            <Button
              className="mt-0 bg-primary p-0 h-[35px] gap-2 flex"
              disabled={planName === TRANSACTION._100}
              onClick={() => setActiveIndex(1)}
            >
              <span className="flex items-center">
                <FiLayers className="mr-1" />
                Abonnement upgraden
              </span>
            </Button>
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-2 md:flex md:justify-between md:items-center p-4 gap-4">
            <div className="flex flex-col">
              <Text className="text-gray-light md:text-xs">Begindatum</Text>
              <Text className="font-bold">{startDate}</Text>
            </div>
            <div className="flex flex-col">
              <Text className="text-gray-light md:text-xs self-end">
                Vernieuwingsdatum
              </Text>
              <Text className="font-bold self-end">{endDate}</Text>
            </div>
            <div className="flex flex-col">
              <Text className="text-gray-light md:text-xs">Duur</Text>
              <Text className="font-bold">1 maand</Text>
            </div>
            <div className="flex flex-col">
              <Text className="text-gray-light md:text-xs self-end">
                Volgende betaling
              </Text>
              <div className="flex items-center">
                <Text className="font-bold self-end">€{amountDue} </Text>
                <Text className="text-gray-light">
                  /{apiUsed} API calls{" "}
                  {planName !== "Altum AI - gratis account" && "(incl. BTW)"}
                </Text>
              </div>
            </div>
          </div>
          <div className="lg:hidden flex flex-col md:flex-row gap-2 md:self-end">
            <Button
              className="mt-0 bg-[#F2F2F2] p-0 h-[35px] gap-2 flex"
              size={"sm"}
              disabled={planName === "Altum AI - gratis account"}
              onClick={() => setCancelPlanModalDisplay(true)}
            >
              <span className="flex items-center">
                <IoCloseCircleOutline className="mr-1" />
                Abonnement opzeggen
              </span>
            </Button>
            <Button
              className="mt-0 bg-primary p-0 h-[35px] gap-2 flex"
              disabled={planName === TRANSACTION._100}
              onClick={() => setActiveIndex(1)}
            >
              <span className="flex items-center">
                <FiLayers className="mr-1" />
                Abonnement upgraden
              </span>
            </Button>
          </div>
        </div>
      </div>
      {planName && (
        <CancelPlanModal
          user={user}
          plan={planName}
          setCancelPlanModalDisplay={setCancelPlanModalDisplay}
          cancelPlanModalDisplay={cancelPlanModalDisplay}
          history={history}
        />
      )}
    </>
  );
};

export default SubscriptionCard;
