import { createSlice, AsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ApiOutput } from "../@types";
import { translationError } from "./createApiThunk";
import { toast } from "react-toastify";

interface ApiInitState {
  loading: boolean;
  result: {};
  errors: unknown[];
  savedQueries: {};
  oneTimeResult: {};
  ping: string | null;
}
interface ApiOutputPayload {
  output: ApiOutput;
  query: {
    postcode: string;
  };
}
const createApiSlice = (
  sliceName: string,
  postApi: AsyncThunk<any, any, any>,
  oneTimeUse?: AsyncThunk<any, any, any>,
) => {
  const initialState: ApiInitState = {
    loading: false,
    result: {},
    errors: [],
    savedQueries: {},
    oneTimeResult: {},
    ping: null,
  };
  const apiSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      clearResults: (state) => {
        state.result = {};
        state.savedQueries = {};
      },
      modifyQueries: (state) => {
        state.loading = false;
        state.result = {};
      },
      translations: (state) => {
        state.loading = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(postApi.pending, (state) => {
          state.loading = true;
          state.errors = [];
        })
        .addCase(
          postApi.fulfilled,
          (state, action: PayloadAction<ApiOutputPayload>) => {
            state.loading = false;
            state.result = action.payload.output;
            state.savedQueries = action.payload.query;
          },
        )
        .addCase(postApi.rejected, (state, action) => {
          state.loading = true;
          state.errors.push(action.payload);
        })
        .addCase(translationError.fulfilled, (state, action) => {
          state.loading = false;
          toast.error(action.payload, { toastId: action.payload });
        })
        .addCase(translationError.rejected, (state, action) => {
          state.loading = false;
          toast.error(action.payload as string, {
            toastId: action.payload as string,
          });
        });

      if (oneTimeUse) {
        builder
          .addCase(oneTimeUse.pending, (state) => {
            state.loading = true;
            state.errors = [];
          })
          .addCase(
            oneTimeUse.fulfilled,
            (state, action: PayloadAction<ApiOutputPayload>) => {
              state.loading = false;
              state.oneTimeResult = action.payload.output;
              state.savedQueries = action.payload.query;
              const used = window.localStorage.getItem(
                `${sliceName}-demo-cookie`,
              );
              if (used) {
                window.localStorage.setItem(
                  `${sliceName}-demo-cookie`,
                  (parseInt(used) + 1).toString(),
                );
              } else {
                window.localStorage.setItem(`${sliceName}-demo-cookie`, "1");
              }
            },
          )
          .addCase(oneTimeUse.rejected, (state, action) => {
            state.errors.push(action.payload);
          });
      }
    },
  });

  return apiSlice;
};

export default createApiSlice;
