export const EnergyLabelField = [
  {
    label: "Postcode*",
    name: "post_code",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "house_number",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "house_addition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
];
