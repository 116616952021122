import React, { FC } from "react";
import ProgressBarComponent from "../../../components/Progress";
import Title from "../../../components/TItle";
import Text from "../../../components/Text";

type Props = {
  question: string;
  description: string;
  step: number;
};

const OnboardingHeader: FC<Props> = ({ question, description, step }) => {
  const percentage = (step * 100) / 5;
  return (
    <>
      <ProgressBarComponent label={`${step} van 5`} percentage={percentage} />
      <div className="mt-4 mb-4 md:mt-8 md:mb-4 text-center">
        <Title>{question}</Title>
        <Text className="text-gray-light">{description}</Text>
      </div>
    </>
  );
};

export default OnboardingHeader;
