import React from "react";
import SkeletonText from "../../../../../components/skeleton/components/Text";

type Props = {};

const ResultSkeleton = (props: Props) => {
  return (
    <div className="flex animate-pulse gap-8">
      <div className="flex flex-col justify-between gap-4">
        {Array.from({ length: 8 }).map(() => (
          <SkeletonText width={"w-[300px]"} height={"h-[72px]"} />
        ))}
      </div>

      <SkeletonText width={"w-3/4"} height={"h-full"} />
    </div>
  );
};

export default ResultSkeleton;
