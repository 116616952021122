import { FC } from "react";

type Props = {
  label: string;
};
const labelClasses: { [x: string]: string } = {
  "A++++": "bg-label-a-4",
  "A+++": "bg-label-a-3",
  "A++": "bg-label-a-2",
  "A+": "bg-label-a-1",
  A: "bg-label-a",
  B: "bg-label-b",
  C: "bg-label-c",
  D: "bg-label-d",
  E: "bg-label-e",
  F: "bg-label-f",
  G: "bg-label-g",
};

const EnergyLabel: FC<Props> = ({ label }) => {
  return (
    <div
      className={`flex items-center text-white font-bold text-lg rounded-l-full ${
        labelClasses[label] || "bg-gray-500 w-[65px]"
      }`}
    >
      <div className="relative flex items-center justify-center px-3">
        {label}
      </div>
    </div>
  );
};

export default EnergyLabel;
