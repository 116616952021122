import { createRoot } from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import App from "./App";

import store from "./redux/store";

import reportWebVitals from "./reportWebVitals";
// import { PersistGate } from "redux-persist/integration/react";
// import { persistStore } from "redux-persist";

const stripeKey =
  process.env.REACT_APP_STAGE === "production"
    ? process.env.REACT_APP_STRIPE_PK_LIVE
    : process.env.REACT_APP_STRIPE_PK_TEST;

const stripePromise = loadStripe(stripeKey!);

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <App />
    </Provider>
  </Elements>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
