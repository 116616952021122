import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/authSlice";
import apiUsageSlice from "./features/apiUsageSlice";
import sustainabilitySlice from "./features/sustainabilitySlice";
import avmSlice from "./features/avmSlice";
import amenitiesSlice from "./features/amenitiesSlice";
import wozSlice from "./features/wozSlice";
import locationSlice from "./features/locationDataSlice";
import moveDataSlice from "./features/moveDataSlice";
import referenceSlice from "./features/referenceSlice";
import transactionSlice from "./features/transactionSlice";
import imageLabelSlice from "./features/imageLabelSlice";
import conditionScoreSlice from "./features/conditionScoreSlice";
import objectGeometrySlice from "./features/objectGeometrySlice";
import objectDataSlice from "./features/objectDataSlice";
import energyLabelSlice from "./features/energyLabelSlice";
import bagSlice from "./features/bagSlice";
import wwsSlice from "./features/wwsSlice";
import checkoutSlice from "./features/checkoutSlice";
import stripeSlice from "./features/stripeSlice";
import adminSlice from "./features/adminSlice";
import settingsSlice from "./features/settingsSlice";
import onboardingSlice from "./features/onboardingSlice";
import energyInsightsSlice from "./features/energyInsightSlice";
import rebuildSlice from "./features/rebuildSlice";
import subscriptionSlice from "./features/subscriptionSlice";
import solarscanSlice from "./features/solarscanSlice";
import autosuggestSlice from "./features/autosuggestSlice";
import rentalReferenceSlice from "./features/rentalReferenceSlice";
import energyClimateSlice from "./features/energyClimateSlice";

// const persistConfig = {
// 	key: "root",
// 	version: 1,
// 	storage,
// };
const reducers = {
  auth: authSlice,
  apiUsage: apiUsageSlice,
  sustainability: sustainabilitySlice,
  avm: avmSlice,
  amenities: amenitiesSlice,
  woz: wozSlice,
  locationData: locationSlice,
  moveData: moveDataSlice,
  reference: referenceSlice,
  transaction: transactionSlice,
  imageLabel: imageLabelSlice,
  conditionScore: conditionScoreSlice,
  objectGeometry: objectGeometrySlice,
  objectData: objectDataSlice,
  energyLabel: energyLabelSlice,
  rebuild: rebuildSlice,
  wws: wwsSlice,
  autosuggest: autosuggestSlice,
  solarscan: solarscanSlice,
  rentalreference: rentalReferenceSlice,
  energyClimate: energyClimateSlice,
  energyInsight: energyInsightsSlice,
  admin: adminSlice,
  bag: bagSlice,
  checkout: checkoutSlice,
  portal: stripeSlice,
  settings: settingsSlice,
  onboarding: onboardingSlice,
  subscription: subscriptionSlice,
};

// const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: reducers,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
