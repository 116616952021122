import { CiLock, CiMail } from "react-icons/ci";
import DividerWithText from "../../../../components/DividerWithText";
import TextInput from "../../../../components/TextInput";
import AuthHeader from "../../components/AuthHeader";
import Social from "../../components/Social";
import Text from "../../../../components/Text";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import AuthForm from "../../components/AuthForm";
import AuthContainer from "../../components/AuthContainer";
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useValidation } from "../../../../hooks/useValidator";
import { signIn } from "../../../../redux/actions/authActions";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

type Props = {};
interface AuthFormData {
  email: string;
  password: string;
}

const SigninRightSection = (props: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isAuthenticated, user, loading } = useAppSelector(
    (state) => state.auth,
  );
  const [formData, setFormData] = useState<AuthFormData>({
    email: "",
    password: "",
  });
  const [touchedFields, setTouchedFields] = useState<{
    [key: string]: boolean;
  }>({});
  const { errors, validate } = useValidation();
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard/startpagina");
    }
  }, [isAuthenticated, history]);

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      setTouchedFields((prev) => ({ ...prev, [name]: true }));
      validate(formData);
    },
    [formData, validate],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (touchedFields[name]) {
        validate({ ...formData, [name]: value });
      }
    },
    [touchedFields, formData, validate],
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault(); // Force validation on submit

      dispatch(signIn({ formData, dispatch }));
    },
    [dispatch, formData],
  );
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const { state } = useLocation();

  if (state && isAuthenticated) {
    return <Redirect to={state.from.pathname} />;
  }

  if (!state && isAuthenticated && user?.role === "user") {
    return <Redirect to="/dashboard/startpagina" />;
  }

  if (!state && isAuthenticated && user?.role === "admin") {
    return <Redirect to="/admin/dashboard" />;
  }

  return (
    <AuthContainer>
      <AuthHeader
        title={"Log in op jouw account"}
        description={"Selecteer een methode om in te loggen."}
      />

      <Social />
      <DividerWithText
        className="md:max-w-[424px] w-[340px]"
        text={"of log in met e-mail"}
      />
      <AuthForm
        cta="Inloggen"
        onSubmit={handleSubmit}
        isLoading={loading}
        isError={!!errors["email"] || !!errors["password"]}
      >
        <TextInput
          type="email"
          name="email"
          placeholder="Email"
          className="mt-3"
          icon={CiMail}
          message={touchedFields.email ? errors["email"] : ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="relative">
          <TextInput
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Wachtwoord (8+ tekens)"
            className="mt-3"
            icon={CiLock}
            message={touchedFields.password ? errors["password"] : ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 mt-1"
          >
            {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
          </button>
        </div>
        <Text className="text-center mt-1 text-xs">
          Wachtwoord vergeten?{" "}
          <Link
            to="/forgot-password"
            className="text-green-600 hover:underline text-xs"
          >
            Wachtwoord opnieuw instellen
          </Link>
          .
        </Text>
      </AuthForm>

      <Text className="text-center mt-4">
        Nog geen account?{" "}
        <Link to="/" className="text-green-600 hover:underline">
          Aanmelden
        </Link>
        .
      </Text>
    </AuthContainer>
  );
};

export default SigninRightSection;
