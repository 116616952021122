import { ChangeEvent, useEffect, useMemo, useState } from "react";
import Button from "../../components/Button";
import OnboardingField from "./components/OnboardingField";
import OnboardingHeader from "./components/OnboardingHeader";
import {
  addOnboardingResponse,
  getOnboardingQuestions,
  getOnboardingState,
} from "../../redux/actions/onboardingActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useHistory } from "react-router-dom";
import Navbar from "../../components/nav-bar";
import { IoMdArrowBack } from "react-icons/io";
import { Helmet } from "react-helmet";
import { OnboardingQuestion } from "../../@types";
import { sendToSlack } from "../../helpers/sendToSlack";

function convertToQuestionAnswerFormat(
  onboardingQuestions: OnboardingQuestion[],
  responses: {
    question_id: string;
    responses: string[];
  }[],
) {
  return responses.map((response) => {
    const answersArray = response.responses;
    const question = onboardingQuestions.find(
      (data) => data.question_id === response.question_id,
    );
    return {
      question: question?.question_text,
      answers: answersArray.join(", "),
    };
  });
}
const Onboarding = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { onboardingQuestions, onboardingSteps, loading } = useAppSelector(
    (state) => state.onboarding,
  );
  const { user } = useAppSelector((state) => state.auth);
  const [multiSelect, setMultiSelect] = useState<string[]>([]);
  const [step, setStep] = useState(1);
  const [onboardingData, setOnboardingData] = useState<
    {
      question_id: string;
      responses: string[];
    }[]
  >([]);

  const currentStepQuestion = useMemo(
    () => onboardingQuestions?.find((question) => question.step === step),
    [onboardingQuestions, step],
  );

  useEffect(() => {
    dispatch(getOnboardingQuestions());
    dispatch(getOnboardingState());
  }, [dispatch]);

  const handleStepChange = (newStep: number) => {
    if (step === 5) {
      dispatch(
        addOnboardingResponse({
          data: onboardingData,
          stepId: onboardingSteps?.find(
            (step: { stepRank: number }) => step.stepRank === 2,
          )?.stepId,
        }),
      )
        .then(() => {
          const slackResponses =
            onboardingQuestions &&
            convertToQuestionAnswerFormat(onboardingQuestions, onboardingData);
          user && slackResponses && sendToSlack(user.email, slackResponses);
        })
        .then(() => history.push("/dashboard/aandeslag"));
    } else setStep(newStep);
  };
  const previous = () => {
    setStep(step - 1);
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { value } = e.target;
    if (step === 4) {
      if (multiSelect.includes(value)) {
        setMultiSelect(multiSelect.filter((item) => item !== value));
      } else {
        setMultiSelect([...multiSelect, value]);
      }
    }

    const newResponses = step !== 4 ? [value].filter(Boolean) : multiSelect;

    setOnboardingData((prev) => {
      const index = prev.findIndex(
        (item) => item.question_id === currentStepQuestion?.question_id,
      );
      const updatedData = [...prev];
      if (index !== -1) {
        updatedData[index] = { ...updatedData[index], responses: newResponses };
      } else {
        updatedData.push({
          question_id: currentStepQuestion?.question_id!,
          responses: newResponses,
        });
      }
      return updatedData;
    });
  };
  return (
    <>
      <Helmet>
        <title>Onboarding - Altum AI</title>
      </Helmet>
      <Navbar />
      <div className="flex justify-between mt-1 mb-2 md:mt-2 md:mb-10">
        {
          <Button
            onClick={() => previous()}
            className={`${
              step === 1 ? "text-gray-light" : "text-primary"
            } justify-self-start `}
            disabled={step === 1}
          >
            <IoMdArrowBack className="h-6 w-6" /> Ga terug
          </Button>
        }
        <Button
          className="text-gray-light justify-self-end"
          onClick={() => history.push("/dashboard/aandeslag")}
        >
          Overslaan
        </Button>
      </div>
      <div className="md:m-auto flex flex-col mx-4 md:max-w-[485px] self-center w-[340px] md:w-full">
        {currentStepQuestion && (
          <>
            <OnboardingHeader
              question={currentStepQuestion.question_text}
              description={currentStepQuestion.description}
              step={currentStepQuestion.step}
            />
            <OnboardingField
              step={currentStepQuestion.step}
              loading={loading}
              multiSelect={multiSelect}
              onboardingData={onboardingData}
              setOnboardingData={setOnboardingData}
              handleChange={handleChange}
              handleClick={(e) => {
                e.preventDefault();
                handleStepChange(step + 1);
              }}
              currentQuestion={currentStepQuestion}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Onboarding;
