import React, { FormEvent, useEffect, useState } from "react";
import FormBody from "../FormBody";
import { solarscan } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { SolarScanField } from "./solarScanData";
import { postSolarscan } from "../../../redux/actions/solarscanAction";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const SolarScanForm = () => {
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 10 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.objectData);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);

  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    formValues.postcode = formValues.postcode.split(" ").join("").toUpperCase();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey &&
      dispatch(postSolarscan({ formData: newFormData, apiKey: apiKey }));
  };

  return (
    <div>
      <FormBody
        title={"Zonnepanelen dakenscan API"}
        sectionFields={sectionFields}
        desc={
          "Berekent de optimale indeling van zonnepanelen in termen van aantal, positie etc., rekening houdend met de oriëntatie en geometrie van het dak."
        }
        img={solarscan}
        path={"https://docs.altum.ai/apis/zonnepanelen-dakenscan-api"}
        initialFields={SolarScanField}
        handleSubmit={handleFormSubmit}
      />
    </div>
  );
};

export default SolarScanForm;
