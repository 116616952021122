import React, { FC } from "react";
import { GrMoney } from "react-icons/gr";
import Text from "../../../../components/Text";
import Subtitle from "../../../../components/Subtitle";
import { Link } from "react-router-dom";

type Props = {
  currentPlan: string | undefined;
  creditUsed: string | number;
  totalCredit: string;
  renewalDate: string | null | undefined;
  nextInvoices: string;
  usagePercentage: number;
};

const ActivePlanCard: FC<Props> = ({
  currentPlan,
  creditUsed,
  totalCredit,
  renewalDate,
  nextInvoices,
  usagePercentage,
}) => {
  return (
    <div className="shadow-[0px_0px_4px_0px_#00000026] rounded-lg w-full md:w-[360px] h-48 p-3 flex flex-col justify-between">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <div className="text-[#0888ff] bg-[#e1f0fe] rounded-full w-10 h-10 text-center p-2.5">
            <GrMoney size={20} />
          </div>
          <Subtitle className="text-base">{currentPlan}</Subtitle>
        </div>
        <Text className="text-primary">Actief</Text>
      </div>
      <div>
        <div className="flex gap-2 items-center">
          <Text className="text-gray-light">
            {totalCredit === "onbeperkt" ? "API calls" : "Credit resterend"}:{" "}
          </Text>
          <Subtitle className="text-base fon">
            {creditUsed}/{totalCredit}
          </Subtitle>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="">
          <div className="flex gap-1 items-center">
            <Text className="">Vernieuwingsdatum:</Text>
            <Subtitle className="text-sm">{renewalDate}</Subtitle>
          </div>
          <div className="flex gap-1 items-center">
            <Text className="">Volgende betaling</Text>
            <Subtitle className="text-sm">€{nextInvoices || "-"}</Subtitle>
          </div>
        </div>
        <div className="border-2 border-primary rounded-lg h-10 p-2 text-center">
          <Link className="" to="/dashboard/abonnement">
            <Text className="text-xs font-medium text-primary">
              Opwaarderen
            </Text>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ActivePlanCard;
