import { Label, Radio } from "flowbite-react";
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  MouseEventHandler,
} from "react";

type Props = {
  name?: string;
  value?: string | number;
  label?: string;
  selected?: boolean;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler;
  onFocus?: FocusEventHandler;
};

const RadioInput: FC<Props> = ({
  name,
  label,
  value,
  selected,
  className,
  onFocus,
  onChange,
}) => {
  return (
    <div className={`flex items-center  ${className}`}>
      <Radio
        name={name}
        value={value}
        checked={selected}
        onChange={onChange}
        onFocus={onFocus}
        className={`focus:ring-primary dark:focus:ring-primary text-primary border-gray-dark`}
      />
      <Label htmlFor={name} className="ml-2 text-xs md:text-sm text-gray-dark">
        {label}
      </Label>
    </div>
  );
};

export default RadioInput;
