import { useCallback, useState, useRef, useEffect } from "react";
import { PropertyDetails, ConfigureOutput } from "../../@types";
import { ImageUploader } from "../../components/ImageUploader";
import { ConfigureOutputForm } from "./components/ConfigureOutputForm";
import { PropertyDetailsForm } from "./components/PropertyDetailsForm";
import axios from "axios";
import PropertyDescription from "./components/PropertyDescription";
import Subtitle from "../../components/Subtitle";
import Text from "../../components/Text";
import { LuImagePlus, LuPencil, LuSettings } from "react-icons/lu";
import { TbHomeEdit } from "react-icons/tb";
import Button from "../../components/Button";
import DashboardContainer from "../Dashboard/components/Container";
import Header from "../Dashboard/components/Header";
import { toast } from "react-toastify";
import RatingForm from "./components/RatingForm";
import { Link } from "react-router-dom";
import { FiCopy } from "react-icons/fi"; // Import the copy icon

interface GeneratedDescriptionResponse {
  description: string;
  chatHistoryId: string;
}
const uploadFileInChunks = async (file: File) => {
  const fileId = `${Date.now()}-${file.name}`;
  const chunkSize = 200 * 1024; // 1MB chunks
  const totalChunks = Math.ceil(file.size / chunkSize);
  let chunkIndex = 0;

  const config = {
    headers: {
      "Content-Type": "application/octet-stream",
      "X-File-Name": encodeURIComponent(file.name),
      "X-File-Type": file.type,
      "X-File-Size": file.size.toString(),
      "X-File-Id": fileId,
      "X-Chunk-Index": "0",
      "X-Total-Chunks": totalChunks.toString(),
    },
  };

  while (chunkIndex < totalChunks) {
    const chunk = file.slice(
      chunkIndex * chunkSize,
      (chunkIndex + 1) * chunkSize,
    );
    config.headers["X-Chunk-Index"] = chunkIndex.toString();

    try {
      await axios.post(
        "/api/v1/property-description/upload-chunk",
        chunk,
        config,
      );
      chunkIndex++;
      const percentCompleted = Math.round((chunkIndex / totalChunks) * 100);
      console.log(`Upload progress: ${percentCompleted}%`);
    } catch (error) {
      console.error("Error uploading chunk:", error);
      throw error;
    }
  }

  return fileId;
};

const PropertyDescriptionForm: React.FC = () => {
  const [images, setImages] = useState<File[]>([]);
  const [propertyDetails, setPropertyDetails] = useState<PropertyDetails>({
    yearOfConstruction: "",
    location: "",
    area: "",
    propertyType: "",
    facilities: "",
  });
  const [configureOutput, setConfigureOutput] = useState<ConfigureOutput>({
    tone: "",
    targetAudience: "",
    languagePreference: "",
    descriptionLength: "",
  });
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [generatedDescription, setGeneratedDescription] =
    useState<GeneratedDescriptionResponse | null>(null);

  const [errors, setErrors] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(true);

  const handleImagesSelected = (newImages: File[]) => {
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleImageRemove = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleGenerateDescription = useCallback(async () => {
    setIsLoading(true);
    setErrors([]);
    setGeneratedDescription(null);

    try {
      // Upload images in chunks
      let fileIds: string[] = [];
      if (images.length > 0) {
        fileIds = await Promise.all(images.map(uploadFileInChunks));
      }

      const formData = new FormData();

      // Append file IDs instead of actual files
      fileIds.forEach((fileId) => {
        formData.append(`imageIds`, fileId);
      });

      // Append property details
      formData.append("propertyDetails", JSON.stringify(propertyDetails));

      // Append configure output
      formData.append("configureOutput", JSON.stringify(configureOutput));

      // Append additional notes
      if (additionalNotes) {
        formData.append("additionalNotes", additionalNotes);
      }

      const response = await axios.post<GeneratedDescriptionResponse>(
        "/api/v1/property-description/generate-description",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      setGeneratedDescription(response?.data);
    } catch (error: any) {
      console.error("Error generating description:", error);
      setErrors(
        error?.response?.data?.errors || [
          "An error occurred while generating the description",
        ],
      );
    } finally {
      setIsLoading(false);
    }
  }, [images, propertyDetails, configureOutput, additionalNotes]);

  const handleCopyDescription = () => {
    // Implement copy functionality
  };

  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (generatedDescription && descriptionRef.current) {
      descriptionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [generatedDescription]);

  return (
    <DashboardContainer pageTitle={"Property Description Form - Altum AI"}>
      <Header title={"Maak uw woningbeschrijving"} />
      <div className="mx-auto p-1 md:p-6 space-y-8 md:shadow-[0px_0px_4px_0px_#00000026] rounded-md md:mx-6 md:my-4 m-2 flex flex-col gap-6">
        <div className="flex gap-2">
          <LuImagePlus className="mt-2" size={20} />
          <div className="w-full flex flex-col gap-4">
            <div>
              <Subtitle className="text-base">
                Afbeeldingen voor de beschrijving
              </Subtitle>
              <Text className="text-gray-light">
                Upload een woningfoto om uw beschrijving te verrijken - meer
                foto's betekenen een gedetailleerdere beschrijving.
              </Text>
            </div>
            <ImageUploader
              images={images}
              onImagesSelected={handleImagesSelected}
              onImageRemove={handleImageRemove}
            />
          </div>
        </div>

        <div className="flex gap-2">
          <TbHomeEdit className="mt-2" size={20} />
          <div className="w-full flex flex-col gap-4">
            <div>
              <Subtitle className="text-base">Woninggegevens</Subtitle>
              <Text className="text-gray-light">
                Vul gegevens over de woning in voor een nauwkeurigere
                beschrijving.
              </Text>
            </div>
            <PropertyDetailsForm
              propertyDetails={propertyDetails}
              onPropertyDetailsChange={setPropertyDetails}
            />
          </div>
        </div>

        <div className="flex gap-2">
          <LuSettings className="mt-2" size={20} />
          <div className="w-full flex flex-col gap-4">
            <div>
              <Subtitle className="text-base"> Stel de uitvoer in</Subtitle>
              <Text className="text-gray-light">
                Pas de uitvoer van uw gegenereerde beschrijving aan.
              </Text>
            </div>

            <ConfigureOutputForm
              configureOutput={configureOutput}
              onConfigureOutputChange={setConfigureOutput}
            />
          </div>
        </div>

        <div className="flex gap-2">
          <LuPencil className="mt-2" size={20} />
          <div className="w-full flex flex-col gap-4">
            <div>
              <Subtitle className="text-base">Aanvullende opmerkingen</Subtitle>
              <Text className="text-gray-light">
                Voeg eventuele extra informatie of specifieke verzoeken voor de
                beschrijving toe.
              </Text>
            </div>

            <textarea
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
              className="w-full p-2 border rounded"
              rows={4}
            />
          </div>
        </div>

        <Button
          onClick={handleGenerateDescription}
          className="w-full md:w-[360px] bg-[#BB6BD9] text-white self-center ml-1 md:self-start md:ml-6"
          isProcessing={isLoading}
        >
          {isLoading ? "Bezig met genereren..." : "Genereer beschrijving"}
        </Button>
        {errors.length > 0 && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            <strong className="font-bold">Errors:</strong>
            <ul className="list-disc list-inside">
              {errors.map((error, index) => (
                <li key={index}>{error.msg}</li>
              ))}
            </ul>
          </div>
        )}
        {generatedDescription?.description && (
          <div
            className="mt-8 lg:mt-[56px] p-2 rounded-lg shadow-[0px_0px_4px_0px_#00000026]"
            ref={descriptionRef}
          >
            {/* <h2 className="text-2xl font-bold mb-4">
              Gegenereerde beschrijving
            </h2> */}
            <PropertyDescription
              description={generatedDescription.description}
              chatHistoryId={generatedDescription.chatHistoryId}
            />
          </div>
        )}
        <div className="underline self-end">
          <Link to="/property-description/history">
            Bekijk de eerder gegenereerde beschrijving
          </Link>
        </div>
      </div>
    </DashboardContainer>
  );
};
export default PropertyDescriptionForm;
