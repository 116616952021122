import { useEffect, useState } from "react";
import APIResult from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import {
  clearSolarscanResults,
  modifySolarscanQueries,
} from "../../../redux/actions/solarscanAction";
import {
  FormProvider,
  useFormContext,
} from "../../../pages/Api/usage-pages/components/FormContext";

const SolarScanResult = () => {
  const { loading, result, savedQueries } = useAppSelector(
    (state) => state.solarscan,
  );
  const query = savedQueries as any;
  const property: any = result;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { buildingPhoto, setPostalAddress, fetchAddressData } =
    useFormContext();

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      const postalAddress = `${query.postcode}-${query.housenumber}-${
        query.houseaddition ?? ""
      }`;
      setPostalAddress(postalAddress);
    }
  }, [
    property,
    query.houseaddition,
    query.housenumber,
    query.postcode,
    setPostalAddress,
  ]);
  useEffect(() => {
    if (Object.keys(result).length > 0 && buildingPhoto.length === 0) {
      fetchAddressData();
    }
  }, [result, buildingPhoto.length, fetchAddressData]);
  if (Object.keys(property).length === 0 || property.length === 0) {
    return <Redirect to="/solarscan" />;
  }
  const clearResults = () => {
    dispatch(clearSolarscanResults());
    history.push("/solarscan");
  };

  const modifyResults = () => {
    dispatch(modifySolarscanQueries());
    history.push("/solarscan");
  };
  const formattedOutput =
    !!property &&
    property.map(
      (
        item: {
          orientation: any;
          image_url: string;
          area: string | number;
          index: string | number;
          geo_data: { type: string; features: any[] };
        },
        index: number,
      ) => {
        const outputFormat = {
          title: `Dakoppervlak ${index + 1}`,
          img: item.image_url,
          details: [
            {
              key: "Index",
              value: item.index,
            },
            {
              key: "Oriëntatie",
              value: item.orientation,
            },
            {
              key: "Oppervlak in m2",
              value: convertToEuFormat(item.area),
            },
          ],
        };

        return [outputFormat];
      },
    );
  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: "",
    },
    {
      img: "",
      title: "Huisnummer toevoeging",
      result: `-`,
    },
    // {
    //   img: "",
    //   title: "Latitude",
    //   result: property?.Latitude,
    // },
    // {
    //   img: "",
    //   title: "Longitude",
    //   result: property?.Longitude,
    // },
  ];

  const overview = [
    {
      title: "Resultaat",
      details: [
        {
          key: "Postcode",
          value: query?.postcode,
        },
        {
          key: "Huisnummer",
          value: query?.housenumber,
        },
        {
          key: "Huisnummer toevoeging",
          value: query?.houseaddition,
        },
        // {
        //   key: "Total panel capacity",
        //   value: convertToEuFormat(property?.total_panel_capacity),
        // },
      ],
    },
  ];

  const reference = [
    { text: "Verduurzaming advies", path: "/sustainability" },
    { text: "Energielabel vaststellen", path: "/energy-label" },
    { text: "Marktwaarde bepalen", path: "/avm" },
  ];
  return (
    <APIResult
      property={property}
      loading={loading}
      title={"Zonnepanelen dakenscan API"}
      street={""}
      postCode={""}
      city={""}
      overview={[...overview, ...formattedOutput.flat()]}
      reference={reference}
      descSection={desc}
      buildingPhoto={buildingPhoto}
      path="https://docs.altum.ai/apis/object-geometrie-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
    />
  );
};

const Index = () => {
  return (
    <FormProvider>
      <SolarScanResult />
    </FormProvider>
  );
};
export default Index;
