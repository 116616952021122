import { FormEvent, useEffect, useState } from "react";
import { ObjectGeometryField } from "./objectGeometryField";
import FormBody from "../FormBody";
import { ObjectGeometry } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";

import { postGeometry } from "../../../redux/actions/objectGeometryActions";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const ObjectGeometryForm = () => {
  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.objectGeometry);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 2 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
      houseaddition: "",
    };
    formValues.postcode = formValues.postcode.split(" ").join("").toUpperCase();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey && dispatch(postGeometry({ formData: newFormData, apiKey }));
  };
  return (
    <FormBody
      title={"Objectgeometrie opvragen"}
      desc={
        "Gebruik objectgeometriegegevens als aanvulling op de Verduurzaming API voor verbeterde berekeningen door het ophalen van o.a. dakoppervlakken, oriëntatie en helling"
      }
      path={"https://docs.altum.ai/apis/object-geometrie-api"}
      img={ObjectGeometry}
      initialFields={ObjectGeometryField}
      sectionFields={sectionFields}
      handleSubmit={handleFormSubmit}
    />
  );
};

export default ObjectGeometryForm;
