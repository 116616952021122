import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmailSettings } from "../../@types";

export const getAllUserEmailSettings = createAsyncThunk(
  "settings/getAllUserNotification",
  async (id: string, { rejectWithValue }) => {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const res = await axios.get<EmailSettings>(
        "/api/v1/user-notifications/" + id,
        config,
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message);
    }
  },
);

export const updateUserEmailSettings = createAsyncThunk(
  "settings/updateUserNotifications",
  async (
    arg: {
      id: string;
      field:
        | "error_429"
        | "error_403"
        | "error_500"
        | "error_422"
        | "analytics_email_usage"
        | "invoice_successful"
        | "credit_depleted";
      value: boolean;
    },

    { rejectWithValue },
  ) => {
    try {
      const { id, field, value } = arg;
      const config = { headers: { "Content-Type": "application/json" } };
      const data = { value };
      const res = await axios.patch(
        `/api/v1/user-notifications/${field}/${id}`,
        data,
        config,
      );

      return res.data;
    } catch (error: any) {
      // toast.error(error.response.data.message);
      return rejectWithValue(error.response?.data.message);
    }
  },
);
