import { createApiThunk } from "../../helpers/createApiThunk";
import { AppDispatch } from "../store";

export const postWWS = createApiThunk(
  "wws/postWWS",
  "api",
  "WWS api used",
  "wws",
);

export const clearWWSResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "wws/clearResults" });
};

export const modifyWWSQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "wws/modifyQueries" });
};
