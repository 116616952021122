import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import {
  ArrowDropdownCaretSortSelectArrow,
  ArrowUpPushupCaretSortSelectArrow,
} from "react-basicons";
import axios from "axios";
import { Form } from "../../../../components/Form";
import { EmailSettings, FormField } from "../../../../@types";
import { Colors } from "../../../../styles/Theme";
import Switch from "../../../../components/Switch";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateUserEmailSettings } from "../../../../redux/actions/settingAction";
import Subtitle from "../../../../components/Subtitle";
import { RiMailSettingsLine } from "react-icons/ri";
import { LiaIndustrySolid } from "react-icons/lia";
import { IoWarningOutline } from "react-icons/io5";
import Button from "../../../../components/Button";
import { getUsers } from "../../../../redux/actions/adminActions";
import { loadUser } from "../../../../redux/actions/authActions";

const SettingItem: FC<{
  title: string;
  value: boolean;
  field:
    | "error_429"
    | "error_403"
    | "error_500"
    | "error_422"
    | "analytics_email_usage"
    | "invoice_successful"
    | "credit_depleted";
  user_id: string;
}> = ({ title, value, field, user_id }) => {
  const dispatch = useAppDispatch();

  return (
    <SettingsItem>
      <Subtitle className="text-base">{title}</Subtitle>
      <Switch
        check={value}
        onChange={() =>
          dispatch(
            updateUserEmailSettings({
              id: user_id,
              field,
              value: !value,
            }),
          )
        }
      />
    </SettingsItem>
  );
};

const Settings: FC<EmailSettings> = ({
  analytics_email_usage,
  invoice_successful,
  credit_depleted,
  error_403,
  error_500,
  error_429,
  error_422,
  user_id,
}) => {
  const [selected, setSelected] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const toggle = (i: number) => {
    setSelected(selected === i ? null : i);
  };

  const handleSubmit = useCallback(
    async (data: Record<string, string>) => {
      try {
        await axios.post(`/api/v1/users/${Object.keys(data)[0]}`, data);
        await dispatch(loadUser(dispatch)).unwrap();
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch],
  );
  const settings = [
    {
      title: "429: Te veel aanvragen e-mail",
      field: "error_429" as const,
      value: error_429,
    },
    {
      title: "500: Probleem met API e-mail",
      field: "error_500" as const,
      value: error_500,
    },
    {
      title: "403: Niet geautoriseerde e-mail",
      field: "error_403" as const,
      value: error_403,
    },
    {
      title: "422: Invoervalidatie mislukt e-mail",
      field: "error_422" as const,
      value: error_422,
    },
    {
      title: "Analytics e-mailgebruik",
      field: "analytics_email_usage" as const,
      value: analytics_email_usage,
    },
    {
      title: "Factuur succesvolle e-mail",
      field: "invoice_successful" as const,
      value: invoice_successful,
    },
    {
      title: "E-mail met tegoed opgebruikt",
      field: "credit_depleted" as const,
      value: credit_depleted,
    },
  ];

  return (
    <>
      <div className="mt-4">
        <div className="flex items-center mb-8">
          <RiMailSettingsLine size={20} className="mr-4" />
          <Subtitle className="text-base">Email instellingen</Subtitle>
        </div>
        {settings.map((setting, i) => (
          <SettingItem key={i} {...setting} user_id={user_id} />
        ))}
      </div>
      <div className="mt-4">
        <div className="flex items-center mt-8 mb-8">
          <LiaIndustrySolid size={20} className="mr-4" />
          <Subtitle className="text-base">Wijzigen</Subtitle>
        </div>
        {data.map((item, i) => (
          <Accordion key={i}>
            <SettingsItem onClick={() => toggle(i)}>
              <Subtitle className="text-base">{item[0].label}</Subtitle>
              {selected === i ? (
                <ArrowUpPushupCaretSortSelectArrow color="#707070" />
              ) : (
                <ArrowDropdownCaretSortSelectArrow color="#707070" />
              )}
            </SettingsItem>
            <Content className={selected === i ? "show" : ""}>
              <Form
                fields={item}
                initInput={{ [item[0].name]: "" }}
                handleSubmit={handleSubmit}
                cta="Wijzigen"
              />
            </Content>
          </Accordion>
        ))}

        <DeleteAccountSection />
      </div>
    </>
  );
};

function DeleteAccountSection() {
  return (
    <div className="mt-4">
      <div className="flex items-center mt-8 text-red-500">
        <IoWarningOutline size={20} className="mr-4" />
        <Subtitle className="text-base text-red-500">
          Account verwijderen
        </Subtitle>
      </div>
      <div className="mt-4 border-red-500 border-2 rounded-lg p-4 bg-red-50">
        <Subtitle className="text-base">
          Wil je het account verwijderen? Neem dan contact op via info@altum.ai
        </Subtitle>
        <Button className="bg-red-500 text-white">Account verwijderen</Button>
      </div>
    </div>
  );
}

const data: FormField[][] = [
  [
    {
      label: "Bedrijfsnaam wijzigen",
      type: "text",
      name: "company",
    },
  ],
  [
    {
      label: "KvK nummer wijzigen",
      type: "text",
      name: "kvk",
    },
  ],
  [
    {
      label: "BTW nummer wijzigen",
      type: "text",
      name: "vat",
    },
  ],
];

export default Settings;

const Content = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);

  &.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }
`;

const SettingsItem = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  padding: 10px 10px;
  border: 1px solid ${Colors.main.lightgrey};
  border-radius: 10px;
  align-items: center;
  text-align: center;
`;

const Accordion = styled.div`
  .content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }
`;
