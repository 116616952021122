import createApiSlice from "../../helpers/createApiSlice";
import { postSolarscan, solarscanOneTimeUse } from "../actions/solarscanAction";

const solarscanSlice = createApiSlice(
  "solarscan",
  postSolarscan,
  solarscanOneTimeUse,
);

export const { clearResults, modifyQueries } = solarscanSlice.actions;

export default solarscanSlice.reducer;
