import { useState, useEffect, FormEvent } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Text from "../../components/Text";
import { CiMail } from "react-icons/ci";
import AuthHeader from "./components/AuthHeader";
import AuthForm from "./components/AuthForm";
import TextInput from "../../components/TextInput";
import { toast } from "react-toastify";

function ForgotPassword() {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [timer, setTimer] = useState(5);

  useEffect(() => {
    if (emailSent) {
      const interval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  });

  if (timer === 0) {
    return <Redirect to="/signin" />;
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      setLoading(true);
      const res = await axios.post(
        "/api/v1/users/forgotPassword",
        { email },
        config,
      );

      if (res.status === 200) {
        setEmailSent(true);
        const tagBody = { event: "Forgot Password", userId: email };
        await axios.post("/api/v1/mopsus/tag-event", tagBody, config);
        setLoading(false);
        toast.success(
          "Een link om het wachtwoord te resetten is verzonden naar het",
        );
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setEmail("");
  };
  return (
    <div className="w-full p-5 mt-[50px] gap-2 flex w-full flex-col">
      <Helmet>
        <title>Wachtwoord vergeten - Mopsus - Altum AI</title>
      </Helmet>

      <AuthHeader
        title="Wachtwoord vergeten?"
        description="Vul hieronder je e-mailadres in. Binnen enkele minuten ontvang
        je een e-mail voor het aanmaken van een nieuw wachtwoord."
      />

      <AuthForm
        onSubmit={handleSubmit}
        isLoading={loading}
        cta={"Wachtwoord resetten"}
      >
        <TextInput
          type="email"
          name="email"
          placeholder="Email"
          className="mt-3"
          icon={CiMail}
          // message={touchedFields.email ? errors["email"] : ""}
          onChange={(e) => setEmail(e.target.value)}
          // onBlur={handleBlur}
        />
      </AuthForm>

      <Text className="text-center mt-4">
        Denk je het wachtwoord alweer te weten? <br /> Ga dan naar{" "}
        <Link to="/signin" className="text-green-600 hover:underline">
          Inloggen
        </Link>
      </Text>
    </div>
  );
}

export default ForgotPassword;
