import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { InvoiceResponse, ComingInvoiceResponse } from "../../@types";
import { AppDispatch } from "../store";

// Customer Portal

interface CardPayload {
  id: string;
  name: string;
  expMonth: string;
  expYear: string;
}
type SubscriptionStatus = {
  status: string;
  apiKey?: string;
};
export const getAllInvoices = createAsyncThunk(
  "customerPortal/getAllInvoices",
  async () => {
    const res = await axios.get<InvoiceResponse>(
      "/api/v1/customer-portal/invoices?limit=10",
    );
    return { invoice: res.data };
  },
);

export const getComingInvoice = createAsyncThunk(
  "customerPortal/getComingInvoice",
  async () => {
    const res = await axios.get<ComingInvoiceResponse>(
      "/api/v1/customer-portal/coming-invoice",
    );
    return res.data;
  },
);

export const getAllPaymentMethods = createAsyncThunk(
  "customerPortal/getAllPaymentMethods",
  async () => {
    const res = await axios.get("/api/v1/customer-portal/payment-methods");
    return res.data;
  },
);

export const editCardDetails = createAsyncThunk(
  "customerPortal/editCardDetails",
  async ({ id, name, expMonth, expYear }: CardPayload) => {
    const res = await axios.patch(
      `/api/v1/customer-portal/payment-details/${id}`,
      { name, expMonth, expYear },
      { headers: { "Content-Type": "application/json" } },
    );
    return res.data;
  },
);

export const checkSubscriptionStatus = createAsyncThunk(
  "customerPortal/checkSubscriptionStatus",
  async () => {
    const res = await axios.get<SubscriptionStatus>(
      "/api/v1/mopsus/check-subscription-status",
    );
    return { status: res.data.status, apiKey: res.data.apiKey };
  },
);

export const fetchCardDetails = createAsyncThunk(
  "customerPortal/fetchCardDetails",
  async () => {
    const res = await axios.get("/api/v1/customer-portal/card-payment-methods");
    return res.data;
  },
);

export const fetchSepaDetails = createAsyncThunk(
  "customerPortal/fetchSepaDetails",
  async () => {
    const res = await axios.get("/api/v1/customer-portal/sepa-payment-methods");
    return res.data;
  },
);
//Invoice
export const getTransactionComingInvoice = createAsyncThunk(
  "customerPortal/getTranInv",
  async () => {
    const res = await axios.get<ComingInvoiceResponse>(
      "/api/v1/customer-portal/coming-transaction-invoice",
    );
    return res.data;
  },
);

export const getWozComingInvoice = createAsyncThunk(
  "customerPortal/getWozInv",
  async () => {
    const res = await axios.get<ComingInvoiceResponse>(
      "/api/v1/customer-portal/coming-woz-invoice",
    );
    return res.data;
  },
);
export const getObjectDataComingInvoice = createAsyncThunk(
  "customerPortal/getObjectDataInv",
  async () => {
    const res = await axios.get<ComingInvoiceResponse>(
      "/api/v1/customer-portal/coming-object-data-invoice",
    );
    return res.data;
  },
);

export const getAvmComingInvoice = createAsyncThunk(
  "customerPortal/getAvmInv",
  async () => {
    const res = await axios.get<ComingInvoiceResponse>(
      "/api/v1/customer-portal/coming-avm-invoice",
    );
    return res.data;
  },
);
export const getReferenceComingInvoice = createAsyncThunk(
  "customerPortal/getReferenceInv",
  async () => {
    const res = await axios.get<ComingInvoiceResponse>(
      "/api/v1/customer-portal/coming-reference-invoice",
    );
    return res.data;
  },
);

export const getEcoComingInvoice = createAsyncThunk(
  "customerPortal/getEcoInv",
  async () => {
    const res = await axios.get<ComingInvoiceResponse>(
      "/api/v1/customer-portal/coming-sustainability-invoice",
    );
    return res.data;
  },
);
export const getEnergyComingInvoice = createAsyncThunk(
  "customerPortal/getEnergyInv",
  async () => {
    const res = await axios.get<ComingInvoiceResponse>(
      "/api/v1/customer-portal/coming-energy-label-invoice",
    );
    return res.data;
  },
);

//STATUS

export const checkTransactionSubscriptionStatus = createAsyncThunk(
  "customerPortal/checkTransactStatus",
  async () => {
    const res = await axios.get<SubscriptionStatus>(
      "/api/v1/mopsus/check-transaction-subscription-status",
    );

    return { status: res.data.status, apiKey: res.data.apiKey };
  },
);

export const checkWozSubscriptionStatus = createAsyncThunk(
  "customerPortal/checkWozStatus",
  async () => {
    const res = await axios.get<SubscriptionStatus>(
      "/api/v1/mopsus/check-woz-subscription-status",
    );
    return { status: res.data.status, apiKey: res.data.apiKey };
  },
);

export const checkObjectDataSubscriptionStatus = createAsyncThunk(
  "customerPortal/checkObjectDataStatus",
  async () => {
    const res = await axios.get<SubscriptionStatus>(
      "/api/v1/mopsus/check-object-data-subscription-status",
    );
    return { status: res.data.status, apiKey: res.data.apiKey };
  },
);

export const checkAvmSubscriptionStatus = createAsyncThunk(
  "customerPortal/checkAvmStatus",
  async () => {
    const res = await axios.get<SubscriptionStatus>(
      "/api/v1/mopsus/check-avm-subscription-status",
    );
    return { status: res.data.status, apiKey: res.data.apiKey };
  },
);

export const checkReferenceSubscriptionStatus = createAsyncThunk(
  "customerPortal/checkReferenceStatus",
  async () => {
    const res = await axios.get<SubscriptionStatus>(
      "/api/v1/mopsus/check-reference-subscription-status",
    );
    return { status: res.data.status, apiKey: res.data.apiKey };
  },
);

export const checkEcoSubscriptionStatus = createAsyncThunk(
  "customerPortal/checkEcoStatus",
  async () => {
    const res = await axios.get<SubscriptionStatus>(
      "/api/v1/mopsus/check-sustainability-subscription-status",
    );
    return { status: res.data.status, apiKey: res.data.apiKey };
  },
);

export const checkEnergySubscriptionStatus = createAsyncThunk(
  "customerPortal/checkEnergyLabelStatus",
  async () => {
    const res = await axios.get<SubscriptionStatus>(
      "/api/v1/mopsus/check-energy-label-subscription-status",
    );
    return { status: res.data.status, apiKey: res.data.apiKey };
  },
);

export const clearStripeState = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "customerPortal/clearState" });
};
