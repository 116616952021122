import createApiSlice from '../../helpers/createApiSlice';
import {
  postTransaction,
  transactionOneTimeUse,
} from '../actions/transactionApiAction';

const transactionSlice = createApiSlice(
  'transaction',
  postTransaction,
  transactionOneTimeUse,
);

export const { clearResults, modifyQueries } = transactionSlice.actions;

export default transactionSlice.reducer;
