import React from "react";
import SkeletonImage from "../../../../../components/skeleton/components/Image";
import SkeletonText from "../../../../../components/skeleton/components/Text";

type Props = {};

const PieChatSkeleton = (props: Props) => {
  return (
    <div className="animate-pulse flex flex-col m-auto">
      <div className="flex justify-center m-4">
        <SkeletonImage width="w-56" height="h-56" />
      </div>
      <div className="flex flex-wrap justify-center gap-3">
        {Array.from({ length: 5 }).map(() => (
          <div className="flex items-center gap-2">
            <SkeletonText width={"w-4"} height={"h-2"} />
            <SkeletonText width={"w-32"} height={"h-3"} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChatSkeleton;
