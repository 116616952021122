import {
  MOPSUS,
  TRANSACTION,
  REACT_APP_STRIPE,
  UNLIMITED,
} from "./stripePlanTypes";

type PlanMopsusPPU = keyof typeof REACT_APP_STRIPE.MOPSUS;
type PlanMopsusRec = keyof typeof REACT_APP_STRIPE.RECURRING;
type PlanTransaction = keyof typeof TRANSACTION;
type PlanUnlimited = keyof typeof UNLIMITED;

const isProd = process.env.REACT_APP_STAGE === "production";

function getMeteredPlanId(planName: string): string {
  const planKey = Object.keys(MOPSUS).find(
    (key) => MOPSUS[key as PlanMopsusPPU] === planName,
  );
  return isProd
    ? REACT_APP_STRIPE.MOPSUS[planKey as PlanMopsusPPU]
    : REACT_APP_STRIPE.TEST_PRICES.MOPSUS[planKey as PlanMopsusPPU];
}

function getMeteredTransactionId(planName: string) {
  const planKey = Object.keys(TRANSACTION).find(
    (key) => TRANSACTION[key as PlanTransaction] === planName,
  );
  return isProd
    ? REACT_APP_STRIPE.TRANSACTION[planKey as PlanTransaction]
    : REACT_APP_STRIPE.TEST_PRICES.TRANSACTION[planKey as PlanTransaction];
}

function getRecurringPlanId(planName: string) {
  const planKey = Object.keys(MOPSUS).find(
    (key) => MOPSUS[key as PlanMopsusRec] === planName,
  );
  return isProd
    ? REACT_APP_STRIPE.RECURRING[planKey as PlanMopsusRec]
    : REACT_APP_STRIPE.TEST_PRICES.RECURRING[planKey as PlanMopsusRec];
}
function getRecurringTransactionId(planName: string) {
  const planKey = Object.keys(TRANSACTION).find(
    (key) => TRANSACTION[key as PlanTransaction] === planName,
  );
  return isProd
    ? REACT_APP_STRIPE.TRANSACTION_RECURRING[planKey as PlanTransaction]
    : REACT_APP_STRIPE.TEST_PRICES.TRANSACTION_RECURRING[
        planKey as PlanTransaction
      ];
}

function getRecurringUnlimitedId(planName: string) {
  const planKey = Object.keys(UNLIMITED).find(
    (key) => UNLIMITED[key as PlanUnlimited] === planName,
  );
  return isProd
    ? REACT_APP_STRIPE.UNLIMITED[planKey as PlanUnlimited]
    : REACT_APP_STRIPE.TEST_PRICES.UNLIMITED[planKey as PlanUnlimited];
}

export {
  getMeteredPlanId,
  getMeteredTransactionId,
  getRecurringPlanId,
  getRecurringTransactionId,
  getRecurringUnlimitedId,
};

export const errorTranslation = (errorMessage: string) => {
  const translations: Record<string, string> = {
    "Subscription cancelled": "Subscription cancelled",
    "Your card was declined": "Uw kaart is geweigerd",
    "Your card has insufficent funds": "Uw kaart heeft onvoldoende saldo",
    "Your card's security code is incorrect":
      "De beveiligingscode van uw kaart is onjuist",
    "An error occurred while processing your card. Try again in a little bit":
      "Er is een fout opgetreden tijdens het verwerken van uw kaart. Probeer het over een tijdje opnieuw",
    "Your card number is invalid": "Uw kaartnummer is ongeldig",
  };

  return (
    translations[errorMessage] ||
    "Er is een fout opgetreden tijdens het verwerken van uw kaart. Probeer het over een tijdje opnieuw"
  );
};
