import { AppDispatch } from '../store';
import { createApiThunkImgApi } from '../../helpers/createApiThunk';

// Dispatch function to POST Image label API
export const postConditionScore = createApiThunkImgApi(
  'conditionScore/postConditionScore',
  'api',
  'Condition Score api used',
  'condition-score',
);

export const clearConditionScoreResults =	() => async (dispatch: AppDispatch) => {
  dispatch({ type: 'conditionScore/clearResults' });
};

export const modifyConditionScoreQueries =	() => async (dispatch: AppDispatch) => {
  dispatch({ type: 'conditionScore/modifyQueries' });
};

export const postConditionScoreOneTimeUse = createApiThunkImgApi(
  'conditionScore/postConditionScoreOneTimeUse',
  'demo',
  'Condition Score demo used',
  'condition-score',
);
