import { FC } from "react";
import Subtitle from "../../../../../components/Subtitle";
import ReturnInputField from "../../../../../components/ReturnInputField";
import { identifyPropertyField } from "../data";
import { useFormContext } from "../../components/FormContext";

type Props = {};

const IdentifyProperty: FC<Props> = () => {
  const { formValues, setFormValues } = useFormContext();
  return (
    <div className="">
      <Subtitle className="text-base">
        Welke woning wil je verduurzamen?
      </Subtitle>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
        {identifyPropertyField.map((field, index) => (
          <ReturnInputField
            key={index}
            label={field.label}
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            options={field.options}
            onChange={(e) => {
              "target" in e &&
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default IdentifyProperty;
