import React, { FC, useState } from "react";
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// import fonts
import { SourceObject } from "@react-pdf/types";
import workSansBold from "./fontsPDF/WorkSans-Bold.ttf";
import workSansExtraLight from "./fontsPDF/WorkSans-ExtraLight.ttf";
import workSansSemiBold from "./fontsPDF/WorkSans-SemiBold.ttf";
import workSansLight from "./fontsPDF/WorkSans-Light.ttf";
import workSansMedium from "./fontsPDF/WorkSans-Medium.ttf";
import workSansRegular from "./fontsPDF/WorkSans-Regular.ttf";

// import images
import altum from "../../assets/images/Logo-AltumAI.png";
import header from "../../imgs/pdfReport/header.png";
import home from "../../imgs/pdfReport/home.png";
import homeBorder from "../../imgs/pdfReport/home-border.png";
import formatDate from "../../helpers/convertToDutchMonth";
import convertToEUFormat from "../../helpers/convertToEuFormat";

// Register fonts for document
Font.register({
  family: "WorkSans",
  format: "truetype",
  // fontStyle: 'normal',
  fonts: [
    {
      src: workSansExtraLight,
      fontWeight: 100,
    },
    {
      src: workSansLight,
      fontWeight: 200,
    },
    {
      src: workSansRegular,
      fontWeight: 300,
    },
    {
      src: workSansMedium,
      fontWeight: 500,
    },
    {
      src: workSansSemiBold,
      fontWeight: 600,
    },
    {
      src: workSansBold,
      fontWeight: 700,
    },
  ],
});
const styles = StyleSheet.create({
  btn: {
    margin: "1rem 0",
    padding: "0.7rem",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "16px",
    border: "1px solid black",
    transition: "transform 0.3s ease",
    maxWidth: "230px",
    cursor: "pointer",
    color: "black",
    textDecoration: "none",
  },
  copyright: {
    position: "absolute",
    bottom: 10,
    fontSize: 10,
    width: 600,
    textAlign: "center",
    fontFamily: "WorkSans",
    fontWeight: 200,
  },
  question: {
    marginTop: 32,
    marginBottom: 8,
    fontSize: 14,
    fontFamily: "WorkSans",
    fontWeight: 500,
  },
  headerImage: {
    position: "absolute",
    left: -15,
    top: 28,
    height: 45,
  },
  headerContainer: {
    position: "relative",
  },
  heading: {
    fontSize: 18,
    marginBottom: 40,
    marginTop: 40,
    color: "#32aed7",
    letterSpacing: "0.4pt",
  },
  homeImage: {
    position: "absolute",
    height: 480,
    // top: -360,
    // left: 70
  },
  titleContainer: {
    height: 500,
    position: "relative",
    justifyContent: "flex-end",
    flexDirection: "column",
    paddingBottom: 160,
    marginLeft: 50,
    // width: 380
  },
  ecowaardeIcon: {
    position: "absolute",
    width: 100,
    top: 32,
    left: 30,
  },
  mainPage: {
    padding: 32,
    flexDirection: "column",
    // justifyContent: 'center',
    color: "#2B2D34",
  },
  page: {
    padding: 30,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 18,
    marginBottom: 15,
  },
  text: {
    fontSize: 14,
    marginBottom: 10,
    lineSpacing: 18,
    lineHeight: "1.3pt",
    fontFamily: "WorkSans",
    fontWeight: 200,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 16,
    fontSize: 14,
  },
  tableHeader: {
    backgroundColor: "#42ba7a",
    color: "white",
    padding: 6,
    paddingLeft: 10,
    fontSize: 14,
    fontFamily: "WorkSans",
    fontWeight: 600,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  tableColHeader: {
    flex: 1,
    padding: 8,
    backgroundColor: "#F0F0F0",
  },
  tableCol: {
    flex: 2,
    padding: 8,
  },
  tableCellHeader: {
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "left",
  },
  footer: {
    position: "absolute",
    bottom: 10,
    fontSize: 12,
    width: 600,
    textAlign: "center",
    fontFamily: "WorkSans",
    fontWeight: 200,
  },
});

interface Props {
  data: any;
  buildingPhoto: SourceObject;
}

// Create Document Component
const DownloadAvmReport: FC<Props> = (props) => {
  const [map, setMap] = useState("");
  // const [buildingPhoto, setBuildingPhoto] = useState('');
  const { data } = props;

  // Get Map Image from Google Maps Static api
  const getMap = (lon: any, lat: any) => {
    fetch(
      `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCx4vspKDUEOBO6QxTw4K_R5JKbhEfKHhs&center=${lat},${lon}&markers=color:red%7Clabel:S%7C${lat},${lon}&zoom=14&size=800x200&language=nl`,
    ).then(
      (result) => {
        setMap(result.url);
      },
      (error) => {
        console.log(error);
      },
    );
  };
  getMap(data.longitude, data.latitude);

  // Prepare new measures array that have differences in before and after, and add to eact item name in Dutch

  function Report() {
    return (
      <Document>
        {/* 1 Page start Cover page */}
        <Page size="A4" style={styles.mainPage}>
          <Image src={altum} style={styles.ecowaardeIcon} />
          <View style={styles.titleContainer}>
            <Image src={homeBorder} style={[styles.homeImage, { top: 150 }]} />
            <Image src={home} style={[styles.homeImage, { top: 150 }]} />

            <Text style={styles.title}>
              Waarderapport voor {data.street} {data.housenumber}
            </Text>
          </View>
        </Page>
        {/* 1 Page end */}

        {/* 2 Page  start Het Ecowaarderapport */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Waarderapport</Text>
          </View>

          <Text style={styles.text}>
            De AVM API (Automated Valuation Model) van Altum AI biedt unieke
            inzichten in de vastgoedwaarden van woningen in Nederland. Het model
            berekent de marktwaarde van een woning op basis van meer dan 150
            variabelen die in realtime worden bijgewerkt, zoals woonoppervlakte,
            inhoud en perceeloppervlakte. Om de marktwaarde en objectgegevens te
            kunnen leveren, identificeert de API het object aan de hand van de
            postcode en huisnummergegevens. De respons bevat informatie over de
            woning, zoals adres, gebouwtype, bouwjaar, binnenoppervlakte en
            indien beschikbaar het huidige energielabel.
          </Text>
          <View style={styles.headerContainer}>
            <Text style={styles.subTitle}>
              Introductie voor het Waarderapport
            </Text>
          </View>
          <Text style={styles.text}>
            Het waarderapport dat u in handen heeft, is gebaseerd op de
            geavanceerde technologie van Altum AI's AVM API. Dit rapport biedt
            een gedetailleerde en nauwkeurige inschatting van de marktwaarde van
            de woning, rekening houdend met diverse factoren zoals de
            woonoppervlakte, inhoud, perceeloppervlakte en het energielabel.
            Door gebruik te maken van meer dan 150 realtime bijgewerkte
            variabelen, biedt dit rapport een uniek en betrouwbaar inzicht in de
            huidige waarde van het vastgoed. Of u nu een koper, verkoper, of een
            vastgoedprofessional bent, dit waarderapport is een essentieel
            instrument om weloverwogen beslissingen te nemen in de dynamische
            vastgoedmarkt van Nederland.
          </Text>

          {/* Copyright */}
          <Text style={styles.footer}>
            {`Porduct van Altum AI | Copyright  ${new Date().getFullYear()} | altum.ai`}
          </Text>
        </Page>
        {/* 2 Page end */}

        {/* 3 Page start Basisgegevens, building and map images needed */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Basisgegevens</Text>
          </View>
          {/* Basic data table */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Postcode</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.postcode}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Huisnummer</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.housenumber}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Woonplaats</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.city}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Straat</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.street}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Woningtype</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.house_type}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Bouwjaar</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.build_year}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Woonoppervlakte</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {convertToEUFormat(data.inner_surface_area)} m²
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Woonoppervlakte</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {convertToEUFormat(data.outer_surface_area)} m²
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Volume</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {convertToEUFormat(data.volume)} m³
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Energielabel</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.energy_label}</Text>
              </View>
            </View>
          </View>

          {/* Building image */}
          <View style={styles.table}>
            <Text style={styles.tableHeader}>Afbeelding woning</Text>
            <Image src={props.buildingPhoto} />
          </View>
          <Text style={styles.footer}>
            {`Porduct van Altum AI | Copyright  ${new Date().getFullYear()} | altum.ai`}
          </Text>
        </Page>
        {/* 3 Page end */}

        {/* 4 Page start Huidige situatie */}
        <Page size="A4" style={styles.page}>
          {/* Current energy info, icons and details about info needed  */}
          <View style={styles.headerContainer}>
            <Text style={styles.heading}>Waarderingsgegevens</Text>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Waarderingsdatum</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatDate(data?.valuation_date)}
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Waardebepaling</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  €{convertToEUFormat(data.price_estimation)}
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>
                  Betrouwbaarheidspercentage
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.confidence}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>
                  Betrouwbaarheidsindicator
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.accuracy_indicator}</Text>
              </View>
            </View>
          </View>

          <View style={styles.headerContainer}>
            <Text style={styles.heading}>Accuratesse Indicator</Text>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>
                  Accuratesse Indicator
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHeader}>Definitie</Text>
              </View>
            </View>

            {/* Row 1 */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>1</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {"<"} 70% accuratesse in 10% afwijking
                </Text>
              </View>
            </View>

            {/* Row 2 */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>2</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {">"}= 70% accuratesse in 10% afwijking
                </Text>
              </View>
            </View>

            {/* Row 3 */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>3</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {">"}= 80% accuratesse in 10% afwijking
                </Text>
              </View>
            </View>

            {/* Row 4 */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>4</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {">"}= 90% accuratesse in 10% afwijking
                </Text>
              </View>
            </View>

            {/* Row 5 */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>5</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {">"}= 90% accuratesse in 10% afwijking and {">"}= 80%
                  accuratesse in 5% afwijking
                </Text>
              </View>
            </View>

            {/* Row 6 */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>6</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {">"}= 95% accuratesse in 10% afwijking
                </Text>
              </View>
            </View>

            {/* Row 7 */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>7</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {">"}= 95% accuratesse in 10% afwijking and {">"}= 80%
                  accuratesse in 5% afwijking
                </Text>
              </View>
            </View>
          </View>

          {/* Copyright */}
          <Text style={styles.footer}>
            {`Porduct van Altum AI | Copyright  ${new Date().getFullYear()} | altum.ai`}
          </Text>
        </Page>
        {/* 4 Page end */}
      </Document>
    );
  }
  if (map) {
    return (
      <PDFDownloadLink
        document={<Report />}
        fileName={`Waarderapport-${data.street}-${data.housenumber}.pdf`}
        style={styles.btn}
      >
        Download PDF
      </PDFDownloadLink>
    );
  }
  return null;
};
export default React.memo(DownloadAvmReport);
