import { FC, useState, useMemo } from "react";
import Subtitle from "../../../../components/Subtitle";
import Text from "../../../../components/Text";
import { ApiIntegrationConnection } from "react-basicons";
import Dropdown from "../../../../components/Dropdown";
import calculatePercentageDifference from "../../../../helpers/percentDifference";
import { CgArrowTopRight, CgArrowBottomRight } from "react-icons/cg";
import { Link } from "react-router-dom";

type Props = {
  usageToday: number;
  usageYesterday: number;
  usageThisWeek: number;
  usageLastWeek: number;
};

const TotalAPICallCard: FC<Props> = ({
  usageToday,
  usageYesterday,
  usageThisWeek,
  usageLastWeek,
}) => {
  const [period, setPeriod] = useState("Vandaag");
  const periods = ["Vandaag", "Gisteren", "Deze week"];

  const { dailyPercentageUsage, weeklyPercentageUsage } = useMemo(
    () => ({
      dailyPercentageUsage: calculatePercentageDifference(
        usageYesterday,
        usageToday,
      ),
      weeklyPercentageUsage: calculatePercentageDifference(
        usageLastWeek,
        usageThisWeek,
      ),
    }),
    [usageToday, usageYesterday, usageThisWeek, usageLastWeek],
  );

  const usagePercentage = useMemo(
    () =>
      period === "Vandaag" || period === "Gisteren"
        ? dailyPercentageUsage
        : weeklyPercentageUsage,
    [dailyPercentageUsage, period, weeklyPercentageUsage],
  );

  const totalCredit = useMemo(
    () =>
      period === "Vandaag"
        ? usageToday
        : period === "Gisteren"
        ? usageYesterday
        : usageThisWeek,
    [period, usageThisWeek, usageToday, usageYesterday],
  );

  const isIncrease = usagePercentage >= 0;

  return (
    <div className="shadow-[0px_0px_4px_0px_#00000026] rounded-lg w-full md:w-[360px] h-48 p-3 flex flex-col gap-2">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <div className="text-[#ffc300] bg-[#fff7dd] rounded-full w-10 h-10 text-center p-1.5">
            <ApiIntegrationConnection size={30} color="#ffc300" />
          </div>
          <Subtitle className="text-base">Verbruik</Subtitle>
        </div>
        <Dropdown label={period} items={periods} setItem={setPeriod} />
      </div>
      <div>
        <div className="flex gap-1 items-center">
          <Subtitle className="">{totalCredit}</Subtitle>
          <Text className="text-gray-light">
            API {totalCredit === 1 ? "call" : "calls"}
          </Text>
        </div>
        <div className="flex gap-1 items-center">
          <Subtitle className="text-sm flex items-center">
            {isIncrease ? <CgArrowTopRight /> : <CgArrowBottomRight />}
            {Math.abs(usagePercentage) === Infinity
              ? "-"
              : Math.abs(usagePercentage)}
            %
          </Subtitle>
          <Text className="text-base text-gray-light">
            {isIncrease ? "toename" : "afname"} vs{" "}
            {period === "Vandaag" || period === "Gisteren"
              ? "gisteren"
              : "vorige week"}
          </Text>
        </div>
      </div>
      <Link to="/dashboard/analytics" className="self-end flex mt-6">
        <Text className="text-primary">Analytics</Text>
      </Link>
    </div>
  );
};

export default TotalAPICallCard;
