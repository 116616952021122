import React, { useState, useEffect } from "react";
import styled from "styled-components";
import APIResult from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  convertNumberRange,
  getStreetViewImage,
} from "../../../helpers/common";
import formatDate from "../../../helpers/convertToDutchMonth";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { Redirect, useHistory } from "react-router-dom";
import {
  clearReferenceResults,
  modifyReferenceQueries,
} from "../../../redux/actions/referenceActions";
import Modal from "../../Modal";
import { Card } from "../../SolutionsCard";
import { fetchAddressData } from "../../../helpers/addressUtils";

interface Style {
  gap?: string;
  justify?: string;
  color?: string;
  height?: string;
  padding?: string;
  width?: string;
  align?: string;
  background?: string;
  position?: string;
}
interface ItemType {
  key: string;
  value: string;
  Latitude: string;
  Longitude: string;
  HouseNumber: string;
  City: string;
  Street: string;
  TransactionPrice: string;
  InnerSurfaceArea: number;
  Transactiondate: string;
  PostCode: string;
  HouseType: string;
  BuildYear: number;
  OuterSurfaceArea: number;
  Volume: number;
  BuurtCode: string;
  WijkCode: string;
  TimeforSaleDays: string | number;
  DefinitiveEnergyLabel: string;
  DefinitiveValidity: string;
  DefinitiveType: string;
  PriceIndex: number;
  Distance: number;
  HouseAddition: string;
}

const ReferenceResult = () => {
  const { loading, result } = useAppSelector((state) => state.reference);
  const property: any = result;
  const [buildingPhoto, setBuildingPhoto] = useState<string>("");

  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearReferenceResults());
    history.push("/referentie");
  };

  const modifyResults = () => {
    dispatch(modifyReferenceQueries());
    history.push("/referentie");
  };

  if (Object.keys(result).length === 0) return <Redirect to="/referentie" />;

  const desc = [
    {
      img: "",
      title: "Bouwjaar",
      result: property.GivenHouse?.BuildYear,
    },
    {
      img: "",
      title: "Type Woning",
      result: property.GivenHouse?.HouseType,
    },
    {
      img: "",
      title: "Energielabel",
      result: property.GivenHouse?.EnergyLabel?.DefinitiveEnergyLabel,
    },
    {
      img: "",
      title: "Woonoppervlakte",
      result: property.GivenHouse?.InnerSurfaceArea + "m2",
    },
  ];

  const reference = [
    { text: "Marktwaarde bepalen", path: "/avm" },
    {
      text: "Kadaster transactie ophalen",
      path: "/transactie-api",
    },
    { text: "WOZ waarde opvragen", path: "/woz" },
  ];

  return (
    <>
      <APIResult
        property={property}
        loading={loading}
        title={"Woningreferenties"}
        street={""}
        postCode={""}
        city={""}
        overview={[]}
        reference={reference}
        buildingPhoto={buildingPhoto}
        path={""}
        clearResult={clearResults}
        modifyResult={modifyResults}
        descSection={desc}
        Ref={RefItems}
        result={result}
        description={<ReferenceDesc property={property} />}
      />
    </>
  );
};

const RefItems = () => {
  const { result } = useAppSelector((state) => state.reference);
  const property: any = result;

  const [selectedItem, setSelectedItem] = useState<ItemType | null>(null);

  const [trigger, setTrigger] = useState(false);
  const [referenceHouses, setReferenceHouses] = useState<any[]>([]);

  useEffect(() => {
    const fetchAddressDataForHouses = async () => {
      const houses = await Promise.all(
        property.ReferenceData.ReferenceHouses.map(async (item: ItemType) => {
          const postalAddress = `${item.PostCode}-${item.HouseNumber}-${
            item.HouseAddition ?? ""
          }`;
          const result = await fetchAddressData(postalAddress);
          return { ...item, imageUrl: result?.imageUrl };
        }),
      );
      setReferenceHouses(houses);
    };

    fetchAddressDataForHouses();
  }, [property.ReferenceData.ReferenceHouses]);

  const handleCardClick = (item: any) => {
    setSelectedItem(item);
    setTrigger(true);
  };

  const formattedData = [
    {
      key: "Postcode",
      value: selectedItem?.PostCode,
    },
    {
      key: "Huisnummer",
      value: selectedItem?.HouseNumber,
    },
    {
      key: "Huisnummer toevoeging",
      value: `${
        selectedItem?.HouseAddition ? selectedItem?.HouseAddition : "-"
      }`,
    },

    {
      key: "Straat",
      value: selectedItem?.Street,
    },
    {
      key: "Woonplaats",
      value: selectedItem?.City,
    },
    {
      key: "Woningtype",
      value: selectedItem?.HouseType,
    },

    {
      key: "Bouwjaar",
      value: selectedItem?.BuildYear,
    },

    {
      key: "Woonoppervlakte",
      value: `${convertToEuFormat(selectedItem?.InnerSurfaceArea || 0)} m2`,
    },
    {
      key: "Perceeloppervlakte",
      value: `${convertToEuFormat(selectedItem?.OuterSurfaceArea || 0)} m2`,
    },
    {
      key: "Inhoud",
      value: `${convertToEuFormat(selectedItem?.Volume || 0)} m3`,
    },

    {
      key: "Latitude",
      value: selectedItem?.Latitude,
    },
    {
      key: "Longitude",
      value: selectedItem?.Longitude,
    },
    {
      key: "Transactie datum",
      value: `${formatDate(selectedItem?.Transactiondate || "")}`,
    },
    {
      key: "Koopsom",
      value: `€ ${convertNumberRange(selectedItem?.TransactionPrice || "")}`,
    },
    {
      key: "Buurtcode",
      value: selectedItem?.BuurtCode,
    },
    {
      key: "Wijkcode",
      value: selectedItem?.WijkCode,
    },
    {
      key: "Aantal dagen te koop",
      value: selectedItem?.TimeforSaleDays,
    },
    {
      key: "Energielabel klasse",
      value: selectedItem?.DefinitiveEnergyLabel,
    },
    {
      key: "Energielabel geldig tot",
      value: selectedItem?.DefinitiveValidity,
    },
    {
      key: "Energielabel type",
      value: selectedItem?.DefinitiveType,
    },
    {
      key: "Price Index",
      value: selectedItem?.PriceIndex,
    },
    {
      key: "Afstand",
      value: `${selectedItem?.Distance} m`,
    },
  ];

  return (
    <GridContainer>
      {referenceHouses.map(
        (item: ItemType & { imageUrl?: string }, index: number) => (
          <div onClick={() => handleCardClick(item)} key={index}>
            <Card
              buildingPhoto={item.imageUrl}
              houseNumber={`${item.HouseNumber} ${item.HouseAddition ?? ""}`}
              city={item.City}
              street={item.Street}
              transactionPrice={item.TransactionPrice}
              innerSurfaceArea={item.InnerSurfaceArea}
              transactionDate={item.Transactiondate}
            />
          </div>
        ),
      )}

      {selectedItem && trigger && (
        <Modal trigger={trigger} setTrigger={setTrigger}>
          <div style={{ overflowY: "auto" }}>
            <SubTitle>Resultaat</SubTitle>
            {formattedData.map((item, index) => (
              <ColumnContainer key={index}>
                <BoldText>{item.key}</BoldText>
                <ListContainer>
                  <LightText>{item.value}</LightText>
                </ListContainer>
              </ColumnContainer>
            ))}
          </div>
        </Modal>
      )}
    </GridContainer>
  );
};

const ReferenceDesc: React.FC<{ property: any }> = ({ property }) => {
  return (
    <LightText style={{ textAlign: "left" }}>
      Voor de woning uit <strong>{property.GivenHouse?.BuildYear}</strong>, met
      het type <strong>{property.GivenHouse.HouseType}</strong>, en een
      oppervlakte van <strong>{property.GivenHouse.InnerSurfaceArea} m2</strong>{" "}
      zijn de volgende referenties het meest vergelijkbaar:
    </LightText>
  );
};

const ListContainer = styled.div`
  background-color: #fbfbfb;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  /* { property.address.street + " "  + property.address.house_number + ' ' + property.address.city } */
`;

export const ColumnContainer = styled.div<Style>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || "1rem"};
  background-color: ${(props) => props.color || "white"};
  height: ${(props) => props.height || ""};
  padding: ${(props) => props.padding || ""};
  width: ${(props) => props.width || ""};
  position: ${(props) => props.position || ""};
  margin: 1rem;
`;

export const LightText = styled.p<Style>`
  color: ${(props) => props.color || "#818589"};
`;

export const BoldText = styled.h3``;

export const SubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: green;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 950px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default ReferenceResult;
