import React, { useState } from "react";
import ReturnInputField from "../../../../../components/ReturnInputField";
import { useFormContext } from "../../components/FormContext";
import { basicInfoFields } from "../data";

const BasicInfo = () => {
  const { formValues, setFormValues } = useFormContext();
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  // const validateRequiredFields = () => {
  //   const newErrors: Record<string, string> = {};
  //   basicInfoFields.forEach(({ name, required, label }) => {
  //     if (required && !formValues[name]) {
  //       newErrors[name] = `${label} is required`;
  //     }
  //   });
  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  return (
    <div>
      <h2>Woningdata</h2>
      {basicInfoFields.map(({ name, ...field }) => (
        <div key={name}>
          <ReturnInputField
            {...field}
            name={name}
            value={name && formValues[name] ? formValues[name] : ""}
            onChange={(event) => {
              if ("target" in event) {
                handleInputChange(event as React.ChangeEvent<HTMLInputElement>);
              }
            }}
          />
          {errors[name] && <span className="text-red-500">{errors[name]}</span>}
        </div>
      ))}
    </div>
  );
};

export default BasicInfo;
