import { ButtonSizes, Button as FButton } from "flowbite-react";
import React, { FC, MouseEventHandler, ReactNode } from "react";
import { ImSpinner2 } from "react-icons/im";

type Props = {
  children: ReactNode;
  className?: string;
  size?: keyof ButtonSizes;
  type?: "button" | "submit" | "reset";
  outline?: boolean;
  pill?: boolean;
  isProcessing?: boolean;
  isError?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
};

const Button: FC<Props> = ({
  children,
  className,
  outline,
  pill,
  type = "button",
  isProcessing,
  isError,
  disabled,
  onClick,
  size = "lg",
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <FButton
      size={size}
      className={`mt-6 items-center max-h-[56px] focus:ring-0 dark:focus:ring-0 ${className}`}
      outline={outline}
      pill={pill}
      type={type}
      isProcessing={isProcessing}
      disabled={isProcessing || isError || disabled}
      processingSpinner={<ImSpinner2 className="h-6 w-6 animate-spin" />}
      color={"primary"}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </FButton>
  );
};

export default Button;
