export const autoSuggestField = [
  {
    label: "Search*",
    name: "search",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Aantal resultaten",
    name: "limit",
    type: "number",
    placeholder: "3",
    tooltip: "",
  },
  {
    label: "Fuzzy zoeken",
    name: "fuzzy",
    type: "checkbox",
    tooltip:
      "Fuzzy zoeken is een uitbreiding op het zoeken die een overeenkomst produceert met termen die een vergelijkbare samenstelling hebben",
  },
];
//https://api.altum.ai/autosuggest/v2?search=4485PK
