import React from "react";
import { Page, Image, Text, View } from "@react-pdf/renderer";
import styles from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import { Ecovalue } from "../../../../../assets/images/api/APIimages";
import BlobVector from "../../../../../assets/images/BlobVector.png";

interface CoverPageProps {
  data: {
    address: {
      street: string;
      house_number: string;
      city: string;
    };
  };
}

const CoverPage: React.FC<CoverPageProps> = ({ data }) => (
  <Page size="A4" style={styles.mainPage}>
    <View style={styles.logoContainer}>
      <Image src={altumLogo} style={styles.logo} />
    </View>
    <View style={styles.titleContainer}>
      <Text style={styles.mainTitle}>Verduurzamings</Text>
      <Text style={styles.mainTitle}>rapport</Text>
      <Text style={styles.subtitle}>
        Het verduurzamingsadvies om uw {"\n"}energielabel te verbeteren en
        {"\n"}
        maandelijkse lasten te verlagen.
      </Text>
    </View>
    <View style={styles.randomSvgContainer}>
      <Image src={BlobVector} style={styles.randomSvg} />
    </View>
    <View style={styles.houseImageContainer}>
      <Image src={Ecovalue} style={styles.houseImage} />
    </View>
    <View style={styles.addressContainer}>
      <Text style={styles.addressLabel}>VERDUURZAMINGSRAPPORT VOOR</Text>
      <Text style={styles.addressText}>
        {data.address.street.toUpperCase()} {data.address.house_number}
      </Text>
      <Text style={styles.addressCity}>{data.address.city.toUpperCase()}</Text>
    </View>
  </Page>
);

export default CoverPage;
