import { useEffect } from "react";
import APIResult from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { splitAddress } from "../../../helpers/common";
import { useHistory, Redirect } from "react-router-dom";
import {
  clearEnergyLabelResults,
  modifyEnergyLabelQueries,
} from "../../../redux/actions/energyLabelActions";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import {
  FormProvider,
  useFormContext,
} from "../../../pages/Api/usage-pages/components/FormContext";

const EnergyLabelResult = () => {
  const { result, loading } = useAppSelector((state) => state.energyLabel);
  const property: any = result;

  const { buildingPhoto, setPostalAddress, fetchAddressData } =
    useFormContext();

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      const postalAddress = `${property.post_code}-${property.house_number}-${
        property.house_addition ?? ""
      }`;
      setPostalAddress(postalAddress);
    }
  }, [property, setPostalAddress]);

  useEffect(() => {
    if (Object.keys(property).length > 0 && buildingPhoto.length === 0) {
      fetchAddressData();
    }
  }, [property, buildingPhoto.length, fetchAddressData]);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearEnergyLabelResults());
    history.push("/energy-label");
  };

  const modifyResults = () => {
    dispatch(modifyEnergyLabelQueries());
    history.push("/energy-label");
  };

  if (Object.keys(property).length === 0) {
    return <Redirect to="/energy-label" />;
  }

  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: property.BagID,
    },
    {
      img: "",
      title: "Huisnummer toevoeging",
      result: `${property.HouseAddition ? property.HouseAddition : "-"}`,
    },
    {
      img: "",
      title: "Latitude",
      result: property.Latitude,
    },
    {
      img: "",
      title: "Longitude",
      result: property.Longitude,
    },
  ];
  const reference = [
    { text: "Verduurzaming advies", path: "/sustainability" },
    { text: "Objectgeometrie opvragen", path: "/object-geometry" },
    { text: "Marktwaarde bepalen", path: "/avm" },
  ];

  const overview = [
    {
      title: "Grondoppervlaktes",
      details: [
        {
          key: "Bouwjaar",
          value: property.build_year,
        },
        {
          key: "Oppervlakte",
          value: property.inner_surface_area,
        },
        {
          key: "Woningtype",
          value: property.house_type,
        },
      ],
    },

    {
      title: "Verduurzaminggegevens",
      details: [
        {
          key: "Installatietype",
          value: property.installation,
        },
        {
          key: "Gevelisolatie",
          value: property.wall_insulation,
        },
        {
          key: "Dakisolatie",
          value: property.roof_insulation,
        },

        {
          key: "Vloerisolatie",
          value: property.floor_insulation,
        },
        {
          key: "Glas woonkamer",
          value: property.living_room_windows,
        },
        {
          key: "Glas slaapkamers",
          value: property.bedroom_windows,
        },
        {
          key: "Douche WTW",
          value: property.shower,
          unit: "",
        },
        {
          key: "Geldig tot en met",
          value: property.definitive_validity_date,
          unit: "",
        },
        {
          key: "Aantal zonnepanelen m2",
          value: property.solar_panels,
        },
        {
          key: "Watt piek performance per m2",
          value: property.solarpanel_watt_peak,
        },
        {
          key: "CO\u2082",
          value: convertToEuFormat(property.CO2),
        },
        {
          key: "Definitief energielabel",
          value: property.definitive_energy_label,
        },
        {
          key: "Energielabel norm",
          value: property.definitive_energy_label_type,
        },
        {
          key: "Geldig t/m",
          value: property.definitive_energy_label_validity_date,
        },
        {
          key: "Ingeschat energielabel",
          value: property.current_estimated_energy_label,
        },
        {
          key: "BENG 2score",
          value: property.current_estimated_BENG2_score,
        },
        {
          key: "Geschat gasverbruik m3",
          value: property.estimated_gas_usage,
        },
        {
          key: "Geschat elektriciteitsverbruik kWh",
          value: property.estimated_energy_usage,
        },
      ],
    },
  ];
  return (
    <APIResult
      property={property}
      loading={loading}
      title={"NTA 8800 Energielabel API bepalen"}
      street={property.house_number}
      postCode={splitAddress(property.post_code)}
      city={property.house_number}
      overview={overview}
      reference={reference}
      descSection={desc}
      buildingPhoto={buildingPhoto}
      path="https://docs.altum.ai/apis/energielabel-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
    />
  );
};

const Index = () => {
  return (
    <FormProvider>
      <EnergyLabelResult />
    </FormProvider>
  );
};
export default Index;
