import { AppDispatch } from "../store";
import { createApiThunk } from "../../helpers/createApiThunk";

export const postEnergyInsight = createApiThunk(
  "energyInsight/postEnergyInsight",
  "api",
  "Energy Insight api used",
  "energyinsights",
);

export const clearEnergyInsightResults =
  () => async (dispatch: AppDispatch) => {
    dispatch({ type: "energyInsight/clearResults" });
  };

export const modifyEnergyInsightQueries =
  () => async (dispatch: AppDispatch) => {
    dispatch({ type: "energyInsight/modifyQueries" });
  };

export const energyInsightOneTimeUse = createApiThunk(
  "energyInsight/energyInsightOneTimeUse",
  "demo",
  "Energy Insight demo used",
  "energyinsights",
);
