import React, { memo, useRef, FC, InputHTMLAttributes } from "react";
import styled from "styled-components";
import useIsomorphicLayoutEffect from "../hooks/useIsomorphicLayoutEffect";
import usePrevious from "../hooks/usePrevious";
import { Colors } from "../styles/Theme";

interface ISingleOTPInputProps extends InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
  autoFocus?: boolean;
}

const SingleOTPInputComponent: FC<ISingleOTPInputProps> = ({
  focus,
  autoFocus,
  ...otherProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);

  useIsomorphicLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <StyledInput ref={inputRef} {...otherProps} />;
};

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;

// Styled component for input
const StyledInput = styled.input`
  border-radius: 8px;
  text-align: center;
  border: 1px solid #cbd5e0;
  background-color: white;
  font-weight: bold;
  color: #718096;
  font-size: 16px;
  width: 48px;
  height: 48px;
  &:focus {
    box-shadow: none;
    border: 1px solid ${Colors.main.green};
  }
`;
