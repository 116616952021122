import createApiSlice from '../../helpers/createApiSlice';
import {
  postConditionScore,
  postConditionScoreOneTimeUse,
} from '../actions/conditionScoreActions';

const conditionScoreSlice = createApiSlice(
  'conditionScore',
  postConditionScore,
  postConditionScoreOneTimeUse,
);

export const { clearResults, modifyQueries } = conditionScoreSlice.actions;

export default conditionScoreSlice.reducer;
