import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckoutState, SaveCheckoutPayload } from '../actions/checkoutActions';

const initialState: CheckoutState = {
  amount: null,
  plan: null,
  status: null,
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    saveCheckout: (state, action: PayloadAction<SaveCheckoutPayload>) => {
      state.amount = action.payload.amount;
      state.plan = action.payload.plan;
      state.status = action.payload.status;
    },
    clearCheckout: (state) => {
      state.amount = null;
      state.plan = null;
      state.status = null;
    },
  },
});

export const { saveCheckout, clearCheckout } = checkoutSlice.actions;
export default checkoutSlice.reducer;
