import React, { FC } from "react";
import Subtitle from "../../../../components/Subtitle";
import Text from "../../../../components/Text";
import { FaCheck } from "react-icons/fa6";

type Props = {
  steps: {
    stepId: string;
    stepName: string;
    stepDescription: string;
    rank: string;
    completed: boolean;
    handler: (id: string) => void;
  }[];
};

const OnboardingStep: FC<Props> = ({ steps }) => {
  return (
    <div className="flex flex-col text-start gap-8 mt-8">
      <Subtitle className="text-lg font-bold">Aan de slag</Subtitle>

      {steps.map((step) => (
        <div className="flex gap-4">
          <div className="bg-secondary min-w-8 h-8 rounded-md text-center flex justify-center items-center">
            {step.completed && <FaCheck className="text-primary" />}
          </div>
          <div className="flex flex-col">
            <Subtitle className="text-base font-medium cursor-pointer">
              <div onClick={() => step.handler(step.stepId)}>
                {step.stepName}
              </div>
            </Subtitle>
            <Text className="text-xs text-gray-light">
              {step.stepDescription}
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OnboardingStep;
