import { ChangeEvent, FC, FormEvent } from "react";
import DragNDrop from "../DragnDrop/DragNDrop";
import FormBody from "./FormBody";
import { FormOptions, FormOptionsSustainability } from "../../@types";

export interface ImageOptions {
  url: string | undefined;
  image: string | (File & string) | File | undefined;
  preview: string | undefined;
}

interface ImageFormProps {
  handleFormSubmit: (event: FormEvent<HTMLFormElement>) => void;
  imageOptions: any;
  setImageOptions: (options: ImageOptions) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  title: string;
  desc: string;
  path: string;
  initialFields: any[];
  setFormValues?: (value: FormOptions | FormOptionsSustainability) => void;
  formValues?: FormOptions | FormOptionsSustainability;
  img?: string;
}

interface ImageUploadProps {
  handleFormSubmit: (event: FormEvent<HTMLFormElement>) => void;
  imageOptions: any;
  setImageOptions: (options: ImageOptions) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ImageForm: FC<ImageFormProps> = ({
  handleFormSubmit,
  imageOptions,
  handleChange,
  setImageOptions,
  initialFields,
  title,
  desc,
  path,
  img,
}) => {
  return (
    <FormBody
      title={title}
      desc={desc}
      path={path}
      initialFields={initialFields}
      sectionFields={[]}
      handleSubmit={handleFormSubmit}
      imageComponent={ImageUpload}
      handleChange={handleChange}
      imageOptions={imageOptions}
      setImageOptions={setImageOptions}
      img={img}
    >
      <ImageUpload
        handleFormSubmit={handleFormSubmit}
        handleChange={handleChange}
        imageOptions={imageOptions}
        setImageOptions={setImageOptions}
      />
    </FormBody>
  );
};

const ImageUpload: FC<ImageUploadProps> = ({
  handleFormSubmit,
  imageOptions,
  handleChange,
  setImageOptions,
}) => {
  return (
    <div className="api-form">
      <form className="form" onSubmit={handleFormSubmit}>
        <div
          className="form-required-fields form-required-fields-image"
          style={{ flexWrap: "wrap" }}
        >
          {imageOptions.preview && (
            <div className="image-api-preview">
              <img src={imageOptions.preview} alt="" />
            </div>
          )}
          <div className="form-group image-api-request-form">
            <div className="image-api-request-form-group">
              <input
                type="text"
                name="url"
                placeholder="Plak hier een link naar een afbeelding"
                value={imageOptions.url || ""}
                onChange={handleChange}
                style={{ border: "1px solid #1e9d66" }}
              />
            </div>
            <span>OF</span>
            <DragNDrop
              setImageOptions={(files: File[]) => {
                setImageOptions({
                  image: files[0],
                  url: undefined,
                  preview: URL.createObjectURL(files[0]),
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(imageOptions.url || imageOptions.image || imageOptions.preview) && (
            <button type="submit" className="btn btn-primary">
              Versturen
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ImageForm;
