import FormBody from "../FormBody";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { LocationData } from "../../../assets/images/api/APIimages";
import { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { postLocationData } from "../../../redux/actions/locationDataActions";
import { LocationDataFields } from "./locationDataField";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const LocationDataForm = () => {
  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.moveData);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 2 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    formValues.postcode = formValues.postcode.split(" ").join("").toUpperCase();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey &&
      dispatch(postLocationData({ formData: newFormData, apiKey: apiKey }));
  };

  return (
    <FormBody
      title={"Locatie-gegevens API"}
      desc={
        "Met de Locatie-gegevens API haal je data op van objecten actief op de woningmarkt."
      }
      path={"https://docs.altum.ai/apis/locatie-gegevens-api"}
      initialFields={LocationDataFields}
      sectionFields={sectionFields}
      handleSubmit={handleFormSubmit}
      img={LocationData}
    />
  );
};

export default LocationDataForm;
