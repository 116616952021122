import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import APIForm from "./APIForm";
import { FormInputProps } from "./APIForm";
import CodeBlock from "./Codeblock";
import { Colors, device } from "../../styles/Theme";
import { ChevronLeftArrow } from "react-basicons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FormProvider,
  useFormContext,
} from "../../pages/Api/usage-pages/components/FormContext";
import BackButton from "../BackButton";
import Subtitle from "../Subtitle";

interface DescBoxProps {
  img?: string;
  desc?: string;
  path: string;
}

interface FormBodyItemProps {
  title: string;
  desc: string;
  slider?: any;
  img?: string;
  path: string;
}

interface FormBodyProps {
  // ... your existing props
  imageComponent?: React.ComponentType<any>;
  children?: React.ReactNode;
}

interface ImageOptions {
  url: string | undefined;
  image: string | (File & string) | File | undefined;
  preview: string | undefined;
}

interface imageComponentProps {
  imageOptions?: any;
  setImageOptions?: (options: ImageOptions) => void;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const DescBox: React.FC<DescBoxProps> = ({ img, desc, path }) => {
  return (
    <DescBoxContainer>
      <DescBoxText>{desc}</DescBoxText>

      <ImageStyle>
        <img src={img} alt="img" />
        <ButtonStyle>
          <a href={path} target="_blank" rel="noreferrer">
            Documentatie
          </a>
        </ButtonStyle>
      </ImageStyle>
    </DescBoxContainer>
  );
};

const FormBody: React.FC<
  FormBodyItemProps & FormInputProps & FormBodyProps & imageComponentProps
> = ({
  title,
  desc,
  img,
  path,
  initialFields,
  sectionFields,
  handleSubmit,
  children,
}) => {
  const [formActive, setFormActive] = useState<boolean>(false);
  const { formValues } = useFormContext();
  useEffect(() => {
    formValues && Object.keys(formValues).length > 0
      ? setFormActive(true)
      : setFormActive(false);
  }, [formValues]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        {/* <Link
          to="/"
          style={{
            display: "flex",
            gap: "10px",
            fontWeight: "bold ",
            alignItems: "center",
            color: "black",
          }}
        >
          <ChevronLeftArrow />
          <div>Ga terug</div>
        </Link> */}
        <BackButton link="/dashboard/products" color="text-primary" />
        <Subtitle className="px-4">{title}</Subtitle>
        <RowFlex>
          <CodeBlockContainer>
            <div className="container">
              {formActive ? (
                <div>
                  <CodeBlock
                    text={
                      formValues && title === "Verduurzamingsadvies genereren"
                        ? { data: formValues }
                        : formValues && title === "Autosuggestie API"
                        ? `https://api.altum.ai/autosuggest/v2?search=${
                            formValues.search
                          }${
                            formValues.fuzzy ? "&fuzzy=" + formValues.fuzzy : ""
                          }${
                            !!formValues.limit
                              ? "&limit=" + formValues.limit
                              : ""
                          }`
                        : formValues
                        ? formValues
                        : ""
                    }
                  />
                </div>
              ) : (
                <DescBox img={img} desc={desc} path={path} />
              )}
            </div>
          </CodeBlockContainer>
          <FormContainer className="px-4 mt-4">
            {!children && (
              <APIForm
                initialFields={initialFields}
                sectionFields={sectionFields}
                handleSubmit={handleSubmit}
                setIsFormActive={setFormActive}
              />
            )}
            {children}
          </FormContainer>
        </RowFlex>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
  /* background: ${Colors.main.lightGreen}; */
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem 0;
`;

const RowFlex = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const CodeBlockContainer = styled.div`
  flex: 3;
  height: 700px;
  width: 100%;
  position: sticky;
  top: 20px;
  border-radius: 10px;

  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
    transform: translateX(100%);
  }
  .block.active {
    opacity: 1;
    transform: translateX(0%);
  }

  @media ${device.laptop} {
    display: none !important;
  }
`;

const FormContainer = styled.div`
  flex: 6;
 
  @media (max-width: 767px) {
    flex-basis: 100%;
`;

const ButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e9d66;
  width: 200px;
  margin-top: 3rem;
  :hover {
    transform: scale(1.1);
  }
  a {
    color: #1e9d66;
    background: white;
    padding: 1rem;
    border-radius: 4px;
  }
`;

const ImageStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  img {
    max-width: 100%;
    height: 300px;
  }
`;

const DescBoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 1rem;
  background: #1e9d66;
  color: white;
  max-width: 100%;
  height: 100%;
  border-radius: 10px;
`;
const DescBoxText = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding: 2rem;
`;

export default FormBody;
