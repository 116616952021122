import { FormEvent, useEffect } from "react";
import { TransactionFields } from "./transactionFields";
import { postTransaction } from "../../../redux/actions/transactionApiAction";
import { FormOptions } from "../../../@types";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import FormBody from "../FormBody";
import { TransactionAPI } from "../../../assets/images/api/APIimages";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const KadasterForm = () => {
  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.transaction);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);
  const sectionFields = [{ title: "", startIndex: 0, endIndex: 2 }];

  const dispatch = useAppDispatch();

  const apiKey = useAppSelector(
    (state) => state.auth.user?.transaction_api_key,
  );

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const newFormData: FormOptions = {
        postcode: "",
        housenumber: "",
      };

      Object.entries(formValues).forEach(([key, value]) => {
        if (value !== undefined) {
          newFormData[key as keyof FormOptions] = value;
        }
      });
      const config = {
        headers: {
          "x-api-key": apiKey,
          "Content-Type": "application/json",
        },
      };
      axios.defaults.withCredentials = false;
      apiKey &&
        (await axios.post(
          "https://api.altum.ai/transaction/ping",
          newFormData,
          config as AxiosRequestConfig,
        ));
      toast.success(
        `Transactiegegevens voor ${formValues.postcode}-${formValues.housenumber} zijn beschikbaar`,
      );
      apiKey && dispatch(postTransaction({ formData: newFormData, apiKey }));
    } catch (error: any) {
      if (error.response?.status === 400) {
        return toast.error(
          `Transactiegegevens voor ${formValues.postcode}-${formValues.housenumber} zijn momenteel niet beschikbaar gezien deze invoer`,
        );
      }
      return toast.error(error.response.data);
    }
  };

  return (
    <FormBody
      title={"Kadaster transactie opvragen"}
      desc={
        "Met de Kadaster transactie API is het mogelijk om per woning alle transactiegegevens op te kunnen halen zoals geregistreerd door het Kadaster in Nederland van afgelopen maand t/m 1993 terug."
      }
      path={"https://docs.altum.ai/apis/transaction-api"}
      img={TransactionAPI}
      initialFields={TransactionFields}
      sectionFields={sectionFields}
      handleSubmit={handleFormSubmit}
    />
  );
};

export default KadasterForm;
