import React from "react";

const DividerWithText: React.FC<{ text: string; className?: string }> = ({
  text,
  className,
}) => {
  return (
    <div
      className={`flex items-center justify-center my-2 mx-auto ${className}`}
    >
      <div className="flex-grow border-t border-gray-300"></div>
      <span className="flex-shrink mx-4 text-gray-dark text-lg">{text}</span>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
  );
};

export default DividerWithText;
