import React, { ChangeEventHandler, FC } from "react";

interface FieldProps {
  id: string;
  type: string;
  placeholder: string;
  required: boolean;
  autoComplete: string;
  value: any;
  onChange: ChangeEventHandler;
  disabled?: boolean;
}
export const Field: FC<FieldProps> = ({
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  disabled,
}) => (
  <div className="checkout-formrow">
    <input
      className="StripeElement p-2"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  </div>
);
