export default function convertUnixTimestampToDate(
  timestamp: number | undefined,
): string {
  const dateObject = timestamp && new Date(timestamp * 1000);
  const formattedDate = formatter.format(dateObject);
  return formattedDate;
}
const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

const formatter = new Intl.DateTimeFormat("nl", options);
