import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getApisAnalytics,
  getApisTotalCalls,
  getUsage,
  getUsageLogs,
} from "../actions/apiUsage";

const initialState = {
  usage: {
    loading: false,
    plan: "-",
    totalCredits: "-",
    remaining: "-",
    used: 0,
    transactionUsed: 0,
    subscription: false,
    transactionSubscription: false,
    usageToday: 0,
    usageYesterday: 0,
    usageLastWeek: 0,
    usageThisWeek: 0,
    transactionUsageToday: 0,
    transactionUsageYesterday: 0,
    transactionUsageThisWeek: 0,
    transactionUsageLastWeek: 0,
  },
  usageLogs: { loading: false, logs: [] },
  error: null || "",
  totalApiCallsSum: 0,
  analytics: {
    loading: false,
    statistics: [],
  },
  responseStatus: {
    "400": 0,
    "422": 0,
    "429": 0,
    "500": 0,
    success: 0,
  },
};

const usageSlice = createSlice({
  name: "apiUsage",
  initialState,
  reducers: {
    clearState: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsage.pending, (state) => {
        state.usage.loading = true;
      })
      .addCase(getUsage.fulfilled, (state, action: PayloadAction<any>) => {
        state.usage.loading = false;
        state.usage = action.payload;
      })
      .addCase(getUsage.rejected, (state) => {
        state.usage.loading = false;
        state.error = "Failed to load usage data";
      })

      .addCase(getApisTotalCalls.pending, (state) => {
        state.usage.loading = true;
      })
      .addCase(
        getApisTotalCalls.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.usage.loading = false;
          state.totalApiCallsSum = action.payload;
        },
      )
      .addCase(getApisTotalCalls.rejected, (state) => {
        state.usage.loading = false;
        state.error = "Failed to load total API calls";
      })

      .addCase(getApisAnalytics.pending, (state) => {
        state.analytics.loading = true;
      })
      .addCase(
        getApisAnalytics.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.analytics.loading = false;
          state.analytics.statistics = action.payload.statistics;
          state.responseStatus = action.payload.responseStatus;
        },
      )
      .addCase(getApisAnalytics.rejected, (state) => {
        state.analytics.loading = false;
        state.error = "Failed to load API analytics";
      })
      .addCase(getUsageLogs.pending, (state) => {
        state.usageLogs.loading = true;
      })
      .addCase(getUsageLogs.fulfilled, (state, action: PayloadAction<any>) => {
        state.usageLogs.loading = false;
        state.usageLogs.logs = action.payload;
      })
      .addCase(getUsageLogs.rejected, (state) => {
        state.usageLogs.loading = false;
        state.error = "Failed to get usage logs.";
      });
  },
});
export default usageSlice.reducer;
