import { AppDispatch } from "../store";
import { createApiThunkAutosuggest } from "../../helpers/createApiThunk";

export const postAutosuggest = createApiThunkAutosuggest(
  "autosuggest/postAutosuggest",
  "api",
  "Autosuggest api used",
  "autosuggest",
);

export const clearAutosuggestResults = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "autosuggest/clearResults" });
};

export const modifyAutosuggestQueries = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "autosuggest/modifyQueries" });
};

export const autosuggestOneTimeUse = createApiThunkAutosuggest(
  "autosuggest/avmOneTimeUse",
  "demo",
  "Autosuggest demo used",
  "autosuggest",
);
