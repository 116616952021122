import { createSlice } from "@reduxjs/toolkit";
import { getAllUserEmailSettings } from "../actions/settingAction";
import { EmailSettings } from "../../@types";

interface SettingsState {
  loading: boolean;
  error: string | null | unknown;
  emailSettings: EmailSettings | null;
}
const initialState: SettingsState = {
  loading: false,
  error: null,
  emailSettings: null,
};
const settingsSlice = createSlice({
  name: "setings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserEmailSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUserEmailSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.emailSettings = action.payload;
      })
      .addCase(getAllUserEmailSettings.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      });
  },
});

// Reducer
export default settingsSlice.reducer;
