import createApiSlice from '../../helpers/createApiSlice';
import {
  geometryOneTimeUse,
  postGeometry,
} from '../actions/objectGeometryActions';

const geometrySlice = createApiSlice(
  'geometry',
  postGeometry,
  geometryOneTimeUse,
);

export const { clearResults, modifyQueries } = geometrySlice.actions;

export default geometrySlice.reducer;
