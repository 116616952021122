import React, { Fragment, MouseEvent } from "react";
import "./modal.css";
import { History } from "history";
import { useAppDispatch } from "../../../../redux/hooks";
import { cancelSubscription } from "../../../../redux/actions/subscriptionActions";
import { User } from "../../../../@types";
import {
  getMeteredPlanId,
  getMeteredTransactionId,
  getRecurringUnlimitedId,
} from "../../../../helpers/stripeValues";
import {
  isTransactionPlan,
  isYearlyPlan,
} from "../../../../helpers/stripeHelper";
import Modal from "../../../../components/Modal";

interface CancelPlanModalProps {
  user: User | null;
  plan: string;
  setCancelPlanModalDisplay: (value: boolean) => void;
  cancelPlanModalDisplay: boolean;
  downgradePlan?: boolean;
  history: History;
}

const CancelPlanModal: React.FC<CancelPlanModalProps> = ({
  user,
  plan,
  setCancelPlanModalDisplay,
  cancelPlanModalDisplay,
  history,
  downgradePlan,
}) => {
  const dispatch = useAppDispatch();

  const handleSubmit = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleModalClose();
    if (!user) return;
    const planId = isYearlyPlan(plan)
      ? getRecurringUnlimitedId(plan)
      : isTransactionPlan(plan)
      ? getMeteredTransactionId(plan)
      : getMeteredPlanId(plan);
    const body = {
      apikey: isTransactionPlan(plan) ? user.transaction_api_key : user.api_key,
      customerId: user.stripe_customer_id,
      planId: planId,
    };
    dispatch(cancelSubscription(body)).then(() =>
      history.push("/dasboard/startpagina"),
    );
  };

  const handleModalClose = () => {
    setCancelPlanModalDisplay(false);
  };

  return (
    <>
      <Modal
        trigger={cancelPlanModalDisplay}
        setTrigger={setCancelPlanModalDisplay}
      >
        <div className="heading">
          <h1>{downgradePlan ? "Downgraden" : "Annuleer"} abonnement</h1>
        </div>
        <p className="modal-description">
          Weet je zeker dat je het abonnement wilt <strong>{plan}</strong>{" "}
          {downgradePlan ? "downgraden" : "opzeggen"}? <br /> <br />
          Onverbruikte vooruitbetaalde credits vervallen en openstaand pay per
          use verbruik wordt direct afgerekend. Bekijk na het downgraden de
          eindfactuur onder Betalingen.
          <br /> <br />
          Voor het wijzigen van het abonnement naar enkel Pay per use dien je
          dit Abonnement te selecteren. Bekijk na annuleren de eindfactuur onder
          Betalingen.
        </p>
        <div>
          <button className="btn btn-dark" onClick={handleSubmit}>
            Ja, ik weet het zeker
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CancelPlanModal;
