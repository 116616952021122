import createApiSlice from "../../helpers/createApiSlice";
import { moveDataOneTimeUse, postMoveData } from "../actions/moveDataActions";

const moveDataSlice = createApiSlice(
	"moveData",
	postMoveData,
	moveDataOneTimeUse
);

export const { clearResults, modifyQueries } = moveDataSlice.actions;

export default moveDataSlice.reducer;
