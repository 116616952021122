import React from "react";
import styled from "styled-components";
import { Colors } from "../styles/Theme";
import Text from "./Text";

// TypeScript interface for component props
interface ProgressBarProps {
  label: string;
  percentage: number;
  color?: string; // Optional prop for custom color
}

// Styled components
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const TextBase = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${Colors.main.green};
`;

const TextSmall = styled(TextBase)`
  font-size: 0.875rem;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e5e7eb;
  border-radius: 9999px;
  height: 0.625rem;
`;

const ProgressBar = styled.div<{ percentage: number; color: string }>`
  background-color: ${(props) => props.color || "#4b9be7"};
  height: 0.625rem;
  border-radius: 9999px;
  width: ${(props) => props.percentage}%;
`;

// React component
const ProgressBarComponent: React.FC<ProgressBarProps> = ({
  label,
  percentage,
  color,
}) => {
  return (
    <>
      <FlexContainer>
        <TextBase></TextBase>
      </FlexContainer>
      <ProgressBarContainer>
        <ProgressBar
          percentage={percentage}
          color={Colors.main.green}
        ></ProgressBar>
      </ProgressBarContainer>
      <Text>{label}</Text>
    </>
  );
};

export default ProgressBarComponent;
