import {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
  useCallback,
} from "react";
import { CiLock, CiMail } from "react-icons/ci";
import Text from "../../../../components/Text";
import TextInput from "../../../../components/TextInput";
import DividerWithText from "../../../../components/DividerWithText";
import Social from "../../components/Social";
import AuthHeader from "../../components/AuthHeader";
import { Link, useHistory } from "react-router-dom";
import AuthForm from "../../components/AuthForm";
import AuthContainer from "../../components/AuthContainer";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { signUp } from "../../../../redux/actions/authActions";
import { useValidation } from "../../../../hooks/useValidator";
import ReviewBadge from "./ReviewBadge";

interface AuthFormData {
  email: string;
  password: string;
}

const SignupRightSection = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isAuthenticated, id, loading } = useAppSelector(
    (state) => state.auth,
  );
  const [formData, setFormData] = useState<AuthFormData>({
    email: "",
    password: "",
  });

  const [touchedFields, setTouchedFields] = useState<{
    [key: string]: boolean;
  }>({});
  const { errors, validate } = useValidation();

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard/startpagina");
    }
  }, [isAuthenticated, history]);

  useEffect(() => {
    if (id.length > 0) {
      dispatch({ type: "auth/clearResults" });
      history.push(`/verify-email?email=${formData.email}`);
    }
  }, [dispatch, formData.email, history, id.length]);
  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      setTouchedFields((prev) => ({ ...prev, [name]: true }));
      validate(formData);
    },
    [formData, validate],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (touchedFields[name]) {
        validate({ ...formData, [name]: value });
      }
    },
    [touchedFields, formData, validate],
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const errors = validate(formData);
      if (!errors["email"] || !errors["password"]) {
        dispatch(signUp(formData));
      }
    },
    [dispatch, formData, validate],
  );

  return (
    <AuthContainer>
      <AuthHeader
        title="Maak een gratis account aan"
        description="Ga probleemloos aan de slag met 15 gratis credits, geen betalingsgegevens nodig! Zonder aansluitkosten"
      />
      <Social />
      <DividerWithText
        className="md:max-w-[424px] w-[340px]"
        text="of ga verder met e-mail"
      />
      <AuthForm
        cta="Aanmelden"
        onSubmit={handleSubmit}
        isLoading={loading}
        isError={!!errors["email"] || !!errors["password"]}
      >
        <TextInput
          type="email"
          name="email"
          placeholder="Email"
          className="mt-3"
          icon={CiMail}
          message={touchedFields.email ? errors["email"] : ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextInput
          type="password"
          name="password"
          placeholder="Wachtwoord (8+ tekens)"
          className="mt-3"
          icon={CiLock}
          message={touchedFields.password ? errors["password"] : ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </AuthForm>
      <Text className="mt-3 text-center mx-4">
        Als je doorgaat, ga je akkoord met onze{" "}
        <a
          href="/terms-and-conditions"
          className="text-green-600 text-sm hover:underline"
          target="_blank"
        >
          Algemene voorwaarden
        </a>{" "}
        en{" "}
        <a
          href="/privacy-policy"
          className="text-green-600 hover:underline text-sm"
          target="_blank"
        >
          Privacybeleid.
        </a>
        .
      </Text>
      <Text className="text-center mt-4">
        Heb je al een account?{" "}
        <Link to="/signin" className="text-green-600 hover:underline">
          Inloggen
        </Link>
      </Text>
      <div className="flex justify-center items-center p-4 mt-16">
        <ReviewBadge />
      </div>
    </AuthContainer>
  );
};

export default SignupRightSection;
