import { FormEvent, useEffect } from "react";
import FormBody from "../FormBody";
import { rentalReference } from "../../../assets/images/api/APIimages";
import { FormOptions } from "../../../@types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { rentalReferenceFields } from "./rentalReferenceFields";
import { postRentalReference } from "../../../redux/actions/rentalReferenceAction";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const RentalReferenceForm = () => {
  const sectionFields = [
    { title: "Adres", startIndex: 0, endIndex: 2 },
    { title: "Andere opties", startIndex: 3, endIndex: 24 },
  ];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.reference);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);

  const dispatch = useAppDispatch();

  const handleReferenceFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    formValues.postcode = formValues.postcode.split(" ").join("").toUpperCase();
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey && dispatch(postRentalReference({ formData: newFormData, apiKey }));
  };

  return (
    <div>
      <FormBody
        title={"Huurreferentie API"}
        sectionFields={sectionFields}
        desc={
          "De interactieve huurreferentie API geeft de taxateur volledige flexibiliteit bij het vinden van huurreferentieobjecten op basis van meerdere variabelen en berekent de marktwaarde huur voor de woning."
        }
        img={rentalReference}
        path={""}
        initialFields={rentalReferenceFields}
        handleSubmit={handleReferenceFormSubmit}
      />
    </div>
  );
};

export default RentalReferenceForm;
