import React, { useState } from "react";
import { FormProvider } from "../../components/FormContext";
import Sustainability from "../components/SustainabilityContainer";
import SustainabilityForm from "./SustainabilityForm";

type Props = {};

const Index = (props: Props) => {
  const [page, setPage] = useState(1);
  return (
    <FormProvider>
      <Sustainability page={page}>
        <SustainabilityForm page={page} setPage={setPage} />
      </Sustainability>
    </FormProvider>
  );
};

export default Index;
