import SubscriptionCard from "./SubscriptionCard";
import { useAppSelector } from "../../../../redux/hooks";
import { getPrice } from "../../../../helpers/stripeHelper";
import billingTime from "../../../../helpers/billingDetails";
import { ComingInvoiceResponse } from "../../../../@types";
import Invoice from "./Invoice";
import { useSubscriptionContext } from "../../../../hooks/useSubscriptionContext";

const Overview = () => {
  const { comingInvoice, transactionComingInvoice } = useAppSelector(
    (state) => state.portal,
  );
  const { altumPlan, apiCalls } = useSubscriptionContext();

  const renderSubscriptionCard = (
    invoice: ComingInvoiceResponse | null,
    isDefault = false,
  ) => {
    const planName =
      invoice?.product.name || (isDefault ? altumPlan : undefined);
    if (!planName) return null;

    const planPrice = invoice ? getPrice(planName) : 0;
    const billingDetails =
      invoice && billingTime(invoice.subscription, invoice.comingEvent);

    return (
      <SubscriptionCard
        planName={planName}
        planPrice={planPrice}
        startDate={billingDetails?.startDate}
        endDate={billingDetails?.endDate}
        amountDue={billingDetails?.amountDue || 0}
        apiUsed={apiCalls}
      />
    );
  };

  return (
    <div className="mt-4 flex flex-col gap-4">
      {renderSubscriptionCard(comingInvoice, true)}
      {renderSubscriptionCard(transactionComingInvoice)}
      <Invoice />
    </div>
  );
};

export default Overview;
