const formatDate = (date: string): string => {
	const dateStr = date.toString();
	const d =
		dateStr.slice(0, 4) + "-" + dateStr.slice(4, 6) + "-" + dateStr.slice(6, 8);
	const newDate = new Date(d).toDateString().slice(4).split(" ");
	const monthMap: Record<string, string> = {
		Jan: "januari",
		Feb: "februari",
		Mar: "maart",
		Apr: "april",
		May: "mei",
		Jun: "juni",
		Jul: "juli",
		Aug: "augustus",
		Sep: "september",
		Oct: "oktober",
		Nov: "november",
		Dec: "december",
	};

	return monthMap[newDate[0]]
		? `${newDate[1]} ${monthMap[newDate[0]]} ${newDate[2]}`
		: "";
};

export default formatDate;
