export type ApiName = keyof typeof apiErrorHandlers;

export const getApiError = (
  apiName: ApiName,
  apiStatusCode: number,
  detail?: string,
) => {
  const errorHandler = apiErrorHandlers[apiName];
  return errorHandler
    ? errorHandler(apiStatusCode, detail)
    : "Er is geen respons omdat het geen valide request is op de API";
};

const getSustainabilityAPIError = (statusCode: number, detail?: string) => {
  switch (statusCode) {
    case 200:
      return "Aanvraag is geslaagd";
    case 301:
      if (!detail) {
        return getCommonAPIError(301);
      }
      if (detail === "Unable to check apartment.") {
        return "Het is momenteel niet mogelijk verduurzamingadvies te genereren voor appartementen.";
      }
      if (
        detail ===
        "Address not found in database, combination of zip code and house number."
      ) {
        return "Het opgegeven adres is niet gevonden";
      }
      break;
    case 311:
      if (
        detail ===
        "Error generating response for target energylabel A as energylabel current situation is already A"
      ) {
        return "Er is geen advies mogelijk omdat het energielabel al A betreft";
      }
      if (
        detail ===
        "There are no measures to suggest for this combination of input."
      ) {
        return "Voor de opgegeven input is er geen advies mogelijk";
      }
      break;
    case 400:
      return "Er is geen respons omdat het geen valide request is op de API";
    case 401:
      return "API key ontbreekt of bestaat niet";
    case 403:
      return "Verboden";
    case 422:
      return "Aanvraag kan niet verwerkt worden, enkele inputs voldoen niet aan de documentatie";
    case 429:
      return "Het aantal credits is momenteel 0. Upgrade het abonnement via het Dashboard onder Abonnement";
    case 500:
      return "Service is niet beschikbaar - kijk op";
    default:
      return "Onbekende fout";
  }
};

const getAvmApiError = (statusCode: number, details?: string | null) => {
  switch (statusCode) {
    case 200:
      return "Aanvraag is geslaagd";
    case 400:
      if (details === "The given house type is unsupported or unknown.") {
        return "Woningtype is onbekend. Vul dit aan via de opties.";
      }
      if (details === "The given house address was not found in database.") {
        return "Adres is onbekend, controleer de invoer";
      }
      break;
    case 403:
      return "Verboden";
    case 401:
      return "API key ontbreekt of bestaat niet";
    case 422:
      return "Aanvraag kan niet verwerkt worden, enkele inputs voldoen niet aan de documentatie";
    case 429:
      return "Het aantal credits is momenteel 0. Upgrade het abonnement via het Dashboard onder Abonnement";
    case 500:
      return "Service is niet beschikbaar - kijk op";
  }
};

const getCommonAPIError = (statusCode: number) => {
  switch (statusCode) {
    case 200:
      return "Aanvraag is geslaagd";
    case 400:
      return "Er is geen respons omdat het geen valide request is op de API";
    case 403:
      return "Verboden";
    case 401:
      return "API key ontbreekt of bestaat niet";
    case 422:
      return "Aanvraag kan niet verwerkt worden, enkele inputs voldoen niet aan de documentatie";
    case 429:
      return "Het aantal credits is momenteel 0. Upgrade het abonnement via het Dashboard onder Abonnement";
    case 500:
      return "Service is niet beschikbaar - kijk op";
    default:
      return "Er is geen respons omdat het geen valide request is op de API";
  }
};
const apiErrorHandlers = {
  sustainability: getSustainabilityAPIError,
  avmplus: getAvmApiError,
  woz: getCommonAPIError,
  autosuggest: getCommonAPIError,
  "location-data": getCommonAPIError,
  movedata: getCommonAPIError,
  amenities: getCommonAPIError,
  labelling: getCommonAPIError,
  transaction: getCommonAPIError,
  "condition-score": getCommonAPIError,
  "interactive-reference": getCommonAPIError,
  geometry: getCommonAPIError,
  energylabel: getCommonAPIError,
  bag: getCommonAPIError,
  objectdata: getCommonAPIError,
  energyinsights: getCommonAPIError,
  rebuild: getCommonAPIError,
  solar: getCommonAPIError,
  "rental-reference": getCommonAPIError,
  "energy-climate": getCommonAPIError,
  wws: getCommonAPIError,
};
