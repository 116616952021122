import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import Switch from "../Switch";
import { Link } from "react-router-dom";
import CodeBlock from "./Codeblock";
import { PaperNote, Redo, AddPlusNewSquareBoxSquare } from "react-basicons";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addOnboardingState } from "../../redux/actions/onboardingActions";
import MeasuresDetailBox from "../Ecovalue/MeasuresDetailBox";
import { TfiAngleLeft, TfiAngleRight, TfiClose } from "react-icons/tfi";
import Table from "../Table";
import Subtitle from "../Subtitle";
import useWindowSize from "../../hooks/useWindowSize";

interface Props {
  property: any;
  loading: boolean;
  title: string;
  street: string;
  postCode: string;
  city: string;
  descSection?: Desc[];
  overview: any;
  measures?: any;
  reference: ButtonItems[];
  buildingPhoto?: string;
  result?: any;
  path: string;
  clearResult: any;
  modifyResult: any;
  Ref?: any;
  description?: any;
  PDF?: any;
  tableValues?: any;
}

interface Desc {
  img: string;
  title: string;
  result: any;
}

interface Style {
  gap?: string;
  justify?: string;
  color?: string;
  height?: string;
  padding?: string;
  width?: string;
  align?: string;
  background?: string;
  position?: string;
}

interface ButtonType extends Sidebar {
  index?: number;
  icon?: any;
}

interface Sidebar {
  path?: string;
  text: string;
  action?: any;
}

interface List {
  overview?: Result[];
  measures?: any;
  ref?: {
    [key: string]: string | number | null;
  };
  tableValues?: any;
}

interface Result {
  title: string;
  img: string;
  details: any[];
}

interface Buttons {
  title: string;
  reference: ButtonItems[];
  path: string;
  descSection?: Desc[];
  clearResult: any;
  modifyResult: any;
  PDF?: any;
  buildingPhoto?: any;
  property: any;
  sideopen: boolean;
  setSideopen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ButtonItems {
  text: string;
  path: string;
}

const sidebarItems = [{ text: "Documentatie", icon: <PaperNote /> }];

const APIResult: React.FC<Props> = ({
  property,
  title,
  descSection,
  overview,
  reference,
  measures,
  buildingPhoto,
  path,
  clearResult,
  modifyResult,
  Ref,
  description,
  PDF,
  tableValues,
}) => {
  const [toggled, setToggled] = useState(false);
  const [sideopen, setSideopen] = useState(true);
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();
  useEffect(() => {
    window.scrollTo(0, 0);
    windowSize.width < 700 ? setSideopen(false) : setSideopen(true);
  }, [windowSize.width]);
  const onboardingSteps = useAppSelector(
    (state) => state.onboarding.onboardingSteps,
  ) as { stepId: string; stepName: string; completed: boolean }[];

  const makeCall =
    onboardingSteps &&
    onboardingSteps.find(
      (step) => step.stepName === "Eerste API call uitvoeren",
    );

  useEffect(() => {
    if (makeCall && !makeCall.completed) {
      dispatch(addOnboardingState(makeCall.stepId));
    }
  }, [dispatch, makeCall]);

  return (
    <>
      <Container sideopen={sideopen}>
        <div className="body-container">
          <ColumnContainer gap={"2rem"} width="100%" padding="1rem 2rem">
            <RowFlex justify="center"></RowFlex>

            <RowFlex align="center" justify="left">
              <div className="text">Visuele output </div>
              <Switch onChange={(event) => setToggled(event.target.checked)} />
              <div className="json">API output</div>
            </RowFlex>
            {!toggled && (
              <DescContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    textAlign: "center",
                  }}
                >
                  <h2 style={{ marginBottom: "10px" }}>{title}</h2>
                  {description}
                </div>

                {buildingPhoto && <ImageContainer src={buildingPhoto} />}
              </DescContainer>
            )}

            {toggled ? (
              <CodeBlock text={property} />
            ) : (
              <>
                <RowFlex justify="space-between">
                  <ColumnContainer></ColumnContainer>
                </RowFlex>

                <div className="title-container">
                  <h2>Overzicht</h2>
                </div>
                <ResultList
                  overview={overview}
                  measures={measures}
                  tableValues={tableValues}
                />
              </>
            )}
            {Ref && <Ref />}
          </ColumnContainer>
        </div>
        <div className="side-toggle" onClick={() => setSideopen(!sideopen)}>
          {!sideopen && <TfiAngleRight size={30} />}
        </div>
        <div className="sidebar-container">
          <ResultSidebar
            reference={reference}
            title={title}
            path={path}
            descSection={descSection}
            clearResult={clearResult}
            modifyResult={modifyResult}
            PDF={PDF}
            buildingPhoto={buildingPhoto}
            property={property}
            sideopen={sideopen}
            setSideopen={setSideopen}
          />
        </div>
      </Container>
    </>
  );
};

const ResultSidebar: React.FC<Buttons> = ({
  reference,
  path,
  modifyResult,
  clearResult,
  PDF,
  buildingPhoto,
  property,
  sideopen,
  setSideopen,
}) => {
  return (
    <ColumnContainer
      gap={""}
      height="100%"
      color="#1E9D66"
      width="300px"
      style={{
        alignItems: "center",
        borderLeft: "1px solid #707070",
        zIndex: "500",
      }}
      padding="0"
    >
      <div className="flex flex-col">
        <div className="mt-2 self-end" onClick={() => setSideopen(!sideopen)}>
          <TfiClose size={15} className="text-white" />
        </div>
        <ul>
          <BoldText
            style={{
              color: "white",
              textAlign: "center",
              margin: "2rem 0",
              opacity: "0.8",
            }}
          >
            Meer data voor deze woning
          </BoldText>
          {reference?.map((item, index) => {
            return (
              <ButtonInternal
                index={index}
                path={item.path}
                text={item.text}
                color={"white"}
              />
            );
          })}
        </ul>

        <div style={{ border: "1px solid white", margin: "2rem 0" }} />

        <ul style={{ marginTop: "2rem" }}>
          <Button
            action={clearResult}
            text={"Nieuw API verzoek"}
            color="white"
            icon={<Redo color="white" />}
          />
          <Button
            color="white"
            action={modifyResult}
            text={"Hergebruik input"}
            icon={<AddPlusNewSquareBoxSquare color="white" />}
          />
        </ul>

        <div style={{ border: "1px solid black", margin: "2rem 0" }} />

        <ul>
          {sidebarItems.map((item, index) => {
            return (
              <div>
                <Button
                  index={index}
                  path={path}
                  text={item.text}
                  color={"black"}
                  icon={item.icon}
                />
              </div>
            );
          })}
          {PDF && buildingPhoto && property && (
            <PDF buildingPhoto={buildingPhoto} data={property} />
          )}
        </ul>
      </div>
    </ColumnContainer>
  );
};

const Button: React.FC<ButtonType & Style> = ({
  index,
  path,
  text,
  color,
  background,
  action,
  icon,
}) => {
  return (
    <ButtonStyle
      key={index}
      color={color}
      background={background}
      onClick={action}
    >
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <a
          href={path}
          target="_blank"
          rel="noreferrer"
          style={{ color: color }}
        >
          {text}
        </a>
        {icon}
      </div>
    </ButtonStyle>
  );
};

const ButtonInternal: React.FC<ButtonType & Style> = ({
  index,
  path,
  text,
  color,
  background,
}) => {
  return (
    <ButtonStyle key={index} color={color} background={background}>
      <Link to={path} style={{ color: color }}>
        {text}
      </Link>
    </ButtonStyle>
  );
};

export const ResultList: React.FC<List> = ({
  overview,
  ref,
  measures,
  tableValues,
}) => {
  return (
    <>
      {tableValues && (
        <>
          <Subtitle className="text-base">Distributie huurwaardes</Subtitle>
          <div className="mt-4 w-[330px] md:w-full border rounded-md">
            <Table
              headers={[
                "Oppervlakte",
                "Aantal referenties",
                "Huurprijs per m2",
                "Gemiddelde huurprijs",
              ]}
              tableItems={tableValues}
              noDataText="Er zijn nog geen huurwaardes beschikbaar."
            />
          </div>
        </>
      )}
      {overview?.map((item, index) => (
        <ColumnContainer key={index}>
          <BoldText>{item.title}</BoldText>
          {item.img && (
            <ListContainer key={index}>
              <ImageContainer src={item.img} />
            </ListContainer>
          )}
          {item.details.map((detail, index) => (
            <>
              <ListContainer key={index}>
                <LightText>{detail.key}:</LightText>
                <LightText color="#1E9D66">
                  {detail.value} {detail.unit}
                </LightText>
              </ListContainer>
            </>
          ))}
        </ColumnContainer>
      ))}

      {Object.entries(ref ?? {}).map(([key, value], index) => (
        <ColumnContainer key={index}>
          <BoldText>{key}</BoldText>
          <ListContainer>
            <LightText>{value}</LightText>
          </ListContainer>
        </ColumnContainer>
      ))}
      {measures &&
        Object.entries<any>(measures).map((measure, index) => (
          <Fragment key={index}>
            {measure[1].before.desc === measure[1].after.desc ? null : (
              <MeasuresDetailBox measure={measure} index={index} />
            )}
          </Fragment>
        ))}
    </>
  );
};

const Container = styled.div<{ sideopen: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .sidebar-container {
    position: fixed;
    transition: width 0.3s linear 0.1s;
    width: ${({ sideopen }) => (sideopen ? "300px" : "0px")};
    height: 100vh;
    right: 0;
  }
  .side-toggle {
    position: fixed;
    top: 50vh;
    right: ${({ sideopen }) => (sideopen ? "300px" : "5px")};
    color: grey;
    cursor: pointer;
  }
  .body-container {
    transition: width 0.3s linear 0.1s;
    width: 100%;
    overflow: auto;
    /* margin: 0 2rem; */
  }
  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  h2 {
    color: black;
    opacity: 0.8;
    font-weight: 700;
  }
`;

const ButtonStyle = styled.div<Style>`
  /* background-color: ${(props) => props.background || "white"}; */
  margin: 0.75rem 0;
  padding: 0.5rem;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  border: 1px solid ${(props) => props.color || "white"};
  transition: transform 0.3s ease;
  max-width: 230px;
  cursor: pointer;

  a {
    /* color: ${(props) => props.color || "#1e9d66"}; */
    color: white;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

const RowFlex = styled.div<Style>`
  display: flex;
  gap: ${(props) => props.gap || "1rem"};
  justify-content: ${(props) => props.justify || " "};
  align-items: ${(props) => props.align || " "};
`;

const ColumnContainer = styled.div<Style>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || "1rem"};
  background-color: ${(props) => props.color || "white"};
  height: ${(props) => props.height || ""};
  padding: ${(props) => props.padding || ""};
  width: ${(props) => props.width || ""};
  position: ${(props) => props.position || ""};
  /* .sidebar-content {
		max-width: 100%;
	} */

  hr {
    background-color: white !important;
  }
`;

export const LightText = styled.p<Style>`
  color: ${(props) => props.color || "	#818589"};
  text-align: left;
`;

export const BoldText = styled.h4`
  font-weight: 800;
`;

const DescContainer = styled.div`
  width: 100%;
  min-height: 200px; // Use min-height to ensure a minimum height
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 5px 8px 0px rgba(15, 9, 83, 0.09);
  padding: 2rem;
  border: 1px solid rgba(48, 57, 105, 0.15);
  overflow: hidden; // To ensure the border-radius is respected by child elements

  @media (max-width: 1200px) {
    height: auto; // Let the container adjust its height based on content
  }

  .container {
    display: flex;
    flex-direction: row;
  }
`;

const ImageContainer = styled.img`
  width: 100%;
`;

export const ListContainer = styled.div`
  background-color: #fbfbfb;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  border: 1px solid rgba(48, 57, 105, 0.15);
  border-radius: 4px;
`;

export default APIResult;
