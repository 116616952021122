import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import Text from "./Text";

interface TabsProps {
  tabs: string[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const { activeIndex, setActiveIndex } = useTabContext();
  const handleTabClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div className="text-sm font-medium text-center text-gray-500">
      <ul className="flex -mb-px cursor-pointer">
        {tabs.map((tab, index) => (
          <li
            key={index}
            onClick={(e) => {
              e.preventDefault();
              handleTabClick(index);
            }}
            className={`inline-block p-4 border-b-2 rounded-t-lg me-2 ${
              activeIndex === index
                ? "text-primary border-primary"
                : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            }`}
            aria-current={activeIndex === index ? "page" : undefined}
          >
            <Text>{tab}</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
const TabContext = createContext<
  | { activeIndex: number; setActiveIndex: Dispatch<SetStateAction<number>> }
  | undefined
>(undefined);

const TabProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabContext.Provider value={{ activeIndex, setActiveIndex }}>
      {children}
    </TabContext.Provider>
  );
};
const useTabContext = () => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error("useTabContext must be used within a FormProvider");
  }
  return context;
};

export { TabProvider, useTabContext };
