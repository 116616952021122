import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiName, getApiError } from "./apiErrors";
import addGAEvent from "./addGAEvent";
import { ApiOutput } from "../@types";
import { toast } from "react-toastify";

export interface ApiOutputPayload {
  output: ApiOutput["Output"];
  query: {
    postcode: string;
  };
}
export const createApiThunk = (
  actionType: string,
  eventCategory: string,
  eventAction: string,
  apiName: ApiName,
) =>
  createAsyncThunk<ApiOutputPayload, { formData: any; apiKey: string }>(
    actionType,
    async (arg, { dispatch, rejectWithValue }) => {
      try {
        const { formData, apiKey } = arg;
        const data = { formData, apiKey, apiName };
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
        axios.defaults.withCredentials = false;
        const url = `/api/v1/mopsus/api-call`;
        const res = await axios["post"]<any>(url, data, config);
        const output = res.data.data.Output
          ? res.data.data.Output
          : res.data.data.output
          ? res.data.data.output
          : res.data.data;
        addGAEvent(eventCategory, eventAction);
        return { output, query: formData };
      } catch (error: any) {
        const errorMessage = error.response?.data?.message?.detail
          ? JSON.stringify(error.response.data?.message?.detail)
          : error.response.data?.message?.Output
          ? error.response.data?.message?.Output
          : error.response.data.message?.message
          ? error.response.data?.message.message
          : "Unknown error occurred";
        dispatch(translationError(errorMessage));
        return rejectWithValue(errorMessage);
      }
    },
  );

export const createApiThunkAutosuggest = (
  actionType: string,
  eventCategory: string,
  eventAction: string,
  apiName: ApiName,
) =>
  createAsyncThunk<ApiOutputPayload, { formData: any; apiKey: string }>(
    actionType,
    async (arg, { dispatch, rejectWithValue }) => {
      try {
        const { formData, apiKey } = arg;
        const data = { formData, apiKey, apiName };
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
        axios.defaults.withCredentials = false;
        const url = `/api/v1/mopsus/autosuggest`;
        const res = await axios["post"]<any>(url, data, config);
        const output = res.data.data.Output
          ? res.data.data.Output
          : res.data.data.output
          ? res.data.data.output
          : res.data.data;
        addGAEvent(eventCategory, eventAction);
        return { output, query: formData };
      } catch (error: any) {
        const errorMessage = error.response?.data?.message?.detail
          ? JSON.stringify(error.response.data?.message?.detail)
          : error.response.data.message
          ? error.response.data?.message
          : "Unknown error occurred";
        dispatch(translationError(errorMessage));
        return rejectWithValue(errorMessage);
      }
    },
  );

export const createApiThunkImgApi = (
  actionType: string,
  eventCategory: string,
  eventAction: string,
  apiName: "condition-score" | "labelling",
) =>
  createAsyncThunk(
    actionType,
    async (
      arg: {
        formData: any;
        apiKey: string;
        contentType: string;
        endpoint: string;
      },
      { rejectWithValue },
    ) => {
      try {
        const { formData, apiKey, contentType, endpoint } = arg;
        const config = {
          headers: {
            "Content-Type": `${contentType}`,
            Accept: "application/json",
            "x-api-key": apiKey,
          },
        };
        const data = new FormData();
        data.append("image", formData.image);

        axios.defaults.withCredentials = false;
        const url = `https://api.altum.ai/${apiName}`;
        const res = await axios.post<ApiOutput>(
          `${url}/${endpoint}`,
          endpoint === "img" ? data : { url: formData.url },
          config,
        );
        const output = res.data.Output;

        addGAEvent(eventCategory, eventAction);
        // const tagBody = { event: "Used Sustainability API", userId: user.email };
        // await axios.post(`/api/v1/mopsus/tag-event`, tagBody, config);
        return { output, query: { preview: formData.preview } };
      } catch (error: any) {
        const message = getApiError(
          apiName,
          error.response.status || 400,
          error.response.Output,
        );

        toast.error(
          message ||
            "Er is geen respons omdat het geen valide request is op de API",
        );
        return rejectWithValue(error.response?.data);
      }
    },
  );

export const translationError = createAsyncThunk<string, string>(
  "translation/toDutch",
  async (text: string, thunkApi) => {
    try {
      const config = { headers: { "Content-Type": "application/json" } };

      // POST to API server
      const res = await axios.post<string>(
        "/api/v1/mopsus/translator",
        { text },
        config,
      );
      return res.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.message || error.response?.data.message,
      );
    }
  },
);
