import React from "react";
import DashboardContainer from "../Dashboard/components/Container";
import { PropertyProvider } from "./usePropertyContext";
import ChatHistory from "./components/ChatHistory";
import Header from "../Dashboard/components/Header";

type Props = {};

const HistoryPage = (props: Props) => {
  return (
    <DashboardContainer pageTitle={"History"}>
      <PropertyProvider>
        <Header title="Geschiedenis" />
        <ChatHistory />
      </PropertyProvider>
    </DashboardContainer>
  );
};

export default HistoryPage;
