import { InputProps } from "../../../../@types";
import {
  FloorInsulation,
  HeatPump,
  RoofInsulation,
  Shower,
  SolarPanel,
  Ventilation,
  WallInsulation,
  WindowGlass,
} from "../../../../assets/images/api/sustainability/sustainabilityImages";

export const identifyPropertyField: InputProps[] = [
  {
    label: "Postcode*",
    name: "post_code",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "house_number",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "addition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Woningtype",
    name: "house_type",
    type: "select",
    placeholder: "Selecteer woningtype ",
    tooltip:
      "Het woningtype is van invloed op de berekeningen. Geef het huidige woningtype op ",
    options: [
      { label: "2-onder-1-kapwoning", value: 1 },
      { label: "hoekwoning", value: 2 },
      { label: "tussenwoning", value: 3 },
      { label: "vrijstaand", value: 4 },
      { label: "appartment", value: 5 },
    ],
  },
  {
    label: "Bouwjaar",
    name: "build_year",
    type: "number",
    placeholder: "2023",
    tooltip: "Voer het originele bouwjaar in van de woning",
  },

  {
    label: "Totale woonoppervlakte (m2)",
    name: "inner_surface_area",
    type: "number",
    placeholder: "100",
    tooltip:
      "Het oppervlak van de woning heeft invloed op de energetische berekeningen",
  },
  {
    label: "Budget (€)",
    name: "max_investment",
    type: "number",
    placeholder: "10000",
    tooltip:
      "Maximaal toegestaan investeringsbudget. Zonder budget is het bedrag ongelimiteerd",
  },
  {
    label: "Gewenst minimum energielabel",
    name: "target_label",
    type: "slider",
    tooltip:
      "Het opgegeven doellabel als minimum energielabel doel voor het verduurzaming advies",
  },
];

export const searchCriteria = {
  label: "Zoekcriteria",
  name: "search_criteria",
  type: "select",
  placeholder: "Selecteer zoekcriterium",
  tooltip:
    "Het zoekcriterium heeft grote invloed op het advies. Standaard word gezocht naar maatregelen met hoogste rendement",
  options: [
    { label: "Laagste investeringskosten", value: 0 },
    { label: "Grootste energiebesparing", value: 1 },
    { label: "Hoogste rendement op investering", value: 2 },
    { label: "Hoogste baten", value: 3 },
    { label: "Best mogelijke energielabel", value: 4 },
  ],
};

export const propertyStatus = [
  {
    label: "Aantal bewoners",
    name: "inhabitants",
    type: "number",
    placeholder: "2",
    tooltip:
      "Het aantal inwoners heeft invloed op de berekeningen t.a.v verbruik",
  },
  {
    label: "Gasverbruik (m3 p/j)",
    name: "gas_usage",
    type: "number",
    placeholder: "1200",
    tooltip:
      "Het huidige jaarlijkse gasverbruik. Standaard wordt een berekening gedaan o.b.v. woningtype, bouwjaar en oppervlakte.",
  },
  {
    label: "Elektriciteitsverbruik (KwH p/k)",
    name: "electricity_usage",
    type: "number",
    placeholder: "1200",
    tooltip: "Voer het huidige jaarlijkse elektriciteitsverbruik in (kWh)",
  },
];

export const measures = [
  {
    title: "Gevelisolatie",
    subtitle:
      "Isolerend materiaal aanbrengen in de spouw tussen de buiten- en binnenmuur van je woning.",
    description:
      "De ruimte tussen de buitenmuur en de binnenmuur wordt gevuld met isolatiemateriaal (spouwmuurisolatie). Zo ontsnapt er in de winterminder warmte naar buiten en bespaar je gas! In de zomer blijft je huis juist binnen koel en comfortabel",
    image: WallInsulation,
    inputs: [
      {
        label: "Gevelisolatie",
        name: "wall_insulation",
        type: "radio",
        placeholder: "Selecteer huidig isolatieniveau",
        tooltip:
          "Geef de huidige uitgangsituatie op. Als dit niet wordt opgegeven wordt uitgegaan",
        options: [
          { label: "Geen isolatie", value: 0 },
          { label: "Matig (5cm)", value: 1 },
          { label: "Goed (8cm)", value: 2 },
          { label: "Zeer goed (16cm)", value: 3 },
        ],
      },
    ],
  },
  {
    title: "Dakisolatie",
    subtitle:
      "Het dichten van overbodige openingen en het aanbrengen van een isolerende laag.",
    description:
      "Een dak zonder isolatie verliest veel warmte en wordt gezien als een van de grootste energieverspillers in huis. Bij dakisolatie dicht je overbodige openingen en wordt een isolerende laag aangebracht. Zo kun je een groot deel van je warmteverlies terugdringen en bespaar je enorm op je energierekening en woon je comfortabeler.",
    image: RoofInsulation,
    inputs: [
      {
        label: "Dakisolatie",
        name: "roof_insulation",
        type: "radio",
        placeholder: "Selecteer huidig isolatieniveau",
        tooltip:
          "Geef de huidige uitgangsituatie op. Als dit niet wordt opgegeven wordt uitgegaan",
        options: [
          { label: "Geen", value: 0 },
          { label: "Matig (3-5cm)", value: 1 },
          { label: "Goed (8-10cm)", value: 2 },
          { label: "Zeer goed (17cm)", value: 3 },
          { label: "N.V.T", value: 4 },
        ],
      },
    ],
  },
  {
    title: "Vloerisolatie",
    subtitle: "Isolatie aan de onderzijde van de vloer via de kruipruimte.",
    description:
      "Vloerisolatie voorkomt optrekkende kou en vocht uit de bodem. De isolatie zorgt voor een aangenamer klimaat in huis en een stabielere temperatuur waardoor je minder hoeft te verwarmen en dus bespaart op je gasrekening.",
    image: FloorInsulation,
    inputs: [
      {
        label: "Vloerisolatie",
        name: "floor_insulation",
        type: "radio",
        placeholder: "Selecteer huidig isolatieniveau",
        tooltip:
          "Geef de huidige uitgangsituatie op. Als dit niet wordt opgegeven wordt uitgegaan",
        options: [
          { label: "Geen isolatie", value: 0 },
          { label: "Matig (3cm)", value: 1 },
          { label: "Goed (8cm)", value: 2 },
          { label: "Zeer goed (15cm)", value: 3 },
          { label: "N.V.T", value: 4 },
        ],
      },
    ],
  },
  {
    title: "Aantal zonnepanelen (in m2)",
    subtitle:
      "Zonnepanelen zetten zonlicht om naar stroom. Deze stroom kun je zelf gebruiken of terug leveren aan het net.",
    description:
      "Zonnepanelen zetten zonlicht om naar stroom. Deze stroom kun je zelf gebruiken en hoef je niet meer af te nemen van je energie-maatschappij. Zo bespaar je op je energierekening! Bijkomende voordelen zijn het genereren van minder CO2₂-uitstoot, een hogere woningwaarde én een relatief korte terugverdientijd.",
    image: SolarPanel,
    inputs: [
      {
        label: "Aantal zonnepanelen (in m2)",
        name: "solar_panels",
        type: "number",
        image: SolarPanel,
        placeholder: "0",
        tooltip:
          "Het huidige aantal aanwezige zonnepanelen in vierkante meter oppervlak ",
      },
      {
        label: "Wattpiek zonnepanelen per m2",
        name: "solarpanel_watt_peak",
        type: "number",
        placeholder: "212",
        tooltip: "",
      },
    ],
  },
  {
    title: "CV-ketel of warmtepomp",
    subtitle:
      "Diverse type ketels en warmtepompen die de verwarming en warmwatervoorziening in je huis regelen.",
    description:
      "Combiketels verwarmen je huis en verzorgen je warmwatervoorziening. Nieuwere ketels gebruiken warmte efficiënter. Een HR++ cv-ketel verbruikt bijna een kwart minder energie. Daarnaast is de uitstoot van CO2 en stikstofdioxide lager wat ten goede komt aan het milieu.",
    image: HeatPump,
    inputs: [
      {
        name: "installation",
        type: "radio",
        placeholder: "Selecteer huidige ketel of warmtepomp",
        tooltip:
          "Geef de huidige uitgangsituatie op. Als dit niet wordt opgegeven wordt uitgegaan",
        options: [
          { label: "Lokaal gasverw.+geiser", value: 0 },
          { label: "VR-ketel+geiser", value: 1 },
          { label: "CR-combi", value: 2 },
          { label: "VR-combi", value: 3 },
          { label: "HR-combi", value: 4 },
          { label: "VR-combi+zonneboiler", value: 5 },
          { label: "HR-combi+zonneboiler", value: 6 },
          { label: "WP bodem combi", value: 7 },
          { label: "WP lucht combi", value: 8 },
          { label: "HRe-ketel (micro WKK)", value: 9 },
          { label: "Stadsverwarming", value: 10 },
          { label: "Biomassaketel", value: 11 },
          { label: "Hybride warmtepomp", value: 12 },
          { label: "Collectieve warmtepomp", value: 13 },
        ],
      },
      {
        label: "Warmtegebruik",
        name: "heat_usage",
        type: "number",
        placeholder: "1200",
        tooltip:
          "Als er sprake is van stadsverwarming, voer het warmteverbruik in als m3 per jaar",
      },
    ],
  },

  {
    title: "Ventilatie",
    subtitle:
      "Ventilatie met mechanische afvoer, balansventilatie hele woning met WTW of vraaggestuurde ventilatie i.p.v. natuurlijke ventilatie.",
    description:
      "Schone lucht in huis is noodzakelijk voor je gezondheid. Daarvoor is ventilatie nodig, de aanvoer van verse lucht en afvoer van vervuilde lucht en vocht. Er zijn diverse opties.",
    image: Ventilation,
    inputs: [
      {
        label: "Ventilatie",
        name: "ventilation",
        type: "radio",
        placeholder: "Selecteer huidige ventilatie",
        tooltip:
          "Geef de huidige uitgangsituatie op. Als dit niet wordt opgegeven wordt uitgegaan",
        options: [
          { label: "Natuurlijke Ventilatie", value: 0 },
          { label: "Mechanische Ventilati", value: 1 },
          { label: "Gebalanceerde Ventilatie", value: 2 },
          { label: "Decentrale Mechanische Ventilatie", value: 3 },
          { label: "Vraaggestuurde Ventilatie", value: 4 },
        ],
      },
    ],
  },
  {
    title: "Glas woonkamer",
    subtitle: "HR++ glas en triple glas i.p.v. enkel of gewoon dubbel glas.",
    description:
      "HR++ glas en triple glas isoleert veel beter dan enkel glas of gewoon dubbel glas. De warmte blijft in huis en je energierekening gaat omlaag.",
    image: WindowGlass,
    inputs: [
      {
        label: "Glas woonkamer",
        name: "living_room_windows",
        type: "radio",
        placeholder: "Selecteer type glas",
        tooltip:
          "Geef de huidige uitgangsituatie op. Als dit niet wordt opgegeven wordt uitgegaan",
        options: [
          { label: "Enkel glas", value: 0 },
          { label: "Dubbel glas", value: 1 },
          { label: "HR++ glas", value: 2 },
          { label: "Drievoudig glas", value: 3 },
        ],
      },
    ],
  },
  {
    title: "Glas slaapkamers",
    subtitle: "HR++ glas en triple glas i.p.v. enkel of gewoon dubbel glas.",
    description:
      "HR++ glas en triple glas isoleert veel beter dan enkel glas of gewoon dubbel glas. De warmte blijft in huis en je energierekening gaat omlaag.",
    image: WindowGlass,
    inputs: [
      {
        label: "Glas slaapkamers",
        name: "bedroom_windows",
        type: "radio",
        placeholder: "Selecteer type glas",
        tooltip:
          "Geef de huidige uitgangsituatie op. Als dit niet wordt opgegeven wordt uitgegaan",
        options: [
          { label: "Enkel glas", value: 0 },
          { label: "Dubbel glas", value: 1 },
          { label: "HR++ glas", value: 2 },
          { label: "Drievoudig glas", value: 3 },
        ],
      },
    ],
  },
  {
    title: "Huidige douche WTW",
    subtitle:
      "Warmteterugwinning haalt warmte uit wegstromend douchewater, en gebruikt die om koud leidingwater te verwarmen.",
    description:
      " Een douchewarmteterugwinning haalt warmte uit wegstromend douchewater, en gebruikt die om koud leidingwater voor te verwarmen. Daardoor is er minder energie nodig om het water verder op te warmen naar de temperatuur waarmee je doucht.",
    image: Shower,
    inputs: [
      {
        label: "Huidige douche WTW",
        name: "shower",
        type: "radio",
        placeholder: "Selecteer aanwezigheid douche WTW",
        tooltip:
          "Geef de huidige uitgangsituatie op. Als dit niet wordt opgegeven wordt uitgegaan",
        options: [
          { label: "Geen Douche Warmteterugwinning", value: 0 },
          { label: "Douche Warmteterugwinning", value: 1 },
        ],
      },
    ],
  },
];

export const preference = [
  {
    label: "Uitsluiten van maatregelen ",
    name: "lock_measures",
    type: "multiselect",
    placeholder: "Sluit een categorie uit in het advies",
    tooltip: "",
    options: [
      { value: "wall_insulation", label: "Gevelisolatie" },
      { value: "floor_insulation", label: "Vloerisolatie" },
      { value: "roof_insulation", label: "Dakisolatie" },
      { value: "living_room_windows", label: "Isolatie woonkamer ramen" },
      { value: "bedroom_windows", label: "Slaapkamer Ramen" },
      { value: "ventilation", label: "Ventilatie" },
      { value: "solar_panels", label: "Zonnepanelen" },
      { value: "instalation", label: "Installatie" },
      { value: "shower", label: "Warmte Terug Winning Douche" },
    ],
  },
  {
    label: "Uitsluiten individuele maatregelen",
    name: "exclude_measures",
    type: "multiselect",
    placeholder: "Uitsluiten individuele maatregelen",
    tooltip:
      "Sluit specifieke maatregelen uit die niet meegenomen worden in het advies",
    options: [
      { value: 1003, label: "Gevelisolatie niveau 3 zeer goed" },
      { value: 2003, label: "Vloerisolatie niveau 3 zeer goed" },
      { value: 3003, label: "Dakisolatie niveau 3 zeer goed" },
      { value: 4003, label: "Drievoudig glas voor leefruimte" },
      { value: 4103, label: "Drievoudig glas voor slaapruimte" },
      { value: 6001, label: "Ventilatiesysteem 1 mechanische afzuiging" },
      {
        value: 6002,
        label:
          "Ventilatiesysteem 2 gebalanceerde ventilatie met warmteterugwinning",
      },
      {
        value: 6003,
        label: "Ventilatiesysteem 3 decentrale mechanische ventilatie",
      },
      {
        value: 6004,
        label: "Ventilatiesysteem 4 mechanische afzuiging met CO2 vraagsturing",
      },
      { value: 8004, label: "HR-combi" },
      { value: 8005, label: "VR-combi + zonneboiler" },
      { value: 8006, label: "HR-combi + zonneboiler" },
      { value: 8007, label: "WP bodem combi" },
      { value: 8008, label: "WP lucht combi" },
      { value: 8009, label: "HRe ketel" },
      { value: 8029, label: "Biomassaketel" },
      { value: 8030, label: "Hybride warmtepomp" },
      { value: 8031, label: "Collectieve bodemwarmtepomp" },
    ],
  },
  {
    label: "Ketel vervangen toegestaan",
    name: "replace_boiler",
    type: "radio",
    placeholder: "Selecteer vervangen ketel",
    tooltip: "Standaard is het vervangen van de ketel toegestaan",
    options: [
      { label: "Nee", value: "false" },
      { label: "Ja", value: "true" },
    ],
  },
  {
    label: "Warmtepomp toegestaan",
    name: "heat_pump_allowed",
    type: "radio",
    placeholder: "Selecteer mogelijkheid warmtepomp",
    tooltip: "Standaard is het adviseren van een warmtepomp toegestaan  ",
    options: [
      { label: "Nee", value: "false" },
      { label: "Ja", value: "true" },
    ],
  },
  {
    label: "Kozijnen vervangen",
    name: "replace_windowframes",
    type: "radio",
    placeholder: "Selecteer mogelijkheid warmtepomp",
    tooltip: "Standaard is het adviseren van een warmtepomp toegestaan  ",
    options: [
      { label: "Nee", value: "false" },
      { label: "Ja", value: "true" },
    ],
  },
];

export const options = [
  {
    loc: ["body", "data", "heat_pump_allowed"],
    msg: "value is not a valid boolean",
    type: "value_error.strictbool",
  },
  {
    loc: ["body", "data", "replace_boiler"],
    msg: "value is not a valid boolean",
    type: "value_error.strictbool",
  },
  {
    loc: ["body", "data", "house_type"],
    msg: "value is not a valid integer",
    type: "type_error.integer",
  },
];
