import { tryParseInt } from "./tryParseInt";

const convertToEuFormat = (value: number | string) => {
  const [isInt, parsed] = tryParseInt(value);
  if (!isInt || !parsed) {
    return value;
  }

  const decimalCount = (num: number) => {
    const numStr = String(num);
    return numStr.includes(".") ? numStr.split(".")[1].length : 0;
  };

  // const numberValue = parsed ? parsed : parseFloat(value);

  return new Intl.NumberFormat("nl", {
    maximumFractionDigits: 2,
    maximumSignificantDigits: decimalCount(parsed) > 0 ? undefined : 4,
  }).format(parsed);
};

export default convertToEuFormat;
